
 

<sidenav class="container-p-x bg-white sidenav-white" [onClose]="onMenuClose.bind(this)" [onOpened]="onMenuActionDone.bind(this)" [onClosed]="onMenuActionDone.bind(this)" style="border-right: 2px solid #eee; color: #6d6d6d !important; font-size: 10pt; padding-top: 10px;" [hidden]='showNav ? false : true'>
    <div style="width: 100%">
     <a href="javascript:void(0)" class="sidenav-link text-large" (click)="toggleSidenav()" style="width: 30px; display: inline;">
        <i style="padding-left:3px;" class="fal fa-bars align-right"></i>
      </a>
      
      <span *ngIf="!catalog.isRefreshing && !catalog.needConnection" (click)="refresh()" class="fal fa-sync fa-lg" style="display: inline; cursor: pointer; margin-left: 270px;font-size: 13pt;"></span>
      <div *ngIf="refreshing && catalog.isRefreshing" class="spinner-border spinner-border-sm" role="status" style="margin: auto; display: inline-block; float: right; margin-right: 5px; margin-top: 5px;">
        <span  class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- Links -->
    <div class="sidenav-inner">
          <!-- <div style="text-align: center;  padding-top: 10px; padding-left: 25px">
              <label class="switcher switcher-lg">
                <input type="checkbox" class="switcher-input" (click)="toggleMenuLayout()" >
                <span class="switcher-indicator">
                  <span class="switcher-yes" style="background-color: #808080"></span>
                  <span class="switcher-no" style="background-color: #808080"></span>
                </span>
                <span class="switcher-label" style="color:white; font-size: small">{{menuType}} View</span>
              </label>
          </div> -->

          <sidenav-router-link route="/explore/overview" icon="fal fa-mountains" *ngIf="catalog.menuItems.length > 0 && isExplore ? true : false" linkClass="extra-pad" >
          Overview
          </sidenav-router-link>
          <sidenav-divider class="mb-1" *ngIf="catalog.menuItems.length > 0 && isExplore ? true : false" ></sidenav-divider>

           <!-- Search -->
      
        <div style="padding-left: 3px; position:relative !important" *ngIf="catalog.menuItems.length > 0 && !catalog.isRefreshing ? true : false" >
          <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
            <i class="fal fa-search navbar-icon align-middle"></i>
            <span class="navbar-search-input pl-2">
              <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic; margin-left: 3px !important;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" >
            </span>
            <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
          </label> -->
          <div *ngIf="searchList['count'] >= 0" style="text-align: left; padding-bottom:5px; margin-bottom: 5px; border-bottom: 1px solid #3e3e3e;">
            Found {{searchList['count']}} results
          </div>
          <div class="search" *ngIf="searchList['count'] > 0 ? true : false" style="font-size: small; overflow-y: scroll; max-height: 300px"  (scroll)="onScroll($event)">
            <dl *ngIf="searchList.databases.length > 0 ? true : false">
              <dt>DATABASES ({{searchList.databases.length}})</dt>
              <ng-container *ngIf="isExplore">
                <dl class="searchResultItem" *ngFor="let db of searchList.databases" routerLink="{{db.link}}" >{{db.text}}</dl>
              </ng-container>
              <ng-container *ngIf="!isExplore">
                <dl class="searchResultItem" *ngFor="let db of searchList.databases" >{{db.text}}</dl>
              </ng-container>
            </dl>
              
            <dl *ngIf="searchList.schemas.length > 0 ? true : false">
              <dt>SCHEMAS ({{searchList.schemas.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let sch of searchList.schemas" routerLink="{{sch.link}}">{{sch.database}} . {{sch.name}}</dl>
            </dl>

            <dl *ngIf="searchList.tables.length > 0 ? true : false">
              <dt>TABLES ({{searchList.tables.length}})</dt>
              <dd *ngFor="let tb of searchList.tables">
                <dl>
                  <dt class="searchResultItem" ngbTooltip="{{tb.database}}.{{tb.schema}}.{{tb.name}}" routerLink="{{tb.link}}" placement="right" data-container="body">
                  {{shortenText(tb.database +'.'+ tb.schema+ '.' +tb.name, 'search')}} 
                  </dt>
                   <dd style="padding-left: 25px" class="searchResultItem" *ngFor="let c of tb.columns" routerLink="{{c.link}}" [queryParams]="{column: c.column}">
                   {{c.name}}
                  </dd>
                </dl>
              </dd>
            </dl>
          
            <dl *ngIf="searchList.functions.length > 0 ? true : false">
              <dt>FUNCTIONS ({{searchList.functions.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let f of searchList.functions" routerLink="{{f.link}}" ngbTooltip="{{f.database}} . {{f.schema}} .{{f.name}}" placement="right" data-container="body">
                {{shortenText(f.database +'.'+ f.schema+ '.' +f.name, 'search')}}
              </dl>
            </dl>

            <dl *ngIf="searchList.fileFormats.length > 0 ? true : false">
              <dt>FILE FORMATS ({{searchList.fileFormats.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let f of searchList.fileFormats" routerLink="{{f.link}}" ngbTooltip="{{f.database}} . {{f.schema}} .{{f.name}}" placement="right" data-container="body">
                {{shortenText(f.database +'.'+ f.schema+ '.' +f.name, 'search')}}
              </dl>
            </dl>

            <dl *ngIf="searchList.pipes.length > 0 ? true : false">
              <dt>PIPES ({{searchList.pipes.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let p of searchList.pipes" routerLink="{{p.link}}" ngbTooltip="{{p.database}} . {{p.schema}} .{{p.name}}" placement="right" data-container="body">
                {{shortenText(p.database +'.'+ p.schema+ '.' +p.name, 'search')}}
              </dl>
            </dl>
            <dl *ngIf="searchList.stages.length > 0 ? true : false">
              <dt>STAGES ({{searchList.stages.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let s of searchList.stages" routerLink="{{s.link}}" ngbTooltip="{{s.database}} . {{s.schema}} .{{s.name}}" placement="right" data-container="body">
                {{shortenText(s.database +'.'+ s.schema+ '.' +s.name, 'search')}}
              </dl>
            </dl>

             <dl *ngIf="searchList.sequences.length > 0 ? true : false">
              <dt>SEQUENCES ({{searchList.sequences.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let s of searchList.sequences" routerLink="{{s.link}}" ngbTooltip="{{s.database}} . {{s.schema}} .{{s.name}}" placement="right" data-container="body">
                {{shortenText(s.database +'.'+ s.schema+ '.' +s.name, 'search')}}
              </dl>
            </dl>

            <dl *ngIf="searchList.enabledRoles.length > 0 ? true : false">
              <dt>ENABLED ROLES ({{searchList.enabledRoles.length}})</dt>
              <dl class="searchResultItem"  *ngFor="let e of searchList.enabledRoles" routerLink="{{e.link}}">{{e.name}}</dl>
            </dl>
           
          </div>
          <div *ngIf="searchList['count'] > 12 ? true : false" style="text-align: center">
            <i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg"></i>
            <i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg"></i>
          </div>
        </div>
        
        <!-- <sidenav-divider class="mb-1" *ngIf="catalog.menuItems.length > 0 ? true : false" ></sidenav-divider> -->
         <ng-container *ngIf="!catalog.isEditor && catalog.isRefreshing ? true : false"  >
             <div class="align-self-center" style="margin-top: 40px">
                  <div style="width: 165px;">
                    Building expolore catalog...
                  </div>
                 <div style="height: 75px; width: 75px; margin:auto">
                    <img height="50px" width="50px" src="./assets/img/snowguide_loading_main.gif">
                  </div>
            </div>
          </ng-container>
          <ng-container *ngIf="catalog.isEditor && catalog.isRefreshing && !catalog.needConnection ? true : false"  >
             <div class="align-self-center" style="margin-top: 40px">
                  <div style="width: 160px; margin: auto;" *ngIf="!catalog.waitingOnConnection && catalog.start">
                    Retrieving user catalog...
                  </div>
                  <div style="width: 160px; margin: auto;" *ngIf="!catalog.waitingOnConnection && !catalog.start">
                    Building user catalog...
                  </div>
                  <div style="width: 160px; margin: auto;" *ngIf="catalog.waitingOnConnection && !catalog.needConnection">
                    Waiting on connection...
                  </div>
                 <div style="height: 75px; width: 75px; margin:auto">
                    <img height="50px" width="50px" src="./assets/img/snowguide_loading_main.gif">
                  </div>
            </div>
          </ng-container>
      <div style="color: white; width: 300px;">
        
        <div *ngIf="!catalog.hasCatalog && !closeCatalogWarning && !isCollapsed && isSetup && !catalog.isEditor && !catalog.isRefreshing" class="pulse blue" style="padding: 15px; border-radius: 10px; font-size: 13pt;">
          <div 
            style="margin-top: -5px; margin-right: -10px; width: 100%; text-align: right; height: 10px; cursor: pointer;"
            (click)="closeCatalogWarning = !closeCatalogWarning; toggleSidenav();"
          >
            <span class="badge indicator"><i class="far fa-times fa-2x"></i></span>
          </div>
          <div style="margin-top: 10px">
            Your <span style="font-weight: bold;"> Data Catalog </span>  <i class="fal fa-mountains" style="font-weight: bold; padding-left: 2px; padding-right: 2px;"></i> is being built right now! 
          </div>
          <div style="margin-top: 20px;">
            Click the refresh icon <i class="fal fa-sync" style="font-weight: bold; padding-left: 2px; padding-right: 2px;"></i> above in the next several minutes.  
          </div>
        </div>
        <div *ngIf="catalog.needConnection && catalog.isEditor" class="pulse red" style="padding: 15px; border-radius: 10px; font-size: 13pt;">
          <div style="margin-top: 10px">
            Create a connection to Snowflake and select a role to see your objects.  
          </div>
        </div>
        <div class="pulse red" *ngIf="!catalog.isEditor && !isSetup" style="padding: 15px; border-radius: 10px; font-size: 13pt;">
          <div style="margin-top: 10px; text-align: center;"  >
            Explore your data warehouse
             <button style="margin-top: 10px;background: white; font-weight: bold;"  type="button" class="btn btn-light" (click)="routeToConnections();">
              Setup Now
            </button>
          </div>
        </div>
      </div>
      

      
      <div [ngClass]="showColumns || showDatabase || showSchema? 'main-sidenav-items-with-columns' : 'main-sidenav-items'">
        <div class="main-sidenav-items-inner">
          <ng-container  *ngFor='let item of catalog.menuItems'  >
             <ng-container *ngIf="item.text == 'Databases' ? true : false">
                <ng-container  *ngIf="checkSubmenu(item)">
                  <ng-container *ngFor='let subitem of item.submenu'>
                    <!-- LAYER 1 -->
                    <sidenav-router-link *ngIf="!checkSubmenu(subitem)" route="{{subitem.link}}" [active]="isActive(subitem.link)" icon="{{item.icon}}">
                      {{subitem.text}}
                    </sidenav-router-link>
                  
                    <!-- LAYER 2 -->
                    <ng-template #rightClickSubitem>
                        
                        <ul style="list-style-type: none; padding: 0; margin: 0; font-size: 11pt; ">
                          <li class="download-list">Some meta data</li>
                          <li (click)="refresh(subitem)" class="download-list"><i class="fal fa-sync fa-lg" style="color: green; padding-left: 10px;"></i>&nbsp;&nbsp;Refresh</li>
                        </ul>
                      </ng-template>
                    <sidenav-menu  *ngIf='checkSubmenu(subitem)' 
                    text="{{subitem.text}}" 
                    [open]="isMenuOpen(subitem.link)" 
                    [id]="subitem.text" 
                    icon="{{item.icon}}" 
                    (click)="triggerShowDatabase(subitem)"
                    >

                      
                            
                      <ng-container *ngFor='let subitem2 of subitem.submenu'>
                        <!-- <sidenav-router-link *ngIf='!checkSubmenu(subitem2)'  route="{{subitem2.link}}" [active]="isActive(subitem2.link)">
                          {{subitem2.text}}
                        </sidenav-router-link> -->
                         <!-- LAYER 3 -->
                         <ng-container *ngIf="subitem2.text != 'Schemas'? true : false">
                           <sidenav-menu *ngIf="checkSubmenu(subitem2)" icon="{{subitem2.icon}}" text="{{buildText(subitem2)}}" [open]="isMenuOpen(subitem2.link)" [active]="isMenuActive(subitem2.link)" [id]="subitem2.text" linkClass="submenu-custom">
                            <ng-container *ngFor='let subitem3 of subitem2.submenu'>

                               <!-- LAYER 4 -->
                              <sidenav-menu *ngIf='checkSubmenu(subitem3)' text="{{subitem3.text}}" [active]="isMenuActive(subitem3.link)" [open]="isMenuOpen(subitem3.link)"  icon="{{subitem3.icon}}" [id]="subitem3.text">
                                <ng-container *ngFor='let subitem4 of subitem3.submenu'>

                                  <!-- LAYER 5 -->
                                  <sidenav-menu  *ngIf='checkSubmenu(subitem4)' text="{{buildText(subitem4)}}" [active]="isMenuActive(subitem4.link)" [open]="isMenuOpen(subitem4.link)"  icon="{{subitem4.icon}}" [disabled]="isDisabled(subitem4)">
                                      <ng-container *ngFor='let subitem5 of subitem4.submenu'>
                                          <sidenav-router-link *ngIf='isExplore && !checkSubmenu(subitem5)' route="{{subitem5.link}}" [active]="isActive(subitem5.link)" tooltipText="{{subitem5.text}}">
                                            {{shortenText(subitem5.text, 'menu')}}
                                          </sidenav-router-link>
                                          <sidenav-router-link *ngIf='!isExplore && !checkSubmenu(subitem5)' [active]="isActiveAlt(subitem5)" style="padding-left: 8px !important" tooltipText="{{subitem5.text}}">
                                            {{shortenText(subitem5.text, 'menu')}}
                                          </sidenav-router-link>
                                      </ng-container>   
                                  </sidenav-menu>
                                  <sidenav-router-link *ngIf='isExplore && !checkSubmenu(subitem4)' route="{{subitem4.link}}" [active]="isActive(subitem4.link)" icon="{{subitem2.icon}}" [ngClass]="getClass(subitem4)" [disabled]="isDisabled(subitem4)" >
                                    {{buildText(subitem4)}}
                                  </sidenav-router-link>
                                   <sidenav-router-link *ngIf='!isExplore && !checkSubmenu(subitem4)' icon="{{subitem2.icon}}" [ngClass]="getClass(subitem4)" [disabled]="isDisabled(subitem4)" >
                                    {{buildText(subitem4)}}
                                  </sidenav-router-link>
                                </ng-container>
                              </sidenav-menu>
                              <sidenav-router-link *ngIf="isExplore && !checkSubmenu(subitem3)" route="{{subitem3.link}}" [active]="isActive(subitem3.link)" icon="{{subitem2.icon}}" tooltipText="{{subitem3.text}}">
                                {{shortenText(subitem3.text, 'menu')}}
                              </sidenav-router-link>
                               <sidenav-router-link linkClass="submenu-custom" *ngIf="!isExplore && !checkSubmenu(subitem3)" icon="{{subitem3.icon}}" tooltipText="{{subitem3.text}}" [active]="subitem3.active">
                                {{shortenText(subitem3.text, 'menu')}}
                              </sidenav-router-link>
                            </ng-container>
                          </sidenav-menu>
                        </ng-container>
                        <ng-container *ngIf="subitem2.text == 'Schemas' ? true : false">
                          <ng-container *ngFor='let subitem3 of subitem2.submenu'>

                             <!-- LAYER 4  For Schemas-->
                            <sidenav-menu text="{{subitem3.text}}" [active]="isMenuActive(subitem3.link)" [open]="isMenuOpen(subitem3.link)"  icon="{{subitem2.icon}}" [id]="subitem3.text" linkClass="submenu-custom" (click)="triggerShowSchema(subitem3); catalog.getObjects(subitem3);"><!-- -->
                              <ng-container *ngIf="subitem3.submenu.length > 0" (click)="stopPropagation();" class="submenu-custom">
                                <label class="nav-item navbar-text navbar-search-box p-0 active" style="margin-left: 55px;">
                                  <i class="fal fa-search navbar-icon align-middle"></i>
                                  <span class="navbar-search-input pl-2">
                                    <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic; margin-left: 3px !important;"  (input)="onSchemaSearchChange(subitem3, $event.target.value); $event.stopPropagation();" [(ngModel)]="search" name="search" autocomplete="off" >
                                  </span>
                                  <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="schemaObjectsReset(subitem3); $event.stopPropagation();" style="cursor: pointer;"></i>
                                </label>
                              </ng-container>
                              <ng-container *ngFor='let subitem4 of subitem3.submenu'>
                                    <ng-container *ngFor='let subitem5 of subitem4.submenu'>
                                        <sidenav-router-link *ngIf='isExplore' route="{{subitem5.link}}" [active]="isActive(subitem5.link)" tooltipText="{{subitem5.text}}" icon="{{subitem4.icon}}" linkClass="submenu-custom" (click)="triggerShowColumns(subitem5); $event.stopPropagation();">
                                          {{shortenText(subitem5.text, 'menu')}}
                                        </sidenav-router-link>
                                         <sidenav-router-link *ngIf='!isExplore' [active]="subitem5.name == selectedItem.name" tooltipText="{{subitem5.text}}" icon="{{subitem4.icon}}" linkClass="submenu-custom" (click)="triggerShowColumns(subitem5);$event.stopPropagation();">
                                          {{shortenText(subitem5.text, 'menu')}}
                                        </sidenav-router-link>
                                    </ng-container>  
                                <sidenav-router-link *ngIf="!checkSubmenu(subitem4)" route="{{subitem4.link}}" [active]="isActive(subitem4.link)" icon="{{subitem4.icon}}" [ngClass]="getClass(subitem4)" [disabled]="isDisabled(subitem4)" tooltipText="{{subitem4.text}}">
                                  <div *ngIf="!catalog.loadingTables">{{buildText(subitem4)}}</div>
                                  <div *ngIf="catalog.loadingTables" class="spinner-border spinner-border-sm" role="status" style="margin: auto; display: inline-block; float: right;">
                                      <span  class="sr-only">Loading...</span>
                                  </div>
                                </sidenav-router-link>

                              </ng-container>
                              <ng-container *ngIf="(subitem3.submenu[0].submenu.length + subitem3.submenu[1].submenu.length) < (subitem3.meta.TABLE_COUNT + subitem3.meta.VIEW_COUNT) && !catalog.loadingTables && search.length == 0"  >
                                <div (click)="catalog.getObjects(subitem3, false, true);$event.stopPropagation()" style=" font-size: 11pt; cursor: pointer; color: #26B4FF; text-align: center;">
                                  <span>Next 50 </span> 
                                  <span *ngIf="!catalog.nextLoading" style="padding-left: 5px;" class="fal fa-chevron-down"></span>
                                  <span *ngIf="catalog.nextLoading" style="padding-left: 5px;">
                                    <div class="spinner-border spinner-border-sm" role="status">
                                      <span  class="sr-only">Loading...</span>
                                    </div>
                                  </span>
                                </div>
                              </ng-container> 
                              

                            </sidenav-menu>
                             <!--  <sidenav-router-link *ngIf="!checkSubmenu(subitem3)" route="{{subitem3.link}}"  icon="{{subitem2.icon}}" [active]="isActive(subitem3.link)" tooltipText="{{subitem3.text}}" linkClass="submenu-custom">
                                {{shortenText(subitem3.text, 'menu')}}
                              </sidenav-router-link> -->
                            </ng-container>
                        </ng-container>
                      </ng-container>
                      <sidenav-divider class="mb-1"></sidenav-divider>
                    </sidenav-menu>
                  </ng-container>
                </ng-container>
            </ng-container>
          <ng-container  *ngIf="item.text == 'Databases' ? false : true">
            <sidenav-menu [id]="item.text" text="{{item.text}}" [open]="isMenuOpen(item.link)" [active]="isMenuActive(item.link)" icon="{{item.icon}}" *ngIf="checkSubmenu(item)">
                <ng-container  *ngIf="checkSubmenu(item)">
                  <ng-container *ngFor='let subitem of item.submenu'>
                    <!-- LAYER 1 -->
                    <sidenav-router-link *ngIf="!checkSubmenu(subitem)" route="{{subitem.link}}" [active]="isActive(subitem.link)" icon="{{item.icon}}" tooltipText="{{subitem.text}}">
                      {{subitem.text}}
                    </sidenav-router-link>
                  </ng-container>
                </ng-container>
            </sidenav-menu>
        </ng-container>
      </ng-container>
      </div>
    </div>

  
  <div *ngIf="showColumns" class="sidenav-columns">
      <div style="padding-bottom: 10px;">
        <div style="font-weight: bold; color: black;  display:inline-block; width: 200px; word-break: break-all;"><i class="fal fa-table" style="padding-right: 5px;"></i>
           {{selectedItem.name}}
        </div>
        <div style="display:inline-block; text-align: right; float: right; cursor: pointer; color: #c1c1c1;" (click)="triggerShowColumns(false);"><i class="fal fa-times fa-lg"></i>
        </div>
         <div style="display:inline; float: right; margin-right: 10px;">
          <button class="btn btn-xs btn-primary" style="font-weight: bold;">
            <i class="fal fa-sync"></i>
          </button>
        </div>
        <div style="display:inline; float:right;">
          <div ngbDropdown #scriptDropdown="ngbDropdown"  placement="bottom-start" class="d-inline-flex" style="margin-right: 5px;">
            <button class="btn btn-xs btn-primary" [ngClass]="tableScriptsLoading ? 'disabled' : ''" style="font-weight: bold" id="dropdownScripts" ngbDropdownToggle [disabled]="tableScriptsLoading || scripts.length == 0">
                <i  *ngIf="!tableScriptsLoading"  class="fal fa-code"></i>
                <div *ngIf="tableScriptsLoading" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important;">
              <span  class="sr-only">Loading...</span>
            </div>
            </button>

            <div ngbDropdownMenu  aria-labelledby="dropdownScripts" style="min-width: 1rem; width: 113px;">
              <div *ngFor="let script of scripts">
                <button ngbDropdownItem (click)="openScriptModal(scriptModal, { windowClass: 'modal-xl animate', centered: true }, script.key)" style="float: left; margin-right:0px; padding-left: 10px;">
                  <span [ngClass]="script.class" style="width: 22px;"></span>{{script.display}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-size: 9pt;">
         <div style="display: inline-block; padding-right: 10px;" *ngIf="selectedItem.obj != 'views'">
            <!-- <span class="fal fa-list-ol" style="padding-right: 3px;"></span> -->
            <span style="font-weight: 600;">{{selectedItem.meta.ROW_COUNT | number:'1.0':'en-US'}}</span>
            <span >ROWS</span>
          </div>
          <div style="display: inline-block;" [ngStyle]="{'float': selectedItem.obj != 'views' ? 'right' : 'none'}">
            <!-- <span class="fal fa-user-lock" style="padding-right: 3px;"></span> -->
            <span >OWNER:</span>
            <span style="font-weight: 600;">{{selectedItem.meta.TABLE_OWNER}}</span>
          </div>
      </div>
      
      <div style="margin-bottom: 5px; border-bottom: 1px solid #f2f2f2;  width: 100%;padding-top: 10px; font-size: 9pt;">
        <span class="fal fa-columns" style="padding-right: 3px;"></span>
        <span >COLUMNS:</span>
        <span *ngIf="!columnsLoading" style="font-weight: 600;"> {{selectedItem.columns.length}}</span>
        <span *ngIf="columnsLoading">
          <div class="spinner-border spinner-border-sm" role="status" style="margin: auto; font-size: 9pt">
              <span  class="sr-only">Loading...</span>
            </div>
        </span>
        
      </div>
      <div class="row column-hover" *ngFor="let col of selectedItem.columns" style="font-size: 9pt;">
        <div class="col-6" style="color: #363636; " routerLink="{{col['link']}}" [queryParams]="{column: col['COLUMN_NAME']}">

       
                   
        {{shortenText(col['COLUMN_NAME'], 'column')}}


      </div>
        <div class="col-6 text-right" >{{col['COLUMN_DATA_TYPE']}}</div>
      </div>
       <ng-template #columnInfo>
          <div style="padding:10px 20px; font-size: 11pt;">
            Column options
           
          </div>
       </ng-template>
  </div>


  <div *ngIf="showSchema && !showColumns && selectedItem.meta"  class="sidenav-columns">
      <div style="padding-bottom: 15px;">
        <div style="font-weight: bold; color: black;  display:inline; "><i class="fal fa-sitemap" style="padding-right: 5px;"></i>{{selectedItem.name}}</div>

        <div style="display:inline; text-align: right; float: right; cursor: pointer; color: #c1c1c1;" (click)="triggerShowSchema(false);"><i class="fal fa-times fa-lg"></i></div>
        <div style="display:inline; float: right; margin-right: 15px;">
          <button class="btn btn-xs btn-primary" style="font-weight: bold; float:right;" (click)="refreshSchema(selectedItem)">
            <i *ngIf="!object.refreshing" class="fal fa-sync"></i>
            <div *ngIf="object.refreshing" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important;">
              <span  class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
      <div style="font-size: 9pt;">
        <div class="row">
          <div style="padding-right: 20px; padding-left: 0.75rem; display: inline-block;">
            <span class="fal fa-table" style="padding-right: 3px;"></span>
            <span>TABLES:</span>
            <span style="font-weight: 600;">{{selectedItem.meta.TABLE_COUNT}}</span>
          </div>
          <div>
            <span class="fal fa-window-maximize" style="padding-right: 3px;"></span>
            <span style="font-size: 9pt;">VIEWS:</span>
             <span style="font-weight: 600;">{{selectedItem.meta.VIEW_COUNT}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 15px;">
          <div class="col" style="display: inline-block;">
            <div style="font-weight: 500; display: inline-block; width: 140px;">OWNER:</div>
            <div style="display: inline-block;">{{selectedItem.meta.SCHEMA_OWNER}}</div>
          
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 140px;">CREATED: </span>
            <span style="display: inline-block;">{{selectedItem.meta.CREATED | date: 'mediumDate'}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 140px;">LAST ALTERED: </span>
            <span style="display: inline-block;">{{selectedItem.meta.LAST_ALTERED | date: 'mediumDate'}}</span>
            </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 140px;">IS MANAGED ACCESS: </span>
            <span style="display: inline-block;">{{selectedItem.meta.IS_MANAGED_ACCESS}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 140px;">IS TRANSIENT: </span>
            <span style="display: inline-block;">{{selectedItem.meta.IS_TRANSIENT}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 140px;">RETENTION TIME: </span>
            <span style="display: inline-block;">{{selectedItem.meta.RETENTION_TIME}} day(s)</span>
            </div>
        </div>
      </div>
  </div>

  <div container="body" *ngIf="showDatabase && !showColumns && selectedItem.meta"  class="sidenav-columns">

      <div style="padding-bottom: 15px;">
        <div style="font-weight: bold; color: black;  display:inline; "><i class="fal fa-database" style="padding-right: 5px;"></i>{{selectedItem.name}}</div>

        <div style="display:inline; text-align: right; float: right; cursor: pointer;color: #c1c1c1;" (click)="triggerShowDatabase(false);"><i class="fal fa-times fa-lg"></i></div>
        <div style="display:inline; float: right; margin-right: 15px;">
          <button class="btn btn-xs btn-primary" style="font-weight: bold; float:right;" (click)="refreshDatabase(selectedItem)">
           <i *ngIf="!object.refreshing" class="fal fa-sync"></i>
            <div *ngIf="object.refreshing" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important;">
              <span  class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
      <div style="font-size: 9pt;">
        <div class="row">
          <div style="padding-right: 11px; padding-left: 0.75rem; display: inline-block;">
            <span class="fal fa-sitemap" style="padding-right: 1px;"></span>
            <span style="font-size: 9pt;">SCHEMAS:</span>
            <span style="font-weight: 600;">{{selectedItem.meta.SCHEMA_COUNT | number:'1.0':'en-US' }}</span>
          </div>
          <div style="padding-right: 11px; display: inline-block;">
            <span class="fal fa-table" style="padding-right: 1px;"></span>
            <span>TABLES:</span>
            <span style="font-weight: 600;">{{selectedItem.meta.TABLE_COUNT | number:'1.0':'en-US' }}</span>
          </div>
          <div style="padding-right: 0px; display: inline-block;">
            <span class="fal fa-window-maximize" style="padding-right: 1px;"></span>
            <span style="font-size: 9pt;">VIEWS:</span>
             <span style="font-weight: 600;">{{selectedItem.meta.VIEW_COUNT | number:'1.0':'en-US' }}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 15px;">
          <div class="col" style="display: inline-block;">
            <div style="font-weight: 500; display: inline-block; width: 110px;">OWNER:</div>
            <div style="display: inline-block;">{{selectedItem.meta.DATABASE_OWNER}}</div>
          
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 110px;">CREATED: </span>
            <span style="display: inline-block;">{{selectedItem.meta.CREATED | date: 'mediumDate'}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 110px;">LAST ALTERED: </span>
            <span style="display: inline-block;">{{selectedItem.meta.LAST_ALTERED | date: 'mediumDate'}}</span>
            </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 110px;">IS TRANSIENT: </span>
            <span style="display: inline-block;">{{selectedItem.meta.IS_TRANSIENT}}</span>
          </div>
        </div>
        <div class="row" style="padding-top: 5px;">
          <div class="col">
            <span style="font-weight: 500; display: inline-block; width: 110px;">RETENTION TIME: </span>
            <span style="display: inline-block;">{{selectedItem.meta.RETENTION_TIME}} day(s)</span>
            </div>
        </div>
      </div>
    </div>
  </div>

<ng-template #scriptModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fal fa-code fa-lg"></span>&nbsp;&nbsp;
          {{script.title}}<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body">
        <codemirror 
          #scriptcode
          [(ngModel)]="script.code"
          [config]="script.config"
          (focus)="onFocus()"
          (blur)="onBlur()"
          >
        </codemirror>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="openEditor(script.code)"><span class="fal fa-copy"></span>&nbsp;&nbsp;Open in Editor</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #mergeModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fal fa-code fa-lg"></span>&nbsp;&nbsp;
          {{script.title}}<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-9" style="border-right: 1px solid #ccc;">
            <codemirror 
              #scriptcode
              [(ngModel)]="script.code"
              [config]="script.config"
              (focus)="onFocus()"
              (blur)="onBlur()"
              >
            </codemirror>
          </div>
          <div class="col-3">
            <form>
              <label class="form-label">Table Name or Subquery</label>
              <textarea class="form-control" (input)="onSearchChange($event.target.value)" placeholder="select * from [TABLE]

or    

[DATABASE].[SCHEMA].[TABLE]"
              style="min-height: 270px"></textarea>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="openEditor(script.code)"><span class="fal fa-copy"></span>&nbsp;&nbsp;Open in Editor</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

