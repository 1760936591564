import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { MenuService } from './menu/menu.service';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SearchPipePipe } from './pipes/search-pipe.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ConvertSecondsPipe } from './pipes/convert-seconds.pipe';
// import { SocketService } from './websocket/socket.service';

@NgModule({
  declarations: [SearchFilterPipe, SearchPipePipe, DateAgoPipe, ConvertSecondsPipe],
  imports: [
    CommonModule
  ],
  providers: [
  ],
  exports: [CommonModule, SearchFilterPipe, SearchPipePipe, DateAgoPipe, ConvertSecondsPipe]
})
export class SharedModule {

	static forRoot() {
        return {
            ngModule: SharedModule,
            // providers: [ //services that you want to share across modules
            //     SharedService,
            //     SharedService2
            // ]
        }
    }
}
