
<div class="layout-container">
	<div class="layout-content" style="justify-content: normal;">
		
			<div #dtContainer class="card" style="margin: 25px; border-radius: 10px;">
				<div style="background-color: white; border: 1px solid #eee; border-radius: 10px; ">
					<div class="row" style=" padding: 15px 10px 15px">
						<div class="col-md-2 my-auto text-left">
							<i class="fa-thin fa-list-check fa-2x" style="padding-right:5px;"></i>
							<span style=" font-size: 22pt;font-weight: 100;">Jobs: </span>
							 <span style="font-weight: 100;font-size: 22pt">{{jobs.length}}</span>
						</div>
						<div class="col-md-6 my-auto text-left">
		                
						 <label class="nav-item navbar-text navbar-search-box p-0 active">
							<i class="ion ion-ios-search navbar-icon align-middle"></i>
					          <span class="navbar-search-input pl-2" style="width: 400px !important">
					            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important">
					          </span>
					          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
					        </label>
						</div>
					
						<div class="col-md-4 my-auto text-right"> 
							<div style="width: 100%;">
								<div class="automate-job-nav" (click)="refresh()">
									<div style="vertical-align: middle;" class="fa-thin fa-sync fa-2x"></div>
								</div>
								<div  class="automate-job-nav">
						              <button class="btn btn-primary btn-sg" type="button" (click)="openNewJobModal(newJobModal, { windowClass: 'modal-xl animate', centered: true })">
								            New Job <i class="fa-regular fa-grid-2-plus" style="padding-left: 5px;"></i>
								          </button>
					      		</div>
					      		
					      		
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col">
			        		<ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="jobsTable"></ngx-loading-bar>
								<table class="table" style="margin-bottom:0px !important;">
									<thead style="border-color: #979899; font-weight: bold; table-layout: fixed;">
									    <tr style="border-color: #979899;" >
									      <th scope="col-sm" style="font-weight: bold;">
									      	<span style="float: left">Name</span>
									      	<span  style="color:#a9abac;" class="sort-arrow" (click)="sortColumns('name')"><i class="fa fa-sort-alt"></i></span>
									      </th>
									      <th scope="col" style="font-weight: bold">
									      	<span style="float:left">Owner</span>
									      	<span class="sort-arrow" (click)="sortColumns('owner')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
									      </th>
									      <th scope="col" style="font-weight: bold">
									      	<span style="float:left">Description</span>
									      	
									      </th>
									       <th scope="col" style="font-weight: bold; width: 130px;">
									      	<span style="float:left">Status</span>
									      	<span class="sort-arrow"  (click)="sortColumns('job_status')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
									      </th>
									       <th scope="col" style="font-weight: bold">
									      	<span style="float:left">Last Run</span>
									      	
									      </th>
									       <th scope="col-sm" style="font-weight: bold">
									      	<span style="float:left">Next Run</span>
									      	
									      </th>
									       <th scope="col" style="font-weight: bold;">
									      	<span style="float:left">Enabled</span>
									      	<span class="sort-arrow"  (click)="sortColumns('enabled')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
									      </th>
									       <th scope="col" style="font-weight: bold; width: 170px"></th>
									    </tr>
									  </thead>
									  <tbody style="overflow: auto">
									  	<ng-container *ngFor="let job of jobs">
										  	<tr class="clickable-row" (click)="expandJob(job.job_id)" [ngClass]="{'job-open-header': job.expanded}">
										  		<!-- [ngClass]="[? : '']" -->
										  	  	<td class="align-middle">{{job.name}}</td>
								                <td class="align-middle">{{job.owner}}</td>
								                <td class="align-middle">{{job.description}}</td>
								            
								                
								                <!-- <td class="align-middle">{{job.schedule}}</td> -->
								                <td class="align-middle">
								                	<div class="text-center" style="width:120px; padding:4px; font-size: 9pt;" [ngClass]="{
								                	'gray-border': job.enabled == 0 || !job.enabled,
					                            	'blue-border': job.job_status == 'New Job' || job.job_status == 'Running', 
					                            	'purple-border': (job.enabled == 1 || job.enabled) && (job.job_status == null ||job.job_status == 'Scheduled'),
					                            	'green-border': job.job_status == 'Succeeded',
					                                'red-border': job.job_status == 'Failed' || job.job_status == 'Stopped' || job.job_status == 'Stopping'
					                            	}"

					                            	>
					                            		<span class="dot" [ngClass]="{
					                            		'gray-dot': job.enabled == 0 || !job.enabled,
					                                	'blue-dot': job.job_status == 'New Job' || job.job_status == 'Running', 
					                                	'purple-dot': (job.enabled == 1 || job.enabled) && (job.job_status == null || job.job_status == 'Scheduled'),
					                                	'red-dot': job.job_status == 'Failed' || job.job_status == 'Stopped' || job.job_status == 'Stopping',
					                                	'green-dot': job.job_status == 'Succeeded',
					                                	'pulse': job.job_status == 'Running' || job.job_status == 'Stopping',
					                                	'blue': job.job_status == 'Running',
					                                	'red': job.job_status == 'Stopping'
					                                	}" 
					                                	>
					                                	</span>
														<span *ngIf="job.job_status != null && (job.enabled == 1 || job.enabled)">{{job.job_status}}</span>
														<span *ngIf="job.job_status == null && (job.enabled == 1 || job.enabled)">Scheduled</span>
														<span *ngIf="job.enabled == 0 || !job.enabled">Disabled</span>
					                         </div>
					                        
								                	
								                </td> 
								                 <td class="align-middle">
								                 	<div *ngIf="job.job_status != 'Running' && job.last_run_date != null">
								                 		{{job.last_run_date | dateAgo}} ago
								                 	</div>
								                 	<div *ngIf="job.job_status == 'Running'">
								                 		<span style="font-weight: bold">Duration: </span> <span>{{(job.last_run_date == null) ? '' : job.last_run_date | dateAgo}}</span>
								                 	</div>
								             	</td>
								             	<td class="align-middle">{{job.next_run | date:'short'}}</td>
								                <td class="align-bottom" style="text-align: left;padding-right: 35px !important;">
								                	<p-inputSwitch 
								                	#ngModelDir="ngModel"
								                	[(ngModel)]="job.enabled" 
								                	(ngModelChange)="checkEnable(job, ngModelDir);"
								                	(click)="$event.stopPropagation()"
								                	></p-inputSwitch>

								                	<!-- (click)="$event.stopPropagation(); checkEnable(disableJobModal, { windowClass: 'modal animate', centered: true }, job)" -->
								            	</td>
								            	<td class="align-middle" style="padding-left: 0px !important; padding-right: 0px !important; text-align: left;">
								            
								            		<!-- <span class="fal fa-play-circle fa-lg hover-over-green" style="color: green; padding:8px !important; border-radius: 5px"
									            		*ngIf="getJobChildren(job).length > 0 && !job.is_running && runningJobs.indexOf(job.job_id) == -1" 

									            		[ngbPopover]="stepChoices" 
														container="body" 
														popoverClass="download-popover" 
														[autoClose]="'outside'"
														placement="bottom-left"
														(click)="$event.stopPropagation();">

									            	</span> -->
									            	<!-- Job is ready -->
											    	<span class="fal fa-play-circle fa-lg hover-over-green hover-over-btn"
								            		*ngIf="!job.is_running && runningJobs.indexOf(job.job_id) == -1" 
								            		ngbTooltip="Run Job"
								            		(click)="executeJob(job.job_id); $event.stopPropagation();">
								            		</span>

								            		<!-- <ng-template #stepChoices>
													
												        <div class="step-choices" (click)="executeJob(job.job_id, false); $event.stopPropagation();">
												          Job Only
												        </div>
												        <hr style="margin-top: 0rem; margin-bottom: 0rem;" />
												        <div class="step-choices" (click)="executeJob(job.job_id, true); $event.stopPropagation();">
												          Job and Dependants
												        </div>

												      	
													</ng-template> -->
													<div *ngIf="job.starting" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color:#2196F3 !important;">
												        <span  class="sr-only">Loading...</span>
								            		  <!-- <div  
												      </div> -->
												    </div>


								            		<span *ngIf="runningJobs.indexOf(job.job_id) > -1 && !(job.starting)" class="fa-regular fa-circle-stop fa-lg hover-over-red hover-over-btn" ngbTooltip="Stop Job" (click)="stopJob(job.job_id); $event.stopPropagation();">
								            		  <!-- <div  class="spinner-border spinner-border-sm" role="status" style="margin: auto; color:#2196F3 !important;">
												        <span  class="sr-only">Loading...</span>
												      </div> -->
												    </span>
												    <span  
												    ngbTooltip="Job History"
												    class="fal fa-poll-h fa-lg hover-over-btn hover-over-purple" (click)="openJobHistoryModal(displayJobHistoryModal, { windowClass: 'modal-xl  animate', centered: true }, job); $event.stopPropagation()">
												    	
												    </span>
							            			<span
							            			*ngIf="job.owner != 'Carve'" class="fal fa-edit fa-lg hover-over-gray hover-over-btn" (click)="openEditJobModal(editJobModal, { windowClass: 'modal-lg animate', centered: true }, job); $event.stopPropagation()"></span>
							            			
							            			<span  *ngIf="job.owner != 'Carve'" class="fal fa-trash-alt fa-lg hover-over-red hover-over-btn" (click)="openDeleteJobModal(deleteJobModal, { windowClass: 'modal animate', centered: true }, job); $event.stopPropagation()"></span>
							            			
							            			<span *ngIf="job.owner == 'Carve'" style="padding:34px;">&nbsp;</span>
							            		</td>
									    </tr>
										<ng-container *ngIf="job.expanded">
										  <tr style="color: black;" [ngClass]="{'job-open-body': job.expanded}"
										 >
									      	<td colspan="9" style="padding-left: 15px !important;background-color: #fbfbfb">
									      		
									             <div class="row">
									             	<div class="col-4" *ngIf="job.owner != 'Carve'">
									             		<div style="width: 520px;" >
										             		<div style="padding-top: 10px;color: #495057;display: inline-block;">
										             			<div class="btn-primary btn-sg step-btn" style="width: 120px;" (click)="openNewStepModal(newStepModal, { windowClass: 'modal-lg animate', centered: true }, job); $event.stopPropagation()">
													            	<span class="fal fa-plus-circle" style="margin-right: 10px;">
													            	</span>New Step
													            </div>
											                </div>
												       
											            </div>
										            </div>
									             </div>

									               <!--  -->

									          
			
									             		<div class="row" *ngFor="let row of diagramLayout.rows" style="margin-bottom:40px; margin-top: 40px;">
									             			<div class="col" *ngFor="let column of row.columns"  style="margin-right:15px;">
									             				<div 
										             				*ngIf="!column.empty && column.obj_type =='step'" 
										             				[id]=column.att_id class="card step" 
										             				[ngClass]="[
										             					 column.is_running || runningSteps.indexOf(job.job_id+'_'+column.id) > -1? 'running-highlighted-step': '',
										             					 column.execution_status == 'Success' ? 'success-highlighted-step': '',
										             					 column.execution_status == 'Failed' ? 'failed-highlighted-step': '', 
										             					 column.highlighted ? 'highlighted-step' : '',
										             					 column.is_enabled ? '' : 'disabled-step'
										             				]"
										             				(click)="openStepRow(job.job_id, column.id)"
									             				>
									             					<div class="card-header step-header">
									             						<div class="float-left step-header-title">
																	    	{{column.name}}
																	    </div>
																	    <div  *ngIf="column.step_type == 'Published SQL File'"
																	    	class="float-right code-file" 
																	    	[ngClass]="{'code-file-highlight': column.highlighted}"
																	    	(click)="openStepDetailsModal(displayStepDetails, { windowClass: 'modal-xl animate', centered: true }, job.job_id, column.id); $event.stopPropagation()"
																	    >
																	    	<i class="fal fa-file-code fa-lg"></i>
																	    </div>
																	    <div *ngIf="column.step_type == 'Code Package'" class="float-right code-file">
							             									<img *ngIf="column.language == 'Python'" src="./assets/img/python-logo-only.svg" style="height: 20px; width: 17px; margin-top: 2px;"/>
									             								
																	    </div>

																	 </div>

																	 <div class="card-body step-body">
																		 <div style="width: 25px; float: left;">

																 			<!-- Ready -->
																	 		<span class="fal fa-play-circle fa-lg play-icon" style="color: #55c8a0; border-radius: 5px"
															            		*ngIf="column.is_enabled && getChildren(job, column.id).length > 0 && column.execution_status == 'ready' && runningJobs.indexOf(job.job_id) == -1" 
															            		[ngbPopover]="stepChoices" 
																				container="body" 
																				popoverClass="download-popover" 
																				[autoClose]="'outside'"
																				placement="bottom-left"
																				(click)="$event.stopPropagation();">

															            	</span>
															            	<!-- Job is ready -->
																	    	<span 
																	    	ngbTooltip="Run Step" 
							            									placement="top" 
							            									data-container="body" 
							            									class="fal fa-play-circle fa-lg play-icon" 
							            									style="color: #55c8a0; border-radius: 5px"
														            		*ngIf="getChildren(job, column.id).length == 0 && column.execution_status == 'ready' &&  column.is_enabled && runningJobs.indexOf(job.job_id) == -1 && !column.is_running && runningSteps.indexOf(job.job_id+'_'+column.id) == -1" (click)="executeStep(job.job_id, column.id, false); $event.stopPropagation();">
														            		</span>
														            		<ng-template #stepChoices>
																			
																		        <div class="step-choices" (click)="executeStep(job.job_id, column.id, false);$event.stopPropagation()">
																		          <i class="fa-thin fa-list-radio" style="padding-right: 5px"></i>
																		          Step Only
																		        </div>
																		        <hr style="margin: 0px;" />
																		        <div class="step-choices" (click)="executeStep(job.job_id, column.id, true);$event.stopPropagation()">
																		          <i class="fa-thin fa-list-tree" style="padding-right: 5px"></i>Step and Dependants
																		        </div>

																		      	
																			</ng-template>
																			<!-- Running -->
																			<span *ngIf="column.is_running || runningSteps.indexOf(job.job_id+'_'+column.id) > -1" style="padding:8px;">
														            		  <div  class="spinner-border spinner-border-sm" role="status" style="margin: auto; color:#2196F3 !important;">
																		      	<span  class="sr-only">Loading...</span>
																		      </div>
																	    	</span>

																	    	<!-- Success -->
																	    	
																		 	<span *ngIf="column.execution_status == 'Succeeded'" class="fal fa-check fa-lg" style="border-radius: 5px; color: green !important;"
														            		 >
														            		</span>

														            		<!-- Failed -->
														            		<span *ngIf="column.execution_status == 'Failed'" class="fal fa-times fa-lg" style="border-radius: 5px; color: red !important;"
														            		 >
														            		</span>
				
																		</div>
																		<!-- <span *ngIf="job.owner != 'Carve'" class="fal fa-edit fa-lg play-icon hover-over-gray" style="color: gray; padding:8px !important; border-radius: 5px" (click)="openEditStepModal(newStepModal, { windowClass: 'modal animate', centered: true }, job, column.id);"></span>
														            			<span *ngIf="job.owner == 'Carve'" style="padding:34px;">&nbsp;</span>
														            			<span *ngIf="job.owner != 'Carve'" class="fal fa-trash-alt fa-lg play-icon hover-over-red" style="color: red; padding:8px !important; border-radius: 5px" (click)="openDeleteJobStepModal(deleteJobStepModal, { windowClass: 'modal animate', centered: true }, job, column.id); $event.stopPropagation()"></span> -->
																		<div 
																		class="float-right" 
																	    	(click)="openStepResultsModal(displayStepResults, { windowClass: 'modal-xl animate', centered: true }, job.job_id, column.id); $event.stopPropagation()"
																			*ngIf="column.execution_status == 'Failed' || column.execution_status == 'Succeeded'"
																		>
																			<i  style="color: gray;" class="fal fa-poll-h fa-lg"></i>
																			
																		</div>
																	</div>
																</div>
									             			</div>
									             		</div>
									             		<!-- <div> -->
									             <div class="row" *ngIf="showStepRow" style="padding-bottom: 50px;">
										      		<div class="col">
												      	<div class="card step-card" style="border: 1px solid #e9e9e9;">
											                <table class="table" style="margin-bottom:0px !important;">
																<thead style="border-color: #979899; font-weight: bold; table-layout: fixed;">
																    <tr style="border-color: #979899; font-weight: bold" class="non-hover">
									                                <th scope="col" style="font-weight: bold">Step Name</th>
									                                <th scope="col" style="font-weight: bold">Description</th>
									                                <th scope="col" style="font-weight: bold">Run Type</th>
									                                <th scope="col" style="font-weight: bold">Runs After</th>
									                                <th scope="col" style="font-weight: bold" >Step Type</th>
									                                <th *ngIf="displayStep.musher_job_step_type == 'Published SQL File'" scope="col" style="font-weight: bold" >File Name</th>
									                                <th *ngIf="displayStep.musher_job_step_type == 'Code Package'" scope="col" style="font-weight: bold" >Package Name</th>
									                                <th scope="col" style="font-weight: bold; width: 250px;">Last Run</th>
									                                <th scope="col" style="font-weight: bold; width: 60px;">Enabled</th>
									                                <th scope="col" style="width: 148px"></th>
										                            </tr>
										                        </thead>
										                        <tbody style="overflow: auto">
							               							<tr scope="row" class="non-hover">
										                                <td>{{displayStep.musher_job_step_name}}</td>
										                                <td>{{displayStep.musher_job_step_description}}</td>
										                                <td [ngStyle]="{color: getRunTypeColor(displayStep.run_type, 'run_type')}"
										                                	>{{displayStep.run_type}}</td>
										                                <td>{{displayStep.run_after}}</td>
										                                <td>{{displayStep.musher_job_step_type}}</td>
										                                <td *ngIf="displayStep.musher_job_step_type == 'Code Package'">
										                                	{{displayStep.package_name}}
										                                	<!-- <span><i 
											                                	*ngIf="displayStep.musher_job_step_type != 'Admin App Function'" 
											                                	class="fal fa-file-code fa-lg" 
											                                	style="color: #2196f3; cursor: pointer;"
											                                	(click)="openStepDetailsModal(displayStepDetails, { windowClass: 'modal-xl animate', centered: true }, job.job_id, displayStep.musher_job_step_id); $event.stopPropagation()"

											                                	></i>
										                                		<i *ngIf="displayStep.musher_job_step_type == 'Admin App Function'" class="fal fa-file-code fa-lg" style="color: #ccc;"></i>
										                                	</span> -->
										                                </td>
										                                <td *ngIf="displayStep.musher_job_step_type == 'Published SQL File'">
										                                	{{displayStep.query_display_name}}
										                                </td>
										         
										                                <td>
										                                	
										                                	
										                                	<div class="text-center" style="width:120px; padding:4px; font-size:9pt; display:inline-block;" [ngClass]="{
										                                	
											                                	'gray-border': displayStep.job_step_is_enabled == 0,
											                                	'green-border': displayStep.job_step_current_execution_status == 'Succeeded',
											                                	'blue-border':displayStep.job_step_current_execution_status == 'Running',
											                                	'red-border': displayStep.job_step_current_execution_status == 'Failed' || displayStep.job_step_current_execution_status == 'Stopped',
											                                	'purple-border': displayStep.job_step_current_execution_status == 'Not Running'
											                                	}" 

											                                	>
											                                		<span class="dot" [ngClass]="{
											                                		'gray-dot': displayStep.job_step_is_enabled == 0,
												                                	'green-dot': displayStep.job_step_current_execution_status == 'Succeeded',
												                                	'blue-dot':displayStep.job_step_current_execution_status == 'Running',
												                                	'red-dot': displayStep.job_step_current_execution_status == 'Failed' || displayStep.job_step_current_execution_status == 'Stopped',
												                                	'purple-dot': displayStep.job_step_current_execution_status == 'Not Running'
												                                	}" 
												                                	>
												                                	</span>
																					<span>{{displayStep.job_step_current_execution_status}}
																					</span>
		
													                          </div>
													                          <!-- <span style="padding-left: 10px;"> {{displayStep.last_run_date | dateAgo}}</span> -->
										                                	
										                                </td>
										                               
												                            <td style="text-align: center; padding-top: 15px;">
															                	<p-inputSwitch
															                	#ngModelDir="ngModel"
															                	[(ngModel)]="displayStep.job_step_is_enabled"
															                	(ngModelChange)="checkStepEnable(job, displayStep, ngModelDir);"
															                	>
															                	</p-inputSwitch>
															            	</td>

															            	<td style="padding-left: 0px !important; padding-right: 20px !important; text-align: right;">
															            		<span  
															            		ngbTooltip="Step History" 
							            										placement="top" data-container="body"
															            		class="fal fa-poll-h fa-lg hover-over-purple hover-over-btn" (click)="openStepHistoryModal(displayStepHistoryModal, { windowClass: 'modal-xl animate', centered: true }, job, displayStep); $event.stopPropagation()"></span>
														            			<span 
														            			
														            			*ngIf="job.owner != 'Carve'" class="fal fa-edit fa-lg hover-over-gray hover-over-btn" (click)="openEditStepModal(newStepModal, { windowClass: 'modal animate', centered: true }, job, displayStep);"></span>
														            			<span 
														            			*ngIf="job.owner == 'Carve'" style="padding:34px;">&nbsp;</span>
														            			<span *ngIf="job.owner != 'Carve'" class="fal fa-trash-alt fa-lg hover-over-btn hover-over-red" (click)="openDeleteJobStepModal(deleteJobStepModal, { windowClass: 'modal animate', centered: true }, job, displayStep); $event.stopPropagation()"></span>
														            			
															            	</td>       
									                    			</tr>
																</tbody> 
															</table>
														</div>
													</div>
												</div>
										</td>
									</tr>
								</ng-container>
							</ng-container>
							</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		
	</div>
</div>


  <ng-template #newJobModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-clipboard-list-check fa-2xl sg-txt" style="padding-bottom: 10px; vertical-align: middle;"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:20pt;">New Job</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="padding: 10px 35px;">
            <!-- Form -->
	         <form [formGroup]="newJobForm">
	          <div class="form-group">
	            <label class="form-label">Name</label>
	            <input 
	            	formControlName="name" 
	            	id="name" 
	            	name="name" 
	            	type="text" 
	            	class="form-control" 
	            	required
	            	>
	            <div *ngIf="newJobForm.get('name').invalid && 
			        newJobForm.get('name').errors && 
			        (newJobForm.get('name').dirty || newJobForm.get('name').touched)">
			    <small class="text-danger"
			        *ngIf="newJobForm.get('name').hasError('required')">
			        This field is required.
			    </small>
			</div>
	          </div>
	          <div class="form-group">
	            <label class="form-label">Description</label>
	            <textarea formControlName="description" id="description" name="description" type="text" class="form-control"></textarea>
	          </div>
	          <div class="form-group">
	            <label class="form-label">Notify Email</label>
	            <input 
	            	formControlName="email" 
	            	id="email" 
	            	name="email" 
	            	type="text" 
	            	class="form-control"
	            	>
	           </div>
	          </form>
	           <div class="form-group">
	            	<label class="form-label">Run Type</label>
	            	<ng-select [items]="jobObj.runTypeSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="jobObj.runTypeSelectValue"
					      placeholder="Run Type"
					      style="margin-bottom: 25px;"
					      >
					    
						      <ng-template ng-label-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i>{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i> {{item['label']}}</span>
						      </ng-template>
						    
					    </ng-select>
					  </div>
					   <div class="form-group" *ngIf="jobObj.runTypeSelectValue != null && jobObj.runTypeSelectValue != 'schedule' ">
	            	<label class="form-label">Run After</label>
	            	<ng-select [items]="jobObj.runAfterSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="jobObj.runAfterValue"
					      placeholder="Run After"
					      style="margin-bottom: 25px;"
					      >

						      <ng-template ng-label-tmp let-item="item">
						        <span class="ng-value-label">{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span class="ng-value-label"> {{item['label']}}</span>
						      </ng-template>
					    </ng-select>
					  </div>

	          <div  class="scheduler" *ngIf="jobObj.runTypeSelectValue == 'schedule'">
	          	<label class="form-label">Schedule (UTC)</label>
	          	<div style="border: 1px solid #d2d2d2; border-radius: 5px;">
	      
	          		<!-- Cron Editor option -->
	          		<form [formGroup]="newJobCronForm">
			          	<div style="margin-top: 20px; padding: 25px; border-bottom: 1px solid #ddd; background-color: #f1f7ff;">
			          		<div class="form-group">
					            <label class="form-label">Cron Expression (UTC)</label>
					            <input 
					            	formControlName="expression" 
					            	id="expression" 
					            	name="expression" 
					            	type="text" 
					            	class="form-control"
					            	[(ngModel)]="jobObj.new.schedule"
					            	(change)="getNextRuns(jobObj.new.schedule);"
					            	>
					        </div>
			          	</div>
			         </form>
	          		<div>
	          			
          				<cron-editor [(cron)]="jobObj.new.schedule" [(options)]="cronOptions" (click)="getNextRuns(jobObj.new.schedule);"></cron-editor>
          			
		          		<!-- Custom option -->
		          	</div>
		          	
	          		
	          		<div style="margin-top: 20px; padding: 25px; border-top: 1px solid #ddd; background-color: #f1f7ff;">
	          			<div style="font-weight: 500; padding-bottom: 15px;">Next 5 Run Times (Local)</div>
	          			<ul *ngFor="let t of nextRuns">
	          				<li>{{t | date:'short'}}</li>
	          			</ul>
	          		</div>
			  
	          	</div>
	          	
	          	<!-- <div *ngIf="getNextRun() != null">Next Run Time (Local): {{getNextRun()}}</div> -->
	          </div>
	           <div style="padding-top: 15px;">
	          	 <label class="form-label">Enabled</label>
	          	 <div>
	          	 	<p-inputSwitch [(ngModel)]="jobObj.new.enabled" (click)="$event.stopPropagation()"></p-inputSwitch>
	          	 </div>
                	
              </div>        
	    	
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-primary btn-sg" (click)="addNewJob()"[disabled]="newJobForm.invalid">
        	<span *ngIf="!saving.job" class="fas fa-save"></span>
        	<div *ngIf="saving.job" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
        &nbsp;&nbsp;Save</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #newStepModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-project-diagram fa-2x sg-txt"></span>&nbsp;&nbsp;
		        	<span *ngIf="!stepObj.edit.open" style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">New Step</span>
		        	<span *ngIf="stepObj.edit.open" style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Edit Step</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="padding: 10px 35px;">

          	<form *ngIf="!stepObj.edit.open" [formGroup]="newStepForm">
          		<div class="form-group">
	            	<label class="form-label">Step Name</label>
	            	<input formControlName="name" id="name" name="name" type="text" class="form-control">
	          	</div>
			    		<div class="form-group">
	            	<label class="form-label">Description</label>
	            	<textarea formControlName="description" id="description" name="description" type="text" class="form-control"></textarea>
	          	</div>
	        </form> 

	        <form *ngIf="stepObj.edit.open" [formGroup]="editStepForm">
          		<div class="form-group">
	            	<label class="form-label">Step Name</label>
	            	<input formControlName="name" id="name" name="name" type="text" class="form-control">
	          	</div>
			    		<div class="form-group">
	            	<label class="form-label">Description</label>
	            	<textarea formControlName="description" id="description" name="description" type="text" class="form-control"></textarea>
	          	</div>
	        </form>  


            <!-- Form -->
            <div class="form-group">
	            	<label class="form-label">Step Type</label>
	            	<ng-select [items]="stepObj.typeSelectOptions"
				      bindLabel="label"
				      bindValue="value"
				      [(ngModel)]="stepObj.typeSelectValue"
				      placeholder="Step Type"
				      style="margin-bottom: 25px;"
				      >

					      <ng-template ng-label-tmp let-item="item">
					        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i>{{item['label']}}</span>
					      </ng-template>

					      <ng-template ng-option-tmp let-item="item">
					        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i> {{item['label']}}</span>
					      </ng-template>
					    </ng-select>
					  </div>
					    <div class="form-group" *ngIf="stepObj.typeSelectValue == 'Published SQL File'">
		            		<label class="form-label" >Published File</label>
			                <ng-select 
			                  [items]="allScripts"
							      bindLabel="query_display_name"
							      bindValue="query_id"
							      [(ngModel)]="stepObj.sqlFile"
							      placeholder="Published File"
							       style="margin-bottom: 25px;"
							      >
							      <ng-template ng-label-tmp let-item="item">
							        <span class="ng-value-label">{{item['query_display_name']}}</span>
							      </ng-template>

							      <ng-template ng-option-tmp let-item="item">
							        <div class="ng-value-label" [title]="item['code']"> {{item['query_display_name']}}</div>
							      </ng-template>
						    </ng-select>
				      </div>
				      <div class="form-group" *ngIf="stepObj.typeSelectValue == 'Code Package'">
		            		<label class="form-label" >Code Package</label>
			                <ng-select 
			                  [items]="allPackages"
							      bindLabel="package_name"
							      bindValue="package_id"
							      [(ngModel)]="stepObj.package"
							      placeholder="Code Package"
							       style="margin-bottom: 25px;"
							       (change)="setPackage()"
							      >

							      <ng-template ng-label-tmp let-item="item">
							        <span class="ng-value-label">{{item['package_name']}}</span>
							      </ng-template>

							      <ng-template ng-option-tmp let-item="item">
							        <div class="ng-value-label" [title]="item['code']"> {{item['package_name']}}</div>
							      </ng-template>
						    </ng-select>
				      </div>
				      <div class="form-group" *ngIf="stepObj.typeSelectValue == 'Code Package' && stepObj.selectedPackage.package_language === 'DBT'">
		            		<label class="form-label" >DBT Command</label>
	            			<input [(ngModel)]="stepObj.dbt_command" placeholder="dbt run" id="command" name="command" type="text" class="form-control">
				      </div>
				      <div class="form-group" *ngIf="stepObj.typeSelectValue == 'Code Package' && allEnvironments.length > 0">
		            		<label class="form-label">Environment</label>
			                <ng-select 
			                     [items]="allEnvironments"
							      bindLabel="environment"
							      bindValue="environment"
							      [(ngModel)]="stepObj.environment"
							      placeholder="Environment"
							       style="margin-bottom: 25px;"
							      >
							      <ng-template ng-label-tmp let-item="item">
							        <span class="ng-value-label">{{item['environment']}}</span>
							      </ng-template>

							      <ng-template ng-option-tmp let-item="item">
							        <div class="ng-value-label" [title]="item['code']"> {{item['environment']}}</div>
							      </ng-template>
						    </ng-select>
				      </div>

				      <div class="form-group">
	            	<label class="form-label">Run Type</label>
	            	<ng-select [items]="stepObj.runTypeSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="stepObj.runTypeSelectValue"
					      placeholder="Run Type"
					      style="margin-bottom: 25px;"
					      >
					    
						      <ng-template ng-label-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" [ngStyle]="getRunTypeIconColor(item['value'])" style="padding-right: 5px"></i>{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" [ngStyle]="getRunTypeIconColor(item['value'])" style="padding-right: 5px"></i> {{item['label']}}</span>
						      </ng-template>
						    
					    </ng-select>
					  </div>
					   <div class="form-group" *ngIf="stepObj.runTypeSelectValue != null && stepObj.runTypeSelectValue != 'initial' ">
	            	<label class="form-label">Run After</label>
	            	<ng-select [items]="stepObj.runAfterSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="stepObj.runAfterStepValue"
					      placeholder="Run After"
					      style="margin-bottom: 25px;"
					      >

						      <ng-template ng-label-tmp let-item="item">
						        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i>{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i> {{item['label']}}</span>
						      </ng-template>
					    </ng-select>
					  </div>

			   <!--  <ng-select 
                   *ngIf="typeSelectValue == 'sql' && sqlFile != null"
                  [items]="getVersions(sqlFile)"
						      bindLabel="version_id"
						      bindValue="version_id"
						      [(ngModel)]="version"
						      placeholder="File"
			       style="margin-bottom: 25px;"
			      >
			      <ng-template ng-label-tmp let-item="item">
			        <span class="ng-value-label">{{ 'Version: ' + item['version_id'] + ' - ' + item['publish_comment']}}</span>
			      </ng-template>

			      <ng-template ng-option-tmp let-item="item">
			        <div class="ng-value-label" [title]="item['code']"> {{'Version: ' + item['version_id'] + ' - ' + item['publish_comment']}}</div>
			      </ng-template>
			    </ng-select> -->

			

	         <div style="padding-top: 15px;" *ngIf="!stepObj.edit.open">

	          	 <label class="form-label">Enabled</label>
	          	 <div>
	          	 	<p-inputSwitch [(ngModel)]="stepObj.new.enabled" (click)="$event.stopPropagation()"></p-inputSwitch>
	          	 </div>
                	
              </div> 

            
	         
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button *ngIf="!stepObj.edit.open" type="button" class="btn btn-primary btn-sg" (click)="addNewStep(stepObj.job_id)">
        	<span *ngIf="!saving.step" class="fas fa-save"></span>
       
        	<div *ngIf="saving.step" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
		     &nbsp;&nbsp;Save
    	</button>
    	<button *ngIf="stepObj.edit.open" type="button" class="btn btn-primary btn-sg" (click)="editStep(stepObj.job_id, stepObj.step_id)">
        	<span *ngIf="!saving.step" class="fas fa-save"></span>
       
        	<div *ngIf="saving.step" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
		     &nbsp;&nbsp;Save
    	</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #editJobModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-clipboard-list-check fa-2x sg-txt"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Edit Job</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="padding: 10px 35px;">
            <!-- Form -->
	         <form [formGroup]="editJobForm">
	          <div class="form-group">
	            <label class="form-label">Name</label>
	            <input 
	            	formControlName="name" 
	            	id="name" 
	            	name="name" 
	            	type="text" 
	            	class="form-control" 
	            	required
	            	>
	            <div *ngIf="editJobForm.get('name').invalid && 
			        editJobForm.get('name').errors && 
			        (editJobForm.get('name').dirty || editJobForm.get('name').touched)">
			    <small class="text-danger"
			        *ngIf="editJobForm.get('name').hasError('required')">
			        This field is required.
			    </small>
			</div>
	          </div>
	          <div class="form-group">
	            <label class="form-label">Description</label>
	            <textarea formControlName="description" id="description" name="description" type="text" class="form-control"></textarea>
	          </div>
	          <div class="form-group">
	            <label class="form-label">Notify Email</label>
	            <input 
	            	formControlName="email" 
	            	id="email" 
	            	name="email" 
	            	type="text" 
	            	class="form-control"
	            	>
	           </div>
	          </form>

	          <div class="form-group">
	            	<label class="form-label">Run Type</label>
	            	<ng-select [items]="jobObj.runTypeSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="jobObj.runTypeSelectValue"
					      placeholder="Run Type"
					      style="margin-bottom: 25px;"
					      >
					    
						      <ng-template ng-label-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i>{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span *ngIf="item.show" class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i> {{item['label']}}</span>
						      </ng-template>
						    
					    </ng-select>
					  </div>
					   <div class="form-group" *ngIf="jobObj.runTypeSelectValue != null && jobObj.runTypeSelectValue != 'schedule' ">
	            	<label class="form-label">Run After</label>
	            	<ng-select [items]="jobObj.runAfterSelectOptions"
					      bindLabel="label"
					      bindValue="value"
					      [(ngModel)]="jobObj.runAfterValue"
					      placeholder="Run After"
					      style="margin-bottom: 25px;"
					      >

						      <ng-template ng-label-tmp let-item="item">
						        <span class="ng-value-label">{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item">
						        <span class="ng-value-label"> {{item['label']}}</span>
						      </ng-template>
					    </ng-select>
					  </div>

	          <div  class="scheduler" *ngIf="jobObj.runTypeSelectValue == 'schedule'">
	          	<label class="form-label">Schedule (UTC)</label>
	          	<!-- <div style="border: 1px solid #d2d2d2; border-radius: 5px;">
	          		<cron-editor [(cron)]="jobObj.edit.schedule" [(options)]="cronOptions" (click)="getNextRuns(jobObj.edit.schedule);"></cron-editor>
	          		<div style="margin-top: 20px; padding: 25px; border-top: 1px solid #ddd;">
	          			<div style="font-weight: 500; padding-bottom: 15px;">Next 5 Run Times (Local)</div>
	          			<ul *ngFor="let t of nextRuns">
	          				<li>{{t | date:'short'}}</li>
	          			</ul>
	          		</div>
	          	</div> -->
	          	<div style="border: 1px solid #d2d2d2; border-radius: 5px;">
	      
	          		<!-- Cron Editor option -->
	          		<form [formGroup]="editJobCronForm">
			          	<div style="padding: 25px; border-bottom: 1px solid #ddd; background-color: #f1f7ff;">
			          		<div class="form-group">
					            <label class="form-label">Cron Expression (UTC)</label>
					            <input 
					            	formControlName="expression" 
					            	id="expression" 
					            	name="expression" 
					            	type="text" 
					            	class="form-control"
					            	[(ngModel)]="jobObj.edit.schedule"
					            	(change)="getNextRuns(jobObj.edit.schedule);"
					            	>
					        </div>
			          	</div>
			         </form>
	          		<div>
	          			
          				<cron-editor [(cron)]="jobObj.edit.schedule" [(options)]="cronOptions" (click)="getNextRuns(jobObj.edit.schedule);"></cron-editor>
          			
		          		<!-- Custom option -->
		          	</div>
		          	
	          		
	          		<div style="margin-top: 20px; padding: 25px; border-top: 1px solid #ddd; background-color: #f1f7ff;">
	          			<div style="font-weight: 500; padding-bottom: 15px;">Next 5 Run Times (Local)</div>
	          			<ul *ngFor="let t of nextRuns">
	          				<li>{{t | date:'short'}}</li>
	          			</ul>
	          		</div>
			  
	          	</div>
	          	<!-- <div *ngIf="getNextRun() != null">Next Run Time (Local): {{getNextRun()}}</div> -->
	          </div>
	          <!--  <div style="padding-top: 15px;">
	          	 <label class="form-label">Enabled</label>
	          	 <div>
	          	 	<p-inputSwitch [(ngModel)]="jobObj.edit.enabled" (click)="$event.stopPropagation()"></p-inputSwitch>
	          	 </div>
                	
              </div>   -->      
	    	
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-primary btn-sg" (click)="d('save')" [disabled]="editJobForm.invalid"><span class="fas fa-save"></span>&nbsp;&nbsp;Save</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

   <ng-template #deleteJobModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-clipboard-list-check fa-2x sg-txt-critical"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Delete Job</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this job? </p>
        <h5>{{jobObj.delete.name}}</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="deleteJob()">
        	<span *ngIf="!saving.job" class="fas fa-trash-alt"></span>
        	<div *ngIf="saving.job" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
		    &nbsp;Delete
		</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

  <ng-template #deleteJobStepModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-project-diagram fa-2x sg-txt-critical"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Delete Job Step</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this step? </p>
        <h4>{{stepObj.delete.name}}</h4>

        <p *ngIf="stepObj.delete.children.length > 0">Doing so will disable the following steps:</p>
        <ul>
        	<li *ngFor="let step of stepObj.delete.children">{{step}}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="d('delete')"><span class="fas fa-trash-alt"></span>&nbsp;&nbsp;Delete</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

  <ng-template #disableJobModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-power-off fa-2x sg-txt-critical"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Disable Job</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to disable this job? </p>
        <h4>{{jobObj.disable.name}}</h4>
        <div style="padding-top: 25px;">
	        <p *ngIf="jobObj.disable.children.length > 0">Doing so will also disable the following jobs:</p>
	        <ul>
	        	<li *ngFor="let j of jobObj.disable.children">{{j}}</li>
	        </ul>
	      </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="d('disable')"><span class="fas fa-power-off"></span>&nbsp;&nbsp;Disable</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

 <ng-template #disableJobStepModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-power-off fa-2x sg-txt-critical"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Disable Job Step</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to disable this job step? </p>
        <h4>{{stepObj.disable.name}}</h4>
        <div style="padding-top: 25px;">
	        <p *ngIf="stepObj.disable.children.length > 0">Doing so will also disable the following job steps:</p>
	        <ul>
	        	<li *ngFor="let s of stepObj.disable.children">{{s}}</li>
	        </ul>
	      </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="d('disable')"><span class="fas fa-power-off"></span>&nbsp;&nbsp;Disable</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

   <ng-template #displayStepDetails let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
        			<span style="padding-right: 5px;color: #2196f3;">
        				<i *ngIf="stepDetails.type == 'Published SQL File'" class="fal fa-file-code fa-lg"></i>
        			</span>
		        	<span style="font-weight: bold; font-size:14pt; vertical-align: bottom; line-height: 24px;">{{stepDetails.type}}</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body">
      	<div class="row">
      		<div class="col-6">
      			<div  style="font-weight:bold;">File Name</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.file_name}}</div>
		        <div  style="font-weight:bold;">Publish Date</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.last_update_date | date:'short'}}</div>
		        <div style="font-weight:bold;">File Owner</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.owner}}</div>
		        <div  style="font-weight:bold;">Connection</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.connection_name}}</div>
		      	<div style="font-weight:bold;">SQL</div>
      		</div>
      		<div class="col-6">
		        <div  style="font-weight:bold;">Warehouse</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.warehouse}}</div>
		        <div  style="font-weight:bold;">Role</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.role}}</div>
		        <div style="font-weight:bold;">Database</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.database}}</div>
		        <div style="font-weight:bold;">Schema</div>
		        <div style="padding-bottom: 10px;">{{stepDetails.schema}}</div>
      		</div>
      	</div>
      	<div>
      		<div *ngIf="stepDetails.loading" style="margin: auto">
	    		<div  class="spinner-border spinner-border-lg" role="status" style="margin: auto; color:#2196F3 !important; height: 25px; width: 25px;">
		        	<span  class="sr-only">Loading...</span>
		      	</div>
	    	</div>
	      	<codemirror 
		          #fileCode
		          [(ngModel)]="stepDetails.code"
		          [config]="fileCodeConfig"
		          (focus)="onFocusRefresh(fileCode)"
		        >
			</codemirror>
		</div>
	</div>
      <div class="modal-footer">
      	<button type="button" class="btn btn-primary btn-sg" (click)="d('edit')">Edit</button>
      	<button type="button" class="btn btn-primary btn-sg" (click)="d('copy')">Open Copy</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

    <ng-template #displayStepResults let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
        			<span style="padding-right: 5px;">
        				<i  style="color: gray;" class="fal fa-poll-h fa-lg"></i>
        			</span>
		        	<span style="font-weight: bold; font-size:14pt; vertical-align: bottom; line-height: 24px;">{{stepExecutionResults.name}}</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="font-size: 11pt;">
    
		<div  style="font-weight:bold;">Overall Result</div>
        <div style="padding-bottom: 10px;" [ngStyle]="{color: getRunTypeColor(stepExecutionResults.execution_result, 'result')}">{{stepExecutionResults.execution_result}}</div>
        <div  style="font-weight:bold;">Queries</div>
        <div *ngFor="let query of stepExecutionResults.queries; let i = index" style="padding-bottom: 10px;">
        	<div>{{i+1}}.</div>
        	<div>
        		<codemirror 
		          #queryResultCode
		          [(ngModel)]="query.sql"
		          [config]="resultCodeConfig"
		          (focus)="onFocusRefresh(queryResultCode)"
		        >
				</codemirror>
			</div>
			<div style="padding-left: 45px;padding-top: 15px;">
				<div><div class="query-result-details" >Result</div> <div style="display: inline-block;"  [ngStyle]="{color: getRunTypeColor(query.execution_results.result, 'result')}">{{query.execution_results.result}}</div></div>
				<div><div class="query-result-details">Message  </div> <div style="display: inline-block;">{{query.execution_results.message | json}}</div></div>
				<div><div class="query-result-details">Query ID </div> <div style="display: inline-block;">{{query.execution_results.sfqid}}</div></div>
				<div><div class="query-result-details">Duration </div> <div style="display: inline-block;">{{query.execution_results.total_seconds | convertSeconds}}</div></div>
			</div>
        </div>
      	
	</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #displayJobHistoryModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
        			<span style="padding-right: 5px;">
        				<i  style="color: gray;" class="fal fa-poll-h fa-2xl"></i>
        			</span>
		        	<span style="font-weight: 100; font-size:18pt; vertical-align: bottom; line-height: 20px;">Job History</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="font-size: 11pt;">
    	<div *ngIf="history.loading" style="margin: auto">
    		<div  class="spinner-border spinner-border-lg" role="status" style="margin: auto; color:#2196F3 !important;">
	        <span  class="sr-only">Loading...</span>
	      </div>
    	</div>
    	<div *ngIf="!history.loading">
			<p-table 
						#jobHistoryTable
						styleClass="p-datatable-sm"
						selectionMode="single"
						[value]="jobHistory.history"
						[scrollable]="true" 
						scrollHeight="410px"
						[style]="{height:'410px'}"
				 >
				<!--  [value]="newObjects"  -->
			        <ng-template pTemplate="header" style="font-size:9pt;">
			            <tr style="font-size:9pt;">
			                <th>Result</th>
			                <th>Start Date</th>
			                <th>End Date</th>
			                <th>Duration</th>
			            </tr>
			        </ng-template>
			        <ng-template pTemplate="body" style="font-size: 9pt;" let-new>
			            <tr style="font-size: 9pt; cursor: pointer;" (click)="new.expanded = !new.expanded">
			                <td *ngIf="new.execution_result" [style.color]="new.execution_result =='Succeeded' ? 'green' : (new.execution_result =='Failed' ? 'red' : null)">{{new.execution_result}}</td>
			                <td *ngIf="!new.execution_result" style="color: gray;">Incomplete</td>
			                <td>{{new.start_date | date:'medium'}}</td>
			                <td>{{new.end_date | date:'medium'}}</td>
			              	<td *ngIf="new.duration_seconds">{{new.duration_seconds | convertSeconds}}</td>
						    <td *ngIf="!new.duration_seconds"></td>
			            </tr>
			            <ng-container *ngIf="new.expanded">
						    <tr class="non-hover">
						      <td colspan="4">
							      <div style="box-shadow: 0px 1px 5px #00000040 !important; padding: 15px;"> 
							        <h5 style="color: #9d9d9d">Steps</h5>
							        
										<p-table 
											#jobStepHistoryTable
											styleClass="p-datatable-sm"
											[rowHover]="false"
											[value]="new.job_step_details"
											[scrollable]="true" 
											scrollHeight="410px"
										
									 >
									<!--  [value]="newObjects"  -->
									        <ng-template pTemplate="header" style="font-size:9pt;">
									            <tr style="font-size:9pt;">
									            	<th>Step Name</th>
									                <th>Result</th>
									                <th>Start Date</th>
									                <th>End Date</th>
									                <th>Duration</th>
									                <th>Message</th>
									            </tr>
									        </ng-template>
									        <ng-template pTemplate="body" style="font-size: 9pt;" let-step>
									            <tr class="non-hover" style="font-size: 9pt; cursor: text;">
									            	<td>{{step.job_step_name}}</td>
									                <td *ngIf="step.step_execution_result" [style.color]="step.step_execution_result =='Succeeded' ? 'green' : (step.step_execution_result =='Failed' ? 'red' : null)">{{step.step_execution_result}}</td>
									                <td *ngIf="!step.step_execution_result" style="color: gray;">Incomplete</td>
									                <td>{{step.start_date | date:'medium'}}</td>
									                <td>{{step.end_date | date:'medium'}}</td>
									              	<td *ngIf="step.duration_seconds">{{step.duration_seconds | convertSeconds}}</td>
												    <td *ngIf="!step.duration_seconds"></td>
												    <td>{{step.step_failure_message}}</td>
									            </tr>
									        </ng-template>
										</p-table>
									</div>
						      </td>
						    </tr>
						  </ng-container>
			        </ng-template>
				</p-table>
		</div>
      	
	</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #displayStepHistoryModal let-c="close" let-d="dismiss">
      <div class="modal-header" style="display: inline-block;">
        <div class="modal-title" >
        	<div class="row">
        		<div class="col-11">
        			
		        	<div style="font-weight: 100; font-size:18pt; vertical-align: bottom; line-height: 20px;">
		        		<i  class="fal fa-poll-h" style="margin-right: 5px;"></i>
		        		<span>Step History</span>
		        	
		        	<!-- <span *ngIf="stepHistory.name" style="font-weight: 100; font-size:18pt; vertical-align: bottom; line-height: 20px;"> - </span> -->
		        		<span *ngIf="stepHistory && stepHistory.hasOwnProperty('name')" style="font-weight: 100; font-size:18pt; vertical-align: bottom; line-height: 20px;"> - {{stepHistory.name}}</span>
		        	</div>
		        </div>
		        <div class="col-1">
		        	<div  style="vertical-align: middle; font-size:18pt; line-height: 20px;" (click)="refreshStepHistory();">
		        		<i class="fa-thin fa-sync hover-over"></i>
		        	</div>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="font-size: 11pt;">
    	<div *ngIf="history.loading" style="margin: auto">
    		<div  class="spinner-border spinner-border-lg" role="status" style="margin: auto; color:#2196F3 !important;">
	        <span  class="sr-only">Loading...</span>
	      </div>
    	</div>
    	<div *ngIf="!history.loading">
			<!-- <div class="row">
					<div class="col-md-6">
	                
					 <label class="nav-item navbar-text navbar-search-box p-0 active">
						<i class="ion ion-ios-search navbar-icon align-middle"></i>
				          <span class="navbar-search-input pl-2" style="width: 400px !important">
				            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important">
				          </span>
				          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
				        </label>
					</div>
				
			</div> -->
			<div>
                <ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="stepHistory"></ngx-loading-bar>
                <p-table 
						#stepHistoryTable
						styleClass="p-datatable-sm"
						selectionMode="single"
						[value]="stepHistory.history"
						[scrollable]="true" 
						scrollHeight="410px"
						[style]="{height:'410px'}"
				 >
				<!--  [value]="newObjects"  -->
			        <ng-template pTemplate="header" style="font-size:9pt;">
			            <tr style="font-size:9pt;">
			                <th>Result</th>
			                <th>Start Date</th>
			                <th>End Date</th>
			                <th>Duration</th>
			            </tr>
			        </ng-template>
			        <ng-template pTemplate="body" style="font-size: 9pt;" let-new>
			            <tr style="font-size: 9pt; cursor: pointer;" (click)="expandStepLog(new)">
			                <td *ngIf="new.execution_result != null" [style.color]="new.execution_result =='Succeeded' ? 'green' : (new.execution_result =='Failed' ? 'red' : (new.execution_result =='Stopped' ? 'gray' : null))">{{new.execution_result}}</td>
			                <td *ngIf="new.execution_result == null">Incomplete</td>
			                <td>{{new.start_date | date:'medium'}}</td>
			                <td>{{new.end_date | date:'medium'}}</td>
			              	<td *ngIf="new.duration_seconds">{{new.duration_seconds | convertSeconds}}</td>
						    <td *ngIf="!new.duration_seconds"></td>
			            </tr>
			            <ng-container *ngIf="new.expanded || new.musher_step_log_id == lastOpenedStepLog">
						    <tr class="non-hover">
						      <td colspan="4">
						      	<div> 
								      <div *ngIf="new.musher_job_step_type == 'Code Package' && new.log_details != undefined">
								      	<div  style="font-weight:bold;">Execution Logs</div>
								      	<div *ngFor="let log of new.log_details" style="border-bottom: 1px solid #ccc; padding: 3px 0px;">
								      		{{log.message}}
								      	</div>
								      </div>
								      <div *ngIf="new.musher_job_step_type == 'Code Package' && new.log_details == undefined">
								      	<div  style="font-weight:bold;">No Logs Available</div>
								      </div>
								      <div style="box-shadow: 0px 1px 5px #00000040 !important; padding: 15px;" *ngIf="new.musher_job_step_type == 'Published SQL File' && new.queries.length > 0"> 
								        <div  style="font-weight:bold;">Queries</div>
								        <div *ngFor="let query of new.queries; let i = index" style="padding-bottom: 10px;">
								        	<div>{{i+1}}.</div>
								        	<div>
								        		<codemirror 
										          #queryResultCode
										          [(ngModel)]="query.sql"
										          [config]="resultCodeConfig"
										          (focus)="onFocusRefresh(queryResultCode)"
										        >
												</codemirror>
											</div>
											<div style="padding-left: 45px;padding-top: 15px;">
												<div><div class="query-result-details" >Result</div> <div style="display: inline-block;"  [ngStyle]="{color: getRunTypeColor(query.execution_results.result, 'result')}">{{query.execution_results.result}}</div></div>
												<div><div class="query-result-details">Message  </div> <div style="display: inline-block;">{{query.execution_results.message | json}}</div></div>
												<div><div class="query-result-details">Query ID </div> <div style="display: inline-block;">{{query.execution_results.sfqid}}</div></div>
												<div><div class="query-result-details">Duration </div> <div style="display: inline-block;">{{query.execution_results.total_seconds | convertSeconds}}</div></div>
											</div>
								        </div>
								    </div>
								    <div style="box-shadow: 0px 1px 5px #00000040 !important; padding: 15px;" *ngIf="new.musher_job_step_type == 'Published SQL File' && new.queries.length == 0"> 
								    	<div>No Query Results Available</div>
								    </div>
								</div>
						      </td>
						    </tr>
						  </ng-container>
			        </ng-template>
				</p-table>
			</div>
		</div>
	</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>
  
 
  
  <div toastContainer></div>
