import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../shared/api/api.service';
import { AuthService } from '../../../shared/auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import { LoadingBarService } from '@ngx-loading-bar/core';
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'app-passwords',
  templateUrl: './passwords.component.html',
  styles: [
  ]
})
export class PasswordsComponent implements OnInit {
  @ViewChild('setupModal') setupModal: ElementRef;
  public passwordForm: FormGroup;
  public editPasswordForm: FormGroup;
  headers: HttpHeaders;
  search = '';
  saving: boolean = false;
  deleting: boolean = false;
  startupSQL = '';
  submitted = false;
  passwords=[];
  passwordsOG=[];
  passwordFields: any;
  url: string = environment.url;
  tartupSQL: string = '';
  codeConfig: any;
  page = 'passwords';
  displayMessage = '';

  connObj = {
    typeOptions: [
      {label: 'User Account', value: 'user', icon: "fa-solid fa-user"},
      {label: 'Service Account', value: 'service', icon: 'fa-solid fa-user-gear'},
    ],
    systemOptions: [
      {label: 'Snowflake', value: 'snowflake', logo: './assets/img/snowflake.png', alt_logo: './assets/img/snowflake_bg.png'},
    ],
    systemLogos: {'snowflake': './assets/img/snowflake.png'},
    typeIcons: {'user': "fa-solid fa-user", 'service': 'fa-solid fa-user-gear', 'catalog': "fa-solid fa-user-shield"},
    typeMapping: {'user': 'User Account', 'service': 'Service Account', 'catalog': 'Carve Service Account (Catalog)'},
    systemMapping: {'snowflake': 'Snowflake'}
  }

  selectedConn;

  constructor(
    private auth: AuthService, 
    private api: ApiService,
    private modalService: NgbModal,
    public toastrService: ToastrService,
    private fb: FormBuilder,
    private http: HttpClient,
    private loadingBar: LoadingBarService
    ) { 

      this.passwordForm = this.newPasswordForm();
      this.editPasswordForm = this.newPasswordForm();
      this.headers = this.auth.getHeaders();
    }

  ngOnInit(): void {
    let passwordLoad = this.loadingBar.useRef('passwordLoad')
    passwordLoad.set(20)

    this.passwordFields = {
        "username": "default"
    }
    this.getConnections(passwordLoad);
    this.codeConfig = {
      lineNumbers: true,
      scrollbarStyle: null,
      mode: 'sql',
      readOnly: true,
      theme: 'midnight'
    }
    this.startupSQL = String.raw`/* Create CARVE Role */
use role accountadmin;
create role PC_CARVE_ROLE comment = 'Default role for the CARVE App';
grant ROLE PC_CARVE_ROLE TO ROLE accountadmin;

/* Create the CARVE User */
use role accountadmin;
create user PC_CARVE_SERVICE_USER
  password = '[password]'
  login_name = 'PC_CARVE_SERVICE_USER'
  display_name = 'CARVE'
  default_warehouse = '[warehouse name]'
  default_namespace = 'PC_CARVE_DB.CARVE_HISTORY'
  default_role = 'PC_CARVE_ROLE'
  comment = 'User for the CARVE App';

GRANT ROLE PC_CARVE_ROLE TO USER PC_CARVE_SERVICE_USER;

/*Grant Usage on the Warehouse to the Role*/
grant usage on warehouse [warehouse name] to role PC_CARVE_ROLE;

/* Create database to be used by the CARVE role */
create database PC_CARVE_DB comment = 'Database used to store data from the CARVE App';

/* Grant permissions on the database to the CARVE app */
grant usage, create schema on database PC_CARVE_DB to role PC_CARVE_ROLE;

/* Grant permissions to the Snowflake shared database to the CARVE app */
grant imported privileges on database snowflake to role PC_CARVE_ROLE;

/* Switch to the CARVE role to create the remaining needed schemas */
use role PC_CARVE_ROLE;

/* Create schemas that will be used by the CARVE App within the CARVE Database */
create schema PC_CARVE_DB.CARVE_HISTORY with managed access;
create schema PC_CARVE_DB.CHANGE_TRACKING with managed access;
create schema PC_CARVE_DB.INIT with managed access;
create schema PC_CARVE_DB.SNAPSHOTS with managed access;
create transient schema PC_CARVE_DB.TEMP with managed access;

/* **********************************************************************************************************************************************
*                                                                                                                                               *
*                                                   Grant Permissions to Objects Needing to be Tracked                                          *
*                                                                                                                                               *
********************************************************************************************************************************************** */
use role accountadmin;
create or replace procedure PC_CARVE_DB.INIT.PROC_SET_ACCESS_FOR_CARVE_APP()
returns string
language javascript
EXECUTE AS CALLER
as
$$
/*
    The DATABASES_TO_EXCLUDE list needs to be double quote qualified names of the
    exact databases that CARVE should not have Usage permissions granted.
*/
var DATABASES_TO_EXCLUDE = ["SNOWFLAKE_SAMPLE_DATA", "OTHER_DB"];
/*
    The SCHEMAS_TO_EXCLUDE list needs to be single quote qualified names, with the database
    name enclosed by double quotes of the schemas that CARVE should not have Usage permissions granted:

        Example:  ['"EXAMPLE_DB"."EXAMPLE_SCHEMA"', '"EXAMPLE_DB_2"."EXAMPLE_SCHEMA_2"'];
*/
var SCHEMAS_TO_EXCLUDE = ['"DEVELOPMENT_DB"."IMPORT"', '"CLIENT_1_DB"."TEST_SCHEMA_1"'];

var my_qry = "show databases";
var my_stmt = snowflake.createStatement({sqlText: my_qry});
var my_res = my_stmt.execute();


while (my_res.next())
    {
        var db_name = my_res.getColumnValue("name");
        var origin = my_res.getColumnValue("origin");
        
        if (!DATABASES_TO_EXCLUDE.includes(db_name)){

            if(origin.length > 0){
                var my_qry_3 = "grant IMPORTED PRIVILEGES on database \"" + db_name + "\" to role PC_CARVE_ROLE;"
                var my_stmt_3 = snowflake.createStatement({sqlText: my_qry_3});
                my_stmt_3.execute();
            }
            else{
                  var my_qry_2 = "grant usage on database \"" + db_name + "\" to role PC_CARVE_ROLE;"
                  var my_stmt_2 = snowflake.createStatement({sqlText: my_qry_2});
                  my_stmt_2.execute();
                  
                  var my_qry_3 = "grant usage on future schemas in database \"" + db_name + "\" to role PC_CARVE_ROLE;"
                  var my_stmt_3 = snowflake.createStatement({sqlText: my_qry_3});
                  my_stmt_3.execute();

                  var my_qry_4 = "grant select on future tables in database \"" + db_name + "\" to role PC_CARVE_ROLE;"
                  var my_stmt_4 = snowflake.createStatement({sqlText: my_qry_4});
                  my_stmt_4.execute();

                  var my_qry_5 = "grant select on future views in database \"" + db_name + "\" to role PC_CARVE_ROLE;"
                  var my_stmt_5 = snowflake.createStatement({sqlText: my_qry_5});
                  my_stmt_5.execute();
                  
                  var my_qry_6 = "show schemas in database \"" + db_name + "\";"
                  var my_stmt_6 = snowflake.createStatement({sqlText: my_qry_6});
                  var my_res_6 = my_stmt_6.execute();

                  while (my_res_6.next())
                  {
                      var sch_name = my_res_6.getColumnValue("name");
                      var fqn_schema = "\"" + db_name + "\"" + "." + "\"" + sch_name + "\"";

                      if (!SCHEMAS_TO_EXCLUDE.includes(fqn_schema) && sch_name !== "INFORMATION_SCHEMA"){
                          var my_qry_7 = "grant usage on schema " + fqn_schema + " to role PC_CARVE_ROLE;"
                          var my_stmt_7 = snowflake.createStatement({sqlText: my_qry_7});
                          my_stmt_7.execute();

                          var my_qry_8 = "grant select on future tables in schema " + fqn_schema + " to role PC_CARVE_ROLE;"
                          var my_stmt_8 = snowflake.createStatement({sqlText: my_qry_8});
                          my_stmt_8.execute();

                          var my_qry_9 = "grant select on future views in schema " + fqn_schema + " to role PC_CARVE_ROLE;"
                          var my_stmt_9 = snowflake.createStatement({sqlText: my_qry_9});
                          my_stmt_9.execute();

                          var my_qry_10 = "grant select on all tables in schema " + fqn_schema + " to role PC_CARVE_ROLE;"
                          var my_stmt_10 = snowflake.createStatement({sqlText: my_qry_10});
                          my_stmt_10.execute();

                          var my_qry_11 = "grant select on all views in schema " + fqn_schema + " to role PC_CARVE_ROLE;"
                          var my_stmt_11 = snowflake.createStatement({sqlText: my_qry_11});
                          my_stmt_11.execute();
                      }
                  }
            }
        }
    }
return 'Permissions Granted for user PC_CARVE_SERVICE_USER Successfully';
$$;

USE ROLE ACCOUNTADMIN;
CALL PC_CARVE_DB.INIT.PROC_SET_ACCESS_FOR_CARVE_APP();

/* **********************************************************************************************************************************************
*                                                                                                                                               *
*                                                                       END OF SETUP                                                            *
*                                                                                                                                               *
********************************************************************************************************************************************** */`;

  }
  getConnections(passwordLoad=null){
    let connURL = this.url + '/connection/all'
    this.api.getApiData(connURL, []).subscribe(
        data=>{
          this.passwords = data['message'];
          let has_catalog = false;
          for(let p of this.passwords){
            if(p.user_type == 'catalog') has_catalog = true;
          }
          this.passwordsOG = JSON.parse(JSON.stringify(this.passwords));
          if(passwordLoad != null) passwordLoad.complete();
          
          if(this.connObj.typeOptions.length < 3 && this.auth.isAdmin() && !has_catalog){
            console.log('pushing new option')
            this.connObj.typeOptions.push({label: 'Carve Service Account (Catalog)', value: 'catalog', icon: "fa-solid fa-user-shield"})
            this.connObj.typeOptions = this.connObj.typeOptions.slice(0);
          }
          
        }
    )
  }

  newPasswordForm(): FormGroup {
     return this.fb.group({
        username: ['', Validators.required],
        password:['', [Validators.required]],
        system:['', [Validators.required]],
        user_type:['', [Validators.required]]
      })
  }
  updatePasswordForm(password_obj): FormGroup {
     let p = password_obj;
     return this.fb.group({
        connection_id: [p.id, [Validators.required]],
        username:[p.username, [Validators.required]],
        password:['', [Validators.required]],
        system:[p.system, [Validators.required]],
        user_type:[p.user_type, [Validators.required]]
      })
  }

  editPasswordFormControl() {
    return this.editPasswordForm.controls;
  }

  passwordFormControl() {
    return this.passwordForm.controls;
  }

  enableEdit(connection){
    connection.is_edit = true;
    this.editPasswordForm = this.updatePasswordForm(connection)
  }

 
  openDeleteConnModal(content, options={}, connection){
    
    this.selectedConn = connection;
    this.selectedConn.deleting = false;
    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
      });
  }

  // deletePassword(){

  //   this.saving.delete = true;
  //   let headers = this.headers
  //   let url = '';
  //   if(this.userObj.delete.invited){
  //      url = this.url + '/user/invite/' + this.userObj.delete.id;
  //   }
  //   else{   
  //      url = this.url + '/user/' + this.userObj.delete.id;
  //   }
   
  //   this.http.delete(url, {headers}).subscribe(
  //    data => {
  //      this.toastrService.success('User removed successfully');
  //      this.saving.delete = false;
  //      this.modalService.dismissAll();
  //      this.getUsers();
  //    },
  //    err=>{
  //      this.toastrService.error('Unable to remove user');
  //      this.saving.delete = false;
  //      this.modalService.dismissAll();
  //    });
  // }

  save(){
    if(this.passwordForm.valid){
      let data = this.passwordForm.value;
      console.log(data)
      data.id = 4;
      data.is_edit = false;
      this.passwords.push(data);
    }
  }
  
  onSearchChange(searchValue : string ) {  
      if (searchValue != ''){
        this.passwords = this.searchUsers(searchValue);
      }
      else{
        this.clear()
      }
  }

  searchUsers(searchValue){
    let passwordIds = []
    let newPasswords = []

    for(let i = 0;i < this.passwords.length;i++){
      let item = this.passwords[i]
      
      for(let property in item){
        if(item[property] !== null && property in this.passwordFields){
          let val = item[property].toLowerCase()
           if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
               if(!(passwordIds.includes(item.id))){
                 passwordIds.push(item.id)
                 newPasswords.push(item)
               }
           }
        }
      }
    }
      
    return newPasswords;

  }
  changeUserType(event){
    if(event=='catalog'){
      // set the username
      this.passwordForm.patchValue({
        username: 'PC_CARVE_SERVICE_USER'
      }); ;
      // open the modal 
      this.modalService.open(this.setupModal, { windowClass: 'modal-lg animate', centered: true, backdrop: 'static', keyboard: false}).result.then((result) => {
        }, (reason) => {
            // console.log(reason)
          
       });
    }
    
  }
  clear(){
      this.passwords =JSON.parse(JSON.stringify(this.passwordsOG))
      this.search = '';
  }

  copy(){
    let val = this.startupSQL;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastrService.success('Copied to clipboard');
  }

  saveMasterConnection(connection, connectionObj, connectionForm){
    
    let url = this.url + '/connection/master'
    let headers = this.headers

    if('connection_id' in connection){
      this.http.put(url, JSON.stringify(connection), {headers}).subscribe(
        data=>{ 
            if(data['status'] == 'success'){
              // Set the new connection
              let conn = data['message']
              this.getConnections();
              this.toastrService.success('Updated Catalog connection');
              connectionObj.saving = false;
           } 
           else{
               connectionObj.saving = false;
               this.toastrService.error('Failed to update Catalog connection');
           } 
      },
      error=>{
        connectionObj.saving = false;
        this.toastrService.error('Failed to update Catalog connection');
        
      });
    }
    else{

      this.http.post(url, JSON.stringify(connection),{headers}).subscribe(
          data => {
              this.getConnections();
              this.toastrService.success('Added Catalog connection');
              this.saving = false;
              this.passwordForm.reset(this.newPasswordForm());
              connectionForm.reset()
              
              let refresh_url = this.url + '/jobs/next_execution'
              this.http.post(refresh_url, {},{headers}).subscribe(data=>{
                // Succesfull update for Refresh jobs
                // console.log('Running Refresh jobs: ', data)
                
              })
             
            },
            err => {
              console.log('Error: ', err)
              this.saving = false;
              if( err['error']['message'] == 'connection failed'){
                this.saving = false;
                this.displayMessage = 'Failed to connect to your Snowflake account. Please check your username and password. Or you may need to whitelist the Carve IP address: 34.222.123.100.'
              }
              else{
                this.displayMessage = err['error']['message']
              }
      });  
   }
  }

  saveConnection(saveType, connectionObj){
    var connection;
    var connForm;

    if(saveType == 'new'){
     connection = this.passwordForm.value;
     connForm = this.passwordForm;
     this.saving = true;
    }
    else{
     connection = this.editPasswordForm.value;
     connForm = this.editPasswordForm;
     connectionObj.saving = true;
    }

    if(!connForm.valid){
      this.saving = false;
      if(connectionObj != null){
        connectionObj.saving = false;
      }
      return;
    }

    if(connection.user_type == 'catalog'){
      this.saveMasterConnection(connection, connectionObj, connForm)
      return;
    }
    
    let connURL = this.url + '/connection'
    let params: HttpParams = new HttpParams();
    let headers = this.headers
    
    if('connection_id' in connection){
      connURL = connURL + '/' + connection.connection_id
      this.http.put(connURL,  JSON.stringify(connection), {headers}).subscribe(
        data=>{ 
            if(data['status'] == 'success'){
              // Set the new connection
              let conn = data['message']
              this.getConnections();
              this.toastrService.success('Updated connection');
              connectionObj.saving = false;
           } 
           else{
               connectionObj.saving = false;
               this.toastrService.error('Failed to update connection');
           } 
      },
      error=>{
        connectionObj.saving = false;
        this.toastrService.error('Failed to update connection');
        
      });
    }
    else{
      this.http.post(connURL, JSON.stringify(connection), {headers}).subscribe(
        data=>{ 
            if(data['status'] == 'success'){
              
              this.saving = false;
              // Set the new connection
              // console.log('Added new connection: ', data['message'])
              let conn = data['message']
              this.getConnections();
              this.toastrService.success('Added connection successfully');
              this.saving = false;
              connForm.reset()
           }  
           else{
               this.saving = false;
               this.toastrService.error('Failed to add new connection');
           }  
      },
      error=>{
        this.saving = false;
        console.log(error)
        this.toastrService.error('Failed to add new connection');
      })
    }
  }
  deleteConn(connection){
      connection.deleting = true;
      let connURL = this.url + '/connection/' + connection.connection_id
      if(connection.user_type == 'catalog'){
        connURL = this.url + '/connection/master/' + connection.connection_id
      }
      
      let params: HttpParams = new HttpParams();
      let headers = this.headers
     
      this.http.delete(connURL, {headers}).subscribe(
          data=>{ 
              connection.deleting = false;
              if(data['status'] == 'success'){
                let message = data['message']
                this.getConnections();
                this.toastrService.success('Deleted connection');
              }
               this.modalService.dismissAll();
        },
        err=>{
               this.modalService.dismissAll();
            } 
      );

  }


}
