import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { TablesService } from '../tables.service';
import { DatePipe } from '@angular/common'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import * as am4plugins_regression from "@amcharts/amcharts4/plugins/regression"; 
import { Subscription } from 'rxjs/Subscription';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { AppService } from '../../../../app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApiService } from '../../../../shared/api/api.service';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';


//import { Observable } from 'rxjs/Observable';
//import { Subject } from 'rxjs/Subject';
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-table-overview',
  templateUrl: './table-overview.component.html',
  styles: []
})
export class TableOverviewComponent implements OnInit{

	databaseName = ''
	schemaName = ''
	tableName = ''
    objectType = ''

	url: string = environment.url;
	display: string = ''
	items: Array<any> = []
	view: String = '';
	routes: Array<any> = []
	scripts: Object = {}
    tableSubscription: Subscription;
	summary_loaded = false;
	summary_url: string = ''
	rowsData: Array<any> = [{}];
	storageData: Array<any> = [{}];
    pageName = 'Table_Overview';
    //private status$ = new Subject<any>();

    ddlLoaded = false;
    queriesLoaded = false;
    storageLoaded = false;
    growthLoaded = false;
    gettingChart = false;
    refresh = true;
    chartDDL;
    growthChart;
    storageChart;
    radarChart;

    public summary: any = {
      "table_total_size":'...',
      "column_count": 0,
      "column_count_sum": '',
      "table_row_count":0,
      "table_row_count_sum": '',
      "created":"...",
      "last_queried": "...",
      "last_updated": "...",
      "table_type":"...",
      "time_travel":"...",
      "table_owner":"...",
      "has_invalid_constraint_or_key": "...",
      "has_row_growth_outside_stdev": "...",
      "last_key_validation_date": "...",
      "loaded": false 
    };

	constructor(
		public http: HttpClient,
		public route: ActivatedRoute,
		public auth: AuthService,
		public tables: TablesService,
		private datePipe: DatePipe,
        private appService: AppService,
        private loadingBar: LoadingBarService,
        private api: ApiService
	) { 
		
		this.storageData[0]['label'] = 'Storage MB'
		this.rowsData[0]['label'] = 'Rows'
        this.ddlLoaded = false;
        this.queriesLoaded = false;
        this.storageLoaded = false;
        this.growthLoaded = false;
        this.refresh = true;
        this.appService.pageTitle = 'Table Overview';
        console.log(this.summary)
       
    }


	ngOnInit(){

        console.log('ngOnInit table overview')
        this.resetSummary();
        this.resetCharts();
        var tableObj = this.tables.getTableObj()
        this.databaseName = tableObj['database']
        this.schemaName = tableObj['schema']
        this.tableName = tableObj['table']
        this.objectType = tableObj['object_type']
        this.gettingChart = true;
        this.getData();

        this.tableSubscription = this.tables.getTableObject().subscribe(
            data=>{
                if( this.tables.view == 'overview'){
                   
               
                    let case1 = false
                    if(this.databaseName != data['database']){
                        case1 = true
                    }
                    let case2 = false
                    if(this.schemaName != data['schema']){
                        case1 = true
                    }
                    let case3 = false
                    if(this.tableName != data['table']){
                        case1 = true
                    }


                    if(case1 || case2 || case3){
                        this.resetSummary();
                        this.clearChartDivs()
                        this.databaseName = data['database']
                        this.schemaName = data['schema']
                        this.tableName = data['table']
                        this.gettingChart = true;

                        this.resetCharts();
                        this.tables.resetData()
                        this.getData(true);
                        this.refresh = false;
                    }
                }
            }
        );
	}
    ngOnDestroy(){
        if(this.tableSubscription != undefined){
            this.tableSubscription.unsubscribe();
        }
        am4core.disposeAllCharts();
    }

    resetSummary(){
      this.summary = {
          "table_total_size":0,
          "column_count": 0,
          "column_count_sum": '...',
          "table_row_count":0,
          "table_row_count_sum": '',
          "created":"...",
          "last_queried": "...",
          "last_updated": "...",
          "table_type":"...",
          "time_travel":"...",
          "table_owner":"...",
          "has_invalid_constraint_or_key": "...",
          "has_row_growth_outside_stdev": "...",
          "last_key_validation_date": "...",
          "loaded": false 
        };
    }
    getData(force?:boolean){
        this.summary.loaded = false  
        //let params: HttpParams = new HttpParams();
        //params = params.append('db', this.databaseName)
        //params = params.append('schema', this.schemaName)
        //params = params.append('table', this.tableName)
     
        let summaryUrl = this.url + '/catalog/'+ this.objectType + '/summary';
        let graphUrl = this.url + '/catalog/'+ this.objectType + '/graph';

        let exactPageName = this.pageName + '_' + this.databaseName + '_' + this.schemaName + '_' + this.tableName
        let params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName}]
        let growth_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName},{key: "graph", value: "growth"}]
        let storage_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName},{key: "graph", value: "storage"}]
        let qry_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName},{key: "graph", value: "queries"}]
        let ddl_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName},{key: "graph", value: "ddl"}]

        let chart1Loader = this.loadingBar.useRef('chart1')
        let chart2Loader = this.loadingBar.useRef('chart2')
        let chart3Loader = this.loadingBar.useRef('chart3')
        let chart4Loader = this.loadingBar.useRef('chart4')

        chart1Loader.start()
        chart2Loader.start()
        chart3Loader.start()
        chart4Loader.start()
        
        chart1Loader.set(70)
        chart2Loader.set(50)
        chart3Loader.set(60)
        chart4Loader.set(20)
      
        let tableObj = {
          database: this.databaseName,
          schema: this.schemaName,
          table: this.tableName
        }
        // this.tables.getSummary()
        // this.tables.getGraph()

        // if(this.tables.compareTables(this.tables.getGraphTable(),tableObj) && Object.keys(this.tables.summaryData).length > 0 && this.tables.graphData){
        //     this.summary = this.tables.summaryData
        //     console.log('Getting cached summary')
        //     console.log('Getting cached charts')
        //     this.displayGrowthChart(this.tables.graphData['growth'])
        //     this.displayStorageChart(this.tables.graphData['storage'])
        //     this.displayQueryChart(this.tables.graphData['queries'])
        //     this.displayDDLQueryChart(this.tables.graphData['ddl']) 
        // }
        // else{
        //this.tables.setData('graphTable', tableObj)
        
        this.api.getData(summaryUrl, this.pageName, 'overview_summary', {api: force, params: params}).subscribe(
            data=>{
                 this.summary = data['message'][0]
                 this.summary.loaded = true
                 console.log('summary: ', this.summary)
                 // this.api.setData(exactPageName, 'overview_summary', data)
                 
                //  let mbs_converted = this.convertNumber(this.summary.table_mbs, 'bytes');
                // this.summary.table_mbs = mbs_converted.num
                // this.summary.table_mbs_sum = mbs_converted.str

                let col_converted = this.convertNumber(this.summary.column_count, 'number');
                this.summary.column_count = col_converted.num
                this.summary.column_count_sum = col_converted.str

                let row_converted = this.convertNumber(this.summary.table_row_count, 'number');
                this.summary.table_row_count = row_converted.num
                this.summary.table_row_count_sum = row_converted.str
                // this.api.setData(this.pageName, 'summary', this.summary)
            }
        );

        if(this.objectType == 'table'){
            this.api.getData(graphUrl, this.pageName, 'overview_growth', {api: force, params: growth_params}).subscribe(
            data=>{
                 this.displayGrowthChart(data['message'])
                 chart1Loader.complete()
                 // this.api.setData(this.pageName, 'overview_growth', data)
            }
            );
            
            this.api.getData(graphUrl, this.pageName, 'overview_storage', {api: force, params: storage_params}).subscribe(
                data=>{
                     this.displayStorageChart(data['message'])
                     chart2Loader.complete()
                     // this.api.setData(this.pageName, 'overview_storage', data)
                }
            );
        }
        
        this.api.getData(graphUrl, this.pageName, 'overview_queries', {api: force, params: qry_params}).subscribe(
            data=>{
                 this.displayQueryChart(data['message']) 
                 chart3Loader.complete()
                 // this.api.setData(this.pageName, 'overview_queries', data)
            }
        );
        
        this.api.getData(graphUrl, this.pageName, 'overview_ddl', {params: ddl_params}).subscribe(
            data=>{
                 this.displayDDLQueryChart(data['message'])
                 chart4Loader.complete()
                 // this.api.setData(this.pageName, 'overview_ddl', data)
            }
        );
        
        this.gettingChart = false;

        
  
  }


   resetCharts(){
       this.ddlLoaded = false;
       this.queriesLoaded = false;
       this.storageLoaded = false;
       this.growthLoaded = false;
       am4core.disposeAllCharts()
   }
 
  	
 //
   clearChartDivs(){
       if(document.querySelector('div#DDLQueryChartDiv') != null){
           document.querySelector('div#DDLQueryChartDiv').innerHTML = "";
       }
       if(document.querySelector('div#storageChartDiv') != null){
           document.querySelector('div#storageChartDiv').innerHTML = "";
       }
        if(document.querySelector('div#queryChartDiv') != null){
           document.querySelector('div#queryChartDiv').innerHTML = "";
       }
        if(document.querySelector('div#growthChartDiv') != null){
           document.querySelector('div#growthChartDiv').innerHTML = "";
       }
   }



	// http://35.163.105.221:8000/catalog/databases/CLIENT_1_DB/schemas/TEST_SCHEMA_1/tables/TEST_DATA_TYPES_1_NO_PK/scripts

   private displayDDLQueryChart(chartData){

    //am4core.useTheme(am4themes_animated);
    let chartDDL = am4core.create("DDLQueryChartDiv", am4charts.XYChart);
    chartDDL.data = chartData;
    chartDDL.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chartDDL.zoomOutButton.parent = chartDDL.tooltipContainer;
    chartDDL.zoomOutButton.valign = "bottom";
    chartDDL.zoomOutButton.scale = .6;
 
    chartDDL.legend = new am4charts.Legend();
    chartDDL.legend.useDefaultMarker = true;
    chartDDL.responsive.enabled = true;

    // chartDDL.legend.maxHeight = 30;
    
    //Title
    //let title = chartDDL.titles.create();
    //title.text = "Total Queries by Day";
    //title.fontSize = 18;
    // title.marginBottom = 5;
    
    let marker:any = chartDDL.legend.markers.template.children.getIndex(0);
    marker.cornerRadius(12, 12, 12, 12);
    marker.scale = .7;

    var dateAxis = chartDDL.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    //dateAxis.renderer.labels.template.location = 0.0001;
    dateAxis.start = .6;
    dateAxis.end = 1;
    dateAxis.keepSelection = true;
    dateAxis.tooltip.disabled = true;

    var valueAxis = chartDDL.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.extraMin = 0.2;
    valueAxis.extraMax = 0.2; 
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    
    let valueAxis2 = chartDDL.yAxes.push(new am4charts.ValueAxis());
    //valueAxis2.title.text = "Market Days";
    valueAxis2.tooltip.disabled = true;
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.renderer.labels.template.disabled = true;

    //Table creation series//
    var seriesCreate = chartDDL.series.push(new am4charts.ColumnSeries());
    seriesCreate.dataFields.dateX = "date";
    seriesCreate.dataFields.valueY = "table_create";
    seriesCreate.fill = chartDDL.colors.getIndex(8);
    seriesCreate.stroke = chartDDL.colors.getIndex(8);
    seriesCreate.clustered = false;

    //series.dataFields.temp = "mod_type";
    //seriesCreate.tooltipText = "Table Creation";
    if(this.objectType == 'view'){
        seriesCreate.name = "View Creation";
    }
    else{
        seriesCreate.name = "Table Creation";
    }
   
    seriesCreate.sequencedInterpolation = true;
    seriesCreate.yAxis = valueAxis;
    //series.fillOpacity = 0;
    //seriesCreate.strokeOpacity = 0;
    //seriesCreate.strokeDasharray = "1,3";
    seriesCreate.columns.template.width = 2;
    seriesCreate.tooltip.pointerOrientation = "horizontal";
    
    let bulletCreate = seriesCreate.bullets.push(new am4charts.CircleBullet());
    bulletCreate.circle.radius = 4;
    bulletCreate.fill = chartDDL.colors.getIndex(8);
    bulletCreate.stroke = chartDDL.colors.getIndex(8);
    
    
    //Table Update series//
    var seriesUpdate = chartDDL.series.push(new am4charts.ColumnSeries());
    seriesUpdate.dataFields.dateX = "date";
    seriesUpdate.dataFields.valueY = "table_update";
    seriesUpdate.fill = chartDDL.colors.getIndex(3);
    seriesUpdate.stroke = chartDDL.colors.getIndex(3);
    //seriesUpdate.tooltipText = "Table Update";
    if(this.objectType == 'view'){
        seriesUpdate.name = "View Update";
    }
    else{
        seriesUpdate.name = "Table Update";
    }
   
    seriesUpdate.yAxis = valueAxis;
    seriesUpdate.sequencedInterpolation = true;

    //seriesUpdate.strokeDasharray = "1,3";
    seriesUpdate.columns.template.width = 3;
    seriesUpdate.tooltip.pointerOrientation = "horizontal";
    seriesUpdate.clustered = false;
    let bulletUpdate = seriesUpdate.bullets.push(new am4charts.CircleBullet());
    bulletUpdate.circle.radius = 4;
    bulletUpdate.fill = chartDDL.colors.getIndex(3);
    bulletUpdate.stroke = chartDDL.colors.getIndex(3);
    
    //Column Change series//
    var seriesChange = chartDDL.series.push(new am4charts.ColumnSeries());
    seriesChange.dataFields.dateX = "date";
    seriesChange.dataFields.valueY = "column_change";
    seriesChange.fill = chartDDL.colors.getIndex(6);
    seriesChange.stroke = chartDDL.colors.getIndex(6);
    seriesChange.opacity = 50;
    //seriesChange.tooltipText = "Column Changed";
    seriesChange.name = "Column Changed";
    seriesChange.yAxis = valueAxis;
    seriesChange.sequencedInterpolation = true;
    //seriesChange.strokeOpacity = 0;
    //seriesChange.strokeDasharray = "1,3";
    seriesChange.columns.template.width = 4;
    seriesChange.tooltip.pointerOrientation = "horizontal";
    seriesChange.clustered = false;
    let bulletChange = seriesChange.bullets.push(new am4charts.CircleBullet());
    bulletChange.circle.radius = 4;
    bulletChange.fill = chartDDL.colors.getIndex(6);
    bulletChange.stroke = chartDDL.colors.getIndex(6);
    
    
    
    let lineSeries = chartDDL.series.push(new am4charts.LineSeries());
    lineSeries.name = "Queries";
    lineSeries.dataFields.valueY = "total_queries";
    lineSeries.dataFields.dateX = "date";
    lineSeries.tensionX = 0.7;
    lineSeries.yAxis = valueAxis2;
    //series.tooltipText = "{dateX.formatDate('yyyy-mm')}: {valueY.formatNumber('#.00')}";
    lineSeries.tooltipText = `[bold]{date.formatDate('MMM dd')}[/]
{total_queries} Queries`;
    lineSeries.tooltip.pointerOrientation = "vertical";
    lineSeries.stroke = chartDDL.colors.getIndex(0);
    lineSeries.fill = chartDDL.colors.getIndex(0);
    lineSeries.strokeWidth = 3;
    lineSeries.propertyFields.strokeDasharray = "lineDash";
    lineSeries.tooltip.label.textAlign = "middle";
    
    let bullet3 = lineSeries.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color("#fff");

    chartDDL.cursor = new am4charts.XYCursor();
    chartDDL.cursor.lineY.disabled = true;
    chartDDL.cursor.lineX.disabled = true;
    chartDDL.paddingTop = 10;
    chartDDL.paddingBottom = 10;
    chartDDL.paddingRight = 30;
    chartDDL.paddingLeft = 20;

    this.ddlLoaded = true;
    this.chartDDL = chartDDL;
    //this.tables.addChart(chartDDL, 'ddlChart')
  }

  private displayGrowthChart(chartData){
   
    //AMCHARTS - Table Growth Section - START
    //am4core.useTheme(am4themes_animated);
    let growthChart = am4core.create("growthChartDiv", am4charts.XYChart);
    //chart data query
    //select DATE, TABLE_GROWTH_ROWS, TABLE_GROWTH_BYTES
    //from catalog.table_growth
    //where database_name = 'PRODUCTION_DB'
    //AND SCHEMA_NAME = 'ECOMMERCE'
    //AND TABLE_NAME = 'STORE_SALES'
    //AND date >= (current_date + interval '-365 days')
    //ORDER BY 1;
    growthChart.data = chartData
    growthChart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    growthChart.zoomOutButton.parent = growthChart.tooltipContainer;
    growthChart.zoomOutButton.valign = "bottom";
    growthChart.zoomOutButton.scale = .6;

    
    //Title
    //let title = growthChart.titles.create();
    //title.text = "Table Growth in Rows per Day";
    //title.fontSize = 18;
    //title.marginBottom = 25;
    
    // Create axes
    let dateAxis = growthChart.xAxes.push(new am4charts.DateAxis());
    let valueAxis = growthChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    dateAxis.tooltip.disabled = true;

    // Create series
    let series = growthChart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "table_growth_rows";
    series.dataFields.dateX = "date";
    series.tensionX = 0.7;
    series.tooltipText = `[bold]{date.formatDate('MMM dd')}[/]
{table_growth_rows} Rows`;
    series.minBulletDistance = 10;
    series.strokeWidth = 3;
    series.fillOpacity = 0.5;
    
    let bullet3 = series.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color("#fff");
    
    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";
    
    // Make a panning cursor
    growthChart.cursor = new am4charts.XYCursor();
    //chart.cursor.behavior = "zoomX";
    growthChart.cursor.xAxis = dateAxis;
    //growthChart.cursor.snapToSeries = series;
    growthChart.cursor.lineY.disabled = true;
    growthChart.cursor.lineX.disabled = true;
    growthChart.paddingRight = 0;
    growthChart.paddingLeft = 0;
    growthChart.paddingTop = 10;
    growthChart.paddingBottom = 10;

    // Create a horizontal scrollbar with previe and place it underneath the date axis
    //chart.scrollbarX = new am4charts.XYChartScrollbar();
    //chart.scrollbarX.series.push(series);
    //chart.scrollbarX.parent = chart.bottomAxesContainer;

    dateAxis.start = 0.60;
    dateAxis.keepSelection = true;
    // Remove excess padding from charts
    //dateAxis.renderer.labels.template.marginBottom = -15;
    //dateAxis.renderer.labels.template.paddingBottom = 0;
    //valueAxis.renderer.labels.template.marginLeft = -20;
    //valueAxis.renderer.labels.template.paddingLeft = 0;
    // Turn off the gridlines
    dateAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    
    let regseries = growthChart.series.push(new am4charts.LineSeries());
    regseries.dataFields.valueY = "table_growth_rows";
    regseries.dataFields.dateX = "date";
    regseries.strokeWidth = 2;
    regseries.name = "Trend";



    let reg = regseries.plugins.push(new am4plugins_regression.Regression());
    reg.method = "polynomial";
    
    //Display a range for standard deviation - need to query to get this data separate from the rest of the data as it only needs 2 data points
    //select cast(avg_row_growth_30 as bigint) avg_row_growth_last_30_days
    //,cast(stdev_row_growth_30 as bigint) standard_deviation_row_growth_30_days
    //,cast(case when (avg_row_growth_30 - stdev_row_growth_30) < 0 then 0 else (avg_row_growth_30 - stdev_row_growth_30) end as bigint) lower_range_band
    //,cast(avg_row_growth_30 + stdev_row_growth_30 as bigint) upper_range_band
    //from catalog.table_growth_standard_deviations
    //where database_name = 'PRODUCTION_DB'
    //AND SCHEMA_NAME = 'ECOMMERCE'
    //AND TABLE_NAME = 'STORE_SALES'
    //
    //
    let range = valueAxis.axisRanges.create();
    range.value = 150;
    range.endValue = 300;
    range.axisFill.fill = am4core.color("#396478");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
    this.growthLoaded = true;
    this.growthChart = growthChart;
    //this.tables.addChart(growthChart, 'growthChart')

  }
  
  private displayStorageChart(chartData){
     

    let storageChart = am4core.create("storageChartDiv", am4charts.XYChart);
    //QUERY TO GET THE DATA
    //select date
    //    ,round(((1.0 * table_storage_metrics_active_bytes) / 1000000), 1) table_active_megabytes
          // ,round(((1.0 * table_storage_metrics_time_travel_bytes) / 1000000), 1)  table_time_travel_megabytes
          // ,round(((1.0 * table_storage_metrics_failsafe_bytes) / 1000000), 1)  table_failsafe_megabytes
          // ,round(((1.0 * table_storage_metrics_retained_for_clone_bytes) / 1000000), 1)  table_retained_for_clone_megabytes
           // ,round((1.0 * table_storage_metrics_active_bytes) / ((1.0 * table_storage_metrics_active_bytes) 
            // + table_storage_metrics_time_travel_bytes 
            // + table_storage_metrics_failsafe_bytes 
            // + table_storage_metrics_retained_for_clone_bytes),2) table_active_bytes_percent_of_total_bytes
        // from catalog.table_storage_metrics_daily
        // where database_name = 'PRODUCTION_DB'
          // and schema_name = 'ECOMMERCE'
          // and table_name = 'STORE_SALES'
        // ORDER BY 1;
        
    storageChart.data = chartData
    storageChart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    storageChart.zoomOutButton.parent = storageChart.tooltipContainer;
    storageChart.zoomOutButton.valign = "bottom";
    storageChart.zoomOutButton.scale = .6;
    storageChart.paddingRight = 0;
    storageChart.paddingLeft = 0;
    storageChart.paddingTop = 10;
    storageChart.paddingBottom = 10;
    storageChart.dateFormatter.dateFormat = "MMM dd";
    
    //Title
    let title = storageChart.titles.create();
    //title.text = "Storage by Day";
    //title.fontSize = 18;
    title.marginBottom = 10;
    
    // Create axes
    let dateAxis = storageChart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.date = "date";
    dateAxis.tooltip.disabled = true;

    //dateAxis.keepSelection = true;
    //dateAxis.renderer.grid.template.disabled = true;
    dateAxis.dateFormatter.dateFormat = "MMM dd";
    
    let valueAxis = storageChart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;

    
    let valueAxis2 = storageChart.yAxes.push(new am4charts.ValueAxis());
    //valueAxis2.title.text = "Active % of Total";
    valueAxis2.renderer.opposite = true;
    valueAxis2.tooltip.disabled = true;
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.numberFormatter = new am4core.NumberFormatter();
    valueAxis2.numberFormatter.numberFormat = "#%";

    // Create series
    function createSeries(chart, field, name) {
      
      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.sequencedInterpolation = true;
      
      // Make it stacked
      series.stacked = true;
      
      // Configure columns
      series.columns.template.width = am4core.percent(60);
      //series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
      
      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      //labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;
      series.legendSettings.labelText = "[bold {color}]{name}[/]";
      series.legendSettings.valueText = "[bold]{valueY.close}[/] mb";
      series.legendSettings.itemValueText = "[bold]{valueY.close}[/] mb";
      
      return series;
    }

    // Create series
    createSeries(storageChart, "table_active_megabytes", "Active");
    createSeries(storageChart,"table_time_travel_megabytes", "Time Travel");
    createSeries(storageChart,"table_failsafe_megabytes", "Failsafe");
    createSeries(storageChart,"table_retained_for_clone_megabytes", "Clone Retained");
    
    let series3 = storageChart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = "table_active_bytes_percent_of_total_bytes";
    series3.dataFields.dateX = "date";
    series3.name = "Active % of Total";
    series3.strokeWidth = 2;
    series3.tensionX = 0.7;
    series3.yAxis = valueAxis2;
    //series3.tooltipText = "{table_active_bytes_percent_of_total_bytes.percent}";
    series3.tooltipText = `[bold]{date.formatDate('MMM dd')}[/]
Active Storage % of Total: [bold]{table_active_bytes_percent_of_total_bytes.formatNumber('#%')}[/]
Active MB: [bold]{table_active_megabytes.formatNumber('#.#')}[/]
Time Travel MB: [bold]{table_time_travel_megabytes.formatNumber('#.#')}[/]
Failsafe MB: [bold]{table_failsafe_megabytes.formatNumber('#.#')}[/]
Retained for Clone MB: [bold]{table_retained_for_clone_megabytes.formatNumber('#.#')}[/]`;
    series3.tooltip.pointerOrientation = "vertical";
    series3.hiddenInLegend = true;

    let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
    bullet3.circle.radius = 3;
    bullet3.circle.strokeWidth = 2;
    bullet3.circle.fill = am4core.color("#fff");
    
    // Make a panning cursor
    storageChart.cursor = new am4charts.XYCursor();
    //storageChart.cursor.behavior = "zoomX";
    //storageChart.cursor.xAxis = dateAxis;
    //storageChart.cursor.snapToSeries = series;
    storageChart.cursor.lineY.disabled = true;
    storageChart.cursor.lineX.disabled = true;
    storageChart.paddingRight = 0;
    storageChart.paddingLeft = 0;
    storageChart.paddingTop = 5;
    storageChart.paddingBottom = 5;
    
    /* Add legend */
    storageChart.legend = new am4charts.Legend();
    storageChart.legend.markers.template.disabled = true;
    storageChart.legend.maxWidth = undefined;
    storageChart.legend.maxHeight = 50;


    storageChart.legend.itemContainers.template.paddingTop = 5;
    storageChart.legend.itemContainers.template.paddingBottom = 5;
    storageChart.legend.itemContainers.template.paddingLeft = 5;
    storageChart.legend.itemContainers.template.paddingRight = 5;
    
    dateAxis.start = 0.60;
    dateAxis.end = 1;
    dateAxis.keepSelection = true;
    this.storageLoaded = true;
    this.storageChart = storageChart;
    //this.tables.addChart(storageChart, 'storageChart')

}

dateAsYYYYMMDD(date) {
    return date.getFullYear()
            + '-' + this.leftpad(date.getMonth() + 1, 2)
            + '-' + this.leftpad(date.getDate(), 2);
}
leftpad(val, resultLength = 2, leftpadChar = '0') {
    return (String(leftpadChar).repeat(resultLength)
        + String(val)).slice(String(val).length);
}
datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second-first)/(1000*60*60*24));
}
formatDatePretty(date) {
    if (date !== undefined && date !== "") {
      var myDate = new Date(date);
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][myDate.getMonth()];
      var str = myDate.getDate() + " " + month;
      return str;
    }
    return "";
  }

  private displayQueryChart(chartData){
      /* Chart code */
    // Themes begin
    //am4core.useTheme(am4themes_animated);
    // Themes end



    // Create chart instance
    let chart = am4core.create("queryChartDiv", am4charts.RadarChart);
    // Add data
    chart.data = chartData;
/*
[{
  "category": "Research",
  "value": 80,
  "full": 100
}, {
  "category": "Marketing",
  "value": 35,
  "full": 100
}, {
  "category": "Distribution",
  "value": 92,
  "full": 100
}, {
  "category": "Human Resources",
  "value": 68,
  "full": 100
}];
*/
// Make chart not full circle
chart.startAngle = -90;
chart.endAngle = 180;
chart.innerRadius = am4core.percent(20);

// Set number format
chart.numberFormatter.numberFormat = "#.#";

// Create axes
let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis() as any);
categoryAxis.dataFields.category = "user_name";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.grid.template.strokeOpacity = 0;
categoryAxis.renderer.labels.template.horizontalCenter = "right";
categoryAxis.renderer.labels.template.fontWeight = 500;
categoryAxis.renderer.labels.template.adapter.add("fill", function(fill, target) {
  return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
});
categoryAxis.renderer.minGridDistance = 10;

let valueAxis = chart.xAxes.push(new am4charts.ValueAxis() as any);
valueAxis.numberFormatter.numberFormat = "#.#'%'";
valueAxis.renderer.grid.template.strokeOpacity = 0;
valueAxis.min = 0;
valueAxis.max = 100;
valueAxis.strictMinMax = true;

// Create series
let series1 = chart.series.push(new am4charts.RadarColumnSeries());
series1.dataFields.valueX = "full_percent";
series1.dataFields.categoryY = "user_name";
series1.clustered = false;
series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
series1.columns.template.fillOpacity = 0.08;
//series1.columns.template.cornerRadiusTopLeft = 20;
series1.columns.template.strokeWidth = 0;
series1.columns.template.radarColumn.cornerRadius = 20;

let series2 = chart.series.push(new am4charts.RadarColumnSeries());
series2.dataFields.valueX = "val";
series2.dataFields.categoryY = "user_name";
series2.clustered = false;
series2.columns.template.strokeWidth = 0;
//series2.columns.template.tooltipText = "{user_name}: [bold]{total_queries}[/] queries";
series2.columns.template.tooltipText = `[bold]{user_name}[/]
Queries: [bold]{total_queries.formatNumber('#')}[/]
Total Queries by all Users: [bold]{totals.formatNumber('#')}[/]
{user_name}'s % of Total: [bold]{val}[/]`;
series2.columns.template.radarColumn.cornerRadius = 20;

series2.columns.template.adapter.add("fill", function(fill, target) {
  return chart.colors.getIndex(target.dataItem.index);
});

// Add cursor
//chart.cursor = new am4charts.RadarCursor();




    this.radarChart = chart;
    //this.tables.addChart(radarChart, 'queriesChart')
    this.queriesLoaded = true;

  }

  convertNumber(num, num_type){
      let types = {
          'bytes': {k: 1024, m: 1024000, k_s: 'GB', m_s: 'TB'},
          'number': {k: 1000, m: 1000000, k_s: 'K', m_s: 'M'}
      }
      let new_num = num;
      let num_string = ''
      if(num >= types[num_type].k && num < types[num_type].m){
          new_num = num / types[num_type].k
          new_num = Math.round(new_num * 10) / 10
          num_string = types[num_type].k_s
      }
      else if(num >= types[num_type].m){
          new_num = num / types[num_type].m
          new_num = Math.round(new_num * 10) / 10
          num_string = types[num_type].m_s
      }
      else if(num_type == 'bytes'){
          num_string = 'MB'
      }
      
      return {num: new_num, str: num_string}
  }

  
  shortenText(text){
    var max = 7
    var div = 2
   
    var newText = text
    if(text.length > max){
      var len = text.length
      var toRemove = (len - max) + 1
      var start = (len - toRemove) / div
      var end = start+ toRemove
      newText = text.replace(text.substring(start, end), '...')
    }
    return newText;
  }

  countSummaryDates(){
    let count = 0;
    if (this.summary.created != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.created != null && this.summary.created != ''){
        count++;
    }
    if (this.summary.last_queried != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.last_queried != null && this.summary.last_queried != ''){
        count++;
    }
    if (this.summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.last_key_validation_date  != null && this.summary.last_key_validation_date != ''){
        count++;
    }
    if (this.summary.last_updated != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.last_updated != null && this.summary.last_updated != ''){
        count++;
    }
    return count;
  }
}
