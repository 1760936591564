<div class="container-fluid">
    <div class="row flex-grow-1" style="padding-top: 20px; padding-bottom: 20px" >
            <div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%" >
                        <div class="card-body" style="padding:0px;">
                            <div class="card-subtitle mb-2 card-subtitle-header">
                                          <span class="fa-light fa-table-list overview-icon" style="color:#ff7171"></span>
                                          <span class="text-big card-info-title">QUERIES</span>
                                      </div>
                                        
                                    <div class="card-info-stacked-2">
                                        
                                        <div class="text-center text-big">
                                            <span>{{summary.distinct_types_of_queries}}</span>
                                        </div>
                                        <div class="text-muted text-center">Distinct Query Types</div>
                                    </div>
                                    <div class="card-info-stacked-2">
                                        
                                        <div class="text-center text-big">
                                            <span>{{summary.distinct_users_who_queried}}</span>
                                        </div>
                                        <div class="text-muted text-center">Distinct Users</div>
                                    </div>
                        </div>
                </div>
            </div>
            <div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%" >
                        <div class="card-body" style="padding:0px;">
                            <div class="card-subtitle mb-2 card-subtitle-header">
                                          <span class="fal fa-sliders-v-square overview-icon" style="color:#62ae82;"></span>
                                          <span class="text-big card-info-title">PROFILE</span>
                                      </div>
                                        
                                    <div class="card-info-stacked-2">
                                        
                                        <div class="text-center text-big">
                                            <span >{{summary.day_with_highest_average_query_count}}</span>
                                        </div>
                                        <div class="text-muted text-center">Most Popular Day</div>
                                    </div>
                                    <div class="card-info-stacked-2">
                                        <div class="text-center text-big">
                                            <span *ngIf="summary.total_queries_sampled == '...'">{{summary.total_queries_sampled}}</span>
                                            <span *ngIf="summary.total_queries_sampled != '...'">{{summary.total_queries_sampled | number:'1.0':'en-US'}}</span>
                                        </div>
                                        <div class="text-muted text-center">Total Queries Sampled</div>
                                    </div>
                        </div>
                </div>
            </div>
            <div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%">
                    <div class="card-body" style="padding:0px;">
                        <div class="card-subtitle mb-2 card-subtitle-header"> 
                                  <span class="fal fa-calendar-day overview-icon" style="color:#8c6daf; margin-left: -20px;"></span>
                                  <span class="text-big card-info-title">DATES</span>
                              </div>
                        <div *ngIf="summary.loaded == false" class="card-info-stacked-1" style="font-size: 24px;">...</div>

                        
               

                            <div *ngIf="summary.loaded && summary.most_recent_insert_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_insert_query_start_time != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                                <div *ngIf="summary.most_recent_insert_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_insert_query_start_time != ''" class="text-big text-center">{{summary.most_recent_insert_query_start_time | dateAgo }}</div>
                                <div *ngIf="summary.most_recent_insert_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_insert_query_start_time != null && summary.most_recent_insert_query_start_time != ''" class="text-muted text-center">Last Insert</div>
                            </div>
                            

                            <div *ngIf="summary.loaded && summary.most_recent_merge_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_merge_query_start_time != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                                <div *ngIf="summary.most_recent_merge_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_merge_query_start_time != ''" class="text-big text-center">{{summary.most_recent_merge_query_start_time | dateAgo }}</div>
                                <div *ngIf="summary.most_recent_merge_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_merge_query_start_time != null && summary.most_recent_merge_query_start_time != ''" class="text-muted text-center">Last Merge</div>
                            </div>
                            
                            <div *ngIf="summary.loaded && summary.most_recent_update_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_update_query_start_time != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                                <div *ngIf="summary.most_recent_update_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_update_query_start_time != ''" class="text-big text-center">{{summary.most_recent_update_query_start_time | dateAgo }}</div>
                                <div *ngIf="summary.most_recent_update_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_update_query_start_time != null && summary.most_recent_update_query_start_time != ''" class="text-muted text-center">Last Update</div>
                            </div>
                            
                        <div *ngIf="summary.loaded && summary.most_recent_select_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_select_query_start_time != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                                <div *ngIf="summary.most_recent_select_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_select_query_start_time != ''" class="text-big text-center">{{summary.most_recent_select_query_start_time | dateAgo }}</div>
                                <div *ngIf="summary.most_recent_select_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.most_recent_select_query_start_time != null && summary.most_recent_select_query_start_time != ''" class="text-muted text-center">Last Select</div>
                            </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%">
                    <div class="card-body" style="padding:0px;">
                        
                        <div class="card-subtitle mb-2 card-subtitle-header" style="margin-bottom: 0px !important; padding-bottom: 5px;"> 

                                  <span class="fa-light fa-chart-user overview-icon" style="color:#ce7d00; margin-left: -20px;"></span>
                                  <span class="text-big card-info-title">TOP USERS</span>
                              </div>
                                
                              <div class="card-info-stacked-1" style="padding-left: 0px !important; overflow-y: auto !important; max-height: 100px; padding-top: 0px;">
                               
                                <table class="table" style="table-layout: fixed;">
                                    <thead style="padding-top: 0px;">
                                        <tr style="text-align: left; font-size: 10pt;">
                                            <th style="width: 68%; font-weight: bold;">User</th>
                                            <th style="font-weight: bold; text-align: right;">Queries</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of summary.top_users" style="font-size: 9pt;">
                                            <td  style="text-align: left;">{{user.user_name}}</td>
                                            <td style="text-align: right;">{{user.total_queries}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                             </div>		            
                        
                    </div>
                </div>
            </div>
        </div>
	<div class="row" style="height:400px" >
			<div class="col-lg-8" style="height:375px">
				<div class="card mb-4 card-block shadow-hover" style="height:375px">
					<ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="usagechart1"></ngx-loading-bar>
                    <div class="card-subtitle text-muted card-subtitle-header" style="margin: 0px">
                      <span class="text-big card-info-title">Total Queries by Day and Query Type</span>
                    </div>
                    <div class="card-body" style="height:300px; padding: 0px">
                        <div [hidden]="dailyQueriesLoaded ? false : true" id="DailyOverviewDiv"   style="height:330px; padding-top: 10px">
                        </div>
                    </div>
				</div>
			</div>
			<div class="col-lg-4" style="height:375px">
				<div class="card mb-4 card-block shadow-hover" style="height:375px">
                    <ngx-loading-bar fixed [includeSpinner]="false" color="#1900df" ref="usagechart2"></ngx-loading-bar>
                    <div class="card-subtitle text-muted card-subtitle-header" style="margin: 0px">
                      <span class="text-big card-info-title">Average Queries by Day of Week</span>
                    </div>
                    <div class="card-body" style="height:300px; padding: 0px">
                        <div [hidden]="dayOfWeekLoaded ? false : true" id="QueriesByDayDiv"   style="height:330px; padding-top: 10px">
                        </div>
                    </div>
				</div>
			</div>
		</div>
	<div class="row" style="height:700px" >
			<div class="col-lg-12" style="height:675px">
				<div class="card mb-4 card-block shadow-hover" style="height:675px">
                    <ngx-loading-bar fixed [includeSpinner]="false" color="#00dfac" ref="usagechart3"></ngx-loading-bar>
                    <div class="card-subtitle text-muted card-subtitle-header" style="margin: 0px">
                      <span class="text-big card-info-title">Daily Total Queries by User and Type</span>
                    </div>
                    <div class="card-body" style="height:300px; padding: 0px">
                        <div [hidden]="dailyQueriesLoaded ? false : true" id="DailyDetailDiv"   style="height:630px; padding-top: 10px">
                        </div>
                    </div>
				</div>
			</div>
		
	

		<br />
	
	<br />
	
</div>