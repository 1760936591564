import { Injectable } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  package: any = null;
 
  constructor(
    
  ) { }

  clear(){
    this.package = null;
  }

}
