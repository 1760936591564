import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgModel, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons,NgbTooltipModule,  NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../../../shared/auth/auth.service';
import { ApiService } from '../../../shared/api/api.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from "rxjs";
import { AppService } from '../../../app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styles: [
  ]
})
export class IntegrationsComponent implements OnInit {
  url: string = environment.url;
  env: string = environment.env;
  page: string = 'integrations'
  slackForm: FormGroup;
  s3Form: FormGroup;
  sftpForm: FormGroup;
  bitbucketForm: FormGroup;
  edit: any;
  publicKey = null;
  webhookURL = null;
  newIntegration=false;

  headers: HttpHeaders;
  loading: boolean = false;
  search = '';

  slack_urls = {
    'dev': "https://slack.com/oauth/v2/authorize?client_id=2930916883203.3958844460912&scope=chat:write,chat:write.customize,files:read,files:write,incoming-webhook,channels:read,commands,emoji:read,team:read,channels:join,channels:history,app_mentions:read,im:read,im:write,im:history,channels:manage&user_scope=groups:write,groups:read,groups:history,channels:history,channels:read,channels:write",
    'demo': '',
    'prod': ''
  }

  forms = {}
  existingIntegrations = []
  
  newIntegrations = [
    
    // {
    //   'type': 's3', 
    //   'display': 'S3', 
    //   'href': '', 
    //   'logo': './assets/img/aws-s3-logo.png', 
    //   'loading': false, 
    //   'att': null, 
    //   'form': null,
    //   'modal': 's3ConfigModal'
    // },
    // {
    //   'type': 'sftp', 
    //   'display': 'SFTP', 
    //   'href': '', 
    //   'logo': './assets/img/folder_icon.png', 
    //   'att': '<a href="https://www.flaticon.com/free-icons/sftp" title="sftp icons">Sftp icons created by Freepik - Flaticon</a>', 
    //   'loading': false, 
    //   'form': null,
    //   'modal': 'sftpConfigModal'
    // },
    {  
      'type': 'bitbucket', 
      'display': 'Bitbucket', 
      'href': '', 
      'logo': './assets/img/bitbucket_icon.png', 
      'att': null, 
      'loading': false, 
      'form': null,
      'modal': 'bitbucketConfigModal'
    },
    {  
      'type': 'github', 
      'display': 'GitHub', 
      'href': '', 
      'logo': './assets/img/github.png', 
      'att': null, 
      'loading': false, 
      'form': null,
      'modal': 'githubConfigModal'
    },
    {  
      'type': 'slack', 
      'display': 'Slack', 
      'href': this.slack_urls[this.env], 
      'logo': './assets/img/slack_logo.jpg', 
      'att': null, 
      'loading': false, 
      'form': null,
      'modal': 'slackConfigModal'
    }
    // {  
    //   'type': 'tableau', 
    //   'display': 'Tableau', 
    //   'href': '', 
    //   'logo': './assets/img/tableau.png', 
    //   'att': null, 
    //   'loading': false, 
    //   'form': null,
    //   'modal': 'tableauConfigModal'
    // }
  ]

  routeSubscription: Subscription;
  // order by configured/not configured

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    private http: HttpClient,
    private loadingBar: LoadingBarService,
    private fb: FormBuilder,
    public toastrService: ToastrService,
  ) {

   }

  ngOnInit(): void {
    this.headers = this.auth.getHeaders()
    let intLoader = this.loadingBar.useRef('intLoad')
    intLoader.start()
    intLoader.set(35)

    // This is used for Oauth redirects
    if(this.route.snapshot.queryParamMap.get('code')){
      let code = this.route.snapshot.queryParamMap.get('code')
      let data = {code: code}
      let slackUrl = this.url + '/admin/settings/integrations/slack/connect'
      let headers = this.headers;
      this.newIntegrations[0].loading = true;

      // Send code in to create slack integration
      this.http.post(slackUrl, data, {headers}).subscribe(
        data=>{
          console.log(data)
          this.newIntegrations[0].loading = false;
          this.getIntegrations(intLoader)
        },
        err=>{
          this.newIntegrations[0].loading = false;
        }
      )
    }
    this.getIntegrations(intLoader)
  }

  ngOnDestroy(){
    this.modalService.dismissAll()
  }

  startNewIntegration(){
    this.newIntegration = true;
  }
  returnIntegrations(){
    this.newIntegration = false;
  }

  getNewForm(type): FormGroup{
    if(type == 's3') return this.newS3Form();
    if(type == 'sftp') return this.newSftpForm();
    if(type == 'bitbucket') return this.newBitbucketForm();
    if(type == 'github') return this.newGithubForm();
    if(type == 'tableau') return this.newTableauForm();
  }

  getUpdateForm(integration): FormGroup{
    if(integration.type == 's3') return this.updateS3Form(integration);
    if(integration.type == 'sftp') return this.updateSftpForm(integration);
    if(integration.type == 'bitbucket') return this.updateBitbucketForm(integration);
    if(integration.type == 'github') return this.updateGithubForm(integration);
    if(integration.type == 'tableau') return this.updateTableauForm(integration);
  }

  newS3Form(): FormGroup {
     return this.fb.group({
        bucket: ['', Validators.required], 
        access_key_id: ['', Validators.required],
        access_key_secret: ['', Validators.required]
      })
  }

  newBitbucketForm(): FormGroup {
     return this.fb.group({
        name: ['', Validators.required],
        url: ['', Validators.required],
        branch: ['', Validators.required]
      })
  }
  newGithubForm(): FormGroup {
     return this.fb.group({
        name: ['', Validators.required],
        url: ['', Validators.required],
        branch: ['', Validators.required]
      })
  }

  newTableauForm(): FormGroup {
    return this.fb.group({
        name: ['', Validators.required],
        server: ['', Validators.required],
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
  }

  newSftpForm(): FormGroup {
    return this.fb.group({
        name: ['', Validators.required],
        server: ['', Validators.required],
        port: [22, Validators.required], 
        username: ['', Validators.required],
        password: ['', Validators.required],
        path: ['/home', Validators.required]
    });
  }

  updateS3Form(integration): FormGroup {
    
     return this.fb.group({
        bucket: new FormControl(integration.integration_details.bucket, Validators.required),
        access_key_id: [integration.integration_details.access_key_id, Validators.required],
        access_key_secret: ['', Validators.required]
      })
  }


  updateBitbucketForm(integration): FormGroup {
    
     return this.fb.group({
        url: new FormControl(integration.integration_details.source_url, Validators.required),
        name: new FormControl(integration.integration_name, Validators.required),
        branch: new FormControl(integration.integration_details.main_branch, Validators.required),
      })
  }

  updateGithubForm(integration): FormGroup {
    
     return this.fb.group({
        url: new FormControl(integration.integration_details.source_url, Validators.required),
        name: new FormControl(integration.integration_name, Validators.required),
        branch: new FormControl(integration.integration_details.main_branch, Validators.required),
      })
  }

  updateSftpForm(integration): FormGroup {

    return this.fb.group({
        name: new FormControl(integration.integration_name, Validators.required),
        server: [integration.integration_details.server, Validators.required],
        port: [integration.integration_details.port, Validators.required], 
        username: [integration.integration_details.username, Validators.required],
        password: ['', Validators.required],
        path: [integration.integration_details.path, Validators.required]
    });
  }

  updateTableauForm(integration): FormGroup {

    return this.fb.group({
        name: new FormControl(integration.integration_name, Validators.required),
        server: [integration.integration_details.server, Validators.required],
        username: [integration.integration_details.username, Validators.required],
        password: ['', Validators.required]
    });
  }

  generateKey(){
    let headers = this.headers;

    this.edit.generating_key = true;

    // Test the connection before saving
    let url = this.url + '/admin/settings/integrations/ssh_key';
    this.http.post(url, {}, {headers}).subscribe(
        data=>{
           this.publicKey = data['message'];
           this.edit.generating_key = false;
        }, 
        err=>{
          console.log(err)
          this.edit.generating_key = false;
        }
    );
  }

  generateWebhook(){
    let headers = this.headers;

    this.edit.generating_webhook = true;

    // Test the connection before saving
    let url = this.url + '/admin/settings/integrations/git/webhook';
    this.http.post(url, {}, {headers}).subscribe(
        data=>{
           this.webhookURL = data['message'];
           this.edit.generating_webhook = false;
        }, 
        err=>{
          console.log(err)
          this.edit.generating_webhook = false;
        }
    );
  }

  getIntegrations(intLoader){
    
    let integrationsUrl = this.url + '/admin/settings/integrations'
    this.api.getData(integrationsUrl, this.page , 'integrations').subscribe(
        data=>{
          
          intLoader.complete()
          this.existingIntegrations = data['message']
          for(let i of this.existingIntegrations){
            Object.assign(i, this.getIntegration(i.integration_type));
          }
          if(this.existingIntegrations.length == 0){
            this.newIntegration = true;
          }  
       },
       err=>{
        intLoader.complete()
      });
  }

  getIntegration(integrationType){
    let index = this.newIntegrations.findIndex((obj => obj.type == integrationType));
    return this.newIntegrations[index];
  }

  openIntegrationConfigModal(content, options, integration){

    integration.loading = true;
    this.edit = JSON.parse(JSON.stringify(integration));

    if(integration.integration_id != null){
      this.edit.form = this.getUpdateForm(integration);
      if(integration.integration_type == 'bitbucket' || integration.integration_type == 'github'){
         this.publicKey = integration.integration_details.public_key;
         this.webhookURL = integration.integration_details.webhook_url;
      }
    }
    else{
       this.edit.form = this.getNewForm(integration.type);
    }


    
    this.edit.saving = false;
    this.edit.deleting = false;
    this.edit.testing = false;
    this.edit.delete_check = false;
    this.edit.error = false;
    this.edit.generating_key = false;

    if(integration.integration_details == null && integration.href != '') return;

      this.modalService.open(content, options).result.then((result) => {
        }, (reason) => {
          integration.loading = false;
          this.publicKey = null;
          this.webhookURL = null;
       });
  }

  saveIntegration(){
    // console.log(this.edit)
    if(!this.edit.form.valid){
      this.modalService.dismissAll()
      return false
    }
    this.edit.saving = true;

    let saveData = {
      integration_name: '',
      integration_type: '',
      integration_password: '',
      integration_details: {}
    }
    if(this.edit.type == 's3'){
      saveData.integration_name = this.edit.form.value.bucket
      saveData.integration_type = this.edit.type
      saveData.integration_password = this.edit.form.value.access_key_secret
      saveData.integration_details = {
        bucket: this.edit.integration_id != null ? this.edit.form.controls.bucket.value : this.edit.form.value.bucket,
        access_key_id: this.edit.form.value.access_key_id
      }
    }
    else if(this.edit.type == 'sftp'){
      saveData.integration_name = this.edit.form.value.name
      saveData.integration_type = this.edit.type
      saveData.integration_password = this.edit.form.value.password
      saveData.integration_details = {
        server: this.edit.form.value.server,
        port: this.edit.form.value.port,
        username: this.edit.form.value.username,
        path: this.edit.form.value.path
      }
    }
    else if(this.edit.type == 'bitbucket' || this.edit.type == 'github'){
      if (!this.edit.form.value.url.includes('git@bitbucket.org') && !this.edit.form.value.url.includes('git@github.com') ){
        this.edit.error = 'Invalid Source URL for SSH Connection'
      }
      saveData.integration_name = this.edit.form.value.name
      saveData.integration_type = this.edit.type
      saveData.integration_password = this.publicKey
      saveData.integration_details = {
        source_url: this.edit.form.value.url,
        public_key: this.publicKey,
        main_branch: this.edit.form.value.branch,
        repository: this.edit.form.value.name,
        webhook_url: this.webhookURL
      }
    }

    let headers = this.headers;
 
    // Update the integration
    if(this.edit.integration_id != null){
      console.log('Updating integration!')
      console.log('Update data: ', saveData)
      let url = this.url + '/admin/settings/integrations/' + this.edit.integration_id;
      this.http.put(url, saveData, {headers}).subscribe(
        data=>{
          this.toastrService.success('Integration Updated')
          let intLoader = this.loadingBar.useRef('intLoad')
          intLoader.start()
          intLoader.set(35)
          this.getIntegrations(intLoader)
          this.edit = {}
          this.edit.saving = false;
          this.modalService.dismissAll()
          this.returnIntegrations()
        },
        err=>{
          console.log(err)
          this.edit.saving = false;
          this.edit.error = err['error']['message'];
        }
      )

    }
    else{ // Save the integration for the first time
      let url = this.url +'/admin/settings/integrations';
      this.http.post(url, saveData, {headers}).subscribe(
        data=>{
          this.toastrService.success('Integration Saved')
          console.log(saveData)
          let intLoader = this.loadingBar.useRef('intLoad')
          intLoader.start()
          intLoader.set(35)
          this.getIntegrations(intLoader)
          this.edit = {}
          this.edit.saving = false;
          this.modalService.dismissAll()
          this.returnIntegrations()
        },
        err=>{
          console.log(err)
          this.edit.saving = false;
          this.edit.error = err['error']['message'];
        }
      )
    }
 
  }

  formControl() {
    return this.edit.form.controls;
  }

  deleteIntegration(){
    if(!this.edit.delete_check){
      this.edit.delete_check = true;
      return;
    }
    this.edit.deleting = true;
    this.edit.delete_check = false;

    

    let headers = this.headers;
    // Test the connection before saving
    let url = this.url + '/admin/settings/integrations/' + this.edit.integration_id;
    this.http.delete(url, {headers}).subscribe(
      data=>{
        this.toastrService.success('Integration Deleted')
        let intLoader = this.loadingBar.useRef('intLoad')
        intLoader.start()
        intLoader.set(35)
        this.getIntegrations(intLoader)
        this.modalService.dismissAll()
      },
      err=>{
        this.toastrService.error('Failed to Delete Integration')
        this.modalService.dismissAll()
      }
    )
  }

  parseRepository(){
    let url = this.edit.form.value.url;
    if (url.includes('.git')){
        let splitUrl = url.split("/"); 
        let repoName = splitUrl[splitUrl.length-1].replace('.git', '')
        if(repoName != null || repoName != undefined){
          this.edit.form.controls['name'].setValue(repoName);
        }
    }

  }

  testIntegration(){
    this.edit.testing = true;
    this.edit.connected = false;
    this.edit.error = false;

    let data = {
      integration_name: '',
      integration_type: '',
      integration_password: '',
      integration_details: {}
    }
    if(this.edit.type == 's3'){
      data.integration_name = this.edit.integration_id != null ? this.edit.form.controls.bucket.value : this.edit.form.value.bucket
      data.integration_type = this.edit.type
      data.integration_password = this.edit.form.value.access_key_secret
      data.integration_details = {
        bucket: this.edit.integration_id != null ? this.edit.form.controls.bucket.value : this.edit.form.value.bucket,
        access_key_id: this.edit.form.value.access_key_id
      }
    }
    else if(this.edit.type == 'sftp'){
      data.integration_name = this.edit.form.value.name
      data.integration_type = this.edit.type
      data.integration_password = this.edit.form.value.password
      data.integration_details = {
        server: this.edit.form.value.server,
        port: this.edit.form.value.port,
        username: this.edit.form.value.username,
        path: this.edit.form.value.path
      }
    }
    else if(this.edit.type == 'bitbucket' || this.edit.type == 'github'){
      if (!this.edit.form.value.url.includes('git@bitbucket.org') && !this.edit.form.value.url.includes('git@github.com')){
        this.edit.error = 'Invalid Source URL for SSH Connection'
      }
      data.integration_name = this.edit.form.value.name
      data.integration_type = this.edit.type
      data.integration_password = this.publicKey
      data.integration_details = {
        source_url: this.edit.form.value.url,
        public_key: this.publicKey,
        main_branch: this.edit.form.value.branch,
        repository: this.edit.form.value.name
      }
    }
    

    let headers = this.headers;
    // Test the connection before saving
    let checkUrl = this.url + '/admin/settings/integrations/' + this.edit.integration_id + '/test';
    this.http.post(checkUrl, data, {headers}).subscribe(
      data=>{
        console.log('test result:', data)
        this.edit.testing = false;
        this.edit.connected = true;
      },
      err=>{
        console.log('test result:',err)
        this.edit.testing = false;
        this.edit.connected = false;
        this.edit.error = err['error']['message'];
      }
    )
  }
    

  
}
