import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren,QueryList, ElementRef } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router"
import { AuthService } from "../../shared/auth/auth.service";
import { map, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ActivatedRoute } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal, NgbNav} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: [
    '../../../vendor/styles/pages/authentication.scss',
    '../../../vendor/libs/spinkit/spinkit.scss'
  ],
  animations: [trigger('fadeSlideInOut', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(10px)' }),
      animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' })),
    ])
  ])]
})
export class RegisterComponent {

  @ViewChildren('termsModal') termsModal: QueryList<any>;
  public registerForm: FormGroup;
  public templateForm: FormGroup;

  ipAddress: string = '';
  url: string = environment.url;
  errorCount:number = 0;
  displayMessage: string = ''
  submitted: boolean = false;
  fieldTextType: boolean;
  force: boolean = false;
  toEmail: string = undefined;
  emailResent: boolean = false;
  loading: boolean = true;
  active: number = 1;
  show: boolean = false;
  loadingPdf: boolean = false;
  steps: Array<any>;
  screenWidth: number = 0;
  innerWidth: number = 0; 

  public registration = {
    success: false,
    complete: false,
    error: {
       found: false,
       message: ''
    }
  }

  public verify = {
    success: false,
    name: '',
    status: {
      steps: []
    },
    error: {
       found: false,
       message: ''
    }
  }

  constructor(
    private appService: AppService, 
    private http: HttpClient, 
    private fb: FormBuilder,
    private router: Router, 
    private auth: AuthService,
    private storage: StorageMap,
    private route: ActivatedRoute,
    private modalService: NgbModal) {

    this.appService.pageTitle = 'Register';
    this.ipAddress = '192.0.0.1'
    this.loading = true;
    this.steps = ['Customer Account', 'User Profile', 'App Database']
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

    this.templateForm = fb.group({
        company: ['', Validators.required],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email:['', [Validators.required, Validators.pattern(emailPattern)]],
        password:['', [Validators.required, Validators.minLength(8),
        this.createPasswordStrengthValidator()]],
        snowflake_account: ['', Validators.required],
        source: 'frontend'
      })
    this.registerForm = this.templateForm
    this.getRegistrationEmail();
    
  }

  ngOnInit(){


    this.screenWidth = window.screen.width;
    this.innerWidth = window.innerWidth;

    console.log('screen width', this.screenWidth)
    console.log('inner width', this.innerWidth)
    this.route.queryParams
      .subscribe(
        params => {
          if(params.token && params.email){
            var url = this.url + '/register/verify/' + params.token
            let headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'json' })
          
            this.registration.success = true;
            this.toEmail = params.email;
            this.http.post(url,{}, { headers: headers }).subscribe(
                 data => {
                    this.verify.success = true;
                    this.loading = false;
                    let step_obj = {name: this.steps[0], complete: false}
                    this.verify.status.steps.push(step_obj);
                    this.verify.name = data['message']['first_name']
                    var statusUrl = url + '/status'
                    this.http.get(statusUrl,{ headers: headers }).subscribe(
                      data => {

                        if(data['message']['step_status'] == 'Complete'){
                            this.displayStatus();
                        }
                        else{
                          this.verify.success = false;
                          this.verify.error.found = true;
                          this.verify.error.message = 'Failed to create ' + data['message']['failed_step'];
                        }
                      },
                       err => {
                        
                        this.verify.success = false;
                        this.verify.error.found = true;
                        this.verify.error.message = err['error']['message'];
                        this.loading = false;
                     }
                   );
                },
                 err => {
                  
                  this.verify.success = false;
                  this.verify.error.found = true;
                  this.verify.error.message = err['error']['message'];
                  this.loading = false;
                }

             );
          }
        }
      );
  }


  // async displayStatus(step, status) {

  //   let stepIndex = this.verify.status.steps.findIndex((obj => obj.name == step));
    
  //   if(status == 'started'){
  //     // this.verify.status.step = '';
  //     await this.sleep(1000);
  //     let step_obj = {name: step, complete: false}
      
  //     if(stepIndex == -1) this.verify.status.steps.push(step_obj);
      
  //   }
  //   else if(status == 'complete'){
  //     await this.sleep(1000);
  //     if(stepIndex >= 0){
  //       this.verify.status.steps[stepIndex].complete = true;
  //     }
  //   }
  // }

  async displayStatus() {
     
    for(let i = 0; i < this.steps.length; i++){

      if(i > 0){
        await this.sleep(1000);
        let step_obj = {name: this.steps[i], complete: false}
        this.verify.status.steps.push(step_obj);
        await this.sleep(1500);
        this.verify.status.steps[i].complete = true;
      }
      else{
        this.verify.status.steps[i].complete = true;
      }
    }
    await this.sleep(500);
    this.registration.complete = true;
      
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  getRegistrationEmail(){
    this.loading = true;
    this.storage.get('registration_email', { type: 'string' }).subscribe((email) => {
       if(email != undefined){
         this.registration.success = true;
         this.toEmail = email;
       }
       this.loading = false;
    });
  }


  register(){
    
    this.submitted = true;
    var url = this.url + '/register'
    this.registerForm.patchValue({ip_address: this.ipAddress});
    
    if (this.registerForm.valid) {
      this.http.post(
      url,
      JSON.stringify(this.registerForm.value),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'json' }
      ).subscribe(data => {
          this.submitted = false;
          console.log('Successful Registration: ', data)
          this.registration.success = true;
          // Store the registration email locally, so we can navigate to this page later if needed
          this.toEmail = this.registerForm.get('email').value;
          this.storage.set('registration_email', this.toEmail).subscribe(() => {});
          this.registerForm.reset(this.templateForm.value);
          this.submitted = false;

        },
        err => {
          // console.log('Error: ', err)
          if( err['error']['message'] == 'Email address already registered'){
            this.registration.success = true;
            this.toEmail = this.registerForm.get('email').value;
            this.storage.set('registration_email', this.toEmail).subscribe(() => {});
            this.registerForm.reset(this.templateForm.value);
            
          }
          else{
            this.errorCount++
            this.displayMessage = err['error']['message']
            this.submitted = false;
          }
        });
    }
  }

  resendEmail(){
    this.submitted = true;
    var url = this.url + '/register/resend'
    this.http.post(
        url,
        JSON.stringify({email: this.toEmail}),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json' }
      ).subscribe(data => {
         this.emailResent = true;
         this.verify.error.found = false;
         this.verify.error.message = ''
         this.submitted = false;
       },
        err => {
          this.submitted = false;
      }
    );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get registerFormControl() {
    return this.registerForm.controls;
  }

  createPasswordStrengthValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }

        const hasUpperCase = /[A-Z]+/.test(value);

        const hasLowerCase = /[a-z]+/.test(value);

        const hasNumeric = /[0-9]+/.test(value);

        const hasSpecial = /[@$!%*?&]+/.test(value);

        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;

        return !passwordValid ? {passwordStrength:true}: null;
    }
  }

  openTermsModal(content, options, nav){

    let modal = this.modalService.open(content, options);
    this.loadingPdf = true;
    this.active = 1;
    // console.log(this.termsModal, nav)
    this.show = true;
    modal.result.then((result) => {
      }, (reason) => {
     });
  }

  onProgress(event) {
    // console.log(event)
    // do anything with progress data. For example progress indicator
  }

  pageInitialized(event) {
    // console.log(event)
    this.loadingPdf = false;
    // do anything with progress data. For example progress indicator
  }

  // isValidURL(url):Observable<any> { 
  //   console.log('url to check: ', url);
  //   return this.http.get(url).pipe(
  //     map(data => {
  //       console.log("Here will be return response code Ex :200", data)
  //        // data.status == 200 ? {invalidURL:true}: 
  //       return null;
  //   }),
  //        catchError((err, caught) => {
  //         console.log('Error:', err)
  //         return null;
  //   }));
  // }

  // urlValidator(): ValidatorFn {
  //   return (control:AbstractControl) : ValidationErrors | null => {
  //       const value = control.value;
  //       let url = 'https://'+ value + '.snowflakecomputing.com'
  //       return this.isValidURL(url);
  //   }
  // }
}


