import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorComponent} from './editor.component';
import { CodemirrorModule } from 'ng2-codemirror';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AngularSplitModule } from 'angular-split';
import { CronEditorModule } from '../cron-editor/cron-editor.module';
import {TableModule} from 'primeng/table';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { CatalogModule } from '../data/catalog/catalog.module';

@NgModule({
  
  declarations: [EditorComponent, ],
  imports: [
    CommonModule,
    NgbModule,
    CodemirrorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    AngularSplitModule,
    CronEditorModule,
    TableModule,
    ToastrModule, 
    ToastContainerModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CatalogModule
  ]
})
export class EditorModule { }
