import { Component, Input, ChangeDetectionStrategy, OnInit, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import { AppService } from '../../app.service';
import { MenuService } from '../../shared/menu/menu.service';
import { CatalogService } from './catalog.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styles: [':host { display: block; }']
})
export class CatalogComponent implements OnInit {

  loading: Boolean = true;
  activeItem = {}
  level = ''
  activeItemList: Array<any> = [];
  subscription: Subscription;
  catalogSubscription: Subscription;
  isExplore=true;


  constructor(
    public menu: MenuService,
    private appService: AppService,
    public catalog: CatalogService,
    )
  { 
    this.activeItem = {}
    this.activeItemList = []

    // router.events.subscribe(event => {

    //   if (event instanceof NavigationStart) {
    //     this.navStart = event.url
    //   }
    //   if (event instanceof NavigationEnd){
    //     this.prevEnd = this.navEnd;
    //     this.navEnd = event.url;
    //   }
    // });
    
  }


  getLastTS(){
    var last_ts = 0
    for (let i of this.activeItemList){
      ///console.log('record timestamp: ', i['timestamp'], ' last ts: ',   last_ts)
      if(i['timestamp'] > last_ts){
        last_ts = i['timestamp']
      }
    }
    return last_ts;
  }

  getActiveMenuItem(){
    var lastIndex = 0
    var activeItem = {}
    var timestamp = this.getLastTS()

    //console.log('The nav list: ', this.activeItemList)

    for (let i of this.activeItemList){
      if (i['timestamp'] == timestamp){
        if(i['index'] >= lastIndex){
          lastIndex = i['index']

          if ('database' in i['item']){
            activeItem = {
              'link': i['item']['link'],
              'type': 'object',
              'text': i['item']['text'],
              'database': i['item']['database'],
              'schema': i['item']['database']
            }
          }
          else{
            activeItem = {
              'link': i['item']['link'],
              'type': 'category',
              'text': i['item']['text']
            }
          }
          
        }
        else{
          break;
        }
      }
    }
    
    return activeItem
  }

  parseItem(item){
    var db
    var schema
    var name 
  }

  ngOnInit(){

    

    this.catalog.isEditor = false;
    this.catalog.menuItems = [];
    this.catalog.getLastUpdated().subscribe((data:any)=>{
      let catalogLastUpdate = data['message']['catalog_last_update'];
      let lastUpdated = sessionStorage.getItem("catalog_last_update");
      if(catalogLastUpdate == null )  this.catalog.hasCatalog = false;
      this.catalog.getCatalog();


      // console.log('frontend catalog last update: ', lastUpdated, '. backend catalog last update: ', catalogLastUpdate)
      // if(lastUpdated != catalogLastUpdate || this.catalog.isEditor){
      //   sessionStorage.setItem("catalog_last_update", catalogLastUpdate);
      //   if(this.catalog.isEditor && this.catalog.menuSettings == {}){
      //     this.catalog.needConnection = true;
      //     return
      //   }
      //   else{
      //     this.catalog.needConnection = false; 
      //   }
      // }
      // else{
      
      //.subscribe((data:any)=>{
        //   //console.log('finished loading catalog: ', data)
        //   let schemaObj = this.parseUrl(this.navEnd)
        //   if(data == undefined){
        //     this.catalog.getFreshCatalog().subscribe((data:any)=>{
        //       // console.log('Getting fresh catalog')
        //       this.catalog.setCatalogReplay(data['message'])
        //       if(schemaObj){
        //         this.catalog.getSchemaObjects(schemaObj, false);
        //       }
        //       else{
        //         console.log('No schema obj - not getting objects')
        //       }
        //     })
        //   }
        //   else if('message' in data){
        //       this.catalog.setCatalogReplay(data['message'])
        //       if(schemaObj){
        //         this.getSchemaObjects(schemaObj, false);
        //       }else{
        //         console.log('No schema obj - not getting objects')
        //       }
        //   }
        //   else{
        //       // console.log(data)
        //       // console.log('Using cache for catalog!')
        //       this.catalog.setCatalogReplay(data)
        //       if(schemaObj){
        //         this.getSchemaObjects(schemaObj, false);
        //       }else{
        //         console.log('No schema obj - not getting objects')
        //       }
        //   }     
        // });
      // }
    });
   

    // this won't trigger until the getCachedCatalog function is called
    // this.catalogSubscription = this.catalog.getCatalog().subscribe((result:any)=>{

    //   if(!result){
        // this.catalogSubscription = this.catalog.getCatalog().subscribe((data:any)=>{
        //  //console.log('finished loading catalog: ', data)
        //   this.isExplore=true;
        //   if(data['status'] == 'success'){
        //     this.menu.setMenu(this.catalog.buildCatalogMenu(data['message']));
        //     this.catalog.storeCatalog(data);
        //   }
        // });

       
      //  }
      //  else{
      //     console.log('found cached catalog: ', result)
      //     this.catalog.storeCatalog(result, true);
      //     console.log('store catalog')
      //     this.menu.setMenu(this.catalog.buildCatalogMenu());
      //     console.log('set menu')
      //     //this.menuItems = this.menu.getMenu();
      //     //this.loading = false;
      //   }
      // }

    // );

   // this.catalog.getCachedCatalog();
    //console.log(catalog)
   
  }
  parseUrl(url){
    if(url.indexOf("/tables") > 0 || url.indexOf("/views") > 0){

        let endStr = url.indexOf("/tables") > -1 ? "/tables"  : '/views'
        let database = url.substring(
          url.lastIndexOf("databases/") + 10, 
          url.indexOf("/schemas")
        ); 
        let schema = url.substring(
          url.lastIndexOf("schemas/") + 8, 
          url.indexOf(endStr)
        ); 

      console.log('schema: ', schema)

      if(schema == undefined){
        let schema = url.substring(
            url.lastIndexOf("schemas/") + 8, 
            url.indexOf("/views")
        ); 
      }
      return {database: database, name: schema}
    }
    return false
  }
}
