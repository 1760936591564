import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ViewChildren, ViewEncapsulation, ChangeDetectionStrategy, HostListener,QueryList, ElementRef} from '@angular/core';
import 'codemirror/mode/sql/sql';
import * as CodeMirror from 'codemirror';
import {NgbAccordion} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {CronOptions} from '../cron-editor/CronOptions';
import { CronGenComponent } from '../cron-editor/cron-editor.component';
import cronstrue from 'cronstrue';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DatatableComponent, ColumnMode } from '@swimlane/ngx-datatable';
import '@swimlane/ngx-datatable/themes/material.scss';
import '@swimlane/ngx-datatable/index.scss';
//import { NgxCronModule } from '@swimlane/ngx-cron';
import { SplitComponent, SplitAreaDirective } from 'angular-split'
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth/auth.service';
import { AppService } from '../app.service';
import { Observable, forkJoin } from 'rxjs';
import { EditorService } from './editor.service';

import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ApiService } from '../shared/api/api.service';
import { Subscription } from 'rxjs/Subscription';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CatalogService } from '../data/catalog/catalog.service';

//import { NgxCronService } from '@swimlane/ngx-cron.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: [
  	'../../../node_modules/@swimlane/ngx-datatable/index.css',
  	'../../../node_modules/@swimlane/ngx-datatable/themes/material.css',
  	'../../../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css',
  	'../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../node_modules/@swimlane/ngx-datatable/assets/icons.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class EditorComponent {

	@ViewChild('scriptcode') editor: ElementRef;
	@ViewChild('queryAcc') accordionComponent: NgbAccordion;
	@ViewChild('searchResults') searchResultsWindow: NgbDropdown;
	@ViewChild('connFailed') connFailed: any;
	
	@ViewChild('fileNameInput') fileNameInput: ElementRef;
	@ViewChild('editorWrapper') editorWrapper: ElementRef;
	@ViewChild('editorControl') controlBar: ElementRef;
	@ViewChild(DatatableComponent) table: DatatableComponent;
	@ViewChild(SplitComponent) split: SplitComponent;
	@ViewChild('editorSplit') eSplit: SplitComponent;
	@ViewChildren(SplitAreaDirective) areasEl: QueryList<SplitAreaDirective>;

	@ViewChild(ToastContainerDirective, { static: true })
  	toastContainer: ToastContainerDirective;

	@HostListener('scroll', ['$event'])
	@ViewChild('t') tooltip: NgbTooltip;
	public connectionForm: FormGroup;
	public editConnectionForm: FormGroup;
	public templateForm: FormGroup;
	public fileForm: FormGroup;
	public publishForm: FormGroup;
	public queryResultsValues: Array <any> = new Array <any>();
	public queryResultsColumns: Array <any> = new Array <any>();
	public page = 'editor'
	public openQueryVersion = false;
	public loadingVersions = false;
	selectedVersion;
	ColumnMode = ColumnMode;
	queryCount: number = 0;
	minutes: any = '00';
	seconds: any = '00';
	milliseconds: any = '00';
	isSelect: boolean = false;
	sqlRunning: boolean = false;
	showTimer: boolean = false;
	queryError: boolean = false;
	fileNameEdit: boolean = false;
	navIsToggled: boolean = false;
	editorIsToggled: boolean = false;
	autoSaving: boolean = false;
	connecting: boolean = false;
	allIsChecked: boolean = false;
	profileIsChecked: boolean = false;
	gettingConnections: boolean = false;
	settingFile: boolean = false;
	stoppingQuery: boolean = false;
	loadingFiles: boolean = false;
	includeHeaders: boolean = true;
	counter: number;
	lastAutoSave = null;
	timerRef;
	autoSaveInterval;
	url: string = environment.url;
	query_svc_url: string = environment.query_svc_url;
	direction = 'down'
	allScripts: Array<any> = [];
	allScriptsCopy: Array<any> = [];
	myScripts: Array<any> = [];
	publishedScripts: Array<any> = [];
	docScripts: Array<any> = [];
	queryResults: Array<any> = [];
	config: any = {};
	objects: any = {}
	defaultObjects: any = {}
	connections: any = [];
	roles: Array<any> = [];
	schemas: Array<any> = [];
	warehouses: Array<any> = [];
	databases: Array<any> = [];
	selectedFile: any = {};
	selectedDatabase = '';
	selectedSchema = '';
	selectedWarehouse = '';
	selectedRole= '';
	lastSavedCode = '';
	selectedConn: any;
	newFileName = '';
	deleteConnUsername = '';
	paneSizes = {
		rowLimit: 10,
		editorRow: 60, 
		editorRowMin: 10,
		resultsRow: 30,  
		ResultsRowMin: 10,
		editorCol: 65, 
		navigatorCol: 35,
		navigatorColMax: 35,
		navigtorWidthPx: 400,
		editorColHeight: 450,
		resultsTableHeightPx: 380,
		resultsTableWrapperHeightPx: 400
	};
	saveFile = {error_message: '', has_error: false};
	columns = [];
	cronForm: any;
	cronFormDefault: any;
	search = '';
	darkMode: boolean = false;
	codeSubscription: Subscription;
	saving = {
		connection: false,
		file: false
	}
	profileToggled = false;

	charts = [];
	connObj = {
    typeOptions: [
      {label: 'User Account', value: 'user', icon: "fa-solid fa-user"},
      {label: 'Service Account', value: 'service', icon: 'fa-solid fa-user-gear'},
    ],
    systemOptions: [
      {label: 'Snowflake', value: 'snowflake', logo: './assets/img/snowflake.png', alt_logo: './assets/img/snowflake_bg.png'},
    ],
    systemLogos: {'snowflake': './assets/img/snowflake.png'},
    typeIcons: {'user': "fa-solid fa-user", 'service': 'fa-solid fa-user-gear', 'catalog': "fa-solid fa-user-shield"},
    typeMapping: {'user': 'User Account', 'service': 'Service Account', 'catalog': 'Carve Service Account (Catalog)'},
    systemMapping: {'snowflake': 'Snowflake'}
  }

	// public cronOptions: CronOptions = {
 //       formInputClass: 'form-control cron-editor-input',
 //       formSelectClass: 'form-control cron-editor-select',
 //       formRadioClass: 'cron-editor-radio',
 //       formCheckboxClass: 'cron-editor-checkbox',
       
 //       defaultTime: "00:00:00",

 //       hideMinutesTab: true,
 //       hideHourlyTab: true,
 //       hideDailyTab: false,
 //       hideWeeklyTab: false,
 //       hideMonthlyTab: false,
 //       hideYearlyTab: false,
 //       hideAdvancedTab: true,
 //       hideSpecificWeekDayTab: true,
 //       hideSpecificMonthWeekTab: true,
 //       hideSeconds: true,
 //       showFriendly: true,
 //       use24HourTime: true,
 //       cronFlavor: 'standard',
 //    };


  headers: HttpHeaders;

	constructor(
		private modalService: NgbModal,
		private elementRef: ElementRef,
		private fb: FormBuilder,
		private http: HttpClient,
		private auth: AuthService,
		private appService: AppService,
		private editorService: EditorService,
		public toastrService: ToastrService,
		private api: ApiService,
		public catalog: CatalogService
	) {	 

		this.selectedDatabase = 'Select Database';
		this.selectedSchema = 'Select Schema';
		this.selectedWarehouse = 'Select Warehouse';
		this.selectedRole = 'Select Role';

		this.selectedConn = {id: 0, username: 'Select Connection'}
		this.templateForm = fb.group({
	        username: '',
	        password:'',
	        system: '',
	        user_type: ''
	      })
	    this.connectionForm = this.templateForm;
	    this.publishForm = fb.group({
	    	comment: '',
	    	docAdd: false
	    })
	    this.appService.pageTitle = 'Query';
	    this.headers = this.auth.getHeaders()
	}


	ngOnInit(): void{
		this.toastrService.overlayContainer = this.toastContainer;
		this.cronForm = new FormControl('0 1 1/1 * *');
		this.cronFormDefault = new FormControl('0 1 1/1 * *');
		this.catalog.isEditor = true;
		this.catalog.menuSettings = {};
		this.catalog.isRefreshing = true;
		this.catalog.waitingOnConnection = false;
		this.catalog.needConnection = false;
		this.catalog.menuItems = [];
		// this.catalog.setCatalogReplay([])

		this.getData();
		// Automatically save query every 30 seconds

		var _this = this;
		this.autoSaveInterval = setInterval(function(){
			// Only autosave if the file settings are selected
			if(!_this.checkFileSettings()) _this.autoSave('auto')
		}, 45000)

		this.codeSubscription = this.editorService.getCode().subscribe(
      data=>{
      	if(this.editorService.code != '' && this.editorService.query_id == null){
						this.newFile()
						// console.log('Adding a new file')
				}
 			}
    );
	}

	resetSelected(){
		this.selectedDatabase = 'Select Database';
		this.selectedSchema = 'Select Schema';
		this.selectedWarehouse = 'Select Warehouse';
		this.selectedRole = 'Select Role';
		this.selectedConn = {id: 0, username: 'Select Connection'}
		this.roles = [];
		this.databases = [];
		this.schemas = [];
		this.warehouses = [];
	}
	ngAfterViewInit(): void {
		
		setTimeout(() => this.calculateInitSizes());
		
	}
	ngOnDestroy(): void{
		if(this.autoSaveInterval !== undefined){
			clearInterval(this.autoSaveInterval)
		}
	}

	// Calculate Results height
	// Take the as split - 60px as the height
	// take that (height - 30 / 30) for the row limit 

	getData(){
		this.config = {
			lineNumbers: true,
			mode: 'sql',
			theme: 'default',
			readOnly: 'nocursor'
		}
		this.loadingFiles = true;
		forkJoin(
			this.getConnections(),
			this.getScripts()
		).subscribe(([connData, scriptData]) => {

					// console.log(scriptData)
					if('message' in connData){
						this.connections = connData['message']
					}
					else{
						this.connections = connData
					}

			   	if('message' in scriptData){

			   		this.scriptHandler(scriptData['message']);
			   	}
			   	else{
			   		this.scriptHandler(scriptData);
			   	}
			})
	}

	scriptHandler(scriptData){
		console.log('scripts: ', scriptData)
		this.allScripts = scriptData
		this.sortByUpdateDate();
		if(this.allScripts.length > 0){
        	if(!(this.editorService.active)){
        		this.setFile(this.allScripts[0]);
        		let conn_id = this.selectedFile['settings']['connection_id']
           		if(this.selectedConn.connection_id != conn_id){
           			let conn =  this.connections.find(c => c.connection_id === conn_id);
           			if(conn !== undefined){
           				// This means the connection may have been deleted
           				this.setConn(conn, true, true);

           			}
           		}
        	}
       		
       		this.organizeScripts();
       		if(this.editorService.code != '' && this.editorService.query_id == null){
						this.newFile()
						console.log('Adding a new file')
					}
					else if(this.editorService.query_id != null){
						// set the script to be the published file 
						let scriptIndex = this.allScripts.findIndex((obj => obj.id == this.editorService.query_id && obj.version_id === this.editorService.version_id));
						this.setFile(this.allScripts[scriptIndex]);
					}
					// console.log('refreshing catalog 1')
					// this.catalog.getFreshCatalog();
					// this.editorService.resetVariables();

			this.loadingFiles = false;
			this.editorService.setData('editor_scripts', this.allScripts)
    }
    else{
    	if(this.editorService.code != '' && this.editorService.query_id == null){
						this.newFile()
						// console.log('Adding a new file')
			}
			else{
				this.selectedFile.code = ''
			}
    	this.loadingFiles = false;
    }
	}

	passwordFormControl() {
    return this.connectionForm.controls;
  }

  editPasswordFormControl() {
    return this.editConnectionForm.controls;
  }

	setFile(item){
		
			this.selectFile(item)
			if( Object.keys(item).length <= 1 ){
				return;
			}
			this.settingFile = true;

			// Ignore the empty item 


			let params: HttpParams = new HttpParams();
		  let headers = this.headers

			if( Object.keys(this.selectedFile).length > 0 ){
				// Auto save the previous file before loading the new one
				let err = this.checkFileSettings()
				if(!err){
					this.autoSave('system');
				}	
			}

			let versionURL = this.url + '/query/' + item.id + '/version'
			// If the code is blank, try loading a new one
			if(item.code == '' || item.code == undefined && item.id !== undefined && this.selectedFile.id == item.id){
				let connURL = this.url + '/query/' + item.id
			    this.http.get(connURL, {headers, params}).subscribe(
	        		data=>{ 
	        			if(data['status'] == 'success'){
	        				console.log('file:', item)
	        				item = data['message']
	        				this.selectFile(item)
	        				this.modifyScript(item, 'update')
	        				this.settingFile = false;
	        				this.loadFileSettings(item);
	        				
	        				// Only get versions if the file is versioned
	        				if(item.version_id > 0 && this.selectedFile.id == item.id){
	        					this.loadingVersions = true;
	        					this.http.get(versionURL, {headers, params}).subscribe(
							    		data=>{ 
							    			if(data['status'] == 'success'){
							    				this.loadingVersions = false;
							    				if(this.selectedFile.id == item.id){
							    					this.selectedFile.versions = data['message']
							    					this.modifyScript(this.selectedFile, 'update')
							    				}
							    				
							    			}
							    		}
							    	);
	        				}
	        			}
	        		}
	        	);
			}
			else{
				this.settingFile = false;
				this.loadFileSettings(item);
				if(item.version_id > 0){
					// Make sure that the selected file hasn't changed
					if(this.selectedFile.id != undefined && this.selectedFile.id == item.id){
						this.loadingVersions = true;
						this.http.get(versionURL, {headers, params}).subscribe(
			    		data=>{ 
			    			if(data['status'] == 'success'){
			    				this.loadingVersions = false;
			    				// Double check that the selected file hasn't chnaged
			    				if(this.selectedFile.id == item.id){
			    					this.selectedFile.versions = data['message']
			    					this.modifyScript(this.selectedFile, 'update')
			    				}
			    			}
			    		}
			    	);
					}
				}
			}
	}

	setQueryVersion(item){
		this.setFile(item)
	}

	selectFile(item){
		// console.log('setting file: ', item)
		if(item == undefined){
			item = {code: ''}
		}
		this.selectedFile = item; 
	}

	loadFileSettings(item){
		// Don't refresh the connection but update the other settings
		if(item.settings.connection_id == this.selectedConn.connection_id){
				let skip = false;
				if(this.selectedFile['settings']['role'] == this.selectedRole) skip = true;
				this.setRole(this.selectedFile['settings']['role'], true)
   	 		this.setDb(this.selectedFile['settings']['database'], true)
   	 		this.setSchema(this.selectedFile['settings']['schema'], true)
   	 		this.setWH(this.selectedFile['settings']['warehouse'], true)
   	 		this.setMenuSettings();
   	 		// console.log('refreshing catalog 2')
   	 		this.catalog.waitingOnConnection = false;
   	 		if(!(skip)) this.catalog.getFreshCatalog();
		}
		else{
			
			let conn =  this.connections.find(c => c.id === item.settings.connection_id);
   			this.setConn(conn, false, true);
		}
	}

	getConnections():Observable<any>{
		let connURL = this.url + '/connection'
		return this.api.getData(connURL, this.page, 'connections')
	}
	getScripts():Observable<any>{
		let queryURL = this.url + '/query'
	  return this.api.getData(queryURL, this.page, 'scripts')
	}

	hasSelectedFile(){
		if (Object.keys(this.selectedFile).length > 1){
			return true 
		}
		return false
	}

	calculateInitSizes(){
		this.calcInitEditorRow();
		this.calcInitEditorCol();
	}

	calcInitEditorRow(){
		let h = this.editorWrapper.nativeElement.offsetHeight;
		this.eSplit.displayedAreas[0].size = 60;
 		this.eSplit.displayedAreas[1].size = 40;
 		this.paneSizes.editorRow = 60;
 		this.paneSizes.resultsRow = 40;
 		let rH = ((this.paneSizes.resultsRow / 100) * h)
 		this.paneSizes.resultsTableHeightPx = rH;
 		this.paneSizes.resultsTableWrapperHeightPx = rH;
 		this.paneSizes.rowLimit = Math.floor((rH-94) / 30);
	}
	calcInitEditorCol(){
   		let w = this.controlBar.nativeElement.offsetWidth
   		let openW = 450;
   		let newPercent = (openW / w) * 100;
   		let newWidths = [100-newPercent, newPercent]
   		this.eSplit.displayedAreas[0].size = 100-newPercent;
   		this.eSplit.displayedAreas[1].size = newPercent;
   		this.eSplit.displayedAreas[1].maxSize = newPercent;
   		this.paneSizes.navigatorColMax = newPercent;
   		this.paneSizes.editorCol = 100-newPercent;
   		this.paneSizes.navigatorCol = newPercent;
	}
	getRowLimit(){
		let queries = this.queryResults.length > 0 ? this.queryResults.length : 1; 
		return Math.floor((this.paneSizes.rowLimit - (64/this.queryResults.length)) / queries)
	}

	setMenuSettings(){
		this.catalog.menuSettings = {
 			connection_id: this.selectedConn.connection_id, 
 			database: this.selectedDatabase, 
 			role: this.selectedRole, 
 			schema: this.selectedSchema, 
 			warehouse: this.selectedWarehouse
 		}
	}

	setRole(role, initial=false){
		if(this.roles.includes(role)){
			this.selectedRole = role;
			this.setMenuSettings();
			if(this.selectedFile['settings'] !== undefined){
				this.selectedFile['settings']['role'] = role;
			}
			
			this.warehouses = this.objects[role]['warehouses'];
			if(!(this.warehouses.includes(this.selectedWarehouse))){
				this.selectedWarehouse = 'Select Warehouse'
			}
			// Try and keep selected objects (database and schema) if they belong to the selected role
			this.databases = Object.keys(this.objects[role]['databases']);
			if(!(this.databases.includes(this.selectedDatabase ))){
				this.selectedDatabase = 'Select Database'
				this.selectedSchema = 'Select Schema'
				this.schemas = [];
			}
			else if(!(this.objects[this.selectedRole]['databases'][this.selectedDatabase]['schemas']).includes(this.selectedSchema)){
				this.selectedSchema = 'Select Schema'
				this.schemas = [];
			}
			let err = this.checkFileSettings()
			if(!initial && !err){
				this.catalog.menuSettings = {
   	 			connection_id: this.selectedConn.connection_id, 
   	 			database: this.selectedDatabase, 
   	 			role: this.selectedRole, 
   	 			schema: this.selectedSchema, 
   	 			warehouse: this.selectedWarehouse
   	 		}
				this.catalog.waitingOnConnection = false;
				this.catalog.getFreshCatalog()
				this.autoSave('system');
			}
			else{
				console.log('ran into problems')
			}
		}
	}
	setWH(warehouse, initial?:boolean){
		if(this.warehouses.includes(warehouse)){
			this.selectedWarehouse = warehouse;
			this.setMenuSettings();
			if(this.selectedFile['settings'] !== undefined){
				this.selectedFile['settings']['warehouse'] = warehouse;
			}
			if(!initial){
				this.setConn(this.selectedConn, false, false, true);
			}
			let err = this.checkFileSettings()
			if(!initial && !err){
				this.autoSave('system');
			}
		}
	}
	setDb(database, initial?:boolean){
		
		if(this.databases.includes(database)){
			this.selectedDatabase = database;
			this.setMenuSettings();

			if(this.selectedFile['settings'] !== undefined){
				this.selectedFile['settings']['database'] = database;
			}
			if(!initial){
				this.setConn(this.selectedConn, false, false, true);
			}
			this.schemas = this.objects[this.selectedRole]['databases'][database]['schemas'];
			let err = this.checkFileSettings()
			if(!initial && !err){
				this.autoSave('system');
			}
		}
	}
	setSchema(schema, initial?:boolean){
		
		if(this.schemas.includes(schema)){
			this.selectedSchema = schema;
			this.setMenuSettings();
			let err = this.checkFileSettings()
			let editor = this.editor['instance']
			if(this.selectedFile['settings'] !== undefined){
				this.selectedFile['settings']['schema'] = schema;
				if(!err){
					this.config.readOnly = false;
					editor.setOption("readOnly", this.config.readOnly);
				}
				if(!initial){
					this.setConn(this.selectedConn, false, false, true);
				}
			}
			
			if(!initial && !err){
				// console.log('Changing ReadOnly to False')
				this.config.readOnly = false;
				editor.setOption("readOnly", this.config.readOnly);
				if(this.editorService.active){
					this.saveFileName();
					this.editorService.resetVariables();
				}
				else{
					this.autoSave('system');
				}
			}
		}
	}
	setConn(connection, initial?: boolean, use_file_settings?: boolean, no_reload?: boolean){
		if(this.connections.includes(connection)){
			this.catalog.waitingOnConnection = true;
			this.catalog.needConnection = false;
			this.connecting = true;
			this.selectedConn = connection;
			this.setMenuSettings();
			 
		    let query_svc_url = this.query_svc_url + '/connection/' + connection.connection_id
		    let headers = this.headers;
		    let data = {username: connection.username}

		 		if('settings' in this.selectedFile){
		 			data['database'] = this.selectedFile['settings']['database']
		    	data['schema'] = this.selectedFile['settings']['schema']
		    	data['warehouse'] = this.selectedFile['settings']['warehouse']
		 		}
		    
		    if(use_file_settings){
		    	this.selectedFile['settings']['database'],
		    	this.selectedFile['settings']['schema'],
		    	this.selectedFile['settings']['warehouse']
		    }
		   
		    this.http.post(query_svc_url, data, {headers}).subscribe(
		        data=>{ 
		            if(data['status'] == 'success'){

		            	if(!no_reload){
		            		this.objects = data['message']['objects']
				           	this.defaultObjects = data['message']['default_objects']
				           	this.roles = Object.keys(this.objects);
				           	let editor = this.editor['instance']

			           	 	if(use_file_settings){
			           	 		this.setRole(this.selectedFile['settings']['role'], true)
			           	 		this.setDb(this.selectedFile['settings']['database'], true)
			           	 		this.setSchema(this.selectedFile['settings']['schema'], true)
			           	 		this.setWH(this.selectedFile['settings']['warehouse'], true)
											this.config.readOnly = false;
											editor.setOption("readOnly", this.config.readOnly);
											this.setMenuSettings();
							   	 		this.catalog.waitingOnConnection = false;
							   	 		this.catalog.getFreshCatalog()
			           	 	}
			           	 	else{
			           	 		if(this.selectedFile['settings'] !== undefined){
			           	 			this.selectedFile['settings']['connection_id'] = connection.connection_id;
				           	 		this.selectedFile['settings']['connection_name'] = connection.username;
				           	 		
				           	 		if(this.defaultObjects.role != ""){
				           	 			this.setRole(this.defaultObjects.role, true)
				           	 		}
				           	 		if(this.defaultObjects.database != ""){
				           	 			this.setDb(this.defaultObjects.database, true)
				           	 		}
				           	 		if(this.defaultObjects.schema != ""){
				           	 			this.setSchema(this.defaultObjects.schema, true)
				           	 		}
				           	 		if(this.defaultObjects.warehouse != ""){
					           	 		this.setWH(this.defaultObjects.warehouse, true)
					           		}
					           		if(this.selectedConn != null){
						           		this.setMenuSettings();
									   	 		this.catalog.getFreshCatalog()
									   	 	}
			           	 		}
			           	 		
				           		let err = this.checkFileSettings()
				           		if(!err){
				           			this.config.readOnly = false;
												editor.setOption("readOnly", this.config.readOnly);
				           		}
											if(!initial && !err){
												this.autoSave('system');
											}
				           	}
		            	}
			           	
			        }
			        this.connecting = false;
			        this.catalog.waitingOnConnection = false;
			        this.catalog.needConnection = false;
			    })
		}
		else{
			this.resetSelected();
			this.catalog.needConnection = true;
			this.catalog.menuItems = [];
		}
	}

	
	saveConn(saveType){
		this.saving.connection = true;
		var connection;
		var connForm;

		if(saveType == 'new'){
		 connection = this.connectionForm.value;
		 connForm = this.connectionForm;
		}
		else{
		 connection = this.editConnectionForm.value;
		 connForm = this.editConnectionForm;
		}
		
		let connURL = this.url + '/connection'
    let params: HttpParams = new HttpParams();
    let headers = this.headers
    
    if('connection_id' in connection){
    	connURL = connURL + '/' + connection.connection_id;
    	this.http.put(connURL, JSON.stringify(connection), {headers}).subscribe(
        data=>{ 
            if(data['status'] == 'success'){
            	// Set the new connection
            	let conn = data['message']
            	this.modifyConnections(connection, 'update')
            	this.toastrService.success('Updated connection');
            	this.saving.connection = false;
            	this.modalService.dismissAll();
            	connForm.reset();
           } 
           else{
           	  this.saving.connection = false;
           		this.modalService.open(this.connFailed, { windowClass: 'modal animate', centered: true })
           } 
	    },
	    error=>{
	    	this.saving.connection = false;
	    	this.modalService.open(this.connFailed, { windowClass: 'modal animate', centered: true })
	    });
    }
    else{
    	this.http.post(connURL, JSON.stringify(connection), {headers}).subscribe(
        data=>{ 
            if(data['status'] == 'success'){
            	
            	this.saving.connection = false;
            	this.modalService.dismissAll();
            	// Set the new connection
            	// console.log('Added new connection: ', data['message'])
            	let conn = data['message']
            	this.modifyConnections(conn, 'insert')
            	this.toastrService.success('Added new connection');
            	if(this.connections.length > 0){
            		this.setConn(conn)
            	}
            	connForm.reset();
           }  
           else{
           	  this.saving.connection = false;
           		this.modalService.open(this.connFailed, { windowClass: 'modal animate', centered: true })
           }  
	    },
	    error=>{
	    	this.saving.connection = false;
	    	this.modalService.open(this.connFailed, { windowClass: 'modal animate', centered: true })
	    })
    }
		
	}
	deleteConn(connection){
		let connURL = this.url + '/connection/' + connection.connection_id
	    let params: HttpParams = new HttpParams();
	    let headers = this.headers
	   
    	this.http.delete(connURL, {headers}).subscribe(
	        data=>{ 
	        	  this.saving.connection = false;
	            if(data['status'] == 'success'){
	            	let message = data['message']
	            	this.modifyConnections(connection, 'delete')
	            	this.catalog.menuItems = [];
	        	}    
	    	}
	    );
	}

	deleteFile(item){
			let connURL = this.url + '/query/' + item.id
	    let params: HttpParams = new HttpParams();
	    let headers = this.headers
	   
    	this.http.delete(connURL, {headers}).subscribe(
	        data=>{ 
	            if(data['status'] == 'success'){
	            	// Set the new connection
	            	let message = data['message']
	        	}    
	    	}
	    );

	    // delete locally
	    this.modifyScript(item, 'delete');
	    this.organizeScripts()
	    if(this.allScripts.length == 0){
	    	this.selectedFile = {code: ''}
	    }
	    else{
	    	this.setFile(this.allScripts[0]);
	    }

	    
	}


	getRowIndex(row: any): number {
	    return this.table.bodyComponent.getRowIndex(row); // row being data object passed into the template
	}

	
	toggleDarkMode(){
		// Change the theme of the config
		this.darkMode = !this.darkMode;
		if(this.config['theme'] == 'default'){
			this.config['theme'] = 'midnight';
		}
		else{
			this.config['theme'] = 'default';
		}
		
		let editor = this.editor['instance']
		editor.setOption("theme", this.config['theme']);
	}


	organizeScripts(){
		this.myScripts = [];
		this.publishedScripts = [];
		for (var val of this.allScripts){
			if(val.owned_query){
				this.myScripts.push(val)
			}
			if(val.version_id > 0){
				this.publishedScripts.push(val)
			}
		}
		// Sort the scripts 
		if (this.allScripts.length > 0){
			this.allScripts.sort((a,b) => a.file_name.localeCompare(b.file_name));
			this.allScriptsCopy = this.allScripts;
		}
		if (this.myScripts.length > 0){
			this.myScripts.sort((a,b) => a.file_name.localeCompare(b.file_name));
		}
		if (this.publishedScripts.length > 0){
			this.publishedScripts.sort((a,b) => a.file_name.localeCompare(b.file_name));
		}
	}
	

	modifyScript(file, mod_type){

		var fIndex = this.allScripts.findIndex(x => x.id==file.id)
   		if(fIndex > -1){
   			if(mod_type == 'update'){
   				this.allScripts[fIndex] = file;
   			}
   			else if(mod_type == 'delete'){
   				this.allScripts.splice(Number(fIndex), 1);
   			}
		}
		else if(mod_type == 'insert'){
			this.allScripts.push(file);
		}
		this.organizeScripts();
		this.editorService.setData('editor_scripts', this.allScripts);
	}

	modifyConnections(conn, mod_type){
		var cIndex = this.connections.findIndex(x => x.connection_id==conn.connection_id)
   		if(cIndex > -1){
   			if(mod_type == 'update'){
   				this.connections[cIndex] = conn;
   			}
   			else if(mod_type == 'delete'){
   				this.connections.splice(Number(cIndex), 1);
   				if(this.selectedConn.connection_id == conn.connection_id){
   					this.selectedConn = {};
   					this.resetSelected();

   				}
   			}
		}
		else if(mod_type == 'insert'){
			this.connections.push(conn);
		}
		this.editorService.setData('editor_connections', this.connections);
	}

	
	onSearchChange(searchValue : string ) {  
		
	    if (searchValue != ''){
	       this.accordionComponent.collapse('two')
	       this.allScripts = this.searchScripts(searchValue);
	    }
	    else{
	    	this.clear()
	    }
	    //console.log(this.searchList)
		}

  	searchScripts(searchValue){
		
  		let newScripts = []
		for(let i = 0; i < this.allScriptsCopy.length; i++){
			let item = this.allScriptsCopy[i]
		  	if(item.file_name.toLowerCase().includes(searchValue.toLowerCase())){
		  		newScripts.push(item);
		  	}
		}
		return newScripts;
	 }

	clear(){
	    this.allScripts = this.allScriptsCopy;
	    this.search = '';
	    // Open which ever the selected query is found
	    this.accordionComponent.expand('two')
	 }
	onScroll(event: any) {
		
      // visible height + pixel scrolled >= total height 
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.direction = 'up'
        // change the class of which arrow is being pointed
      }
      else{
        this.direction = 'down'
      }
    }

  checkFileSettings(){
  	let role = this.selectedRole != 'Select Role'? this.selectedRole : null;
	  let database = this.selectedDatabase != 'Select Database' ? this.selectedDatabase : null;
		let schema = this.selectedSchema != 'Select Schema' ? this.selectedSchema : null;
		let connection_id = this.selectedConn.username != 'Select Connection' ? this.selectedConn.connection_id : null;
		let connection_name = this.selectedConn.username != 'Select Connection' ? this.selectedConn.username : null;
		let warehouse = this.selectedWarehouse != 'Select Warehouse' ? this.selectedWarehouse : null;
		let error = ''
		
		
		if(connection_id == null){
			error = 'connection'
			this.catalog.needConnection = true;
			this.catalog.menuItems = [];
		}
		else if(role == null){
			error = 'role'
		}
		else if(warehouse == null){
			error = 'warehouse'
		}
		else if(database == null){
			error = 'database'
		}
		else if(schema == null){
			error = 'schema'
		}

		if(error.length > 0){
			let errorMsg = 'Please select a ' + error
			return errorMsg;
		}
		return false
  }



    


	publishQuery(file, comment){
		let headers = this.headers
	  let versionURL = this.url + '/query/' + this.selectedFile.id + '/version';
	  let baseVersion = file.version_id != undefined ? file.version_id : 0;
	  let role = this.selectedRole != 'Select Role'? this.selectedRole : null;
	  let database = this.selectedDatabase != 'Select Database' ? this.selectedDatabase : null;
		let schema = this.selectedSchema != 'Select Schema' ? this.selectedSchema : null;
		let connection_id = this.selectedConn.username != 'Select Connection' ? this.selectedConn.connection_id : null;
		let connection_name = this.selectedConn.username != 'Select Connection' ? this.selectedConn.username : null;
		let warehouse = this.selectedWarehouse != 'Select Warehouse' ? this.selectedWarehouse : null;
	  let errorMsg = this.checkFileSettings()
	  if(errorMsg){
	  	errorMsg + ' before publishing.'
			this.toastrService.error(errorMsg);
		}
		else{
			let data = {
	   		query_file_name: file.file_name,
	   		base_published_query_version_id: baseVersion,
	   		publish_comment: comment,
	   		code: file.code,
	   		settings: {
	   			role: role,
	   			database: database,
	   			schema: schema,
	   			connection_id: connection_id,
	   			connection_name: connection_name,
	   			warehouse: warehouse
	   		}
		 	}
			
		    this.http.post(versionURL, data, {headers}).subscribe(
		        data=>{ 
		           if(data['status'] == 'success'){
		           	  	// Update the last updated date
		           	  	
		           	  	// Update the version id 
		           	  	this.selectedFile.version_id = data['message']['version_id']
		           	  	this.toastrService.success('Query published');
		           	  	this.http.get(versionURL, {headers}).subscribe(
							    		data=>{ 
							    			if(data['status'] == 'success'){
							    				this.selectedFile.versions = data['message']
							    				this.modifyScript(this.selectedFile, 'update')
							    			}
							    		}
							    	);
		           }
		        }
		    )

			}	  
	}

	// -----------------------------------------Modals ---------------------------------------------//


	openConnModal(content, options={}){
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      this.connectionForm.reset()
	    });
	}
	openEditConnModal(content, options={}, conn){
		this.editConnectionForm =  new FormGroup({
		  	connection_id: new FormControl(),
		  	username: new FormControl(),
		  	password: new FormControl(),
		  	system: new FormControl(),
        user_type: new FormControl()
		});

		let password = ''

		this.editConnectionForm.setValue(
			{
				connection_id: conn.connection_id, 
				username: conn.username, 
				password: '',
				system: conn.system, 
				user_type: conn.user_type
			}
			);
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	  		this.editConnectionForm.reset();
	    });
	}

	openDeleteFileModal(content, options={}, file){
		this.modalService.open(content, options).result.then((result) => {
		    }, (reason) => {
		      	if(reason == 'delete'){
				  this.deleteFile(file);
				}
		    });
	}

	openDeleteConnModal(content, options={}, conn){
		
		this.deleteConnUsername = conn.username;
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      	if(reason == 'delete'){
			  this.deleteConn(conn);
			}
	    });
	}

	 async waitForSaving(saveType) {
	 	while(false){
	 		await this.sleep(500);
	 		if(this.saving[saveType]){
	 			return true
	 		}
	 	}
	 	return true
   }
	  sleep(ms) {
	    return new Promise(resolve => setTimeout(resolve, ms));
	  }

	openPublishModal(content, options={}){
	
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      	if(reason == 'save'){
	      	  let vals = this.publishForm.value;
	      	  this.publishForm.reset();
			  		this.publishQuery(this.selectedFile, vals.comment)
			}
	    });
	}

	private getConnDismissReason(reason: any): string {
		if(reason == 'save'){
			
		}
	    return reason
	}


  	private getDismissReason(reason: any): string {
  		
		if(reason == 'save'){
			
		}
		
	    
	    return reason
	}
	expressionToFriendly(expression){
		if(expression === undefined || expression == '' || expression == null){
			return 'Not Scheduled'
		}
		return cronstrue.toString(expression)
	}
	onSort(event, index){
		
		// Get the correct query result rows
		const rows = [...this.queryResults[index].values];
	
		const sort = event.sorts[0];
		rows.sort((a, b) => {
			return this.orderByComparator(a[sort.prop], b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
		});

		this.queryResults[index].values = rows;
		for(let i = 0; i < this.queryResults[index].values.length; i++){
			this.queryResults[index].values[i].carve_row_index = i+1;
		}
	}

	orderByComparator(a: any, b: any): number {
	  if (a === null || typeof a === 'undefined') a = 0;
	  if (b === null || typeof b === 'undefined') b = 0;
	  if (a instanceof Date && b instanceof Date) {
	    if (a < b) return -1;
	    if (a > b) return 1;
	  } else if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
	    // Convert to string in case of a=0 or b=0
	    a = String(a);
	    b = String(b);
	    // Isn't a number so lowercase the string to properly compare
	    if (a.toLowerCase() < b.toLowerCase()) return -1;
	    if (a.toLowerCase() > b.toLowerCase()) return 1;
	  } else {
	    // Parse strings as numbers to compare properly
	    if (parseFloat(a) < parseFloat(b)) return -1;
	    if (parseFloat(a) > parseFloat(b)) return 1;
	  }

	  // equal each other
	  return 0;
	}

	splitQueries(){
		var editor = this.editor['instance']
		var doc = editor.getDoc();
    var cursor = doc.getCursor();
    var selection = doc.getSelection();
    var queryIndexes = []
    	// Split by ; but ignore if after -- on same line or in between /* */

		// Run Selection
		let cursorIndex = 0;
		let lines = []
		let isSelection = false;
		if(selection != ''){
			lines = selection.split('\n');
			isSelection = true;
		}
		else{ 
			cursorIndex = cursor.line;
			lines = editor.getValue().split('\n')
		}

		let start = this.allIsChecked || isSelection ? 0 : this.getStart(lines, cursorIndex);
		// work our way down the list
		// Remove comments
		let query = ''
		let qIndexes = null;
		let qStart = start;
		for (var i = start; i < lines.length; i++) {


			let obj = this.removeComments(i, lines, false)
			i = obj.index;
			query += ' ' + obj.query;
			
			if(query.includes(';')){
				let qEnd = i;

				// this is the end of the query 
				// end + 1 is the start of the next one
				// query = this.stripChars(query);
				qIndexes = {start: qStart, end: qEnd}
				queryIndexes.push(qIndexes)
				// for(var s = i; i < lines.length; i++ ){
				// 	if(lines[s] != ""){
				// 		qStart = s;
				// 		break
				// 	}
				// }
				qStart = i + 1;
				query = ''
				if(!this.allIsChecked && !isSelection){
					break
				}
			}	
		}
		// If the last query does not have a semi colon, add to the list of queries
		let last_query = query.replace(/\s+/g, '')
		if(last_query != '' && qIndexes != null){
			queryIndexes.push(qIndexes)
		}
		console.log('queries: ', queryIndexes)
    return {queryIndexes: queryIndexes, lines: lines}
	}

	stripChars(query){
		while(query.includes("\t")){
				query = query.replace("\t", " ")
			}
		while(query.includes("  ")){
			query = query.replace("  ", " ")
		}
		query = query.replace("/**/", "");
		return query
	}

	getStart(lines, cursorIndex){
		let start = 0;
		let endCount = 0;
		let startCount = 0;
		// let commentTracker = {closed: true}
		let query = '';
		for(var i = 0; i <= cursorIndex; i++) {
			let obj = this.removeComments(i, lines, true)
			i = obj.index;

			// If last row don't add a delimiter
			query += obj.query;
		}

		let split = query.split(';');
		console.log(split)

		if(split !== undefined){
			// get count for each line delimiter
			let remove = split[split.length-1] == '' || split[split.length-1] == ' ' ? 2 : 1;
			for(var i = 0; i < split.length - remove; i++){

				let lines = split[i].split(' ||| ');
				if(lines !== undefined){
					start += lines.length - 1
				}
			}
		}
		return start
	}
	getEnd(lines, start, cursorIndex){
		let end = lines.length - 1;
		let commentOpen = false;
		// let commentTracker = {closed: true}
		let query = '';
		for(var i = start; i <= lines.length -1; i++) {
			// Just check for a semi-colon not after -- or in between /* */
			let scIndex = lines[i].indexOf(';')
			let comment1 = lines[i].indexOf('--')
			let comment2 = lines[i].indexOf('/*')
			let comment3 = lines[i].indexOf('*/')

			// If a semi-colon is found
			if(scIndex > -1){
				// If no commments are found
				if(comment1 == -1 &&  comment2 == -1 && comment3 == -1) return i
				if(scIndex < comment1 &&  comment2 == -1 && comment3 == -1) return i   
				if(comment2 > -1){
					commentOpen = true;	
				}
				if(comment3 > -1 && scIndex > comment3) return
			}	
		}
		return end
	}

	removeComments(i, lines, hasDelimiter){
		let line = lines[i];
		let query = line.split('--')[0] !== undefined ? line.split('--')[0] : line; // remove single line comment
		let end_comment = '';
		let start_comment = query.split('/*')[1];
		let full_comment = '';
		let commentLines = 0;

		if(hasDelimiter){
			query = ' ||| ' + query
		}

		if(start_comment !== undefined){
			let removed = this.removeInlineMulti(line);
			if(removed){
				line = removed
			}
			else{
				commentLines ++;
				full_comment += "/*" + start_comment
				// keep going until we find the end of the comment or end of code
				for (var x = i + 1; x < lines.length; x++) {
					let l = lines[x];
					commentLines++;

					// Remove any single line comments
					let clean_line =  l.split('--')[0] !== undefined ? l.split('--')[0] : l;
					query += clean_line

					if(l.includes('*/')){
						full_comment +=  l.split('*/')[0] + '*/';
						// we don't want to remove delimeter if specified
						if(hasDelimiter){
							
							let replace = '';
							for(var s = 1; s <= commentLines; s++){
								replace += ' ||| '
							}
							query = query.replace(full_comment, replace)
						}
						else{
							query = query.replace(full_comment, '')
						}
						i = x
						break // found the end of the multi-line comment! 
					}
					else{
						full_comment += clean_line
					}
				}
			}
		}
		
		// Attempt to remove all inline comments
		while(query.includes('/*')){
			let removed = this.removeInlineMulti(query);
			if(!removed){
				// Not an in-line
				// TO DO: figure out how to handle another multi-line
				break
			}
			query = removed;
		}
		
		// Check for additional single or multi-line

		let obj = {index: i, query: query};
		return obj
	}
	removeInlineMulti(line){
		let start_comment = line.split('/*')[1];
		if(start_comment !== undefined){
			if(line.includes('*/')){
				let end_comment = start_comment.split('*/')[0];
				line = line.replace('/*' + end_comment + '*/', '')
			}
			else{
				return false
			}
		}
		return line
	}

	RunAllCheckbox(e){
	   	if (e.target.checked) {
	   		this.allIsChecked=true;
	   		this.queryCount = this.splitQueries().queryIndexes.length;
	    } else {
	       	this.allIsChecked=false;
	       	this.queryCount = 0
	    }
    }

 getSize(size){
  	if(size/1000 > 1 && size/1000 < 1000){
  		return size/1000 + ' KB'
  	}
  	else if(size/1000000 > 1){
  		return size/1000000 + ' MB'
  	}
  	else{
  		return size + ' Bytes'
  	}
  }

  recursiveQuery(index, queryIndexes, lines){
  	
    	if(this.stoppingQuery){
    		return;
    	}
		let tObj = {
			timeRef: undefined,
			counter: undefined,
		    milliseconds: '00',
		    seconds: '00',
		    minutes: '00'
		};
		this.queryResults.push(
	 		{
	 			values: [], 
	 			columns: [], 
	 			limit: this.paneSizes.rowLimit / queryIndexes.length,
	 			heightMultiplier: 0,
	 			queryId: '',
	 			showTimer: true,
	 			sqlRunning: true,
	 			timer: tObj,
	 			aborted: false,
	 			error: false
	 		}
	 	)
			
		let params: HttpParams = new HttpParams();
    let exURL = this.query_svc_url + '/query/execute';
    let headers = this.headers;
    let indexes = queryIndexes[index];
    let sql = lines.slice(indexes.start, indexes.end+1).join('\n')
    let data = {
    	code: sql,
    	settings: this.selectedFile.settings
    };

   
	  if(this.stoppingQuery){return;}

		this.http.post(exURL, data, {headers}).subscribe(
	        data=>{ 
	        	// console.log(data)
	            if(data['status'] == 'success'){
	            	if(this.stoppingQuery) return;
	            	let qid = data['message']['query_id'];
	            	// Get the query results
	        
	            	let qUrl = exURL + '/' + qid + '/connection/' + this.selectedFile.settings.connection_id;
	            	this.queryResults[index].queryId = qid
	            	// Start the timer after the query has been submitted :) 
	            	this.startTimer(index);

	            	let params: HttpParams = new HttpParams();
	            	params = params.append('database', this.selectedFile.settings['database'])
	            	params = params.append('schema', this.selectedFile.settings['schema'])
	            	params = params.append('warehouse', this.selectedFile.settings['warehouse'])
	            	params = params.append('role', this.selectedFile.settings['role'])

	            	// Only provide profile when one query is executed
	            	if(this.profileIsChecked && queryIndexes.length > 1){
	            		params = params.append('get_profile', 'false')
	            		let errorMsg = 'Please execute a single query for profiling.'
									this.toastrService.error(errorMsg);
									this.profileIsChecked = false;
	            	}
	            	else{
	            		params = params.append('get_profile', this.profileIsChecked.toString())
	            	}
	            	
	            	this.http.get(qUrl, {headers, params}).subscribe(
	            		data=>{ 
	            			 if(this.stoppingQuery) return;
	            			 if(data['status'] == 'success'){
	            			 	this.stopTimer(index)
	            			 	this.queryResults[index].sqlRunning = false
	            			 	
	            			 	if(data['message']['results'].length > 0){
	            			 		let width = 50
	            			 		if(data['message']['results'].length > 100 && data['message']['results'].length < 1000){
	            			 			width = 60
	            			 		}
	            			 		else if(data['message']['results'].length > 1000){
	            			 			width = 60
	            			 		}

	            			 		let keys = Object.keys(data['message']['results'][0]);
		            			 	let columns = [];
		            			 	columns.push({prop: 'carve_row_index', name: '', width: width, canAutoResize: false})

		            			 		 	//columns.push({field: 'snowguide_row_index', header: '', width: '35px'})
		            			 	//this.queryResults[index].width = String((225 * keys.length) + 3

		            			 	for(let k of data['message']['columns']){
		            			 		
		            			 		if(k != 'carve_row_index'){
		            			 			let obj = {name:k, prop: k};
		            			 			columns.push(obj);
		            			 		}
		            			 	}
		            			 	this.queryResults[index].values = data['message']['results']
		            			 	this.queryResults[index].columns = columns;

		            			 	if(this.profileIsChecked){
		            			 		this.queryResults[index].profile =  data['message']['profile'];
			            			 	if('variables' in this.queryResults[index].profile){
			            			 			let variables = JSON.parse(data['message']['profile'].variables)
			            			 			let new_variables = [];
			            			 			if('messages' in this.queryResults[index].profile){
			            			 					this.queryResults[index].profile.messages = this.queryResults[index].profile.messages.replace("{", "").replace("}", "")
			            			 				}
			            			 			for(let v in variables){
			            			 				variables[v].name = v;
			            			 				new_variables.push(variables[v])
			            			 			}
			            			 			this.queryResults[index].profile.variables = new_variables;
			            			 			
			            			 	}
		            			 	}
		            			 	

		            			 	if(data['message']['row_count'] == 1 && keys.includes('Error')){
		            			 		this.queryResults[index].error = true;
		            			 		this.sqlRunning = false;
		            			 	}
		            			 	else{
		            			 		// Only run if results have been retrieved, no errors have occurred, and more queries to run
		            			 		index++
		            			 		if(index < queryIndexes.length){
		            			 			
		            			 			this.recursiveQuery(index, queryIndexes, lines)
		            			 		}
		            			 		else{
		            			 			this.sqlRunning = false;
		            			 		}
		            			 	}
	       
	            			 	}
	            			 }
	            			 else{
	            			 	// setConn
	            			 	this.stopTimer(index)
	            			 }
	            		},
        				error => {
        					if(this.stoppingQuery) return;
        					this.stopTimer(index)
        					this.queryResults[index].sqlRunning = false
        					this.sqlRunning = false;
        					
        				}
	            	)
		        }
		        else{
		        	if(this.stoppingQuery) return;
		        }
		    },
		    error => {
		    	if(this.stoppingQuery) return;
				this.stopTimer(index)
				this.queryResults[index].sqlRunning = false
				this.sqlRunning = false;

			}
		)
    }
	runQuery(){
		this.autoSave('system')
		this.stoppingQuery = false;
		if(this.sqlRunning){
			
			return; // Trigger an alert if they try and run the query while a query is already running
		} 
		this.queryResults = []
		let split = this.splitQueries();
		this.queryCount = split.queryIndexes.length;
		if(this.queryCount == 0) return;
		this.sqlRunning = true;
		this.recursiveQuery(0, split.queryIndexes, split.lines)
		this.allIsChecked=false;
	}

	stopQuery(){
		this.stoppingQuery = true;
		let url = this.query_svc_url + '/query/execute';
		let i = 0
		for(var q of this.queryResults){
			if(q.queryId != '' && q.queryId !== undefined){
				
			    let stopUrl = url + '/' + q.queryId + '/connection/' + this.selectedFile.settings.connection_id;
			    let headers = this.headers;
			    if(q.columns.length > 0) {
				
					if(i >= this.queryResults.length -1){
		        		this.sqlRunning = false;
		        	}
		        	i++;
			    }
			    else{
			    	let params: HttpParams = new HttpParams();
            	params = params.append('database', this.selectedFile.settings['database'])
            	params = params.append('schema', this.selectedFile.settings['schema'])
            	params = params.append('warehouse', this.selectedFile.settings['warehouse'])
            	params = params.append('role', this.selectedFile.settings['role'])
							this.http.delete(stopUrl, {headers, params}).subscribe(
						        data=>{ 
						        	if(data['status'] == 'success'){	

						        		if(this.queryResults[i] !== undefined){
						        			this.queryResults[i].sqlRunning = false
						        			this.queryResults[i].aborted = true
						        			this.stopTimer(i)
						        		}
						        		
						        		if(i >= this.queryResults.length -1){
						        	
						        			this.sqlRunning = false;
						        		}
						        	}
						        	i++;
								},
								error=>{
									
									this.queryResults[i].sqlRunning = false
									this.queryResults[i].aborted = true
									this.stopTimer(i)
									if(i >= this.queryResults.length -1){
					
						        		this.sqlRunning = false;
						        	}
						        	i++;
								}
					)
				}
			}
			else{
				this.queryResults[i].sqlRunning = false
				this.queryResults[i].aborted = true
				this.stopTimer(i)

				if(i >= this.queryResults.length -1){
	        		this.sqlRunning = false;
	        	}
	        	i++;
			}
		}
		
	}

	startTimer(queryIndex){
	    const startTime = Date.now() - (this.queryResults[queryIndex].timer.counter || 0);
	    if (this.queryResults[queryIndex].timer.timeRef) {
	        this.clearTimer(queryIndex);
	   	}

	    this.queryResults[queryIndex].timer.timeRef = setInterval(() => {
	        this.queryResults[queryIndex].timer.counter = Date.now() - startTime;
	        this.queryResults[queryIndex].timer.milliseconds = Math.floor(Math.floor(this.queryResults[queryIndex].timer.counter % 1000) / 100).toFixed(0);
	        this.queryResults[queryIndex].timer.minutes = Math.floor(this.queryResults[queryIndex].timer.counter / 60000);
	        this.queryResults[queryIndex].timer.seconds = Math.floor(Math.floor(this.queryResults[queryIndex].timer.counter % 60000) / 1000).toFixed(0);
	        if (Number(this.queryResults[queryIndex].timer.minutes) < 10) {
	          this.queryResults[queryIndex].timer.minutes = '0' + this.queryResults[queryIndex].timer.minutes;
	        } else {
	          this.queryResults[queryIndex].timer.minutes = '' + this.queryResults[queryIndex].timer.minutes;
	        }
	        if (Number(this.queryResults[queryIndex].timer.milliseconds) < 10) {
	          this.queryResults[queryIndex].timer.milliseconds = '0' + this.queryResults[queryIndex].timer.milliseconds;
	        } else {
	          this.queryResults[queryIndex].timer.milliseconds = '' + this.queryResults[queryIndex].timer.milliseconds;
	        }
	        if (Number(this.queryResults[queryIndex].timer.seconds) < 10) {
	          this.queryResults[queryIndex].timer.seconds = '0' + this.queryResults[queryIndex].timer.seconds;
	        } else {
	          this.queryResults[queryIndex].timer.seconds = '' + this.queryResults[queryIndex].timer.seconds;
	        }
	      }, 100);
	}
	stopTimer(queryIndex){
		
		clearInterval(this.queryResults[queryIndex].timer.timeRef);
	}
	clearTimer(queryIndex) {
	    this.queryResults[queryIndex].timer.counter = undefined;
	    this.queryResults[queryIndex].timer.milliseconds = '00',
	    this.queryResults[queryIndex].timer.seconds = '00',
	    this.queryResults[queryIndex].timer.minutes = '00';
	    clearInterval(this.queryResults[queryIndex].timer.timeRef);
  	}
  resizeEvt(evt: any) {
    
  }

   saveFileName(){
	   	let isNew = this.selectedFile.id === undefined ? true : false;
	   	// If file name wasn't changed, just return 
	   	if(this.selectedFile.file_name == this.newFileName && !isNew || this.newFileName == ''|| this.newFileName == undefined){
	   		this.fileNameEdit = false;
	   		return true;
	   	}
	   	this.saveFile.error_message = ''
	   	this.tooltip.close();
	    this.saveFile.has_error = false;

	   	// Don't allow same file name
	   
 
			if(this.fileExists(this.newFileName)){
				// Raise a warning and not save yet
				let fnLen = this.newFileName.length * 2;

	   		this.fileNameInput.nativeElement.setSelectionRange(fnLen, fnLen);
	   		this.fileNameInput.nativeElement.focus();
				this.saveFile.error_message = 'Name already exits!'
				this.saveFile.has_error = true;
				this.tooltip.open();
				return false
			}
		
			this.selectedFile.file_name = this.newFileName;
	   	this.fileNameEdit = false;
	    let headers = this.auth.getHeaders()
	    let errorMsg = this.checkFileSettings()
		  if(errorMsg){
		  	errorMsg + ' before creating new file.'
				this.toastrService.error(errorMsg);
				return false;
			}
	   	this.autoSaving = true
	   	if(isNew){
	   		// POST Record
	   		this.setConn(this.selectedConn, false, false, true);
	   		let connURL = this.url + '/query';
	   		console.log(this.selectedFile)
	   		
		    this.http.post(connURL, this.selectedFile, {headers}).subscribe(
		        data=>{ 
		           if(data['status'] == 'success'){
		           	  // Update the last updated date

	           	  	var file = this.selectedFile
	           	  	this.selectedFile['versions'] = []
									this.allScripts.push(this.selectedFile);
	           	  	var fIndex = this.allScripts.findIndex(x => x.file_name==file.file_name)
						   		if(fIndex > -1){
									this.allScripts[fIndex].id = data['message']['id'];
									this.editorService.setData('editor_scripts', this.allScripts)
								}
								this.organizeScripts();
				           }
				           this.autoSaving = false;
				        }, error=>{
				        	this.toastrService.error('Failed to save file');
				        	this.autoSaving = false;
				        }

		    )
	   		
	   		
	   	}
	   	else{
	   		// PUT record
	   		let data = {
	   			file_name: this.selectedFile.file_name, 
	   			settings: this.selectedFile.settings
	   		};
	   		let connURL = this.url + '/query/' + this.selectedFile.id;
		  
		    this.http.put(connURL, data, {headers}).subscribe(
		        data=>{ 

		           if(data['status'] == 'success'){
		           	  	// Update the last updated date
		           	  	var file = this.selectedFile
		           	  	var fIndex = this.allScripts.findIndex(x => x.id==file.id)
							   		if(fIndex > -1){
											this.allScripts[fIndex].file_name = this.selectedFile.file_name;
											this.editorService.setData('editor_scripts', this.allScripts)
										}
		           	  this.organizeScripts();
		           	  this.autoSaving = false;
		            }
				    }, error=>{
				    		this.toastrService.error('Failed to update file');
		        		this.autoSaving = false;
		        }
		    )
	   	}
	   	return true;
	   	
   }

   getVersionCount(file){
   	let count = 0
   	if('versions' in file){
   		count = file['versions'].length
   	}
   	return count
   }

   fileExists(file_name){
   	 	for (var val of this.allScripts){
   	 		if(val.file_name == file_name){
   	 			return true
   	 		}
   	 	}
   		return false 
   }

   editFileName(){
   	 this.newFileName = this.selectedFile.file_name;
   	 this.fileNameEdit = true;
   	 let fnLen = this.newFileName.length * 2;
   	 this.fileNameInput.nativeElement.setSelectionRange(fnLen, fnLen);
   	 this.fileNameInput.nativeElement.focus();
   }

   checkSubmit(e){
   		if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
           this.runQuery();
        }
        else{
        	this.allIsChecked = false;
        }
   }

   newFile(){

   	let err = this.checkFileSettings()
   	if(err && !this.editorService.active){
   		this.toastrService.error(err + ' before creating a new file');
   		return;
   	}

   	this.fileNameEdit = true;
   	this.newFileName = 'Untitled';
   	let i = 1;
   	let exists = this.fileExists(this.newFileName);
   	while(exists){
   		if(!this.fileExists(this.newFileName + "(" + i + ")")){
   			this.newFileName = this.newFileName + "(" + i + ")";
   			exists = false;
   		}
   		i ++;
   	}
   	let fnLen = this.newFileName.length * 2;

   	let code = this.editorService.code
   	// if(code == ''){
   	// 	this.fileNameInput.nativeElement.setSelectionRange(fnLen, fnLen);
   	// 	this.fileNameInput.nativeElement.focus();
   	// }

	  let script = {
				file_name:this.newFileName, 
				query_type: "own", 
				code: code,
				settings: {
					role:  this.selectedRole != 'Select Role'? this.selectedRole  : null,
					database:  this.selectedDatabase != 'Select Database' ? this.selectedDatabase  : null,
					schema: this.selectedSchema != 'Select Schema' ? this.selectedSchema  : null,
					connection_id: this.selectedConn.username != 'Select Connection' ? this.selectedConn.connection_id : null,
					connection_name: this.selectedConn.username != 'Select Connection' ? this.selectedConn.username : null,
					warehouse: this.selectedWarehouse != 'Select Warehouse'  ? this.selectedWarehouse : null
				}
		};

	// file_name, code, query_type, settings):

 //        # settings object:
 //        #   role
 //        #   database
 //        #   schema
 //        #   connection_id
 //        #   warehouse

   	this.selectedFile = {...script};
   	// if((this.editorService.active = true && this.editorService.code != '') || ){
 		// console.log('saving file because service active')
 		this.saveFileName()
   	// }



   	// Check for all the existing file names - by default use Untitled unless already exists and increment number
   	// Make the new file name the name of the 

   }
	 editorAutoSave(val){
	 	
	 	let err = this.checkFileSettings();
	 	// console.log('Trying to autosave - error: ', err, 'val: ', val)
	 	if(!err){
	 		this.autoSave(val)
	 	}
	 }

   autoSave(val){

   	 // If the user doesn't have any files and they click into the editor - auto create the file
   	 if(val == 'click' && this.selectedFile.file_name == undefined){
				this.newFile()
				// this.saveFileName();
				this.autoSaving = false;
				return;
	 		}

   		// Don't save if code is undefined, auto save is already in flight, or code hasn't changed since last save
   		if((this.selectedFile.code === undefined || this.selectedFile.code == '' || this.lastSavedCode == this.selectedFile.code || this.autoSaving || this.selectedFile.id === undefined) && val != 'system') return;

   	 	let saveTime = new Date();
   	 	let localCriteria = ['space', 'tab', 'delete', 'enter', 'paste', ';']; 
   	 	let remoteCriteria = ['click', 'blur', 'auto', 'system'];

	 	this.autoSaving = true
	 	this.lastAutoSave = saveTime; 
	 	this.lastSavedCode = this.selectedFile.code;

	 	// Only save locally 
	 	if(localCriteria.includes(val)){
	 		// update the latest update date
		 	this.selectedFile.last_update_date = new Date();
	 		this.modifyScript(this.selectedFile, 'update');
	 		// console.log('saved locally')
	 		this.autoSaving = false;
	 	}
	 	// Save locally and remote
	 	else if(remoteCriteria.includes(val)){
	 		// Handle a click into editor to create new file
	 		  // console.log('Remote criteria met: ', val)
		    let headers = this.headers;

		    let conn = this.selectedConn.connection_id != 0 ? this.selectedConn.connection_id   : null;
		    let connName= this.selectedConn.username != 'Select Connection' ? this.selectedConn.username : null;
		    let wh = this.selectedWarehouse != 'Select Warehouse' ? this.selectedWarehouse : null;
		    let role = this.selectedRole != 'Select Role' ? this.selectedRole : null;
		    let db = this.selectedDatabase != 'Select Database' ? this.selectedDatabase : null;
		    let schema = this.selectedSchema != 'Select Schema' ? this.selectedSchema : null;

		    let errorMsg = this.checkFileSettings()
			  if(errorMsg){
			  	errorMsg + ' before file can be autosaved.'
					this.toastrService.error(errorMsg);
					this.autoSaving = false;
					return false;
				}
		    let url = this.url + '/query/' + this.selectedFile.id + '/autosave';
		    let body = {
		    	code: this.selectedFile.code,
		    	file_name: this.selectedFile.file_name,
		    	version_id: this.selectedFile.version_id == undefined ? 0 : this.selectedFile.version_id,
		    	settings: {
		    		connection_id: conn,
		    		role: role, 
		    		warehouse: wh,
		    		database: db,
		    		schema: schema
		    	}
		    };

		    // one last check to make sure empty code doesn't get saved
		    if(this.selectedFile.code === undefined || this.selectedFile.code == ''){
		    		this.autoSaving = false;
		    		return;
		    } 

		    this.http.post(url, body, {headers}).subscribe(
		      data=>{ 
		 			// save successful 
				 			
				 			let lud = data['message']['last_update_date']
				 			this.selectedFile.last_update_date = lud

				 			// update the latest update date
				 			this.modifyScript(this.selectedFile, 'update');
			 				this.sortByUpdateDate()
			 				this.autoSaving = false;
		 		},
		 		error=>{
		 			this.autoSaving = false;
		 		}
	 		);
	 		if(val == 'system'){
	 			this.autoSaving = false;
	 		}
	 	}

	    

 		// also update the latest
 		
 		
   }

   toggleQueryNavigator(){
   		this.navIsToggled = !this.navIsToggled;
   		if(this.navIsToggled){
   			

	   		let e = this.eSplit.getVisibleAreaSizes()[0];
	   		let n = this.eSplit.getVisibleAreaSizes()[1];

	   		let w = this.controlBar.nativeElement.offsetWidth;
	   		let collapseW = 55;
	   		let newPercent = (collapseW / w) * 100;
	   		let newWidths = [100-newPercent, newPercent];
	   		
	   		this.eSplit.displayedAreas[0].size = 100-newPercent;
	   		this.eSplit.displayedAreas[1].size = newPercent;
	   		this.paneSizes.editorCol = 100-newPercent;
	   		this.paneSizes.navigatorCol = newPercent;

	   		
   		}
   		else{
   			this.calcInitEditorCol();
   		}
   }

   toggleEditor(e){
   		this.editorIsToggled = !this.editorIsToggled;
	   	if(this.editorIsToggled){
	   		
	   	 	let collapseH = 60;
	   		let h = this.editorWrapper.nativeElement.offsetHeight;
	   		let newPercent = (collapseH / h) * 100;
		    this.split.displayedAreas[0].size = newPercent;
	   		this.split.displayedAreas[1].size = 100-newPercent;
	   		this.paneSizes.editorRow = newPercent;
	   		this.paneSizes.resultsRow = 100-newPercent;


	   		let rH = ((this.paneSizes.resultsRow / 100) * h);
	   		this.paneSizes.resultsTableHeightPx = rH;
	   		this.paneSizes.resultsTableWrapperHeightPx = rH;
	   		this.paneSizes.rowLimit = Math.ceil((rH-94) / 30);
	   		

	   	}
	   	else{
   			this.calcInitEditorRow();
	   	}
   }
   gutterDblClick(pane, e){
   	if(pane == 'row'){
   		this.toggleEditor(e);
   		this.dragEndResults(e, true)
   	}
   	else if (pane == 'col'){
   		this.toggleQueryNavigator()
   	}
   }
   dragEnd(pane, e){
   	 if (pane == 'row'){

  
   	 	let h = this.editorWrapper.nativeElement.offsetHeight;
   	 	let rH = ((e.sizes[1] / 100) * h);

   		this.paneSizes.resultsTableHeightPx = rH;
   		this.paneSizes.resultsTableWrapperHeightPx = rH;
   		this.paneSizes.rowLimit = Math.ceil((rH-94) / 30);
   		this.dragEndResults(e, true)
   		// console.log('Table Height: ', this.paneSizes.resultsTableWrapperHeightPx)
   		window.dispatchEvent(new Event('resize'));
   	 }
   
   }
   dragEndResults(e, isTop){


   
   	// Need to resize all table wrappers and set new limit
	  	for(var i = 0; i < this.queryResults.length; i++){
	  			let limit = 0, delta = 0
	  			// let add = i > 0 ? 62 : 0;
	  			let height = this.paneSizes.resultsTableHeightPx / this.queryResults.length;
	  			if(isTop){
	  				// console.log('height:', height)
				 		limit = Math.floor((height - 34) / 32);
	  			}
	  			else{
	  				// console.log('sizes: ',e.sizes[i])
	  				let currentHt = (e.sizes[i] / 100 ) * (this.paneSizes.resultsTableHeightPx)
	  				let delta = Math.floor(currentHt - height);
	  				// console.log('current height: ', currentHt)
				 		limit = Math.ceil((currentHt - (94 + (64/this.queryResults.length))) / 30);
	  			}

	  			// console.log('New Limit: ', limit)
	   			
		 			this.queryResults[i].limit = limit;
				 	this.queryResults[i].heightMultiplier = delta;
				 	window.dispatchEvent(new Event('resize'));
				
			}
   	
   }
   private getTime(date) {
    return date != null ? new Date(date).getTime() : 0;
	}


	public sortByUpdateDate(): void {
	    this.allScripts.sort((a, b) => {
	        return this.getTime(b.last_updated_date) - this.getTime(a.last_updated_date);
	    });
	}

	download(content, contentType){
		var data = ''
		if(contentType == 'csv' || contentType == 'xlsx'){
			data = this.create_csv(content)
		}
		else if(contentType == 'pdf'){
			data = this.create_pdf(content)
		}
		else if(contentType == 'clipboard'){
			const selBox = document.createElement('textarea');
		    selBox.style.position = 'fixed';
		    selBox.style.left = '0';
		    selBox.style.top = '0';
		    selBox.style.opacity = '0';
		    selBox.value = this.create_csv(content);
		    document.body.appendChild(selBox);
		    selBox.focus();
		    selBox.select();
		    document.execCommand('copy');
		    document.body.removeChild(selBox);
		    this.toastrService.success('Copied to clipboard');
		    return;
		}
		else{
			return null
		}

		let link = document.createElement("a");
        link.download = this.getFileName(content.queryId, contentType);
        link.href = this.getUrl(data, contentType);
        link.click();

	}


	getUrl(data, dataType){
		
	    const blob = new Blob([data], { type: 'application/' + dataType });
	    var fileUrl = window.URL.createObjectURL(blob);
	    return fileUrl
	}

	create_pdf(content){
		var string = ''

		return string;
	}

	create_csv(content){
		var string = ''
		var headers = ''
		var body = ''
		
		var cols = []
		for (let column of content.columns) {
			
			if(column['prop'] != 'carve_row_index'){
				cols.push(column['prop'])
			}
		}
		if(this.includeHeaders){
	
			headers = cols.join(',')
			headers += '\r\n'
		}
		
		for(var row of content.values){
			var arr = []
			for (let c of cols) {
			    let value = '"'+row[c]+'"';
			    arr.push(value)
			}
			var str = arr.join(',')
			body += str + '\r\n'
		}
		

		string = headers + body
		
		return string;
	}
	getFileName(queryId, contentType){
		return "Query " + queryId + "." + contentType
	}

	returnTimer(timer){
		var timerStr = timer.minutes + ':' + timer.seconds + ':' + timer.milliseconds;
		return timerStr
	}



	clearCharts(){
  	for(let c of this.charts){
  		c.dispose()
  	}
  	this.charts = []
  }


	async createTextCharts(variables){
			this.profileToggled = !this.profileToggled;
	
			if(!this.profileToggled) {
				this.clearCharts();
				return;
			};

			this.clearCharts()
			await this.sleep(100);
			for(let v of variables){
		
				if(v.type != 'Categorical') continue;

					// console.log('Creating Chart for ', v)

					let profile = v;

					let chart = am4core.create(v.name, am4charts.XYChart);
					this.charts.push(chart)

					let vals = []
					let high_vals = profile.value_counts_without_nan;

					for(let k in high_vals){
						let obj = {category: k, count: high_vals[k]}
						vals.push(obj)
					}

					// Sort descending
					vals.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
					// Only take  5
					vals = vals.slice(0, 5);

					// Add data
					chart.data = vals;
					chart.padding(10, 10, 10, 10);

					let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
					categoryAxis.renderer.grid.template.location = 0;
					categoryAxis.dataFields.category = "category";
					categoryAxis.renderer.minGridDistance = 1;
					categoryAxis.renderer.inversed = true;
					categoryAxis.renderer.grid.template.disabled = true;

					let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
					valueAxis.min = 0;

					let series = chart.series.push(new am4charts.ColumnSeries());
					series.dataFields.categoryY = "category";
					series.dataFields.valueX = "count";
					series.tooltipText = "{valueX.value}"
					series.columns.template.strokeOpacity = 0;
					series.columns.template.column.cornerRadiusBottomRight = 5;
					series.columns.template.column.cornerRadiusTopRight = 5;

					let labelBullet = series.bullets.push(new am4charts.LabelBullet())
					labelBullet.label.horizontalCenter = "left";
					labelBullet.label.dx = 10;
					labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
					labelBullet.locationX = 1;

					// as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
					series.columns.template.adapter.add("fill", function(fill, target){
					  return chart.colors.getIndex(target.dataItem.index);
					});

					categoryAxis.sortBySeries = series;
			}
 	}
}
// @Component({
//   selector: 'cron-editor-wrapper',
//   templateUrl: './scheduleModal.html'
// })
// export class CronEditorModalComponent {
//  	public cronForm: any;
// 	public cronFormDefault: any;
// 	public cronOptions: CronOptions = {
//        formInputClass: 'form-control cron-editor-input',
//        formSelectClass: 'form-control cron-editor-select',
//        formRadioClass: 'cron-editor-radio',
//        formCheckboxClass: 'cron-editor-checkbox',
//        defaultTime: "00:00:00",
//        hideMinutesTab: true,
//        hideHourlyTab: true,
//        hideDailyTab: false,
//        hideWeeklyTab: false,
//        hideMonthlyTab: false,
//        hideYearlyTab: false,
//        hideAdvancedTab: true,
//        hideSpecificWeekDayTab: true,
//        hideSpecificMonthWeekTab: true,
//        hideSeconds: true,
//        use24HourTime: true,
//        cronFlavor: 'standard',
//     };
//   constructor() { 
//   	this.cronForm = new FormControl('0 * * * *');
// 	this.cronFormDefault = new FormControl('0 * * * *');
// }

// }


