import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomateComponent} from './automate.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CronEditorModule } from '../cron-editor/cron-editor.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { CodemirrorModule } from 'ng2-codemirror';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [AutomateComponent],
  imports: [
 	  NgbModule,
    CommonModule,
    TableModule,
    InputTextModule,
    InputSwitchModule,
    FormsModule,
    CronEditorModule,
    ReactiveFormsModule,
    NgSelectModule,
    LoadingBarModule,
    CodemirrorModule,
    SharedModule
  ]
})
export class AutomateModule { }
