import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

	private tableObjects$ = new ReplaySubject<any>(1);
   //private columnData$ = new Subject<any>();
    private tableObject = {}
    public graphData = new Object;
    public summaryData = new Object;
    public columnSummaryData = new Object;
    public usageSummaryData = new Object;
    public usageGraphData = new Object;
    public columnData = new Object();
    private tableList = new Array();
    public changed = false;
    public chartReg: any = [];
    public view = '';
    public data = {}
    public resetting = false;

    constructor(private storage: StorageMap) {
        this.resetData();
    }
    resetData(){
        this.resetting = true;
        this.data = {
            tableObject: {},
            columnData: {},
            columnSummary: {},
            graphTable: '',
            summaryTable: '',
            usageSummaryTable: '',
            permissionSummaryTable: '',
            summary: {},
            growthChart: {},
            storageChart: {},
            queriesChart: {},
            ddlChart: {},
        }
        // Delete all of the saved data
        for(let k of Object.keys(this.data)){
            this.storage.delete(k).subscribe(() => {});
        }
    }

    setObjects(database, schema, table, object_type){
       
    	var object = {
    		"database": database,
    		"schema" : schema,
    		"table" : table,
            "object_type": object_type
    	}
    	this.tableObjects$.next(object)
        this.tableObject = object
        // this.setData("tableObject", object);
    }

    getTableObject(): Observable<any> {
        return this.tableObjects$.asObservable();
    }

    getTableObj(){
        return this.tableObject
    }
   
   
    storeGraph(data, graphType){
        // Get list of cached Tables
        this.graphData[graphType] = data;
        this.setData(graphType, data);
    }

    getData(index): Observable<any>{
        return this.storage.get(index)
    }

    setData(index, data){
        this.storage.set(index, data).subscribe(() => {});
    }

    
    addChart(chart, chartType){
        this.chartReg[chartType] = chart;
    }

    disposeChart(chartType){
        // if(chartType in this.chartReg){
        //     console.log('disposing ', chartType)
        //     this.chartReg[chartType].dispose()
        // }
       
    }

    compareTables(tableObj1, tableObj2){
        if(! tableObj1){
            return false;
        }
        if (tableObj1['database'] != tableObj2['database']){
            return false;
        }
        if (tableObj1['schema'] != tableObj2['schema']){
            return false;
        }
        if (tableObj1['table'] != tableObj2['table']){
            return false;
        }
        return true
    }
  
    setView(view){
        this.view = view;
    }

    // getCachedColumns(): Observable<any> {
    //     return this.columnData$.asObservable();
    // }
    // findCachedColumns(){
    //     this.columnData$.next(JSON.parse(sessionStorage.getItem("columnData")))
    // }
}
