import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ViewChildren, ElementRef, QueryList, Inject, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DOCUMENT} from "@angular/common";
import { environment } from '../../environments/environment';
import { AuthService } from '../shared/auth/auth.service';
import { ApiService } from '../shared/api/api.service';
import cronstrue from 'cronstrue';
import { MenuItem} from 'primeng/api';
import { Table } from 'primeng/table';
import { AppService } from '../app.service';
import { NgbModal, ModalDismissReasons,NgbTooltipModule,  NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CronOptions} from '../cron-editor/CronOptions';
import { CronGenComponent } from '../cron-editor/cron-editor.component';
import { NgModel, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { EditorService } from '../editor/editor.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { AutomateService } from '../automate/automate.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import 'codemirror/mode/sql/sql';
// import * as CodeMirror from 'codemirror';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { ConvertSecondsPipe } from '../shared/pipes/convert-seconds.pipe';
// import { SocketService } from '../shared/websocket/socket.service'


import 'leader-line';
declare let LeaderLine: any;

@Component({
  selector: 'app-automate',
  templateUrl: './automate.component.html',
  styles: [
  ]
})

export class AutomateComponent implements OnInit, AfterViewInit {
  url: string = environment.url;
  page = 'automate'
  headers: HttpHeaders;
  jobs: Array<any> = []
  diagramLayout: any;
  selectedJob;
  search = '';
  disableJobSubscribe: Subscription;
  disableJobStepSubscribe: Subscription;

  disablingJob = false;
  disablingStep = false;
  runningJobs = [];
  runningSteps = [];
  nextRuns = [];
  currentJobStep = {};

  // @ViewChildren('jobDiagram', {descendants: true}) jobDiagram: QueryList<any>;
  @ViewChild(Table) dt: Table;
  @ViewChildren('dtContainer') dtContainer: QueryList<any>;
  @ViewChild(ToastContainerDirective, { static: true })
	@ViewChild('disableJobModal') disableJobModal: ElementRef;
	@ViewChild('disableJobStepModal') disableJobStepModal: ElementRef;
	@ViewChild('fileCode') fileCode: ElementRef;


  public allScripts: Array<any> = [];
  public allPackages: Array<any> = [];
  public allEnvironments: Array<any> = [];

  public cronOptions: CronOptions = {
       formInputClass: 'form-control cron-editor-input',
       formSelectClass: 'form-control cron-editor-select',
       formRadioClass: 'cron-editor-radio',
       formCheckboxClass: 'cron-editor-checkbox',
       
       defaultTime: "00:00:00",

       hideMinutesTab: true,
       hideHourlyTab: false,
       hideDailyTab: false,
       hideWeeklyTab: false,
       hideMonthlyTab: false,
       hideYearlyTab: false,
       hideAdvancedTab: true,
       hideSpecificWeekDayTab: true,
       hideSpecificMonthWeekTab: true,
       hideSeconds: true,

       use24HourTime: true,
       cronFlavor: 'standard',
       showFriendly: true
    };

  public newStepForm: FormGroup;
  public newJobForm: FormGroup;
  public newJobCronForm: FormGroup;
  public editJobForm: FormGroup;
  public editJobCronForm: FormGroup;
  public editStepForm: FormGroup;

  public stepObj: any = {
  	new: { enabled: 1},
  	edit: { open: false},
  	delete: {name: '', id: 0, children: []},
  	disable: {name: '', id: 0, children: []},
  	typeSelectOptions: [
	    	{label: 'Published SQL File', value: 'Published SQL File', icon: 'fal fa-file-code' },
	    	{label: 'Code Package', value: 'Code Package', icon: 'fal fa-rectangle-code' }
	  ],
	  typeSelectValue: null,
  	runTypeSelectOptions: [
  			{label: 'Initial step', value: 'initial', icon: 'fal fa-square', show: true},
	    	{label: 'Run after completion', value: 'completion', icon: 'fal fa-arrow-square-right' ,  show: true},
	    	{label: 'Run after success', value: 'success', icon: 'fal fa-check-square' , show: true},
	    	{label: 'Run after failure', value: 'failure', icon: 'fal fa-exclamation-square', show: true }
  	],
  	runTypeSelectValue: null,
	  runTypeSelectOptionsOG: [],
	  runAfterSelectOptions: [],
	  runAfterStepValue: -1,
	  sqlFile: -1,
	  package: -1,
	  environment: null, 
	  selectedPackage: {package_language: null},
	  dbt_command: null
  }


  public stepDetails: any = {
  	code: '',
  	last_update_date: '',
  	version: '',
  	owner: '',
  	type: '',
  	file_name: ''
  }

  public showStepRow = false;
  public displayStep:any;
  public lastOpenedStepLog: any;
  public stepExecutionResults: any;

  public stepObjOG: any;
  public jobsOG: any;

  public jobHistory: any;
  public stepHistory: any;

  runTypeSelectOptions

  public jobObj: any = {
  	
  	runTypeSelectOptions: [
  			{label: 'Schedule', value: 'schedule', icon: 'fal fa-calendar', show: true},
	    	{label: 'Run After', value: 'after', icon: 'fal fa-arrow-square-right' , show: true}
	  ],
  	runTypeSelectValue: null,
	  runAfterSelectOptions: [],
	  jobRunAfterValue: null,
	  detailsSelectOptions: [],
	  new: { schedule: '0 1 1/1 * *', enabled: 1},
	  edit:{ schedule: '0 1 1/1 * *', enabled: 1},
	  delete: { name: '', id: 0},
	  disable: {name: '', id: 0, children: []}
  }
  public jobObjOG: any;
  
  lines = [];
  jobClicked = false;
  showDiagram = true;
  showSteps = true;
  toastContainer: ToastContainerDirective;
  jobFields:any;
  resultCodeConfig:any;
  fileCodeConfig:any;
  history = {
  	loading: false
  }

  saving = {
  	job: false,
  	step: false
  }

  constructor(
  		private api: ApiService,
  		private http: HttpClient,
			private auth: AuthService,
			private appService: AppService,
			private modalService: NgbModal,
			private editorService: EditorService,
			public toastrService: ToastrService,
			private elementRef: ElementRef,
			private cdr: ChangeDetectorRef,
			private automate: AutomateService,
			private loadingBar: LoadingBarService,
			private router: Router, 
			private route: ActivatedRoute,
			@Inject(DOCUMENT) private document
	) { 
	    this.appService.pageTitle = 'Automate';
	    this.document.body.style.overflow = "scroll";
	  
	    this.headers = this.auth.getHeaders()
	    this.jobFields = {
		  	"name": "default",
		  	"owner": "default",
		  	"description": "default",
		    "job_status": "default",
		  }
  }

  ngOnInit(): void {
  	
  	this.toastrService.overlayContainer = this.toastContainer;
  	this.jobObjOG = JSON.parse(JSON.stringify(this.jobObj));
  	this.stepObj.runTypeSelectOptionsOG = this.stepObj.runTypeSelectOptions;
  	this.stepObjOG = JSON.parse(JSON.stringify(this.stepObj));
  	this.diagramLayout = {
  		row_num: 0,
  		column_num: 0,
  		rows: []
  	}
  	this.fileCodeConfig = {
			lineNumbers: true,
			mode: 'sql',
			readOnly: true
		}
		this.resultCodeConfig = {
			lineNumbers: true,
			scrollbarStyle: null,
			mode: 'sql',
			readOnly: true
		}

  	this.getData()
  	this.modalService.dismissAll();
		this.disableJobSubscribe = this.automate.getDisableJob().subscribe(
		      data=>{
		      	let job = data
		      	
		      	if(this.disablingJob){
			      		this.modalService.open(this.disableJobModal, { windowClass: 'modal animate', centered: true }).result.then((result) => {
					    }, (reason) => {
					      if(reason == 'disable'){
					      	// console.log(job)
					      	let jobData = {
										enabled: job.enabled
							  	}
					      	this.updateJob(job, jobData, true)
					      }
					      else{
					      	job.enabled = true;
					      }
					   });
		      	}
		      	this.disablingJob = false;
		  }  
		)
		this.disableJobStepSubscribe = this.automate.getDisableJobStep().subscribe(
		      data=>{
		      	let step = data
		  
		      	if(this.disablingStep){
			      		this.modalService.open(this.disableJobStepModal, { windowClass: 'modal animate', centered: true }).result.then((result) => {
						    }, (reason) => {
						      if(reason == 'disable'){
						      	let stepData = {
											enabled: step.job_step_is_enabled
								  	}
						      	this.updateJobStep(step.musher_job_id, step, stepData)
						      }
						      else{
						      	step.job_step_is_enabled = true;
						      }
						   });
		      	}
		      	this.disablingStep = false;
		  }  
		)
		
  }
  ngOnDestroy(): void{
  	this.removeLines();
  	this.disableJobSubscribe.unsubscribe();
  	this.disableJobStepSubscribe.unsubscribe();
  	this.modalService.dismissAll();
  	this.document.body.style.overflowY = "hidden";
  	// this.automate.clear();
  }

  ngAfterViewInit(): void{

 
  	// , 

  	

  	// let point = LeaderLine.pointAnchor(this.node1.nativeElement, {x: 18, y: 100})

  	// this.jobDiagram.changes
   //    .subscribe(data => {
   //     	console.log('job diagram changed', data)
   //    }
   //  )
  }

  getData(force?: boolean, expandedId?:number){

  	  let jobLoader = this.loadingBar.useRef('jobsTable')
      jobLoader.start()
      jobLoader.set(35)
  		let jobURL = this.url + '/jobs'
  		let options = {api: force}
	   	this.api.getData(jobURL, this.page , 'jobs', options).subscribe(
	    	data=>{
	    		
	    		if('status' in data){
	    			if(data['status'] == 'success'){
		    			let jobs = data['message']
		    			jobLoader.set(75)
		    			
		    			for(let j of jobs){
		    				this.jobs.push(this.translateJobFields(j))
		    			}
		    			// Add hightight attribut to each step
		    			for(let j of this.jobs){
		    				if(j.job_id == expandedId){
		    					this.expandJob(expandedId);
		    				}
		    				for(let s of j.steps){
		    					s.highlighted = false
		    				}
		    			}
		    			this.jobsOG = JSON.parse(JSON.stringify(this.jobs))

		    			jobLoader.complete()
		    			// this.api.setData(this.page, 'jobs', data, true)
		    			this.findRunningJobs();
		    			this.findRunningSteps();

		    			console.log(this.jobs)
							
		    		}
	    		}
	    		else{
	    			this.jobs = data
	    			jobLoader.complete()
	    			for(let i in this.jobs){
	    				if(this.jobs[i].job_id == expandedId){
	    					this.expandJob(expandedId);
	    				}
	    				else{
	    					this.jobs[i].expanded = false;
	    				}
	    			}
		    		this.jobsOG = JSON.parse(JSON.stringify(this.jobs))
		    		this.findRunningJobs();
		    		this.findRunningSteps();
	    		}
	    		
	    	}
	    )
	    let scriptUrl = this.url + '/query/published'
	    this.api.getData(scriptUrl, this.page , 'scripts', options).subscribe(
	    	data=>{
	    		if(data['status'] == 'success'){
	    			this.allScripts = []
	    			for(let script of data['message']){
	    				if(script['current_query_version_id'] > 0){
	    					this.allScripts.push(script)
	    				}
	    			}
	    			// this.api.setData(this.page, 'scripts', data, true)
	    		}

				},
				error => console.log(this.auth.logout())
		)
	    let packageUrl = this.url + '/packages/ready'
	    this.api.getData(packageUrl, this.page , 'packages', options).subscribe(
	    	data=>{
	    		if(data['status'] == 'success'){
	    			this.allPackages = data['message']
	    			// this.api.setData(this.page, 'scripts', data, true)
	    		}

				},
				error => console.log(this.auth.logout())
		)
  }

  reset(){
  	this.jobObj = JSON.parse(JSON.stringify(this.jobObjOG));
		this.stepObj = JSON.parse(JSON.stringify(this.stepObjOG));
  } 

  socketRequestUpdate(data){

  	if(data !='Request UID not found. Job(s) not executed.'){
  		console.log('socket request:', data)
  		let is_executing = [];
  		for(let job of data){
  			let musher_job_id = job['musher_job_id'];
	  		let job_is_executing = job['job_executing'];
	  		if(job_is_executing){
	  			is_executing.push(musher_job_id)
	  		}
	  		let job_execution_result = job['execution_result'];
	  		let steps = job['steps'] != undefined ? job['steps'] : [];
	  		let jobIndex = this.jobs.findIndex((obj => obj.job_id == musher_job_id));

	  		// Update the job status
				for(let s of steps){
					// Update the step status
					let stepIndex = this.jobs[jobIndex].steps.findIndex((obj => obj.musher_job_step_id == s.musher_job_step_id));
					// let execution_details = s['musher_job_step_execution_details']['musher_job_step_details_json']
					let execution_details = {}

					let last_execution_results = {
							name: s.musher_job_step_name,
							failure_message: s.step_failure_message,
						  execution_result: s.execution_result,
						  queries: []
					}
					
					this.jobs[jobIndex].steps[stepIndex].last_run_date = s.start_date;
					
					if(s.is_executing == 1){
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 1
						this.jobs[jobIndex].steps[stepIndex].job_step_current_execution_status = 'Running'
						// let line = this.getLine(musher_job_id, s.musher_job_step_id);
						// if(line != undefined){
						// 	line.hide('none');
						// 	setTimeout(function(){
						// 	    line.show('draw');
						// 	},100);
						// }
					}
					else if(s.execution_result == 'Succeeded'){
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 0
						this.jobs[jobIndex].steps[stepIndex].job_step_current_execution_status = 'Succeeded'
						last_execution_results.queries = s.musher_job_step_execution_details.musher_job_step_details_json.queries
						this.jobs[jobIndex].steps[stepIndex].last_execution_results = last_execution_results;
					}
					else if(s.execution_result == 'Failed'){
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 0
						this.jobs[jobIndex].steps[stepIndex].job_step_current_execution_status = 'Failed'
						last_execution_results.queries = s.musher_job_step_execution_details.musher_job_step_details_json.queries
						this.jobs[jobIndex].steps[stepIndex].last_execution_results = last_execution_results;
					
					}
					else if(s.execution_result == 'Stopping'){
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 0
						this.jobs[jobIndex].steps[stepIndex].job_step_current_execution_status = 'Stopping'
						this.jobs[jobIndex].steps[stepIndex].last_execution_results = last_execution_results;
					}
					else if(s.execution_result == 'Stopped'){
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 0
						this.jobs[jobIndex].steps[stepIndex].job_step_current_execution_status = 'Stopped'
						this.jobs[jobIndex].steps[stepIndex].last_execution_results = last_execution_results;
					}
					else{
						this.jobs[jobIndex].steps[stepIndex].job_step_is_executing = 0
					}

					// Update the job diagram
					for(let r of this.jobs[jobIndex].diagram){
						for(let c of r.columns){
							if(c.id == s.musher_job_step_id){

								if(s.is_executing == 1){
									c.is_running = true
									c.execution_status = 'running'
								}else{
									c.is_running = false
									c.execution_status = s.execution_result 
								}
							}
						}
					}
				}
				this.jobs[jobIndex].last_run_date = null 
				this.jobs[jobIndex].last_run_date = job['start_date'];
				this.jobs[jobIndex].job_status =  job_is_executing == 1 ? 'Running' : job_execution_result;
				this.jobs[jobIndex].is_executing = job_is_executing;
		
  		}
  		
			// Refresh the list
			this.findRunningSteps();
			this.findRunningJobs();
			if(is_executing.length > 0){
				return false;
			}
			else{
				return true;
			}
			
  	}
  	else{
  		return true
  	}
	}

	getRunTypeColor(value, type){
		let color = 'gray'
		if(type == 'run_type'){
			if(value == 'Run after completion'){
			color = '#2196F3'
			}
			else if(value == 'Run after success'){
				color = '#37a57f'
			}
			else if(value == 'Run after failure'){
				color = '#e13a3d'
			}
		}
		else if(type == 'result'){
			if(value == 'Succeeded'){
				color = '#37a57f'
			}
			else if(value == 'Failed'){
				color = '#e13a3d'
			}
		}
		
		return color;
	}

	refreshJobs(){
		let jobURL = this.url + '/jobs'
  	let options = {api: true}
  	let expandedId = null
  	for(let j of this.jobs){
  		if(j.expanded == true){
  			expandedId = j.job_id
  		}
  	}
  	// console.log('expandedId: ', expandedId)
   	this.api.getData(jobURL, this.page , 'jobs', options).subscribe(
    	data=>{
    		if(data['status'] == 'success'){
    			let jobs = data['message']
    			
    			let tempJobs = []
    			
    			for(let j of jobs){
    				tempJobs.push(this.translateJobFields(j))
    			}
    			this.jobs = [...tempJobs]
    			this.expandJob(expandedId);
    			// this.api.setData(this.page, 'jobs', data, true)
    		}
			});
	}

  
  openStepRow(job_id, step_id){
  	let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id));
		let stepIndex = this.jobs[jobIndex].steps.findIndex((obj => obj.musher_job_step_id == step_id));
		if(this.displayStep == undefined){
			this.displayStep = this.jobs[jobIndex].steps[stepIndex];
  		this.showStepRow = true;
		}
		else if(this.displayStep.musher_job_step_id == step_id && this.displayStep.musher_job_id == job_id){
			this.showStepRow = !this.showStepRow;
		}
		else{
			this.displayStep = this.jobs[jobIndex].steps[stepIndex];
  		this.showStepRow = true;
		}
  }


  findRunningJobs(){
  	this.runningJobs = [...[]];
  	// console.log('Checking Jobs to see what is running: ', this.jobs)
  	for(let job of this.jobs){
  		if(job.job_status == 'Running' && this.runningJobs.indexOf(job.job_id) == -1){	
  				this.runningJobs.push(job.job_id)
  		}
  	}
  }

  findRunningSteps(){
  	this.runningSteps = [...[]];
  	for(let job of this.jobs){
  		for(let step of job.steps){
  			let key = job.job_id + '_' + step['musher_job_step_id'];
	  		if(step['job_step_is_executing'] == 1 && this.runningSteps.indexOf(key) == -1){	
	  				this.runningSteps.push(key)
	  		}
	  	}
  	}
  }

  refresh(){
  	// find the expanded job first to reissue the expansion
  	this.removeLines();
  	let expandedId = null
  	for(let j of this.jobs){
  		if(j.expanded == true){
  			expandedId = j.job_id
  		}
  	}
  	let jobs = []
  	let allScripts = []
  	this.jobs = [...jobs]
  	this.allScripts = [...allScripts]
  	this.getData(true, expandedId);
  }

  executeJob(jobId){
  	let jobIndex = this.jobs.findIndex((obj => obj.job_id == jobId));
  	this.jobs[jobIndex].starting == true;
  	if(this.runningJobs.indexOf(jobId) > -1){
  		this.toastrService.warning('Job already running')
  	}
  	else{
  		this.runningJobs.push(jobId);
  		let execURL = this.url + '/jobs/execute'
	  	let jobURL = this.url + '/jobs/' +jobId + '/execute'
	  	let headers = this.headers;
	  	// let msg = include_children ? 'Job and Dependant Job(s) Started' :'Job Started';
	  	this.http.post(jobURL, {}, {headers}).subscribe(
		        data=>{ 
		        	// Get the job request id 
		        	// use to create the websocket
		           
		           if(data['status'] == 'success'){
		           		if(jobId == 1 || jobId == 2){
		           			this.toastrService.success('Job Queued')
		           		}
		           		else{
		           			this.toastrService.success('Job Started')
		           		}
		           }
		           this.jobs[jobIndex].starting == false;
		           // Get the job request id 
		        	 // use to create the websocket updates
		        	  let rid = data['message']['request_uid']
		            let params = [{key: 'request_uid', value: rid}]
		            this.api.getApiData(execURL, params).subscribe(
		           		data=>{ 
		           				// console.log('execute step request id result: ', data)
		           				this.getStatus(rid);
		           		}
		            );
		        }
		  );
  	}
  }

  executeStep(jobId, stepId, include_children){
  	let key = jobId + "_" + stepId;
  	if(this.runningJobs.indexOf(jobId) > -1){
  		this.toastrService.warning('Job is already running')
  	}
  	else if(this.runningSteps.indexOf(key) > -1){
  		this.toastrService.warning('Step is already running')
  	}
  	else{
  		this.runningJobs.push(jobId);
  		this.runningSteps.push(key);
  		let execURL = this.url + '/jobs/execute'
	  	let stepURL = this.url + '/jobs/' + jobId + '/steps/' + stepId +'/execute'
	  	let headers = this.headers;

	  	let msg = include_children ? 'Step and Dependent Step(s) Started' :'Step Started';

	  	this.http.post(stepURL, {include_children: include_children}, {headers}).subscribe(
		        data=>{ 
		        	if(data['status'] == 'success'){
		           	  	this.toastrService.success(msg)
		           }
		        	// Get the job request id 
		        	// use to create the websocket updates
		        	 let rid = data['message']['request_uid']
		           this.getStatus(rid);		           
		           
		           
		        }
		  );
  	}
  }

  async getStatus(rid) {
  	let done = false;
  	let counter = 0
  	let wait = 3000
  	while(!done){
  		let execURL = this.url + '/jobs/execute'
	    let params = [{key: 'request_uid', value: rid}]
	    this.api.getApiData(execURL, params).subscribe(
		   		data=>{ 
		   				done = this.socketRequestUpdate(data['message']['data']);
		   		}
		   );

	    if(counter > 30){
	    	wait = 5000
	    }
	    if(counter > 60){
	    	wait = 10000
	    }
	    if(counter > 120){
	    	wait = 15000
	    }
	    if(counter > 300){
	    	wait = 30000
	    }
	    counter ++; 
	    await this.sleep(wait);
  	}  
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

	onSearchChange(searchValue : string ) {  
	    if (searchValue != ''){
	      this.jobs = this.searchJobs(searchValue);
	    }
	    else{
	    	this.clear()
	    }
	}

	searchJobs(searchValue){
  	let jobNames = []
  	let newJobs = []

		for(let i = 0;i < this.jobs.length;i++){
			let item = this.jobs[i]
			
	  	for(let property in item){
			  if(item[property] !== null && property in this.jobFields){
				  let val = item[property].toLowerCase()
				   if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
				   		if(!(jobNames.includes(item.name))){
				   			jobNames.push(item.name)
				   			newJobs.push(item)
				   		}
				   }
				}
	  	}
		}
			
		return newJobs;
  }
  sortColumns(columnName){
		let dir = this.jobFields[columnName]

		let fa,fb;
		if (dir == 'desc' || dir == 'default'){
			
			this.jobs.sort((a, b) => {
		
				if(columnName == 'enabled'){
						fa = a[columnName]
						fb = b[columnName] 
				}
				else{
					let fa = a[columnName].toLowerCase(),
						fb = b[columnName].toLowerCase() 
				}

				if (fa < fb){
					return -1
				}
				if (fa > fb) {
			        return 1;
			    }
			    return 0;
			});
			this.jobFields[columnName] = 'asc'
		}
		else{
			
			this.jobs.sort((a, b) => {
			
				if(columnName == 'enabled'){
					let fa = a[columnName],
						fb = b[columnName] 
				}
				else{
						fa = a[columnName].toLowerCase()
						fb = b[columnName].toLowerCase() 
				}

				if (fa > fb){
					return -1
				}
				if (fa < fb) {
			        return 1;
			    }
			    return 0;
			});
			this.jobFields[columnName] = 'desc'
		}
		
	}

	clear(){
	    this.jobs = JSON.parse(JSON.stringify(this.jobsOG));;
	    this.search = '';
	}

  getRunTypeIconColor(runType){
  	let style = {color: 'black'}
  	if(runType == 'initial'){
  		style.color = '#35718a'
  	}
  	else if(runType == 'completion'){
  		style.color = '#2196F3'
  	}
  	else if(runType == 'success'){
			style.color = '#37a57f'
  	}
  	else if(runType == 'failure'){
  		style.color = '#e03f3f'
  	}
  	return style
  }

  jobClick(){
  	this.jobClicked = !this.jobClicked
  	
  	if(this.jobClicked){
  		this.removeLines();
  		this.lines = []
  	}
  	else if(this.lines.length > 0){
  		for(let l of this.lines){
  			l.hide()
  		}
  	}
  	
  }

  hideSteps(hide, job_id){
  	this.showSteps = !hide;
  	if(this.showDiagram){
  		this.displayDiagram(job_id)
  	}
  }

  getLine(job_id, step_id){
  	// console.log(this.lines)

  	let lkey = 'step-'+ job_id + '-' + step_id;
  	// console.log('looking fof ', lkey)
  	for(let l of this.lines){
				let key = Object.keys(l)[0]
				if(key == lkey){
					return l[key].line
				}
		}
		return undefined
  }

  removeLines(){
  	for(let l of this.lines){
				let key = Object.keys(l)[0]
				l[key].line.remove();
			}
  	this.lines = [];
  } 

  expandJob(job_id){
  	this.removeLines();
  	this.displayStep = null;
  	this.showStepRow = false;
  	
  	for(let i in this.jobs){
  		if(this.jobs[i].job_id == job_id){
  			if(this.jobs[i].expanded){
  				this.jobs[i].expanded = false
  			}
  			else{
  				this.jobs[i].expanded = true
  				this.displayDiagram(job_id);
  			}
  			
  		}
  		else{
  			this.jobs[i].expanded = false
  		}
  	}
  	
  }

  setHighlighted(job, step_id, highlighted, is_diagram){
  	
  	if(this.showDiagram){
  		for(let r of this.diagramLayout.rows){
	  		for(let c of r['columns']){
	  			if(c.id == step_id){
	  				if(c.execution_result != 'Failed' && c.execution_status != 'Success'){
	  					c.highlighted = highlighted;
	  				}
	  			}
	  		}
	  	}
  	}
  }

  makeLine(element1, element2, runType, is_enabled){
  	let gradient = {
	    startColor: '#ccc',
	    endColor: 'gray'
	  } 

	  let startPlugColor = gradient.startColor;
	  let endPlugColor = gradient.endColor
  	if(runType == 'Run after failure'){
  		gradient.endColor = 'rgba(255,30,34,.65)'
  		endPlugColor = gradient.endColor
  	}
  	else if(runType == 'Run after success'){
  		gradient.startColor = '#ccc'
  		gradient.endColor = 'rgba(0,178,116,.65)'
  		endPlugColor = gradient.endColor
  	}
  	else if(runType == 'Run after completion'){
  		gradient.endColor = '#2196F3';
  		endPlugColor = gradient.endColor;
  	}

  	if(!(is_enabled)){
  		gradient.startColor = '#ccc'
	  	gradient.endColor = '#ccc'
	  	endPlugColor = gradient.endColor;
	  }

  	let settings = {	
  			size: 3, 
  			hide: true,
	  		startPlug: 'behind',
	  		endPlug: 'arrow3',
	  		path: 'fluid',
	  		gradient: gradient,
	  		startPlugColor: startPlugColor, 
	  		endPlugColor: endPlugColor

	  }
	  // console.log(element1, element2)
  	let line =  new LeaderLine(element1, element2, settings);

  	// this.fixLine()
  	return line
  }

  displayDiagram(job_id){
  	this.showDiagram = true;

  	this.removeLines();
  	let i = this.jobs.findIndex(x => x.job_id == job_id);
  	let steps = this.jobs[i].steps 
  	console.log(this.diagramLayout)
  	this.diagramLayout.rows = this.jobs[i].diagram
  	for(let r of this.diagramLayout.rows){
  		for(let c of r['columns']){
  			c.highlighted = false;
  		}
  	}

  	setTimeout(() => {
		  for(let step of steps){
		  	let id = 'step-' +job_id +'-'+ step.musher_job_step_id
		  
		  	if(step.parent_musher_job_step_id > 0){
		  		let pId = 'step-' + job_id +'-'+ step.parent_musher_job_step_id
		  		let el = this.document.getElementById(id)
			  	let pEl = this.document.getElementById(pId)
			    let line = this.makeLine(pEl, el, step.run_type, step.job_step_is_enabled)
			    let obj = {}
			    obj[pId] = {line:line}
					this.lines.push(obj)
		  	}
		 }
			
			for(let l of this.lines){
				let key = Object.keys(l)[0]
				l[key].line.show({duration: 1000, timing: [0.58, 0, 0.42, 1]})
					
			}
			
		}, 100);
	
  }

  addJob(job){
  	this.jobs.push(job)
  	this.dt.reset();
  }


  getVersions(sqlFile){
  	let versions = []
  	for(const obj of this.allScripts){
  		if(obj.file_id == sqlFile ){
  			versions.push(obj)
  		}
  	}
  	return versions
  }

  translateJobFields(backend_result, expanded=false){
		var br = backend_result
		let scheduleTxt = '' 
		let next_run = ''
		if( br.musher_job_cron_schedule_utc == null){
			let parentJobName = ''
			if(br.parent_musher_job_name == null){
				// find the name in the list of jobs
			}
			else{
				parentJobName = br.parent_musher_job_name
			}
			scheduleTxt = 'Run after ' + parentJobName
		}
		else{
			scheduleTxt = cronstrue.toString(br.musher_job_cron_schedule_utc)
		}

		if(br.is_enabled && br.next_execution_time != null && br.next_execution_time != undefined){
			let temp_dt = new Date(br.next_execution_time);
			next_run = temp_dt.toLocaleString();
		}
		var job = 
				{
					job_id: br.musher_job_id,
					name: br.musher_job_name,
					owner: br.musher_job_owner_name,
					description: br.musher_job_description,
					create_date: br.musher_job_create_date,
					last_run_date: br.latest_execution_date,
					last_result: br.latest_execution_result,
					schedule: scheduleTxt,
					cron: br.musher_job_cron_schedule_utc,
					job_status: br.current_execution_status,
					parent_id: br.parent_musher_job_id,
					enabled: br.is_enabled,
					expanded: expanded,
					steps: this.addStepValues(br.musher_job_step_details_json),
					notification_email: br.notification_email_address,
					diagram: br.diagram,
					next_run: next_run,
					starting: false
				} 
		return job
	}

 	addStepValues(steps){
 		let new_steps = []
 		for(let step of steps){
 			let runType = 'Initial Step'
 			if(step.run_on_parent_completion == 1){
	  		runType = 'Run after completion'
	  	}
	  	else if(step.run_on_parent_success == 1){
	  		runType= 'Run after success'
	  	}
	  	else if(step.run_on_parent_failure == 1){
	  		runType = 'Run after failure'
	  	}
	  	
	  	let parent_id = 0
	  	let parent_name = ''
	  	if(step.parent_musher_job_step_id != null ){
	    	parent_id = step.parent_musher_job_step_id
	    }
	    if(parent_id > 0){
	    	var foundIndex = steps.findIndex(x => x.musher_job_step_id == parent_id);
	    	parent_name = steps[foundIndex].musher_job_step_name;
	    }
	    step.run_type = runType;
	    step.run_after = parent_name;
	    step.last_run_date = step.latest_execution_date;

	    new_steps.push(step)
 		}
 		return new_steps

 	}

 	addNewStep(job_id){
 		let rt = this.stepObj.runTypeSelectValue != 'initial' ? this.stepObj.runTypeSelectValue : null;
 		this.saving.step = true;
	  let job_step_name =  this.newStepForm.value.name 
  	let stepData = {
			musher_job_id: job_id, 
    	job_step_name: this.newStepForm.value.name, 
    	job_step_type: this.stepObj.typeSelectValue, 
    	step_description: this.newStepForm.value.description, 
    	published_query_id: this.stepObj.sqlFile, // -1 if null 
    	package_id: this.stepObj.package, // -1 if null
    	is_enabled: this.stepObj.new.enabled,
      parent_musher_job_step_id: this.stepObj.runAfterStepValue, 
      run_on_parent_state: rt, 
      package_environment: this.stepObj.environment,
      package_dbt_command: this.stepObj.dbt_command
  	}

		let headers = this.headers
 		let url = this.url + '/jobs/'+job_id+'/steps';
 		
    this.http.post(url, stepData, {headers}).subscribe(
        data=>{ 
           
           if(data['status'] == 'success'){
           	  	this.toastrService.success('Step Created Successfully');

           	  	// Add step to job
           	  	let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id));
           	  	this.jobs[jobIndex] = this.translateJobFields(data['message'])
           	  	this.saving.step = false;
           	  	this.jobs[jobIndex]['expanded'] = true;
           	  	this.displayDiagram(job_id)

           	  	// Get step id 
           	  	let stepIndex = this.jobs[jobIndex].steps.findIndex((obj => obj.musher_job_step_name == job_step_name));
           	  	let stepId = this.jobs[jobIndex].steps[stepIndex].musher_job_step_id

           	  	this.stepObj = JSON.parse(JSON.stringify(this.stepObjOG));
           	  	this.openStepRow(job_id, stepId)
	  						this.newStepForm.reset();
	  						this.modalService.dismissAll();

           }
          
	        }, 
	        err=>{
	        		this.saving.step = false;
							this.modalService.dismissAll();
							this.toastrService.error('Failed to add new step');
	        }
	  )
	  
	 

 	}

  openNewStepModal(content, options={}, job){
  		this.newStepForm =  new FormGroup({
  			name: new FormControl(),
		  	description: new FormControl()
		});
  	this.stepObj.job_id = job.job_id;

  	if(job.steps.length == 0){
  		this.stepObj.runTypeSelectOptions = [this.stepObj.runTypeSelectOptionsOG[0]]
  	}
  	else{
  		this.stepObj.runTypeSelectOptions = this.stepObj.runTypeSelectOptionsOG.slice()
  	}

  	for(let s of job.steps){

  		this.stepObj.runAfterSelectOptions.push(
  				{label: s.musher_job_step_name, value: s.musher_job_step_id}
  		)
  	}
  	this.document.body.style.overflowY = "hidden";
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'save'){
	    
	      }
	      this.document.body.style.overflowY = "scroll";
	   
	    });
	}

	setPackage(){
		let pack = this.allPackages.find(x => x.package_id === this.stepObj.package);
		this.stepObj.selectedPackage = pack;
		for (let e of pack.environments){
			this.allEnvironments.push({environment: e})
		}
	}

	editStep(job_id, step_id){
		this.saving.step = true;
		let rt = this.stepObj.runTypeSelectValue != 'initial' ? this.stepObj.runTypeSelectValue : null;  	
  	let stepData = {
			musher_job_id: job_id, 
    	job_step_name: this.editStepForm.value.name, 
    	job_step_type: this.stepObj.typeSelectValue, 
    	step_description: this.editStepForm.value.description, 
    	published_query_id: this.stepObj.sqlFile,
    	package_id: this.stepObj.package,  
    	package_environment: this.stepObj.environment,  
      parent_musher_job_step_id: this.stepObj.runAfterStepValue, 
      run_on_parent_state: rt,
      package_dbt_command: this.stepObj.dbt_command
  	}

  	let params: HttpParams = new HttpParams();
		let headers = this.headers
 		let url = this.url + '/jobs/'+job_id+'/steps/'+ step_id;
 		
    this.http.put(url, stepData, {headers, params}).subscribe(
        data=>{ 
           
           if(data['status'] == 'success'){
           	  	this.toastrService.success('Step Updated Successfully');

           	  	// Need to get the entire job instead of steps so we can refresh the diagram
           	  	let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id));
           

           	  	this.jobs[jobIndex] = this.translateJobFields(data['message'])
           	  	this.jobs[jobIndex]['expanded'] = true;
           	  	if(this.showDiagram) this.displayDiagram(job_id);
           	  	let stepIndex = this.jobs[jobIndex].steps.findIndex((obj => obj.musher_job_step_id == step_id))
           	  	this.displayStep = this.jobs[jobIndex].steps[stepIndex];
           	  	this.stepObj = JSON.parse(JSON.stringify(this.stepObjOG));
  							this.editStepForm.reset();
  							this.saving.step = false;
								this.modalService.dismissAll();
           }
          
        },
        err=>{
        		this.saving.step = false;
						this.modalService.dismissAll();
        }
    )
  
  	
	}
	openEditStepModal(content, options={}, job, step){
		// let step = this.jobs[job.job_id].steps.findIndex((obj => obj.musher_job_step_id == step_id));
		console.log('step: ', step)
		this.stepObj.job_id = job.job_id;
		this.stepObj.step_id = step.musher_job_step_id;
		this.stepObj.package = step.package_id;
		this.stepObj.dbt_command = step.package_dbt_command;

		for (let e of step.package_environments){
			this.allEnvironments.push({environment: e})
		}
		this.stepObj.selectedPackage = this.allPackages.find(x => x.package_id === this.stepObj.package);
		
		this.stepObj.environment = step.package_environment;
  	this.editStepForm =  new FormGroup({
  			name: new FormControl(step.musher_job_step_name),
		  	description: new FormControl(step.musher_job_step_description)
		});

  	if(step.run_on_parent_completion == 1){
  		this.stepObj.runTypeSelectValue = 'completion'
  	}
  	else if(step.run_on_parent_success == 1){
  		this.stepObj.runTypeSelectValue = 'success'
  	}
  	else if(step.run_on_parent_failure == 1){
  		this.stepObj.runTypeSelectValue = 'failure'
  	}
  	else{
  		this.stepObj.runTypeSelectValue = 'initial'
  	}

  	this.stepObj.sqlFile = step.query_id;
  	this.stepObj.package = step.package_id;
    
    if(step.parent_musher_job_step_id != null ){
    	this.stepObj.runAfterStepValue = step.parent_musher_job_step_id
    }
    this.stepObj.typeSelectValue = step.musher_job_step_type
  	this.stepObj.edit.open = true;

  	if(job.steps.length == 0){
  		this.stepObj.runTypeSelectOptions = [this.stepObj.runTypeSelectOptionsOG[0]]
  	}
  	else{
  		this.stepObj.runTypeSelectOptions = this.stepObj.runTypeSelectOptionsOG.slice()
  	}
  	// console.log('Run Type Selections:', this.stepObj.runTypeSelectOptions)

  	for(let s of job.steps){
  		if(s.parent_musher_job_step_id != step.musher_job_step_id && s.musher_job_step_id != step.musher_job_step_id){
  			this.stepObj.runAfterSelectOptions.push(
  				{label: s.musher_job_step_name, value: s.musher_job_step_id}
  			)
  		}
  	}

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'save'){}
	    });
	}

  openNewJobModal(content, options={}){
  	this.newJobCronForm =  new FormGroup({
		  	expression: new FormControl()
		});
		this.newJobForm =  new FormGroup({
		  	name: new FormControl(),
		  	description: new FormControl(),
		  	email: new FormControl()
		});

		for(let j of this.jobs){
				this.jobObj.runAfterSelectOptions.push(
					{label: j.name , value: j.job_id}
				) 
		}

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'save'){
	      	
	      }
	    });
	}
	addNewJob(){
			this.saving.job = true;
			let obj = this.newJobForm.value; 	
 			let schedule = null;
 			let parent_id = -1
 			if(this.jobObj.runAfterValue != null){
 				parent_id = this.jobObj.runAfterValue
 			}
 			else{
 				schedule = this.jobObj.new.schedule
 			}

 			let jobData = {
    		job_name: obj.name, 
    		job_desc: obj.description, 
				cron_schedule_utc: schedule,
				notify_email_address: obj.email,
				job_type: 'standard',
				enabled: this.jobObj.new.enabled,
				parent_id: parent_id
    	}

    	let params: HttpParams = new HttpParams();
			let headers = this.headers
   		let url = this.url + '/jobs';
   		
	    this.http.post(url, jobData, {headers, params}).subscribe(
	        data=>{ 
	          
	           if(data['status'] == 'success'){
	           			this.saving.job = false;
	           	  	this.toastrService.success('Job Created Successfully')
	           	  	let newJob = this.translateJobFields(data['message']);
				    			this.jobs.push(newJob)
				    			this.expandJob(newJob.job_id);
				    			// this.api.setData(this.page, 'jobs', this.jobs, true)
					    		
	           }
	           this.saving.job = false;
	           // reset job object
	           this.jobObj = JSON.parse(JSON.stringify(this.jobObjOG));
	           this.newJobForm.reset();
	           this.modalService.dismissAll();
	        },
	        err=>{
	        	this.saving.job = false;
	        	this.modalService.dismissAll();
	        }
	    )
		}

	openEditJobModal(content, options={}, job){
		this.editJobForm =  new FormGroup({
		  	name: new FormControl(job.name),
		  	description: new FormControl(job.description),
		  	email: new FormControl(job.notification_email)
		});
		this.editJobCronForm =  new FormGroup({
		  	expression: new FormControl(job.cron)
		});

		this.jobObj.edit.enabled = job.enabled;
		for(let j of this.jobs){
			if(j.parent_id != job.job_id && j.job_id != job.job_id){
				this.jobObj.runAfterSelectOptions.push(
					{label: j.name , value: j.job_id}
				)
			}
		}
	
		if(job.cron != null){
			this.jobObj.runTypeSelectValue = 'schedule'
			this.jobObj.edit.schedule = job.cron;
		}
		else if(job.parent_id > 0 ){
			this.jobObj.runTypeSelectValue = 'after'
			this.jobObj.runAfterValue = job.parent_id
		}

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'save'){
	      	let obj = this.editJobForm.value;
	      	let schedule = null;
	   			let parent_id = -1
	   			if(this.jobObj.runTypeSelectValue == 'after'){
	   				parent_id = this.jobObj.runAfterValue
	   			}
	   			else if(this.jobObj.runTypeSelectValue == 'schedule'){
	   				schedule = this.jobObj.edit.schedule
	   			}

	   			let jobData = {
	      		job_name: obj.name, 
	      		job_desc: obj.description, 
						cron_schedule_utc: schedule,
						notify_email_address: obj.email,
						parent_id: parent_id
	      	}

					this.updateJob(job, jobData, false)
	      }
	      this.jobObj = JSON.parse(JSON.stringify(this.jobObjOG));
	      this.editJobForm.reset();
	    });
	}

	openDeleteJobModal(content, options={}, job){
		
		this.jobObj.delete.name = job.name;
		this.jobObj.delete.id = job.job_id;
		

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'delete'){
	      	
	      }
	    });
	}

	deleteJob(){
		this.saving.job = true;
		let url = this.url + '/jobs/' + this.jobObj.delete.id;
		let headers = this.headers
		this.http.delete(url, {headers}).subscribe(
        data=>{
        	if(data['status'] == 'success'){
        		this.saving.job = false;
	        	this.toastrService.success('Job Deleted Successfully');
	        	this.modalService.dismissAll();
	        	this.jobObj = JSON.parse(JSON.stringify(this.jobObjOG));

	        	// refresh all jobs
	        	this.refresh()
	        }
	     },
	     err=>{
	     	 this.saving.job = false;
	     	 this.modalService.dismissAll();
	     	 this.jobObj = JSON.parse(JSON.stringify(this.jobObjOG));
	     }
    );
    

	}

	openDeleteJobStepModal(content, options={}, job, step){
		// let step = this.jobs[job.job_id].steps.findIndex((obj => obj.musher_job_step_id == step_id));
		
		let url = this.url + '/jobs/' + job.job_id + '/steps/' + step.musher_job_step_id;
		let headers = this.headers

		this.stepObj.delete.name = step.musher_job_step_name;
		this.stepObj.delete.id = step.musher_job_step_id;
		
		let children = this.getChildren(job, step.musher_job_step_id)
				//recursively get all children 
		this.addStepChildren(0, job, children, 'delete')

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'delete'){
	      	
	      	this.http.delete(url, {headers}).subscribe(
			        data=>{
			        	if(data['status'] == 'success'){
				        	this.toastrService.success('Step(s) Deleted Successfully');
				        	
				          // Just refresh all job steps at this point
									let jobURL = this.url + '/jobs'
							   	this.api.getData(jobURL, this.page , 'jobs', {api:true}).subscribe(
							    	data=>{
							    		if(data['status'] == 'success'){
							    			let jobs = data['message']
							    			let newJobs = []
							    			for(let j of jobs){
							    				newJobs.push(this.translateJobFields(j))
							    			}
							    		
							    			this.jobs = []
							    		  this.jobs.length = 0;
							    		  this.jobs = [...newJobs];

							    			let jobIndex = this.jobs.findIndex((obj => obj.job_id == job.job_id));
							    			this.jobs[jobIndex]['expanded'] = true;
			           	  		if(this.showDiagram) this.displayDiagram(job.job_id);
			           	  		this.showStepRow = false;
			           	  		this.displayStep = {};
							    			// this.api.setData(this.page, 'jobs', data, true)
							    		}
										});
							   }
			        }
			    );
	      }
	      this.stepObj = JSON.parse(JSON.stringify(this.stepObjOG));
	    });
		 
	}

	expressionToFriendly(expression){
		if(expression === undefined || expression == '' || expression == null){
			return 'Not Scheduled'
		}
		return cronstrue.toString(expression)
	}

	checkEnable(job, ngModelDir: NgModel){

		ngModelDir.control.markAsTouched();

		// model.control.markAsTouched();
		this.jobObj.disable.name = job.name;
		this.jobObj.disable.id = job.job_id;
		this.jobObj.disable.children = []
		for(let j of this.jobs){
			if(j.parent_id == job.job_id){
				this.jobObj.disable.children.push(j.name)
			}
		}
		let jobData = {
			enabled: job.enabled
  	}

  	// Change the status to null until jobs are refreshed
  	job.job_status = null
		
		// console.log(job)
		// If enabling job, don't need to prompt
		if(job.enabled){
			this.updateJob(job, jobData, true)
		}
		else{
			
			this.disablingJob = true;
			this.automate.setDisableJob(job)
		}
	}

	checkStepEnable(job, step, ngModelDir){
		ngModelDir.control.markAsTouched();
		// let step = this.jobs[job.job_id].steps.findIndex((obj => obj.musher_job_step_id == step_id));
		// model.control.markAsTouched();
		this.stepObj.disable.name = step.musher_job_step_name;
		this.stepObj.disable.id = step.musher_job_step_id;
		this.stepObj.disable.children = []
		let stepData = {
			enabled: step.job_step_is_enabled
  	}
		if(step.job_step_is_enabled){
			this.updateJobStep(job.job_id, step, stepData)
		}
		else{
				
				let children = this.getChildren(job, step.musher_job_step_id)
				//recursively get all children 
				this.addStepChildren(0, job, children, 'disable')
				this.disablingStep = true;
				this.automate.setDisableJobStep(step)
			}	
	}

	addStepChildren(depth, job, children, changeType){
		depth += 1;
		for(let c of children){
			if(changeType == 'disable'){
				if(!(c in this.stepObj.disable.children)){
					this.stepObj.disable.children.push(c.musher_job_step_name)
					
				}
			}
			else if (changeType == 'delete'){
				if(!(c in this.stepObj.delete.children)){
					this.stepObj.delete.children.push(c.musher_job_step_name)
			
				}
			}
			
			let new_children = this.getChildren(job, c.musher_job_step_id)
			if(new_children.length > 0 && depth < 13){
				this.addStepChildren(depth, job, new_children, changeType)
			}
		}
	}
	getChildren(job, step_id){
		let children = []

		for(let s of job.steps){
			if(s.parent_musher_job_step_id == step_id){
				children.push(s)
			}
		}
		return children
	}

	getJobChildren(job){
		let children = []
		for(let j of this.jobs){
			if(j.job_id == job.job_id){
				children.push(j.job_id)
			}
		}
		return children
	}

	stopJob(job_id){
		let headers = this.headers
		let jobData = {job_id : job_id}
 		 let url = this.url + '/jobs/stop';
		 this.http.post(url, jobData, {headers}).subscribe(
        data=>{ 
           if(data['status'] == 'success'){

       	  		this.toastrService.success('Stopping Job')
       			}
       			else{
       				this.toastrService.error('Failed to Stop Job');
       			}
        }, 
        err=>{
        	this.toastrService.error('Failed to Stop Job');
        }
    )
	}

	updateJob(job, jobData, is_enable){
		let headers = this.headers
 		let url = this.url + '/jobs/' + job.job_id;
		this.http.put(url, jobData, {headers}).subscribe(
        data=>{ 
           if(data['status'] == 'success'){
           			if(is_enable){
           				var state = job.enabled == true ? 'Enabled' : 'Disabled'
           	  		this.toastrService.success(state + ' Job Successfully');

           	  		// // Disable job and all of the children 
           	  		// if(job.enabled == false){
	           	  	// 	for(let j of this.jobs){
	           	  	// 		if(j.parent_id == job.job_id){
	           	  	// 				var foundIndex = this.jobs.findIndex(x => x.job_id == j.job_id);
	           	  	// 				this.jobs[foundIndex].enabled = false
	           	  	// 		}
	           	  	// 	}
	           	  	// }

	           	  	// Get a full job refresh
	           	  	this.refreshJobs()
	         
           			}
           			else{
           				this.toastrService.success('Updated Job Successfully');
         //   				var foundIndex = this.jobs.findIndex(x => x.job_id == job.job_id);
									// this.jobs[foundIndex] = this.translateJobFields(data['message']);

									// Get a full job refresh
	           	  	this.refreshJobs()
	
           			}
           			
           	  	// Find and update job values in array
           	  	
	    					this.jobsOG = JSON.parse(JSON.stringify(this.jobs))
           	  	// this.api.setData('automate', 'jobs', this.jobs, true)
           }
        }
    )

	}

	expandStepLog(step){
		step.expanded = !step.expanded;
		if(this.lastOpenedStepLog == step.musher_step_log_id){
			this.lastOpenedStepLog = null;
		}
		else{
			this.lastOpenedStepLog = step.musher_step_log_id;
		}
	}

	updateJobStep(job_id, step, stepData){
		let headers = this.headers
 		let url = this.url + '/jobs/'+job_id+'/steps/'+ step.musher_job_step_id;
 		
    this.http.put(url, stepData, {headers}).subscribe(
        data=>{ 
           
           if(data['status'] == 'success'){
           	  	this.toastrService.success('Step Updated Successfully');

           	  	// Add step to job
           	  	let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id)); 

           	  	this.jobs[jobIndex] =  this.translateJobFields(data['message'])
           	  	this.jobs[jobIndex]['expanded'] = true;
           	  	if(this.showDiagram) this.displayDiagram(job_id);
           	  	// this.api.setData('automate', 'jobs', this.jobs, true)
           }
          
        }
    )
    this.stepObj = JSON.parse(JSON.stringify(this.stepObjOG));
	}


  openStepDetailsModal(content, options={}, job_id, step_id){
		
		let headers = this.headers
		let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id));
		let job = this.jobs[jobIndex];
		let stepIndex = job.steps.findIndex((obj => obj.musher_job_step_id == step_id));
		let step = job.steps[stepIndex];
		this.stepDetails.code =  ''
		this.stepDetails.loading = true;

		// find index of sql script if step is a Published SQL File
		if(step.query_id != undefined || step.query_id != null){
			let scriptIndex = this.allScripts.findIndex((obj => obj.query_id == step.query_id));
			// If scriptIndex is -1 then the user may not have permission to see the code
			if(scriptIndex > -1){
				let script = this.allScripts[scriptIndex]
				if(['', undefined, null].includes(script.code)){
					console.log('getting code')
					// retrieve the code for the file
					let connURL = this.url + '/query/' + step.query_id
			    this.http.get(connURL, {headers}).subscribe(
	        		data=>{ 
	        			if(data['status'] == 'success'){
	        				this.stepDetails.code = data['message']['code'];
	        				this.stepDetails.loading = false;
	        			}
	        		},
	        		err=>{
	        			this.stepDetails.loading = false;
	        		}
	        	);
				}
				else{
					this.stepDetails.loading = false;
					console.log('already have code: ', script.code)
					this.stepDetails.code = script.code
				}
		
		  	this.stepDetails.last_update_date = script.last_update_date;
		  	this.stepDetails.version = script.current_query_version_id;
		  	this.stepDetails.owner = script.owner_user_name;
		  	this.stepDetails.type = step.musher_job_step_type;
		  	this.stepDetails.file_name = step.query_display_name;
		  	this.stepDetails.query_id = step.query_id;
		  	this.stepDetails.connection_name = script.connection_name;
		  	this.stepDetails.warehouse = script.query_file_warehouse;
		  	this.stepDetails.role = script.query_file_role;
		  	this.stepDetails.database = script.query_file_database;
		  	this.stepDetails.schema = script.query_file_schema;

			}
		} 

		

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'copy'){
	      	
	      	this.editorService.code = this.stepDetails.code;
		    	this.editorService.active = true;
		    	this.modalService.dismissAll();
		    	this.router.navigateByUrl('/query');
	      }
	      else if(reason == 'edit'){
	      	
	      	this.editorService.code = this.stepDetails.code;
	      	this.editorService.query_id = this.stepDetails.query_id;
	      	this.editorService.version_id = this.stepDetails.version;
		    	this.editorService.active = true;
		    	this.modalService.dismissAll();
		    	this.router.navigateByUrl('/query');
	      }
	    });
		 
	}

  openStepResultsModal(content, options={}, job_id, step_id){
		
		let headers = this.headers
		let jobIndex = this.jobs.findIndex((obj => obj.job_id == job_id));
		let job = this.jobs[jobIndex];
		let stepIndex = job.steps.findIndex((obj => obj.musher_job_step_id == step_id));
		let step = job.steps[stepIndex];

  	this.stepExecutionResults = step.last_execution_results;
  	
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      	this.stepExecutionResults = null;
	    });
		 
	}
	onFocusRefresh(cm){
		var cm = cm['instance'];
		cm.refresh()
	}

	openJobHistoryModal(content, options, job){
		this.history.loading = true;
		let url = this.url + '/jobs/'+ job.job_id + '/history'
		let headers = this.headers;
		this.http.get(url, {headers}).subscribe(
				data=>{ 
	  			if(data['status'] == 'success'){
	  				this.jobHistory= data['message'];
	  				console.log('Job History: ', this.jobHistory)
	  				this.history.loading = false;
	  			}
	  		}
	  	);

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	   });
	}
	refreshStepHistory(){
		this.history.loading = true;
		let url = this.url + '/jobs/'+ this.currentJobStep['job']['job_id'] + '/steps/'+ this.currentJobStep['step']['musher_job_step_id'] + '/history'
		let headers = this.headers;
		this.http.get(url, {headers}).subscribe(
				data=>{ 
	  			if(data['status'] == 'success'){
	  				this.stepHistory= data['message'];
	  				this.stepHistory['name'] = this.currentJobStep['step']['musher_job_step_name'];
	  				this.history.loading = false;
	  			}
	  		},
	  		err=>{
	  			this.history.loading = false;
	  		}
	  	);
	}

	openStepHistoryModal(content, options, job, step){
		
		this.currentJobStep = {job: job, step: step}
		this.refreshStepHistory();
	  this.document.body.style.overflowY = "hidden";
		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	    	this.document.body.style.overflow = "scroll";
	   });
		 //this.document.body.style.overflowY = "hidden";
	}

	getNextRuns(cron_schedule_utc){
		if(!cron_schedule_utc) return;
		let headers = this.headers
		let jobData = {cron_schedule_utc : cron_schedule_utc}
 		let url = this.url + '/jobs/next_runs';
		this.http.post(url, jobData, {headers}).subscribe(
        data=>{ 
        	console.log(data)
           if(data['status'] == 'success'){
           		this.nextRuns = data['message']
       			}
       			
        },      
        err=>{ 
        	console.log(err)

        }
    )
	}

}
