import { 
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  AfterViewInit,
  HostBinding,
  ElementRef,
  ViewChild,
  NgZone,
  HostListener
} from '@angular/core';
import { LayoutService } from '../layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SplitComponent, SplitAreaDirective } from 'angular-split'




@Component({
  selector: 'app-layout-2',
  templateUrl: './layout-2.component.html',
  styles: [':host { display: block; }', ':host /deep/ .layout-loading .sidenav-link { transition: none !important; }']
})
export class Layout2Component implements AfterViewInit, OnDestroy {
  // Prevent "blink" effect
  public initialized = false;
  @ViewChild('navSplit') navSplit: SplitComponent;

  constructor(private layoutService: LayoutService, private router: Router) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialized = true;

      this.layoutService.init();
      this.layoutService.update();
      this.layoutService.setAutoUpdate(true);
      if(this.router.url === '/'){
        this.router.navigateByUrl('/explore/overview');
      }
    });
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.layoutService.destroy();
    });
  }

  closeSidenav() {
    setTimeout(() => {
      this.layoutService.setCollapsed(true);
    });
  }
}
