import { Injectable } from  "@angular/core";
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor (
        private router : Router,
        private auth: AuthService
    ) { }

    /**
     * Check if the user is logged in before calling http
     *
     * @param route
     * @param state
     * @returns {boolean}
     */
    canActivate (
        route : ActivatedRouteSnapshot,
        state : RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        if(this.auth.isLoggedIn()){
            // if(!this.auth.isSetup()){
            //     console.log('path: ', route.routeConfig.path)
            //     if(route.routeConfig.path == ''){
            //         return true;
            //     }
            //     else {
            //         this.router.navigate(['/home'])
            //         return;
            //     }
            // }
            if(route.routeConfig.path.includes("admin")){
                if(!this.auth.isAdmin()){
                    this.router.navigate(['/explore/overview'])
                    return;
                }
            }
           return true;
        }
        else {
            console.log('Not logged in!')
            if(route.routeConfig.path != 'login'){
                this.router.navigate(['/login'])
            }
            
            return;
        }
    }
}
