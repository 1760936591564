
<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">
			<h2 style="padding-bottom: 5px;color: black; margin-bottom: 0px;">
			<i class="fal fa-lock-keyhole"></i>
			Connection Manager</h2>
			<p class="text-muted">Establish connections to your database and share them securely.</p>
			<form [formGroup]="passwordForm">
		    	<div class="row">
		        	<div class="col-3">
		              <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Username</span>
		                  <span class="text-danger"
		                    *ngIf="(passwordFormControl().username.touched || submitted) && passwordFormControl().username.errors?.required">
		                    Required
		                  </span>
		                </label>
		                  <input formControlName="username" id="username" name="username" type="text" class="form-control form-border" autocomplete="new-username">
		              </div>
		          	</div>
		          	<div class="col-2">
		              <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">

		                  <span style="padding-right: 15px;">Password</span>
		                  <span class="text-danger"
		                    *ngIf="(passwordFormControl().password.touched || submitted) && passwordFormControl().password.errors?.required">
		                    Required 
		                  </span>
		                </label>
		                <input formControlName="password" id="password" name="password" type="password" class="form-control form-border" autocomplete="new-password" > 
		              </div>
		          	</div>
		          	<div class="col-2">
			          	<div class="form-group ng-carve" >
			                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
			                        	System
			                        	 <span class="text-danger float-right text-right"
						                     *ngIf="(passwordFormControl().system.touched || submitted) && passwordFormControl().system.errors?.required">
						                    Required
						                  </span>
			                        </label>
					            	<ng-select [items]="connObj.systemOptions"
									      bindLabel="label"
									      bindValue="value"
									      formControlName="system"
									      style="height: 2.4rem !important;"
									      >
										      <ng-template ng-label-tmp let-item="item" >
										      	<img *ngIf="passwordFormControl().system.touched" height="15px" width="15px" [src]="item['logo']" style="margin-bottom: 3px; margin-right: 3px;" />
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										      	<img height="15px" width="15px" [src]="item['alt_logo']" style="margin-bottom: 3px; margin-right: 3px;" />
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									</ng-select>
			                      </div>
	              </div>
		          	<div class="col-3">
			          	<div class="form-group ng-carve" >
			                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
			                        	User Type
			                        	 <span class="text-danger float-right text-right"
						                     *ngIf="(passwordFormControl().user_type.touched || submitted) && passwordFormControl().user_type.errors?.required">
						                    Required
						                  </span>
			                        </label>
					            	<ng-select [items]="connObj.typeOptions"
									      bindLabel="label"
									      bindValue="value"
									      formControlName="user_type"
									      (ngModelChange)="changeUserType($event)"
									      style="height: 2.4rem !important;"
									      >
										      <ng-template ng-label-tmp let-item="item" >
										      	<i [ngClass]="item['icon']"></i>
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										      	<i [ngClass]="item['icon']"></i>
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									</ng-select>
			                      </div>
	              </div>
			        <div class="col-2">
			          	<div class="btn btn-outline-info" (click)="saveConnection('new');" style="margin-top: 25px;">
			          		<i *ngIf="!saving" class="fal fa-floppy-disk fa-lg"></i>
			          		<div *ngIf="saving" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
									        <span  class="sr-only">Loading...</span>
	                	</div>
			          		Save
						</div>
			        </div>
		  		</div>
	        </form>  
		    <div class="card mb-4 shadow-hover">
	            	<ngx-loading-bar fixed [includeSpinner]="false" color="#3dabab" ref="passwordLoad"></ngx-loading-bar>
	            	<div class="card-header" style="padding-right: 20px; padding-left: 20px;">
	            		<div class="float-left">
									 <label class="nav-item navbar-text navbar-search-box p-0 active">
										<i class="ion ion-ios-search navbar-icon align-middle"></i>
								          <span class="navbar-search-input pl-2" style="width: 400px !important">
								            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important; margin-left: 20px !important;">
								          </span>
								          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
								        </label>
									</div>
		            </div>
	            	<div class="card-body overview-card" >
	            		<form [formGroup]="editPasswordForm">
										<div style="width: 100%; height: 100%;">
											<p-table 
													#passwordTable
													styleClass="p-datatable-sm"
													[value]="passwords"
													sortMode="single"
					        				responsiveLayout="scroll"
											 >
											
										        <ng-template pTemplate="header" style="font-size:9pt;">
										            <tr>
										                <th pSortableColumn="username">Username<p-sortIcon field="username"></p-sortIcon></th>
										                <th pSortableColumn="password">Password<p-sortIcon field="password"></p-sortIcon></th>
										                <th>System</th>
										                <th>User Type</th>
										                <th style="width: 250px;"></th>
										            </tr>
										        </ng-template>
										        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-connection>
										            <tr style="text-align: left;" class="non-hover">
										                <td>{{connection.username}}</td>
										                <td>
										           				<div *ngIf="!connection.is_edit">
										           					************
										           				</div>
										                	<div *ngIf="connection.is_edit" class="form-group ng-carve" style="margin-bottom: 0.8rem">
												                <label class="form-label d-flex justify-content-between align-items-end">
												                  <span class="text-danger"
												                    *ngIf="(editPasswordFormControl().password.touched || submitted) && editPasswordFormControl().password.errors?.required">
												                    Required 
												                  </span>
												                </label>
												                <input formControlName="password" id="password" name="password" type="password" class="form-control form-border">
												              </div>
										                </td>
										                <td>
										                	<div>
										                		<img height="15px" width="15px" [src]="connObj.systemLogos[connection.system]" style="margin-bottom: 3px;" />
										                		{{connObj.systemMapping[connection.system]}}
										                	</div>
										                		<!-- <div *ngIf="connection.is_edit"class="form-group ng-carve" >
								                        <label class="form-label" style="width: 100%;">
								                        	 <span class="text-danger float-right text-right"
											                     *ngIf="(editPasswordFormControl().system.touched || submitted) && editPasswordFormControl().system.errors?.required">
											                    Required
											                  	</span>
								                        </label>
													            	<ng-select [items]="connObj.systemOptions"
																	      bindLabel="label"
																	      bindValue="value"
																	      formControlName="system"
																	      style="margin-bottom: 25px;"
																	      >
																		      <ng-template ng-label-tmp let-item="item" >
																		      	<img height="15px" width="15px" [src]="item['logo']" style="margin-bottom: 3px;" />
																		        <span class="ng-value-label">{{item['label']}}</span>
																		      </ng-template>

																		      <ng-template ng-option-tmp let-item="item" >
																		      	<img height="15px" width="15px" [src]="item['alt_logo']" style="margin-bottom: 3px;" />
																		        <span class="ng-value-label"> {{item['label']}}</span>
																		      </ng-template>
																				</ng-select>
							                      	</div> -->
										                </td>
										                <td>
										                	<div>
										                		<i [ngClass]="connObj.typeIcons[connection.user_type]" style="margin-right: 3px;"></i>
											                	{{connObj.typeMapping[connection.user_type]}}
											                </div>
										                	<!-- <div *ngIf="connection.is_edit" class="form-group ng-carve" >
													                        <label class="form-label" style="width: 100%;">
													                        	 <span class="text-danger float-right text-right"
																                     *ngIf="(editPasswordFormControl().user_type.touched || submitted) && editPasswordFormControl().user_type.errors?.required">
																                    Required
																                  </span>
													                        </label>
															            	<ng-select [items]="connObj.typeOptions"
																			      bindLabel="label"
																			      bindValue="value"
																			      formControlName="user_type"
																			      style="margin-bottom: 25px;"
																			      >
																				      <ng-template ng-label-tmp let-item="item" >
																				      	<i [ngClass]="item['icon']"></i>
																				        <span class="ng-value-label">{{item['label']}}</span>
																				      </ng-template>

																				      <ng-template ng-option-tmp let-item="item" >
																				      	<i [ngClass]="item['icon']"></i>
																				        <span class="ng-value-label"> {{item['label']}}</span>
																				      </ng-template>
																			</ng-select>
													           </div> -->
										                </td>
										                
										                <td style="width: 220px;">
										                	<div style="float: right;">
										                		<div *ngIf="connection.is_edit" style="display: inline-block;"  class="fal fa-times fa-lg hover-over-gray hover-over-btn" (click)="clear()">
											                	</div>
										                		<div *ngIf="!connection.is_edit && connection.user_type != 'catalog'" style="display: inline-block;"  class="fal fa-edit fa-lg hover-over-gray hover-over-btn" (click)="enableEdit(connection)">
											                	</div>
											                	<div *ngIf="connection.saving" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
																			        <span  class="sr-only">Loading...</span>
											                	</div>
											                	<div *ngIf="connection.is_edit && !connection.saving" style="display: inline-block;"  class="fal fa-floppy-disk fa-lg hover-over-green hover-over-btn" (click)="saveConnection('update', connection);">
											                	</div>
											                	<div  style="display: inline-block;"  class="fal fa-trash-alt fa-lg hover-over-red hover-over-btn" (click)="openDeleteConnModal(deleteConnModal, { windowClass: 'modal animate', centered: true }, connection); $event.stopPropagation()">
											                	</div>
											                </div>
										                </td>
										            </tr>
										        </ng-template>
											</p-table>
									</div>
								</form>
					</div>
			</div>
		</div>
	</div>
</div>

<ng-template #deleteConnModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" style="font-size:16pt;" >
		        	<span class="fal fa-circle-trash sg-txt-critical"></span>&nbsp;
		        	<span style="font-weight: bold;">Delete Connection</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this connection? </p>
        <h5>{{selectedConn.username}}</h5>
        <p *ngIf="selectedConn.user_type == 'catalog'" style="color: red;">If deleted, your catalog and lineage will no longer refresh. </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="deleteConn(selectedConn)">
        	<span *ngIf="!selectedConn.deleting" class="fas fa-trash-alt"></span>
        	<div *ngIf="selectedConn.deleting" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
		    &nbsp;Delete
		</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

  <ng-template #setupModal let-c="close" let-d="dismiss">
  	<div class="modal-body" style="font-size: 11pt; height: 600px; padding-left: 45px; padding-right: 45px;">
			<div style="text-align: center; font-size: 18pt; font-weight: bold; color: black;padding-top:">
				              <i class="fal fa-code" style="padding-right: 5px;"></i>
				              Run the following script in your Snowflake Account 
       </div>
       <div style="text-align: center; font-size: 11pt; color: #828282;">
         This script sets up the neccesary objects and permissions needed to start using the Carve catalog.
       </div>
       <div style="text-align: left; font-size: 11pt; color: #24282c; padding-top: 25px;">
         <div style="font-weight: 500">
          <i class="fal fa-exclamation-circle" style="padding-right: 3px; color: #d74700;"></i> Before executing:</div>
          <ul>
            <li>Change the [password] (you will need to save the password in the Connection Manager)</li>
            <li>Change each instance of [warehouse name]</li>
            <li>Exclude any databases by modifying DATABASES_TO_EXCLUDE</li>
            <li>Exclude any schemas by modifying SCHEMAS_TO_EXCLUDE</li>
          </ul>
       </div>
       <div style="height: 350px;">
         <codemirror 
            #startupScript
            [(ngModel)]="startupSQL"
            [config]="codeConfig"
          >
          </codemirror>
       </div>
     </div>
     <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="copy()"><span class="fal fa-copy"></span>&nbsp;&nbsp;Copy</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

