 
<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">
			<h2 style="padding-bottom: 5px;color: black; margin-bottom: 0px;">Manage Users</h2>
			<p class="text-muted">Add or change users in your Carve account.</p>
		
			<div class="row">
				<div class="col-6">
					<form [formGroup]="userForm" >
						<div class="row">
		                      <div class="col-6">
		                          <div class="form-group">
		                            <label class="form-label d-flex justify-content-between align-items-end">
		                              <span>Email address</span>
		                              <span class="text-danger"
		                                *ngIf="(userFormControl().email.touched || submitted) && userFormControl().email.errors?.required">
		                                Required
		                              </span>
		                            </label>
		                              <input formControlName="email" id="email" name="email" type="text" class="form-control form-border">
		                          </div>
		                      </div>
		                      <div class="col-3">
		                          <div class="form-group">
		                            <label class="form-label d-flex justify-content-between align-items-end">

		                              <span style="padding-right: 15px;">First Name</span>
		                              <span class="text-danger"
		                                *ngIf="(userFormControl().first_name.touched || submitted) && userFormControl().first_name.errors?.required">
		                                Required 
		                              </span>
		                            </label>
		                            <input formControlName="first_name" id="first_name" name="first_name" type="text" class="form-control form-border">
		                          </div>
		                      </div>
		                      <div class="col-3">
		                          <div class="form-group">
		                            <label class="form-label d-flex justify-content-between align-items-end">

		                              <span style="padding-right: 15px;">Last Name</span>
		                              <span class="text-danger"
		                                *ngIf="(userFormControl().last_name.touched || submitted) && userFormControl().last_name.errors?.required">
		                                Required 
		                              </span>
		                            </label>
		                            <input formControlName="last_name" id="last_name" name="last_name" type="text" class="form-control form-border">
		                          </div>
		                      </div>
		                </div>
		            </form>
	            </div>
	            <div class="col-2">
	                      <div class="form-group" class="ng-carve">
	                        <label class="form-label" style="margin-bottom: 4px;">Role</label>
			            	<ng-select [items]="userObj.roleOptions"
							      bindLabel="label"
							      bindValue="value"
							      [(ngModel)]="userObj.roleSelectValue"
							      style="margin-bottom: 25px;"
							      >
							    
								      <ng-template ng-label-tmp let-item="item" >
								        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px" [style.color]="item['value'] == 'admin' ? 'green' : 'inherit' "></i>{{item['label']}}</span>
								      </ng-template>

								      <ng-template ng-option-tmp let-item="item" >
								        <span class="ng-value-label"><i [ngClass]="item['icon']" style="padding-right: 5px"></i> {{item['label']}}</span>
								      </ng-template>
								    
							    </ng-select>
							  
	                      </div>
	            </div>
	            <div class="col-2" style="margin-top: 25px;">
	                	<button type="button" class="btn btn-primary btn-sg setup-btn" (click)="inviteUser();" style="padding: 6px; width: 140px;">
				          <span *ngIf="!saving.new" class="fa-regular fa-envelope-circle-check" style="margin-right: 5px;"></span>
				          <div *ngIf="saving.new" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 7px;">
				            <span  class="sr-only">Loading...</span>
				          </div>
				       	Invite
				        </button>
	            </div>
		    </div>
		    <div class="card mb-4 shadow-hover">
	            	<ngx-loading-bar fixed [includeSpinner]="false" color="#3dabab" ref="userLoad"></ngx-loading-bar>
	            	<div class="card-header" style="padding-right: 20px; padding-left: 20px;">
	            		<div class="float-left">
									 <label class="nav-item navbar-text navbar-search-box p-0 active">
										<i class="ion ion-ios-search navbar-icon align-middle"></i>
								          <span class="navbar-search-input pl-2" style="width: 400px !important">
								            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important; margin-left: 20px !important;">
								          </span>
								          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
								        </label>
									</div>
	            		<div class="float-right" style="width: 400px; text-align: right; color: black; padding-top: 10px;">
		            		<div style="display: inline-block;padding-right: 18px;">Invited ({{usersInfo.invited}})</div>
		            		<!-- <div style="display: inline-block;padding-right: 18px;">Members ({{usersInfo.members}})</div>
		            		<div style="display: inline-block;padding-right: 17px;">Admins ({{usersInfo.admins}})</div> -->
		            		<div style="display: inline-block;font-weight: bold;">Total Users ({{usersInfo.total}})</div>
		            	</div>
		            </div>
	            	<div class="card-body overview-card" >
									<div style="width: 100%; height: 100%;">
										<p-table 
												#userTable
												styleClass="p-datatable-sm"
												[value]="users"
												sortMode="single"
				        				responsiveLayout="scroll"
										 >
										
									        <ng-template pTemplate="header" style="font-size:9pt;">
									            <tr>
									            	  <th style="width:50px"></th>
									                <th pSortableColumn="full_name">Name<p-sortIcon field="full_name"></p-sortIcon></th>
									                <th pSortableColumn="email">Email<p-sortIcon field="email"></p-sortIcon></th>
									                <th style="min-width: 200px;" pSortableColumn="role">Role<p-sortIcon field="role"></p-sortIcon></th>
									                <th pSortableColumn="last_login">Last Login<p-sortIcon field="last_login"></p-sortIcon></th>
									                <th style="width: 120px;"pSortableColumn="is_enabled">Enabled<p-sortIcon field="is_enabled"></p-sortIcon></th>
									                <th style="width: 250px;"></th>
									            </tr>
									        </ng-template>
									        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-user>
									            <tr style="text-align: left;" class="non-hover">
									    						<td style="width:50px">
									
											    							<i *ngIf="user.created_date != null" class="fa-light fa-user-circle fa-2x"
											    							[style.color]="user.icon_color"
											    							
											    							></i>
									    							<i *ngIf="user.created_date == null" class="fa-light fa-circle-envelope fa-2x" style="color: #b6b9bb;" 
									    							></i>
									    						</td>
									                <td><span>{{user.first_name}}</span>&nbsp;<span>{{user.last_name}}</span>&nbsp;
									                	<span *ngIf="user.created_date == null">(invited)</span>
									                	<div *ngIf="user.is_owner" style="display: inline-block; color: #4cbae3; border: 1px solid #4cbae3; border-radius: 15px; width: 75px;padding: 5px; text-align: center; margin-left: 15px; font-size: 8pt;">OWNER</div>
									                </td>
									                <td>{{user.email}}</td>
									                <td style="min-width: 200px;">
									                	<div class="form-group" style="margin-bottom: 0px !important; max-width: 200px;">
													            	<ng-select [items]="roleOptions"
																	      bindLabel="label"
																	      bindValue="value"
																	      [(ngModel)]="user.role"
																	      (change)="submitRole(user)"
																	     	[disabled]="user.created_date == null || user.is_owner"

																	      >
																	    
																		      <ng-template ng-label-tmp let-item="item" >
																		        <span class="ng-value-label">
																		        	<i [ngClass]="item['icon']" style="padding-right: 5px" [style.color]="item['value'] == 'admin' ? 'green' : 'inherit' "></i>
																		        {{item['label']}}</span>
																		      </ng-template>

																		      <ng-template ng-option-tmp let-item="item" >
																		        <span class="ng-value-label">
																		        	<i [ngClass]="item['icon']" style="padding-right: 5px"></i> 
																		        {{item['label']}}</span>
																		      </ng-template>
																		    
																	    </ng-select>
													  
							                      </div>
									                </td>
									                <td>
									                	<span *ngIf="user.created_date != null && user.last_login_date != null">{{user.last_login_date | dateAgo}}</span>
									                	<span *ngIf="user.created_date != null && user.last_login_date == null">Never</span>
									                	<span *ngIf="user.created_date == null">
									                		Invitation sent {{user.email_sent_date | dateAgo}}
									                	</span>
									                </td>
									                <td>
									                	<div style="vertical-align: middle; width: 120px;" >
										                	<p-inputSwitch 
											                	#ngModelDir="ngModel"
											                	[(ngModel)]="user.active" 
											                	(ngModelChange)="checkEnable(user, ngModelDir);"
											                	(click)="$event.stopPropagation()"
											                	[disabled]="user.created_date == null || user.is_owner"
											                	>
											                	</p-inputSwitch>
											              </div>
											            </td>
									                <td style="width: 220px;">
									                		<div style="display: inline-block;" *ngIf="user.created_date == null">
									                			<button type="button" class="btn btn-primary btn-sg setup-btn" (click)="inviteUser(user);" style="padding: 5px; width: 120px; font-size: 9pt;">
																          <span *ngIf="!saving.pending[user.id]" class="fa-regular fa-repeat" style="margin-right: 5px;"></span>
																          <div *ngIf="saving.pending[user.id]" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 7px;">
																            <span  class="sr-only">Loading...</span>
																          </div>
																       	Resend Invite
																        </button>
									                		</div>
										                	<div *ngIf="!user.is_owner" style="display: inline-block; float: right;"  class="fal fa-trash-alt fa-lg hover-over-red hover-over-btn" (click)="openDeleteUserModal(deleteUserModal, { windowClass: 'modal animate', centered: true }, user); $event.stopPropagation()">
										                		
										                	</div>

									                </td>
									            </tr>
									        </ng-template>
										</p-table>
						</div>
					</div>
			</div>
		</div>
	</div>
</div>

<ng-template #deleteUserModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fal fa-user-xmark fa-2x sg-txt-critical"></span>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Delete User</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this user? </p>
        <h5>{{userObj.delete.name}}</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="deleteUser()">
        	<span *ngIf="!saving.delete" class="fas fa-trash-alt"></span>
        	<div *ngIf="saving.delete" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
		    &nbsp;Delete
		</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>

