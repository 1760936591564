import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


// *******************************************************************************
// Layouts

import { Layout1Component } from './layout-1/layout-1.component';
import { Layout1FlexComponent } from './layout-1-flex/layout-1-flex.component';
import { Layout2Component } from './layout-2/layout-2.component';
import { Layout2FlexComponent } from './layout-2-flex/layout-2-flex.component';
import { LayoutWithoutNavbarComponent } from './layout-without-navbar/layout-without-navbar.component';
import { LayoutWithoutNavbarFlexComponent } from './layout-without-navbar-flex/layout-without-navbar-flex.component';
import { LayoutWithoutSidenavComponent } from './layout-without-sidenav/layout-without-sidenav.component';
import { LayoutHorizontalSidenavComponent } from './layout-horizontal-sidenav/layout-horizontal-sidenav.component';
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';


// *******************************************************************************
// Components

import { LayoutNavbarComponent } from './layout-navbar/layout-navbar.component';
import { LayoutSidenavComponent } from './layout-sidenav/layout-sidenav.component';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';


// *******************************************************************************
// Libs

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
//import { NgSpinKitModule } from 'ng-spin-kit';
import { SidenavModule } from '../../vendor/libs/sidenav/sidenav.module';
import { CodemirrorModule } from 'ng2-codemirror';

// *******************************************************************************
// Services

import { LayoutService } from './layout.service';
import { LayoutHorizontalNavbarComponent } from './layout-horizontal-navbar/layout-horizontal-navbar.component';
import { LayoutMainComponent } from './layout-main/layout-main.component';
import { LayoutMainNoSidenavComponent } from './layout-main-no-sidenav/layout-main-no-sidenav.component';
import { LayoutMainSidenavComponent } from './layout-main-sidenav/layout-main-sidenav.component';
import { AngularSplitModule } from 'angular-split';

//import { MenuService } from '../shared/menu/menu.service';

// *******************************************************************************
//

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    SidenavModule,
    FormsModule,
    CodemirrorModule,
    AngularSplitModule
    //NgSpinKitModule
  ],
  declarations: [
    Layout1Component,
    Layout1FlexComponent,
    Layout2Component,
    Layout2FlexComponent,
    LayoutWithoutNavbarComponent,
    LayoutWithoutNavbarFlexComponent,
    LayoutWithoutSidenavComponent,
    LayoutHorizontalSidenavComponent,
    LayoutBlankComponent,

    LayoutNavbarComponent,
    LayoutSidenavComponent,
    LayoutFooterComponent,
    LayoutHorizontalNavbarComponent,
    LayoutMainComponent,
    LayoutMainNoSidenavComponent,
    LayoutMainSidenavComponent
  ],
  providers: [
    LayoutService, 
    //MenuService
  ]
})
export class LayoutModule { }
