
<div style="padding: 25px 25px 10px; background-color: white; height:80px; " class="row m-0" >
  <div class="col-8">
    <div class="row">
      <div class="col" style="max-width:70px; padding-top: 4px">
          <i *ngIf="objectType == 'table'" class="fal fa-table fa-3x sg-txt" ></i>
          <i *ngIf="objectType == 'view'" class="fal fa-window-maximize fa-3x sg-txt" ></i>
      </div>
      <div class="col">
        <div class="row">
          <span style="font-weight: 700; font-size: 14pt;">{{tableName}}</span>
        </div>
        <div class="row">
          <span style="font-size: 9pt;">{{databaseName}}.</span>
          <span style="font-size: 9pt;">{{schemaName}}.</span>
          <span style="font-size: 9pt;">{{tableName}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-4" style="text-align: right;" >
    <div style="width: 100%; text-align: right;">
      <div style="font-weight:bold;">Change Tracking
      </div>
      <div>
        <div style="display:inline-block; margin-top: 5px;">
          <p-inputSwitch 
          #ngModelDir="ngModel"
          [(ngModel)]="ctObj.enabled" 
          (ngModelChange)="checkEnable();"
          (click)="$event.stopPropagation()"
          ></p-inputSwitch>
        </div>
        <div style="display:inline-block; vertical-align: top; margin-right: -5px;">
          <i class="fal fa-edit hover-over-gray hover-over-btn fa-lg" style="padding: 5px !important;" (click)="openCTModal(ctModal, { windowClass: 'modal-xl animate', centered: true })">
          </i>
        </div>
      </div>
    </div>
  </div> -->
    <!-- <span style="font-weight: bold;">Quick Scripts:</span>
    <span class="divider">|</span>
   <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(scriptModal, { windowClass: 'modal-xl animate', centered: true }, 'ddl')">Create DDL
    </button>
    <span class="divider">|</span>
   <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(scriptModal, { windowClass: 'modal-xl animate', centered: true }, 'select')">Select
    </button>
    <span class="divider">|</span>
    <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(scriptModal, { windowClass: 'modal-xl animate', centered: true }, 'insert')">Insert
    </button>
    <span class="divider">|</span>
    <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(scriptModal, { windowClass: 'modal-xl animate', centered: true }, 'update')">Update
    </button>
    <span class="divider">|</span>
    <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(mergeModal, { windowClass: 'modal-xl animate', centered: true }, 'merge')">Merge
    </button>
    <span class="divider">|</span>
    <button class="btn btn-sm quick-script-btn"
      (click)="openScriptModal(scriptModal, { windowClass: 'modal-lg animate', centered: true }, 'delete')">Delete
    </button>
  </div> -->

</div>
<div class="row m-0" style="padding: 0px 25px; background-color: white;">
      <div class="col-8">
        <form [formGroup]="descriptionForm">
              <div class="form-group">
                 <input 
                 class="inline-edit" 
                 formControlName="description"  
                 placeholder="Add description" 
                 (keydown.enter)="$event.target.blur();postDescription();false"
                 (blur)="postDescription();"
                 style="outline-color: #4cbae3; padding-top: 3px; padding-bottom: 3px;">
              </div>
        </form>
      </div>
</div>

<nav class="navbar navbar-expand-lg nav-justified" style="padding: 0px">
    <div class="navbar-collapse justify-content-left" style="text-align: center; width: 100%;background-color:white; padding-left: 35px; border-bottom: 1px solid #eee">
      <div class="demo-vertical-spacing" style="width: 100%;">
        <ul class="nav nav-tabs tabs-alt nav-responsive-xl justify-content-left" style="font-size: 12pt; width: 100%;">
            <li *ngFor="let item of items" style="padding-right: 50px;"><a class="nav-item nav-link table-nav" style="padding: 15px 0px 5px;" 
            (click)="setRoute(item.text)"
            [ngClass]="{'active': view==item.text.toLowerCase()}"
            [routerLink]="item.link" >
            <i [class]="item.class" [ngClass]="{'active': view==item.text.toLowerCase()}"></i>
              {{item.text}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 
   <div *ngIf="setRoute != undefined" class="justify-content-end">
      <div ngbDropdown  class="d-inline-flex" (openChange)="toggled($event)" style="margin-right: 15px">
        <button class="btn btn-default" style="cursor: pointer; color:white; background-color: #2e93a0" ngbDropdownToggle>
          <span class="far fa-cog"></span>&nbsp;&nbsp;
           Report Options
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-left" style="width: 175px">
          <a class="dropdown-item" href="javascript:void(0)"><span class="far fa-save"></span>&nbsp;&nbsp;Save</a>
          <a class="dropdown-item"  href="javascript:void(0)" (click)="open(defaultModal, { windowClass: 'modal-lg animate', centered: true })"><span class="fab fa-slack"></span>&nbsp;&nbsp;Send Slack</a>
          <a class="dropdown-item" href="javascript:void(0)"><span class="far fa-envelope"></span>&nbsp;&nbsp;Send Email</a>
        </div>
      </div>
  </div> -->
  </nav>
  <div class="layout-container">
      <div class="layout-content">
        <div class="router-transitions flex-grow-1 container-p-y no-scrollbar perfect-height" style="padding-top: 0px !important; padding-bottom: 0px !important" #routerScreen>
           <router-outlet></router-outlet>
         </div>
       </div>
  </div>


   <ng-template #ctModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
       <!--  <h5 class="modal-title"><span class="fal fa-analytics fa-lg"></span>&nbsp;&nbsp;
          Change Tracking<br>
         
        </h5> -->
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding-top: 0px; padding-bottom: 0px;">
       <div class="row" style="margin-right: -1.5rem; margin-left: -1.5rem;">
        <div class="col-6" style="padding: 25px;">
          <h2>Enable Change Tracking Configuration</h2>
          <p>Enable change tracking for this object by entering the configuration details below</p>
          <hr>
          <div>
            <form [formGroup]="ctConfigForm">
              <div class="form-group">
                <label class="form-label">Destination Database</label>
                <ng-select [items]="ctObj.databaseOptions"
                bindLabel="label"
                bindValue="value"
                [(ngModel)]="ctObj.databaseValue"
                placeholder="Enter destination database"
                style="margin-bottom: 25px;"
                >
              
                  <ng-template ng-label-tmp let-item="item">
                    <span class="ng-value-label">{{item}}</span>
                  </ng-template>
              </ng-select>
            </div>
            </form>
          </div>
        </div>
        <div class="col-6" style="background-color: #F9F9F9; padding: 25px;">
          <h2>Config Setup Guide</h2>
          <div class="row">
            <div class="col-2 step-pill">Step 1</div>
            <div class="col-10">
              <div class="ct-step-title">Destination Database</div>
              <div class="ct-step-description">Choose the destination Database for the change tracking table to be created in.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 2</div>
            <div class="col-10">
              <div>Destination Schema</div>
              <div>Choose the destination Schema for the change tracking table to be created in.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 3</div>
            <div class="col-10">
              <div>Destination Table Name</div>
              <div>Provide a table name of your choosing for the change tracking table.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 4</div>
            <div class="col-10">
              <div>Columns to Track Initial Value</div>
              <div>This will add a column to the data change tracking table named 'INITIAL_[Col_name]' to track the first value for the column(s) provided in every row of the table.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 5</div>
            <div class="col-10">
              <div>Columns to Exclude Update Comparisons</div>
              <div>This will exclude certain columns in the source object from dictating a change record from being inserted. Common columns to ignore would be update date type columns in the source table that may be updated even though no actual values were updated in the row.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 6</div>
            <div class="col-10">
              <div>Primary Key Columns</div>
              <div>Automatically add new columns to the change tracking table as they're added to the source object. This will not remove columns if they're removed from the source object, only add new columns.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 7</div>
            <div class="col-10">
              <div>Only Daily Inserts</div>
              <div>This setting will allow for the most recent record to not have a new record until a change happens on a separate day. This can be useful for data that changes frequently throughout the day, but not every change during the day is needed to store. It will force the start/end dates to be at the day level and the whatever is in the values at the end of a day before the next days' changes is what's stored.</div>
            </div>
          </div>
          <div class="row">
            <div class="col-2 step-pill">Step 8</div>
            <div class="col-10">
              <div>Auto Add New Columns</div>
              <div>Automatically add new columns to the change tracking table as they're added to the source object. This will not remove columns if they're removed from the source object, only add new columns.</div>
            </div>
          </div>
        </div>
       </div>
     </div>
      
  </ng-template>


  <ng-template #scriptModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fal fa-code fa-lg"></span>&nbsp;&nbsp;
          {{script.title}}<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body">
        <codemirror 
          #scriptcode
          [(ngModel)]="script.code"
          [config]="script.config"
          (focus)="onFocus()"
          (blur)="onBlur()"
          >
        </codemirror>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="openEditor(script.code)"><span class="fal fa-copy"></span>&nbsp;&nbsp;Open in Editor</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>

  <ng-template #mergeModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fal fa-code fa-lg"></span>&nbsp;&nbsp;
          {{script.title}}<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-9" style="border-right: 1px solid #ccc;">
            <codemirror 
              #scriptcode
              [(ngModel)]="script.code"
              [config]="script.config"
              (focus)="onFocus()"
              (blur)="onBlur()"
              >
            </codemirror>
          </div>
          <div class="col-3">
            <form>
              <label class="form-label">Table Name or Subquery</label>
              <textarea class="form-control" (input)="onSearchChange($event.target.value)" placeholder="select * from [TABLE]

or    

[DATABASE].[SCHEMA].[TABLE]"
              style="min-height: 270px"></textarea>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="openEditor(script.code)"><span class="fal fa-copy"></span>&nbsp;&nbsp;Open in Editor</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>