import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { TablesService } from '../tables.service';
import { DatePipe } from '@angular/common'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import * as am4plugins_regression from "@amcharts/amcharts4/plugins/regression"; 
import { Subscription } from 'rxjs/Subscription';
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api/api.service';
import { LoadingBarService } from '@ngx-loading-bar/core';



@Component({
  selector: 'app-table-permissions',
  templateUrl: './table-permissions.component.html',
  styles: []
})
export class TablePermissionsComponent implements OnInit {

  databaseName = ''
	schemaName = ''
	tableName = ''
    objectType = ''
 

	url: string = environment.url;
	display: string = ''
	items: Array<any> = []
	view: String = '';
	routes: Array<any> = []
	scripts: Object = {}
    tableSubscription: Subscription;
	summary_loaded = false;
	summary_url: string = ''
	sankeyData: Array<any> = [{}];
    //private status$ = new Subject<any>();

    sankeyLoaded = false;
    gettingChart = false;
    refresh = true;
    sankeyChart;
    pageName = 'Table_Permissions';
    mainHeight = 700;
    insideHeight = 675;
    sankeyHeight = 635;

    public summary: any = {
    "roles_with_read_access": 0,
    "roles_with_write_access": 0,
    "total_different_privileges_granted": 0,
    "users_with_access_inherited_from_role": 0,
    "users_with_read_access": 0,
    "users_with_write_access": 0

    };

  dailyLoaded: boolean = false;

	constructor(
		public http: HttpClient,
		public route: ActivatedRoute,
		public auth: AuthService,
		public tables: TablesService,
		private datePipe: DatePipe,
        private appService: AppService,
        private loadingBar: LoadingBarService,
        private api: ApiService
	) { 
		
		this.sankeyData[0]['label'] = 'Users and Role Access'
        this.sankeyLoaded = false;
        this.refresh = true;
        this.appService.pageTitle = 'Table Permissions';
       
    }
  ngOnInit() {
       
        this.resetSummary();
        this.resetCharts();
        var tableObj = this.tables.getTableObj()
        this.databaseName = tableObj['database']
        this.schemaName = tableObj['schema']
        this.tableName = tableObj['table']
        this.objectType = tableObj['object_type']
        this.gettingChart = true;
        this.getData();

        this.tableSubscription = this.tables.getTableObject().subscribe(
            data=>{
                if( this.tables.view == 'permission'){
                   
                 

                    let case1 = false
                    if(this.databaseName != data['database']){
                        case1 = true
                    }
                    let case2 = false
                    if(this.schemaName != data['schema']){
                        case1 = true
                    }
                    let case3 = false
                    if(this.tableName != data['table']){
                        case1 = true
                    }

                  

                    if(case1 || case2 || case3){
                        this.resetSummary();
                        this.clearChartDivs()
                        this.databaseName = data['database']
                        this.schemaName = data['schema']
                        this.tableName = data['table']
                        this.objectType = tableObj['object_type']
                        this.gettingChart = true;

                        this.resetCharts();
                        
                        this.getData();
                        this.refresh = false;
                    }
                    
                }
                
            }
        );
	}
     ngOnDestroy(){
        if(this.tableSubscription != undefined){
            this.tableSubscription.unsubscribe();
        }
        am4core.disposeAllCharts();
    }
    resetSummary(){
      this.summary = {
        "roles_with_read_access": 0,
        "roles_with_write_access": 0,
        "total_different_privileges_granted": 0,
        "users_with_access_inherited_from_role": 0,
        "users_with_read_access": 0,
        "users_with_write_access": 0
        };
    }

   resetCharts(){
      
       this.sankeyLoaded = false;
   }
   
  clearChartDivs(){
   document.querySelector('div#TablePermissionsSankeyDiv').innerHTML = "";
   }
  
  private displayPermissionSankeyChart(chartData){
    
    let chart = am4core.create("TablePermissionsSankeyDiv", am4charts.SankeyDiagram);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.paddingLeft = 10;
    chart.paddingRight = 10;
    chart.paddingTop = 20;
    chart.paddingBottom = 20;

    chart.data = chartData;
    
    /*[
      { "from": "Select", "to": "SNOWGUIDE_ROLE", "value": 1, "id":"Select:SNOWGUIDE_ROLE:1" },
      { "from": "Select", "to": "DEV_PROD_ROLE", "value": 1, "id":"Select:DEV_PROD_ROLE:1" },
      { "from": "Select", "to": "ACCOUNTADMIN", "value": 1, "id":"Select:ACCOUNTADMIN:1" },
      { "from": "Select", "to": "Inherited From Role", "value": 1, "id":"Select:Inherited From Role:1" }, 
      
      { "from": "Update", "to": "SNOWGUIDE_ROLE", "value": 1, "id":"Update:SNOWGUIDE_ROLE:1" },
      
      { "from": "Insert", "to": "DEV_PROD_ROLE", "value": 1, "id":"Insert:DEV_PROD_ROLE:1" },
      { "from": "Insert", "to": "ACCOUNTADMIN", "value": 1, "id":"Insert" },
      { "from": "Insert", "to": "H", "value": 1, "id":"Insert" },    

      { "from": "Really Long User Permission Here", "to": "SNOWGUIDE_ROLE", "value": 1, "id":"Really Long User Permission Here:SNOWGUIDE_ROLE:1" },
      { "from": "Really Long User Permission Here", "to": "DEV_PROD_ROLE", "value": 1, "id":"Really Long User Permission Here:DEV_PROD_ROLE:1" },
      { "from": "Really Long User Permission Here", "to": "ACCOUNTADMIN", "value": 1, "id":"Really Long User Permission Here:ACCOUNTADMIN:1" },      
      { "from": "Really Long User Permission Here", "to": "H", "value": 1, "id":"D3-0" },        

      { "from": "SNOWGUIDE_ROLE", "to": "DAVID", "value": 1, "id":"SNOWGUIDE_ROLE:DAVID:2" },
      { "from": "Inherited From Role", "to": "HODY_CATCH", "value": 1, "id":"Inherited From Role:HODY_CATCH:2" },
      { "from": "SNOWGUIDE_ROLE", "to": "HODY_CATCH", "value": 1, "id":"SNOWGUIDE_ROLE:HODY_CATCH:2" },
      { "from": "DEV_PROD_ROLE", "to": "DAVID", "value": 1, "id":"DEV_PROD_ROLE:DAVID:2" },
      { "from": "DEV_PROD_ROLE", "to": "I", "value": 1, "id":"A1-1" },
      { "from": "DEV_PROD_ROLE", "to": "I", "value": 1, "id":"C0-1" },      
      { "from": "DEV_PROD_ROLE", "to": "I", "value": 1, "id":"D1-1" },          
      { "from": "DEV_PROD_ROLE", "to": "M", "value": 1, "id":"B1-1" },          

      { "from": "ACCOUNTADMIN", "to": "I", "value": 1, "id":"A2-1" },
      { "from": "ACCOUNTADMIN", "to": "I", "value": 1, "id":"B2-1" },      
      { "from": "ACCOUNTADMIN", "to": "J", "value": 1, "id":"C1-1" },          
      { "from": "ACCOUNTADMIN", "to": "N", "value": 1, "id":"D2-1" },  

      { "from": "H", "to": "K", "value": 1, "id":"C2-1" },          
      { "from": "H", "to": "N", "value": 1, "id":"D3-1" }

    ];*/

    let hoverState = chart.links.template.states.create("hover");
    hoverState.properties.fillOpacity = 0.5;

    chart.dataFields.fromName = "from";
    chart.dataFields.toName = "to";
    chart.dataFields.value = "value";

    chart.links.template.propertyFields.id = "id";
    chart.links.template.colorMode = "solid";
    chart.links.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    chart.links.template.fillOpacity = 0.1;
    chart.links.template.tooltipText = "";
    chart.nodes.template.nameLabel.label.truncate = false; 
    
    let nodeTemplate = chart.nodes.template;
    nodeTemplate.width = 40;
    nodeTemplate.stroke = am4core.color("#fff");
    nodeTemplate.strokeWidth = 2;
    nodeTemplate.nameLabel.label.fontWeight = "bold";
    nodeTemplate.nameLabel.label.maxWidth = 140;
    nodeTemplate.nameLabel.label.wrap = true;
    nodeTemplate.nameLabel.label.textAlign = "middle";
    nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
      switch (target.parent["level"]) {
        case 1:
          return 0;
        case 2:
          return -1;
        default:
          return 1;
      }
    });
    

    
    
    nodeTemplate.nameLabel.adapter.add("horizontalCenter", function(location, target) {
        switch (target.parent["level"]) {
            case 1:
                return "middle";
            case 2:
                return "middle";
            default:
                return "left";
        }
    });
    
    nodeTemplate.nameLabel.adapter.add("paddingLeft", function(location, target) {
        
        //alert(target.parent["level"]);
        switch (target.parent["level"]) {
            case 1:
                return 25;
            default:
                return 0;
        }
    });
    
    
    /*nodeTemplate.nameLabel.adapter.add("textAlign", function(location, target) {
        switch (target.parent.level) {
            case 1:
                return "middle";
            case 2:
                return "middle";
            default:
                return "start";
        }
    });*/

    // highlight all links with the same id beginning
    chart.links.template.events.on("over", function(event){
      let link = event.target;
      let myLevel = link.id[link.id.length -1];
      let id = link.id.slice(0, -2).split(":");
      link.isHover = true;
      if (myLevel === "1") {
          let roles = [];
          for (var v in id) {

              chart.links.each(function(link){
                
                if (link.id.startsWith(id[v])){
                  let newItem = link.id.slice(0, -2).split(":")[1];
                  if(roles.indexOf(newItem) === -1) roles.push(newItem);
                  link.isHover = true;
                }

              })
          }
          //alert(roles);
          chart.links.each(function(link){
                for (var r in roles){
                    if (link.id.startsWith(roles[r])){
                      //alert(link.id + "YES");
                      link.isHover = true;
                    }
                }

              })
      }
      else {

          let roles = [];
          for (var v in id) {
              //alert(id[v]);
              chart.links.each(function(link){
                if (link.id.slice(0, -2).endsWith(id[v])){
                  let newItem = link.id.slice(0, -2).split(":")[0];
                  if(roles.indexOf(newItem) === -1) roles.push(newItem);
                  link.isHover = true;
                }
                else {

                }

              })
          }
          //alert(roles);
          chart.links.each(function(link){
                for (var r in roles){
                    if (link.id.slice(0, -2).endsWith(roles[r])){
                      //alert(link.id + "YES");
                      link.isHover = true;
                    }
                }

              })
      
      }
    })

    chart.links.template.events.on("out", function(event){  
      chart.links.each(function(link){
        link.isHover = false;
      })
    })

    this.dailyLoaded = true;
  }
  
  getData(){
      
    let chart1Loader = this.loadingBar.useRef('permchart1')
    chart1Loader.start()
    chart1Loader.set(25)
     
    let summaryUrl = this.url + '/catalog/table/permission/summary'
    let graphUrl = this.url + '/catalog/table/permission/graph'
    let exactPageName = this.pageName + '_' + this.databaseName + '_' + this.schemaName + '_' + this.tableName
    let params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: "table", value: this.tableName}]
    
    this.api.getData(summaryUrl, exactPageName, 'permissions_summary', {api: true, params:params}).subscribe(
        data=>{
             
             this.summary = data['message'][0]
        }
    );
    
    this.api.getData(graphUrl, exactPageName, 'permissions_sankey', {api: true,params:params}).subscribe(
        data=>{
             chart1Loader.complete()
             this.mainHeight = data['message'][1]
             this.insideHeight = data['message'][2]
             this.sankeyHeight = data['message'][3]
             this.displayPermissionSankeyChart(data['message'][0])
        }
    );
     
        this.gettingChart = false;
  }
}
