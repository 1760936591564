import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { environment } from '../../../environments/environment';
import { FormGroup,  FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router"
import { AuthService } from "../../shared/auth/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../../../vendor/styles/pages/authentication.scss', './login.component.scss']
})
export class LoginComponent {

  public loginForm: FormGroup;
  public resetForm: FormGroup;
  public templateForm: FormGroup;
  ipAddress: string = '';
  url: string = environment.url;
  errorCount:number = 0;
  displayMessage: string = ''
  loggingIn: boolean = false;
  toEmail: string = '';
  
  reset = {
    active: false,
    submitted: false,
    complete: false,
    error: false
  }

  constructor(
  	private appService: AppService, 
  	private http: HttpClient, 
  	private fb: FormBuilder,
  	private router: Router, 
  	private auth: AuthService) {

    this.appService.pageTitle = 'Login';

    this.ipAddress = '192.0.0.1'
    let emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

    this.templateForm = fb.group({
        email: ['', [Validators.required, Validators.pattern(emailPattern)]],
        password:['', [Validators.required]],
        ip_address:'',
        source: 'frontend'
      })
    this.loginForm = this.templateForm;
    this.resetForm = fb.group({
        email: ['', [Validators.required, Validators.pattern(emailPattern)]],
        ip_address:'',
        source: 'frontend'
    })

  }

  login(){
    this.loggingIn = true
    this.auth.removeSession().subscribe(()=>{
      var url = this.url + '/login'
      this.loginForm.patchValue({ip_address: this.ipAddress});
      if(this.loginForm.valid){
        this.http.post(
          url,
          JSON.stringify(this.loginForm.value),
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          responseType: 'json' }
        ).subscribe(data => {
          
            // Store JWT
            this.auth.setSession(data['message'])
            // Use User Service to get and store user data
            this.loggingIn = false;
            this.router.navigate(['/query'])
          
          },
          err => {
            this.errorCount++
            if(err.error.message == 'locked'){
               this.displayMessage = 'Your account is locked. Try again in 10 minutes.'
            }
            else if(err.error.message == 'inactive'){
              this.displayMessage = 'Your account is inactive. Please contact your administrator.'
            }
            else{
               this.displayMessage = 'Incorrect Email or Password'
            }
           
            this.loginForm.reset(this.templateForm.value);
            this.loggingIn = false;
          }
        );
      }
    });
  }
  startReset(){
    this.reset.active = true;
    this.displayMessage = '';
  }

  resetPassword(){
    // console.log('Resetting password')
    this.reset.submitted = true;
    var url = this.url + '/login/reset'
      this.resetForm.patchValue({ip_address: this.ipAddress});
      if(this.resetForm.valid){
        this.http.post(
          url,
          JSON.stringify(this.resetForm.value),
          { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          responseType: 'json' }
        ).subscribe(data => {
          // console.log(data)
          // Use User Service to get and store user data
          this.toEmail = this.resetForm.controls['email'].value;
          this.reset.submitted = false
          this.reset.complete = true

          },
          err => {
            this.errorCount++
            this.displayMessage = 'Invalid Email'
            this.reset.submitted = false
            this.reset.error = true;
          }
        );
      }
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }
  get resetFormControl() {
    return this.resetForm.controls;
  }
}
