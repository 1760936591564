// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,

  //url: 'http://ec2-52-37-35-99.us-west-2.compute.amazonaws.com:8008/backend',
  socket_url: 'https://dev.carvedata.io',
  env: 'dev',
  url: 'https://dev.carvedata.io/backend',
  query_svc_url: 'https://dev.carvedata.io/query_svc'
   // socket_url: 'https://demo.carvedata.io/socket.io'
};
