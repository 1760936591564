import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class AutomateService {
  private disablingJob$ = new ReplaySubject<any>(1);
  private disablingJobStep$ = new ReplaySubject<any>(1);
  constructor() { }

  setDisableJob(job){
    this.disablingJob$.next(job)
  }

  getDisableJob(): Observable<any> {
        return this.disablingJob$.asObservable();
  }

  setDisableJobStep(step){
    this.disablingJobStep$.next(step)
  }

  getDisableJobStep(): Observable<any> {
       return this.disablingJobStep$.asObservable();
  }
  clear(){
    this.disablingJob$.complete();
    this.disablingJobStep$.complete();
  }
}
