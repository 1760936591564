<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">
			<div style="padding-bottom: 5px;color: black; font-size: 18pt; font-weight: bold;"><i class="fa-thin fa-rectangle-code" style="margin-right: 5px;"></i> Code Packages</div>
			<p class="text-muted">Build custom code packages.</p>

		    <div class="card mb-4 shadow-hover">
	            	<ngx-loading-bar fixed [includeSpinner]="false" color="#3dabab" ref="packageLoad"></ngx-loading-bar>
	            	<div class="card-header" style="padding-right: 20px; padding-left: 20px;">
	            		<div class="float-left">
							 <label class="nav-item navbar-text navbar-search-box p-0 active">
								<i class="ion ion-ios-search navbar-icon align-middle"></i>
						          <span class="navbar-search-input pl-2" style="width: 400px !important">
						            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important; margin-left: 20px !important;">
						          </span>
						          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
						        </label>
						</div>
						
						<div class="float-right"  style="padding-right: 10px">
							  <button class="btn btn-outline-secondary" type="button" style="margin-right: 15px;" (click)="variableTemplate();">
						            <i class="fal fa-memo-pad" style="padding-right: 5px;"></i>Global Configurations
						      </button>
				              <button class="btn btn-outline-primary" type="button" (click)="newPackage();">
						            <i class="fal fa-box-check" style="padding-right: 5px;"></i> New Package 
						      </button>
			      		</div>
		            </div>
	            	<div class="card-body overview-card" >
						<div style="width: 100%; height: 100%;">
							<p-table 
									#packageTable
									styleClass="p-datatable-sm"
									[value]="packages"
									sortMode="single"
								  	[paginator]="false" 
	        						responsiveLayout="scroll"
							 >
							
						        <ng-template pTemplate="header" style="font-size:9pt;">
						            <tr>
						                <th pSortableColumn="name">Package Name<p-sortIcon field="name"></p-sortIcon></th>
						                <th pSortableColumn="language">
						                	Language<p-sortIcon field="language"></p-sortIcon>
						               	</th>
						                <th >Execution File</th>
						                <th >Execution Function</th>
						                <th>Repository</th>
						                <th>Branch</th>
						                <th pSortableColumn="size">Compute Size<p-sortIcon field="size"></p-sortIcon></th>
						                <th pSortableColumn="timeout">Timeout Minutes</th>
						                <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
						               
						            </tr>
						        </ng-template>
						        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-package>
						            <tr style="text-align: left;" class="hover clickable-row" (click)="loadPackage(package)">
						                <td>{{package.package_name}}</td>
						                <td>
						                	<img *ngIf="package.package_language == 'Python'" src="./assets/img/python-transparent.png" style="margin-right: 10px; height: 18px; width: 18px;"/>
						                	<img *ngIf="package.package_language == 'DBT'" src="./assets/img/dbt-orange.png" style="margin-right: 10px; height: 18px; width: 18px;"/>
						                	<span>{{package.package_language}}</span>
						                	<span *ngIf="package.package_language == 'Python'" style="padding-left: 2px;">{{package.package_language_version}}</span>
						                	<span *ngIf="package.package_language == 'DBT'" style="padding-left: 2px;">(Python {{package.package_language_version}})</span>
						                </td>
						                <td>{{package.package_file_name_path}}</td>
						                <td>{{package.package_function_name}}</td>
						                <td>{{package.package_repository_name}}</td>
						                <td>{{package.package_repository_branch_name}}</td>
						                <td>{{package.package_size}}</td>
						                <td>{{package.package_timeout_minutes}}</td>
						                <td style="font-weight: bold; color: green" *ngIf="package.package_status == 'READY'">{{package.package_status}}</td>
						                <td style="font-weight: bold; color: red" *ngIf="package.package_status == 'FAILED'">{{package.package_status}}</td>
						                <td style="font-weight: bold;color: #005aa4" *ngIf="package.package_status == 'PROVISIONING'">{{package.package_status}}</td>
						            </tr>
						        </ng-template>
							</p-table>
						</div>
					</div>
			</div>
		</div>
	</div>
</div>
