
<a
  [routerLink]="route"
  [queryParams]="queryParams"
  [fragment]="fragment"
  [queryParamsHandling]="queryParamsHandling"
  [preserveFragment]="preserveFragment"
  [skipLocationChange]="skipLocationChange"
  [replaceUrl]="replaceUrl"
  class="sidenav-link"
  [ngClass]="linkClass"
  >
  <i class="sidenav-icon" *ngIf="icon" [ngClass]="icon"></i>
  <div [placement]="'right'" [ngbTooltip]="tooltipText"  data-container="body">
    <ng-content></ng-content>
  </div>
  <div *ngIf="badge" class="ml-auto pl-1"><div class="badge" [ngClass]="badgeClass">{{badge}}</div></div>
</a>
  