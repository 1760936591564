import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { TablesService } from '../tables.service';
import { Subscription } from 'rxjs/Subscription';
declare var d3: any;
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api/api.service';




@Component({
  selector: 'app-table-lineage',
  //encapsulation: ViewEncapsulation.None,
  templateUrl: './table-lineage.component.html',
  styles: []
})
export class TableLineageComponent implements OnInit {

    databaseName = ''
    schemaName = ''
    tableName = ''
    objectType = ''
 
    gettingChart = true;
    tableSubscription: Subscription;
    treeData: any = {'children': []};
    url: string = environment.url;
    loading = true;
    @ViewChild('lineageScreen') elementView: ElementRef;
    viewHeight: number;
    pageName = 'Lineage';
    showOverview = true;

  constructor(
    public http: HttpClient,
    public route: ActivatedRoute,
    public auth: AuthService,
    public tables: TablesService,
    private appService: AppService,
    private api: ApiService
    ) { 
      this.appService.pageTitle = 'Table Lineage';
  }

  ngOnInit() {
    
        var tableObj = this.tables.getTableObj()
        this.databaseName = tableObj['database']
        this.schemaName = tableObj['schema']
        this.tableName = tableObj['table']
        this.objectType = tableObj['object_type']

        this.clearChartDivs()
        this.getData();

        this.tableSubscription = this.tables.getTableObject().subscribe(
            data=>{
                if( this.tables.view == 'lineage'){
                   
                    let case1 = false
                    if(this.databaseName != data['database']){
                        case1 = true
                    }
                    let case2 = false
                    if(this.schemaName != data['schema']){
                        case1 = true
                    }
                    let case3 = false
                    if(this.tableName != data['table']){
                        case1 = true
                    }

                  

                    if(case1 || case2 || case3){
               
                        this.clearChartDivs()
                        this.databaseName = data['database']
                        this.schemaName = data['schema']
                        this.tableName = data['table']
                        this.objectType = tableObj['object_type']
                        this.gettingChart = true;
                        
                        this.getData();
                       
                    }
                    
                }
            }
        );
    }
    ngOnDestroy(){
        if(this.tableSubscription != undefined){
            
                this.tableSubscription.unsubscribe();
        }
    }

    getData(){
        this.loading = true;
        
        let lineageUrl = this.url + '/lineage'
        let exactPageName = this.pageName + '_' + this.databaseName + '_' + this.schemaName + '_' + this.tableName
        let params = [{key: "database", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName},{key: "days", value: '180'}]
        
        this.api.getData(lineageUrl, exactPageName, 'lineage_details', {api: true, params: params}).subscribe(
            data=>{
			     this.loading = false
                   //this.tables.storeSummary(data['message'][0])
                   this.treeData = data['message'] 
                   if (this.treeData['children'].length > 0){
                        this.draw(this.treeData);
                   }
            }
        );
        
        
        /* let params: HttpParams = new HttpParams();
        params = params.append('database', this.databaseName)
        params = params.append('schema', this.schemaName)
        params = params.append('table', this.tableName)
        params = params.append('days', '90')
        console.log(params)
     
        
        
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', 'SGToken ' + this.auth.getToken());

        
        let tableObj = {
          database: this.databaseName,
          schema: this.schemaName,
          table: this.tableName
        }
       
        // this.tables.storeGraphTable(tableObj)
        this.http.get(lineageUrl, {headers, params}).subscribe(
            data=>{ 
              this.loading = false
               //this.tables.storeSummary(data['message'][0])
               this.treeData = data['message'] 
               if (this.treeData['children'].length > 0){
                    this.draw(this.treeData);
               }
               else{
                   console.log('no lineage data')
               }
               console.log('finsihed getting lineage: ', this.treeData)
            }
        ) */
    }

    clearChartDivs(){
      if(document.querySelector('chart-svg') !== null){
         document.querySelector('chart-svg').innerHTML = "";
      }
      if(document.querySelector('chart-div') !== null){
         document.querySelector('chart-div').innerHTML = "";
      }
   }

  	// var treeData =
   //      {
   //          "name": "SNOWTRACK.TABLE_HISTORY",
   //          "value": 75,
   //          "male": 51,
   //          "female": 24,
   //          "icon": "../../../../assets/img/snowguide_black.png",
   //          "children":[
   //              {
   //              "children": [],
   //              "days": 365,
   //              "depth": 1,
   //              "direction": "right",
   //              "name": "TEMP.CATALOG_NEW_TABLES_MAX_DATE",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "icon": "../../../../assets/img/snowguide_gray.png",
   //              "type": "INSERT",
   //              "value": 25
   //          },
   //              {
   //              "children": [
   //                  {
   //                      "children": [
   //                      {
   //                          "children": [
   //                              {
   //                                  "children": [],
   //                                  "days": 365,
   //                                  "depth": 2,
   //                                  "name": "TEMP.CATALOG_NEW_TABLES_MAX_DATE2",
   //                                  "type": "CREATE_TABLE_AS_SELECT",
   //                                  "parent": "TEMP.CATALOG_NEW_TABLES2",
   //                                  "icon": "../../../../assets/img/snowguide_white.png",
   //                                  "value": 25
   //                              }
   //                          ],
   //                          "days": 365,
   //                          "depth": 1,
   //                          "icon": "../../../../assets/img/snowguide_white.png",
   //                          "direction": "left",
   //                          "name": "TEMP.CATALOG_NEW_TABLES2",
   //                          "parent": "SNOWTRACK.TABLE_HISTORY",
   //                          "type": "CREATE_TABLE_AS_SELECT",
   //                          "value": 25
   //                      },
   //                      {
   //                          "children": [],
   //                          "days": 365,
   //                          "depth": 2,
   //                          "name": "APP.SNOWGUIDE_FUNCTION_CAT_COLLIST_DBNAMEHERE2",
   //                          "type": "INSERT",
   //                          "parent": "TEMP.CATALOG_NEW_TABLES",
   //                          "direction": "left",
   //                          "value": 5
   //                      }
   //                                  ],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "TEMP.CATALOG_NEW_TABLES_MAX_DATE2",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_NEW_TABLES",
   //                      "direction": "left",
   //                      "value": 25
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "direction": "left",
   //              "name": "TEMP.CATALOG_NEW_TABLES",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "CREATE_TABLE_AS_SELECT",
   //              "value": 25
   //          },
   //          {
   //              "children": [
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.SNOWGUIDE_FUNCTION_CAT_COLLIST_DBNAMEHERE",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 5
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 17
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_CLIENT_1_DB",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 26
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_DAVES_TEST",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 22
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_DAVES_TEST_DATABASE",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 22
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_DEMO_DB",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 22
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_GYDE_DEV",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 22
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_SNOWGUIDE",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 1
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_test_DatAbase",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 20
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.snowguide_function_cat_tablist_1_UTIL_DB",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 19
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "APP.SNOWGUIDE_FUNCTION_CAT_TABLIST_DBNAME",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_TABLES",
   //                      "direction": "right",
   //                      "value": 6
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "direction": "right",
   //              "name": "TEMP.CATALOG_TABLES",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "UPDATE",
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "value": 51
   //          },
   //          {
   //              "children": [
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "SNOWTRACK.TABLE_HISTORY",
   //                      "icon": "../../../../assets/img/snowguide_black.png",
   //                      "type": "INSERT",
   //                      "parent": "TEMP.CATALOG_NEW_TABLES_MAX_DATE",
   //                      "direction": "left",
   //                      "value": 25
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "direction": "left",
   //              "name": "TEMP.CATALOG_NEW_TABLES_MAX_DATE",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "CREATE_TABLE_AS_SELECT",
   //              "value": 25
   //          },
   //          {
   //              "children": [
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "SNOWTRACK.TABLE_HISTORY",
   //                      "icon": "../../../../assets/img/snowguide_black.png",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES",
   //                      "direction": "right",
   //                      "value": 26
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "TEMP.CATALOG_TABLES",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES",
   //                      "direction": "right",
   //                      "value": 26
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "direction": "right",
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "name": "TEMP.CATALOG_UPDATED_TABLES",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "UPDATE",
   //              "value": 50
   //          },
   //          {
   //              "children": [
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "SNOWTRACK.TABLE_HISTORY",
   //                      "icon": "../../../../assets/img/snowguide_black.png",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES",
   //                      "type": "UPDATE",
   //                      "direction": "left",
   //                      "value": 50
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "TEMP.CATALOG_UPDATED_TABLES_MAX_DATE",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES",
   //                      "direction": "left",
   //                      "value": 25
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "direction": "left",
   //              "name": "TEMP.CATALOG_UPDATED_TABLES",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "CREATE_TABLE_AS_SELECT",
   //              "value": 26
   //          },
   //          {
   //              "children": [
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "SNOWTRACK.TABLE_HISTORY",
   //                      "icon": "../../../../assets/img/snowguide_black.png",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES_MAX_DATE",
   //                      "direction": "right",
   //                      "value": 25
   //                  },
   //                  {
   //                      "children": [],
   //                      "days": 365,
   //                      "depth": 2,
   //                      "name": "TEMP.CATALOG_UPDATED_TABLES",
   //                      "type": "CREATE_TABLE_AS_SELECT",
   //                      "parent": "TEMP.CATALOG_UPDATED_TABLES_MAX_DATE",
   //                      "direction": "right",
   //                      "value": 25
   //                  }
   //              ],
   //              "days": 365,
   //              "depth": 1,
   //              "icon": "../../../../assets/img/snowguide_white.png",
   //              "direction": "right",
   //              "name": "TEMP.CATALOG_UPDATED_TABLES_MAX_DATE",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "INSERT",
   //              "value": 25
   //          },
   //          {
   //              "children": [],
   //              "days": 365,
   //              "depth": 1,
   //              "direction": "left",
   //              "name": "TEMP.CATALOG_UPDATED_TABLES_MAX_DATE",
   //              "parent": "SNOWTRACK.TABLE_HISTORY",
   //              "type": "CREATE_TABLE_AS_SELECT",
   //              "value": 25
   //          }
   //          ]};

           




  


  draw(treeData){

    let height = this.elementView.nativeElement.offsetHeight;
    let width = this.elementView.nativeElement.offsetWidth;
 

  	// Set the dimensions and margins of the diagram
    let margin = {top: -175, right: 15, bottom: 15, left: -30};
        

    let colorScale = d3.scaleLinear()
        .domain([0, 1])
        .range(['red', 'green']);
    let widthScale = d3.scaleLinear()
        .domain([1,80])
        .range([1, 10]);

    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    //let tooltip = d3.select("body")
        //.append("div")
        //.attr("class", "tooltip")//add the tooltip class
        //.style("position", "absolute")
        //.style("z-index", "10")
        //.style("visibility", "hidden");
        //.style("opacity", 0);
    let div = d3.select(".chart-div")
        .attr("class", "tooltip")
        .style("opacity", 0);


  
    let svgroot = d3.select(".chart-svg");

    let svg = svgroot.attr("width", width)
        .attr("height", height).call(d3.zoom().on("zoom", function () {
            svg.attr("transform", d3.event.transform)
        }))
        .append("g")
        .attr("transform", "translate("
            + margin.left + "," + margin.top + ")");
    svgroot.append('defs')
        .append('pattern')
        .attr('id', "parent")
        .attr('width', 1)
        .attr('height', 1)
        .attr('patternContentUnits', 'objectBoundingBox')
        .append("image")
        .attr("xlink:href", "../../../../assets/img/snowguide_white.png")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 1)
        .attr("height", 1)
        .attr("preserveAspectRatio", "xMinYMin slice")
        .append('style')
        .attr('type', 'text/css')
        .text("@import url('https://fonts.googleapis.com/css?family=Alatsi&display=swap');");
    svgroot.append('defs')
        .append('pattern')
        .attr('id', "child")
        .attr('width', 1)
        .attr('height', 1)
        .attr('patternContentUnits', 'objectBoundingBox')
        .append("image")
        .attr("xlink:href", "../../../../assets/img/snowguide_gray.png")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 1)
        .attr("height", 1)
        .attr("preserveAspectRatio", "xMinYMin slice");
    svgroot.append('div')
    .html('This is the div')
    .style('border', '1px solid black')
    .style('height: 100px')

    let i = 0,
        duration = 750,
        root;

    // declares a tree layout and assigns the size
    let treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    root = d3.hierarchy(treeData, function(d) { return d.children; });
    root.x0 = height / 2;
    root.y0 = width / 2;



    // Collapse after the root level
    root.children.forEach(function(d){
        if(d.data.direction == "left"){
            d.depth = d.depth * (-1);
        }
    });
    collapse(root);
    update(root);

    // Collapse the node and all it's children
    function collapse(d) {
        if(d.parent&&d.parent.depth < 0) {
            d.depth = d.depth * (-1);
        }
        if(d.children) {
            d._children = d.children;
            d._children.forEach(collapse);
            d.children = null;
        }
    }

    function update(source) {

        // Assigns the x and y position for the nodes
        let treeData = treemap(root);
        // Compute the new tree layout.
        let nodes = treeData.descendants(),
            links = treeData.descendants().slice(1);

        // Normalize for fixed-depth.
        nodes.forEach(function(d){
            d.y = d.depth * 300 + width / 2;
        });
        
        let TEXT_NO_NODE_CONST = "middle";
        let TEXT_YES_NODE_CONST = "middle";
        

        // ****************** Nodes section ***************************

        // Update the nodes...
        let node = svg.selectAll('g.node')
            .data(nodes, function(d) {return d.id || (d.id = ++i); });

        // Enter any new modes at the parent's previous position.
        let nodeEnter = node.enter().append('g')
            .attr('class', 'node')
            .attr("transform", function(d) {
                return "translate(" + source.y0 + "," + source.x0 + ")";
            })
            .on('click', click)
            //.on('mouseover', mouseover)
            .on('mouseout', mouseout);
        nodeEnter.append('defs')
            .append('pattern')
            .attr('id', function(d) { return (d.id+"-icon");})
            .attr('width', 1)
            .attr('height', 1)
            .attr('patternContentUnits', 'objectBoundingBox')
            .append("image")
            .attr("xlink:href", function(d) { return  d.data.icon; })
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", 1)
            .attr("height", 1)
            .attr("preserveAspectRatio", "xMinYMin slice");

        // Add Circle for the nodes
        nodeEnter.append('circle')
            .attr('class', 'node')
            .attr('r', 1e-6)
            .style("fill", function(d) {
                return  d.data.icon? ("url(#"+d.id+"-icon)") : d.children || d._children ?  "url(#parent)" : "url(#child)" ;
                //return  "url(#"+d.id+"-icon)"//) : d._children ?  "url(#parent)" : "url(#child)" ;
            })
            //.style("stroke", function(d){return colorScale(d.data.female/(d.data.female + d.data.male))});

        // Add labels for the nodes
        nodeEnter.append('text')
            .attr("dy", "2.5em")
            //.attr("x", function(d) {
             //   let multiplier = -1
               // if(d.parent&&d.parent.depth < 0) {
                //    multiplier = 1
                //}
                //return d.children || d._children ? (23 * multiplier) : -(23 * multiplier);
            //})
            .attr("text-anchor", function(d) {
                if(d.parent&&d.parent.depth < 0) {
                    TEXT_NO_NODE_CONST = "middle";
                    TEXT_YES_NODE_CONST = "middle";
                }
                return d.children || d._children ? TEXT_NO_NODE_CONST : TEXT_YES_NODE_CONST;
            })
            .text(function(d) { return d.data.name; })
            .style("fill", function(d){return colorScale(d.data.female/(d.data.value))});

        // UPDATE
        let nodeUpdate = nodeEnter.merge(node);

        // Transition to the proper position for the node
        nodeUpdate.transition()
            .duration(duration)
            .attr("transform", function(d) {
                return "translate(" + d.y + "," + d.x + ")";
            });

        // Update the node attributes and style
        nodeUpdate.select('circle.node')
            .attr('r', 18)
            .style("fill", function(d) {
                return  d.data.icon? ("url(#"+d.id+"-icon)") : d.children || d._children ?  "url(#parent)" : "url(#child)" ;
                //return  ("url(#"+d.id+"-icon)")// : d._children ?  "url(#parent)" : "url(#child)" ;
            })
            .attr('cursor', function(d) {
                return d._children ? 'pointer' : 'default';
            });


        // Remove any exiting nodes
        let nodeExit = node.exit().transition()
            .duration(duration)
            .attr("transform", function(d) {
                return "translate(" + source.y + "," + source.x + ")";
            })
            .remove();

        // On exit reduce the node circles size to 0
        nodeExit.select('circle')
            .attr('r', 1e-6);

        // On exit reduce the opacity of text labels
        nodeExit.select('text')
            .style('fill-opacity', 1e-6);

        // ****************** links section ***************************

        // Update the links...
        let link = svg.selectAll('path.link')
            .data(links, function(d) { return d.id; })
            .style('stroke-width', function(d){
                return widthScale(d.data.value)
            });
            
        

        // Enter any new links at the parent's previous position.
        let linkEnter = link.enter().insert('path', "g")
            .attr("class", "link")
            .attr('d', function(d){
                let o = {x: source.x0, y: source.y0}
                return diagonal(o, o)
            })
            .style('stroke-width', function(d){
                return widthScale(d.data.value)
            })
            .on('mouseover',function(d) {
                let fromtext = (d.data.direction == "left") ? d.data.name : d.data.parent;
                let totext = (d.data.direction == "left") ? d.data.parent : d.data.name;
                //var mydirection = d.data.direction;
                
                //if (mydirection = "left") {
                //    fromtext = d.data.name;
                //    totext = d.data.parent;
                //}
                
                div.transition()
                    .duration(200)
                    .style("opacity", .9);
                    //.attr("data-html", "true")
                div .html('<a href="http://www.google.com" target="_blank" >' +  d.data.type  + '</a><div align="left"><b>From:</b> ' + fromtext + '<br><b>To:</b> ' + totext + '<br><b>Total Queries:</b> ' + d.data.value + '</div>')
                    .style("left", (d3.event.pageX) + "px")             
                    .style("top", (d3.event.pageY - 28) + "px");
                d3.select(this).style('stroke', function(d){
                    return colorScale(d.data.female/(d.data.female + d.data.male))
                });
                //div.transition()
                    //.duration(500)
                    //.style("opacity", 0);
                //tooltip.style("visibility", "visible")
                    //.attr("data-html", "true")
                    //.duration(200)
                    //.html(d.data.type + ' <br>From: ' + d.data.name + '<br>To: ' + d.data.parent);
                    //d.children || d._children ? (23 * multiplier) : -(23 *
            })
            //.on('mousemove', function(){
                //return tooltip.style("top", (d3.event.pageY - 40) + "px").style("left", (d3.event.pageX - 130) + "px");
            //})
            //.on('mouseout',function() {
                //d3.select(this).style('stroke', '#ccc');
                //return tooltip.style("visibility", "hidden");
            .on('mouseout', function() {
              d3.select(this).style('stroke', '#ccc');
              d3.select(".tooltip")
                .transition()
                .delay(2000)
                .duration(400)
                .style("opacity",0)
                //.style('pointer-events', 'none')
              });
            //});

        // UPDATE
        let linkUpdate = linkEnter.merge(link);

        // Transition back to the parent element position
        linkUpdate.transition()
            .duration(duration)
            .attr('d', function(d){ return diagonal(d, d.parent) });

        // Remove any exiting links
        let linkExit = link.exit().transition()
            .duration(duration)
            .attr('d', function(d) {
                let o = {x: source.x, y: source.y}
                return diagonal(o, o)
            })
            .style('stroke-width', function(d){
                return widthScale(d.data.value)
            })
            .remove();

        // Store the old positions for transition.
        nodes.forEach(function(d){
            d.x0 = d.x;
            d.y0 = d.y;
        });

        // Creates a curved (diagonal) path from parent to the child nodes
        function diagonal(s, d) {

            let path = `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`

            return path
        }

        // Toggle children on click.
        function click(d) {
            if (d.children) {
                d._children = d.children;
                d.children = null;
            } else {
                d.children = d._children;
                d._children = null;
            }
            update(d);
        }
        function mouseover(d) {
            d3.select(this)
                // .style("stroke", function(d){return colorScale(d.data.female/(d.data.female + d.data.male))})
                .append("text")
                .attr("class", "hover")
                .attr('transform', function(d){
                    return 'translate(18, -10)';
                })
                .text(d.data.type);
        }0

        function mouseout(d) {
            d3.select(this).select("text.hover").remove();
        }
    }


  }

}
