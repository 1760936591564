
<div class="layout-container" style="background-color: white;">
	<div class="layout-content" style="justify-content: normal;">
			<div class="flex-grow-1">
			<!-- Main Row -->
			<div class="row editor-page-wrapper" #editorWrapper style="flex-wrap: nowrap !important; margin: 0px; padding-left: 25px;">
				<!-- Connections -->
				
				<div class="col-lg-12" style="padding-right: 0px; padding-left: 0px;">
		
					<div class="row" style="padding-top: 20px;  margin-right: 0px; margin-bottom: 20px; flex-wrap: nowrap !important; padding-left: 15px;">
						<div class="col-4" style="padding-left: 0px">
							<div ngbDropdown #connDropDown="ngbDropdown" class="d-inline-flex" style="margin-right: 10px;">
								<button class="btn btn-sm btn-default" style="font-weight: bold" id="dropdownConn" ngbDropdownToggle [ngClass]="{'pulse red': selectedConn.id == 0 && !connDropDown.isOpen()}">
						        	<i *ngIf="selectedConn.username == 'Select Connection' && !connecting" class="fas fa-power-off" style="margin-right: 10px;color: #b72e2e"></i>
						        	<i *ngIf="selectedConn.username != 'Select Connection' && !connecting" class="fas fa-power-off" style="margin-right: 10px;color: #538e6c"></i>
						        	<i *ngIf="connecting? true : false" style="margin-right: 10px" class="spinner-border spinner-border-sm text-primary" role="status"></i>
						        	{{selectedConn.username}}
						        	
						        </button>

						        <div ngbDropdownMenu  aria-labelledby="dropdownConn" style="width: 275px">
						        	<div *ngFor="let conn of connections">
							          <button ngbDropdownItem (click)="setConn(conn)" style="width: 200px; float: left; margin-right:0px">{{conn.username}}
							          </button>
							          <div style="display: inline; padding: 0.438rem 0rem;">
							          	<i class="fal fa-edit" 
							          	(click)="openEditConnModal(editConnectionModal, { windowClass: 'modal animate', centered: true }, conn)"
							          	style="margin-right: 10px;padding: 15px 5px 5px; cursor: pointer;"></i>
							          	<i class="fal fa-trash-alt" 
							          	(click)="openDeleteConnModal(deleteConnectionModal, { windowClass: 'modal animate', centered: true }, conn)"
							          	style="padding: 15px 5px 5px; cursor: pointer;"></i>
							          </div>
							         
							          <div class="dropdown-divider"></div>
							        </div>
						          <button ngbDropdownItem (click)="openConnModal(newConnectionModal, { windowClass: 'modal animate', centered: true })" ><i class="fas fa-plug" style="margin-right: 10px;"></i> New Connection</button>
						        </div>
						    </div>
						    <div ngbDropdown #roleDropDown="ngbDropdown" class="d-inline-flex">
						        <button class="btn btn-sm btn-default" style="font-weight: bold" id="dropdownSchema" ngbDropdownToggle [disabled]="roles.length == 0" [ngClass]="{'pulse red':selectedRole == 'Select Role' && roles.length > 0 && !roleDropDown.isOpen()}">
						        	<i class="fal fa-user" style="margin-right: 10px;color: #4e5155;"></i>
						        	{{selectedRole}}</button>
						        <div ngbDropdownMenu aria-labelledby="dropdownSchema">
						          <button ngbDropdownItem *ngFor="let role of roles" (click)="setRole(role)">{{role}}</button>
						        </div>
						    </div>
						</div>
						<div class="col-8">
							<div class="float-right">
								<div ngbDropdown #whDropDown="ngbDropdown" class="d-inline-flex" style="margin-right: 10px;">
							        <button class="btn btn-sm btn-default" style="font-weight: bold" id="dropdownWH" ngbDropdownToggle [disabled]="warehouses.length == 0" [ngClass]="{'pulse red':selectedWarehouse == 'Select Warehouse' && warehouses.length > 0 && !whDropDown.isOpen()}">
							        	<i class="fal fa-server" style="margin-right: 10px; color: #4e5155"></i>
							        	{{selectedWarehouse}}</button>
							        <div ngbDropdownMenu aria-labelledby="dropdownWH">
							          <button ngbDropdownItem *ngFor="let wh of warehouses" (click)="setWH(wh)">{{wh}}</button>
							        </div>
							     </div>
							     <!-- Database and Schema dropdown buttons -->
								<div ngbDropdown #dbDropDown="ngbDropdown" class="d-inline-flex" style="margin-right: 10px;" >
							        <button class="btn btn-sm btn-default" style="font-weight: bold" id="dropdownDB" ngbDropdownToggle [disabled]="databases.length == 0" [ngClass]="{'pulse red':selectedDatabase == 'Select Database' && databases.length > 0 && !dbDropDown.isOpen()}">
							        	<i class="fal fa-database" style="margin-right: 10px; color: #4e5155"></i>
							        	{{selectedDatabase}}</button>
							        <div ngbDropdownMenu aria-labelledby="dropdownDB">
							          <button ngbDropdownItem *ngFor="let db of databases" (click)="setDb(db)">{{db}}</button>
							        </div>
							     </div>
							     <div ngbDropdown #schemaDropDown="ngbDropdown" class="d-inline-flex">
							        <button class="btn btn-sm btn-default" style="font-weight: bold" id="dropdownSchema" ngbDropdownToggle [disabled]="schemas.length == 0" [ngClass]="{'pulse red':selectedSchema == 'Select Schema' && schemas.length > 0 && !schemaDropDown.isOpen()}">
							        	<i class="fal fa-sitemap" style="margin-right: 10px;color: #4e5155;"></i>
							        	{{selectedSchema}}</button>
							        <div ngbDropdownMenu aria-labelledby="dropdownSchema">
							          <button ngbDropdownItem *ngFor="let schema of schemas" (click)="setSchema(schema)">{{schema}}</button>
							        </div>
							    </div>
							</div>
						</div>
						<!-- <div class="col-2">
							<div class="align-self-end float-right">
							   <label class="switcher switcher-dark d-inline-flex">
						        <span class="switcher-label" style="font-size: 13px; color:gray">Dark Mode</span>
						        <input class="switcher-input" type="checkbox" (click)="toggleDarkMode()">
						        <span class="switcher-indicator">
						          <span class="switcher-no" >
						            <span class="ion ion-md-close"></span>
						          </span>
						          <span class="switcher-yes" >
						            <span class="ion ion-md-checkmark"></span>
						          </span>
						          
						        </span>
						      </label>
						  </div>
						</div> -->
					</div>
					<div class="row" style="margin-right: 0px; height: 23px;">
						<!-- File name tab -->
						<div class="col-9 d-flex justify-content-center" style="height: 24px;">
							<!-- On click open delete file modal -->
							<ng-container *ngIf="selectedFile">
								<div *ngIf="selectedFile.owned_query" class="text-right d-inline-flex file-control" style="margin-right: 5px" (click)="openDeleteFileModal(deleteFileModal, { windowClass: 'modal animate', centered: true }, selectedFile)">
									<i class="fas fa-minus fa-sm" style="padding-top: 5px;"></i>
								</div>
								
							</ng-container>
							<!-- <ng-container *ngIf="selectedFile">
								<div *ngIf="selectedFile.owned_query" class="text-right d-inline-flex file-control" style=" width: 25px">
								</div>
							</ng-container> -->
							
							<div [hidden]="!hasSelectedFile()" class="file-name-tab" [ngClass]="{'dark-mode': darkMode}">
								<div *ngIf="!fileNameEdit" style="padding-top: 1px;">
									<div *ngIf="!settingFile" class="dot" [ngClass]="{'blue-dot': selectedFile.owned_query, 'purple-dot': selectedFile.version_id > 0, 'skin-dot': selectedFile.query_type == 'doc'  }" style="display: inline-block;">
										
									</div>
									<div *ngIf="!settingFile && !deletingFile" (click)="editFileName()" style="display:  inline-block;">{{selectedFile.file_name}}</div>
									<ng-template #queryVersionsTitle>
										<div>
											<span style="padding-right: 11px;">Current version: </span>
											<span style="font-weight: normal;text-align: left; width: 120px; " > {{selectedFile.version_id}} (auto update)
											</span>
										</div>
										<div style="padding-top: 10px;">
											<span style="padding-right: 25px;">Last updated: </span> <span style="font-weight: normal; text-align: left; width: 120px;" >{{selectedFile.last_update_date | date: 'short'}}</span>
										</div>
										<div style="padding-top: 10px;">Other versions:</div>
									</ng-template>
									<ng-template #queryVersions >

										<ul style="list-style-type: none; padding: 0; margin: 0; font-size: 11pt" (click)="$event.stopPropagation()">
											<li *ngFor="let version of selectedFile['versions']" class="sql-file" style="padding-top:10px;" (click)=setQueryVersion(version); >
											
												<span>(v.{{version.version_id}})  </span>
												<span> {{version.last_update_date | date: 'short'}} </span>
												<span style="width: 120px"> - {{  (version.publish_comment.length>20)? (version.publish_comment | slice:0:20)+'...':(version.publish_comment)   }}</span>
												
												<!-- <span class="version-popover-icon" style="padding-right: 5px;" (click)="openQueryVersion=true; $event.stopPropagation()"><i class="fal fa-pen-square fa-lg"></i></span>
												<span class="version-popover-icon"><i class="fal fa-copy fa-lg"></i></span> -->
											</li>
										</ul>
										<div *ngIf="loadingVersions" style="margin: auto; width: 100%; text-align: center;" >
										 <div  class="spinner-border spinner-border-sm text-primary" role="status" style="margin: auto;">
									        <span  class="sr-only">Loading...</span>
									      </div>
									      Loading Versions...
									  	</div>
										<!-- <div *ngIf="openQueryVersion" style="border-top: 1px solid #ccc; color: red; padding-top: 15px;">
											<span>Are you sure you want to open this version? Any autosave progress will be lost.</span>
											<button (click)="setQueryVersion(version); openQueryVersion=false">Yes</button>
											<button>No</button>
										</div> -->
										<div *ngIf="getVersionCount(selectedFile) == 0 && !loadingVersions" style="padding-left: 15px;">Not published</div>
									</ng-template>
									<span *ngIf="!settingFile && !deletingFile"   style="color: #949494; cursor: pointer; padding-left: 10px;"
									#qVersions="ngbPopover"
									[ngbPopover]="queryVersions" 
									container="body" 
									placement="bottom"
									popoverClass="versions-popover" 
									[autoClose]="'true'"
									[popoverTitle]="queryVersionsTitle"
									><i class="fal fa-angle-down fa-lg"></i></span>
										
									
									<div *ngIf="settingFile || loadingFiles" style='padding-top: 3px; display: inline-block; color: gray; padding-left: 2px; font-family: Roboto, "Helvetica Neue", sans-serif'>
										<div  class="spinner-border spinner-border-sm text-primary" role="status" style="font-size: 9pt;margin-right: 7px;">
								        <span  class="sr-only">Loading...</span>
								      </div>
								       Loading File...
								    </div>
									<div *ngIf="autoSaving && !settingFile && !deletingFile" style='color: gray; padding-left: 2px; display:  inline-block;font-family: Roboto, "Helvetica Neue", sans-serif'>(Saving...)</div>
									<div *ngIf="deletingFile" style='color: gray; padding-left: 2px;display:  inline-block; font-family: Roboto, "Helvetica Neue", sans-serif'>(Deleting...)
									</div>
								</div>
								<div class="text-left d-inline-flex form-control-sm" style="padding:0px" [hidden]="!fileNameEdit">
									<form class="form-inline mb-4 ng-untouched ng-pristine ng-valid" novalidate="">
								      <label class="sr-only">Name</label>
								      <input 
								      #fileNameInput
								      (blur)="saveFileName()" 
								      (keydown.enter)="saveFileName()"
								      [(ngModel)]="newFileName" 
								      [value]="newFileName"
								      class="form-control mr-sm-2 mb-2 mb-sm-0" 
								      type="text" 
								      name="fileName"
								      [ngbTooltip]="tipContent"
								      #t="ngbTooltip"
								      [autoClose]="false"
								      triggers="manual"
								      style="padding:0px; margin-right: 0px !important; text-align: center; line-height: 2; height: 22px; font-size: small; font-weight: 700; font-family: Courier; border:none;">
								    </form>
								</div>
								<ng-template #tipContent><span style="color: white;">File already exists!</span></ng-template>
							</div>
							
							<!-- On click file name input -->
							<div *ngIf="loadingFiles" style='padding-top: 3px; display: inline-block; color: gray; padding-left: 2px; font-family: Roboto, "Helvetica Neue", sans-serif'>
								
								<div  class="spinner-border spinner-border-sm text-primary" style="display: inline-block;" role="status" style="margin-right: 7px;">
							        <span  class="sr-only">Loading...</span>
							    </div>
								Loading Files...
							</div>
							<div *ngIf="!loadingFiles && !settingFile" class="text-left d-inline-flex file-control"  (click)="newFile()" style="margin-left: 5px; padding-top: 5px;" [ngClass]="{'disabled-file': checkFileSettings()}">
								<i class="fas fa-plus fa-sm" style="padding-top: 7px;"></i>
								<div *ngIf="!hasSelectedFile()" style="color: gray;line-height: 10px; padding-left: 10px; padding-top: 2px; display: inline-block;">
									New File
								</div>	
							</div>
							
							
						</div>
					</div>
							<!-- 3 rows: Query Editor, Options Bar, Search Results Table -->
							<!-- Query Editor -->


						<as-split 
							direction="vertical" 
							[useTransition]="true" 
							[gutterDblClickDuration]="500" 
							(gutterDblClick)="gutterDblClick('row', $event)"
							(dragEnd)="dragEnd('row', $event)"
							style='border: 1px solid #ddd; border-radius: 5px;'

						>
				          <div as-split-area [size]="paneSizes.editorRow" [minSize]="paneSizes.editorRowMin" [order]="1">
				            <as-split 
				            	direction="horizontal"
				            	[useTransition]="true" 
								[gutterDblClickDuration]="500" 
								(gutterDblClick)="gutterDblClick('col', $event)" 
				            	#editorSplit>
				            	<!-- Editor -->
				              <div as-split-area [size]="paneSizes.editorCol" [order]="1" >
				              	<div  style="height: 99.9%">
						              	<codemirror 
									          #scriptcode
									          [(ngModel)]="selectedFile.code"
									          [config]="config"
									          (paste)="editorAutoSave('paste')"
									          (keyup.Enter)="editorAutoSave('enter')"
									          (keyup.Space)="editorAutoSave('space')"
									          (keyup.Backspace)="editorAutoSave('delete')"
									          (keyup.Tab)="editorAutoSave('tab')"
									          (keydown)="checkSubmit($event)"
									          (click)="allIsChecked=false; editorAutoSave('click')"
									          (blur)="editorAutoSave('blur')"
									        >
										</codemirror>
									</div>
				              </div>
				              <!-- Navigator -->
				              <div as-split-area [size]="paneSizes.navigatorCol" [maxSize]="paneSizes.navigatorColMax" [order]="2" style="background-color: white;" >
				              	<!-- [ngStyle]="{'width.px': paneSizes.navigtorWidthPx}" -->
				              	<div style="width: 100%">
					              	<div class="row" style="background-color: white; padding: 10px 0px; width: 100%;">
										<div class="col" style="font-size: 14pt; font-weight: 700; font-family: roboto; padding-top: 10px; margin-left: 15px;min-width: 160px;">
											<i class="fal fa-bars align-middle collapse-query-nav" (click)="toggleQueryNavigator()"></i>
											<span style="font-family: 'Open Sans', sans-serif;
											    font-size: 13pt;
											    font-weight: 200;" [hidden]="navIsToggled">SQL Files</span>
										</div>
										<div [hidden]="navIsToggled" class="col" style="padding-top: 5px; margin-left: 15px; width: 185px">
										      <!-- <div ngbDropdown 
										      	display="dynamic" 
										      	placement="bottom" 
										      	#searchResults="ngbDropdown"
										      >
										      <a ngbDropdownToggle style="display:none"></a> -->
										      	<label class="nav-item navbar-text navbar-search-box p-0 active" style="max-width: 120px">
										          <i class="fal fa-search navbar-icon align-middle" style="padding-right: 5px"></i>
										          <span class="navbar-search-input pl-2">
										            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic; max-width: 90px;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off">
										          </span>
										          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-right" (click)="clear()" style="cursor: pointer; padding-left: 10px"></i>
										        </label>
										        <!-- <div ngbDropdownMenu style="padding: 10px;width:400px; margin-top:25px">
										        	<ng-container *ngIf="search.length > 0 ? true : false">
											          	<div class="row flex-nowrap" *ngFor="let item of allScripts" >
															<div class="col" >
																<div [ngClass]="{'blue-dot': item.type == 'own', 'purple-dot': item.type == 'shared', 'skin-dot': item.type == 'doc'}"></div>
																<div class="sql-file" [ngClass]="{'file-active': item.file_name == selectedFile.file_name}" (click)="setFile(item)">
																	{{item.file_name}}
																	<span *ngIf="item.type =='shared'">(shared)</span>
																	<span *ngIf="item.type =='doc'">(doc)</span>
																</div>
																
															</div>
														</div>
														<div class="row flex-nowrap" *ngIf="allScripts.length == 0">
															No Results Found
														</div>
													</ng-container>
										        </div> -->
										      <!-- </div> -->
										</div>
									</div>
									<div class="row" *ngIf="search.length > 0 ? true : false" style="background-color: white;    margin-left: -0.78rem;">
										<div class="col" style="padding-left: 25px">
											<div class="row">
												<div class="col query-results-title" >Found {{allScripts.length}} queries</div>
											</div>
											<div style="flex-wrap: nowrap; padding-left:5px"  (scroll)="onScroll($event)">
												<div class="row flex-nowrap" *ngFor="let item of allScripts" >
													<div class="col" >
														<div [ngClass]="{'blue-dot': item.type == 'own', 'purple-dot': item.type == 'shared', 'skin-dot': item.type == 'doc'}"></div>
														<div class="sql-file" [ngClass]="{'file-active': item.file_name == selectedFile.file_name}" (click)="setFile(item)">{{item.file_name}} 
															<span *ngIf="item.type == 'shared'"> - shared</span>
															<span *ngIf="item.type == 'doc'"> - doc </span>
														</div>
													</div>
												</div>

											</div>
											<!-- <div class="row" style="padding-top:10px" *ngIf="allScripts.length > 5">
													<div class="col-12 align-middle text-center">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div> -->
										</div>
									</div>
									<!-- <div style="flex-wrap: nowrap; max-height: 105px;overflow-y: scroll;" (scroll)="onScroll($event)">
											    	
														
													</div>
												<div class="row" style="padding-top:10px" *ngIf="allScripts.length > 5">
													<div class="col-12 align-middle text-center">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div>
								  </div> -->
								<!-- Query File Sections -->
								  <div [hidden]="navIsToggled" class="row"  style="background-color: white;" >
									<!-- Query Sections: Mine, Shared, Doc -->
									<div class="col-12" style="overflow: hidden; white-space: nowrap;">
										<div *ngIf="loadingFiles" style="margin: auto; width: 100%; text-align: center;">
										 <div  class="spinner-border spinner-border-sm text-primary" role="status" style="margin: auto;">
									        <span  class="sr-only">Loading...</span>
									      </div>
									  	</div>
									 
										
										<ngb-accordion #queryAcc="ngbAccordion"  class="border-0" activeIds="two">
											<!-- <ngb-panel id="one">
											    <ng-template ngbPanelHeader let-opened="opened">
											    	<div class="d-flex align-items-center justify-content-between">
											        	<button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" style="color: #505050; font-weight: 700">ALL QUERIES <i class="fas fa-angle-down"></i></button>
											        </div>
											    </ng-template>
											    <ng-template ngbPanelContent>
										      		<div style="flex-wrap: nowrap; max-height: 105px;overflow-y: scroll;" (scroll)="onScroll($event)">
											    	
														<div class="row flex-nowrap" *ngFor="let item of allScripts" >
															<div class="col" >
																<div [ngClass]="{'blue-dot': item.type == 'own', 'purple-dot': item.type == 'shared', 'skin-dot': item.type == 'doc'}"></div>
																<div class="sql-file" [ngClass]="{'file-active': item.file_name == selectedFile.file_name}" (click)="setFile(item)">{{item.file_name}}</div>
															</div>
														</div>
													</div>
												<div class="row" style="padding-top:10px" *ngIf="allScripts.length > 5">
													<div class="col-12 align-middle text-center">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div>
											    </ng-template>
											</ngb-panel> -->
										  <ngb-panel id="two">
										    <ng-template ngbPanelHeader>
										    	<div class="d-flex align-items-center justify-content-between">
										        	<button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" style="color: #65abc5; font-weight: bold"><i class="far fa-file-code" style="padding-right: 7px;"></i>My Files ({{myScripts.length}}) <i class="fas fa-angle-down" style="padding-left: 7px;"></i></button>
										        </div>
										    </ng-template>
										    <ng-template ngbPanelContent>
									      		<div style="flex-wrap: nowrap; position: relative;" (scroll)="onScroll($event)">
										    	<!-- My list of querys -->
													<div class="row" *ngFor="let item of myScripts" >
														<div class="col" >
															<span class="dot blue-dot"></span>
															<span class="sql-file" [ngClass]="{'file-active': item.id == selectedFile.id}" (click)="setFile(item)">{{item.file_name}}</span>
															<span *ngIf="item.version_id > 0" class="fal fa-badge-check" style="padding-left: 5px;"></span>
														</div>
													</div>
													
												</div>
												<!-- <div class="row" style="padding-top:10px" *ngIf="myScripts.length > 5">
													<div class="col-12 align-middle text-center" style="padding-left: 0px;">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div> -->
												
										    </ng-template>
										  </ngb-panel>
										  <ngb-panel id="three">
										    <ng-template ngbPanelHeader>
										    	<div class="d-flex align-items-center justify-content-between">
										        	<button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" style="color: #4b4786; font-weight: bold;"><i class="far fa-badge-check" style="padding-right: 7px;"></i>Published Files ({{publishedScripts.length}}) <i class="fas fa-angle-down" style="padding-left: 7px;"></i></button>
										        </div>
										    </ng-template>
										    <ng-template ngbPanelContent>
									      		<div style="flex-wrap: nowrap;" (scroll)="onScroll($event)">
										    	<!-- My list of querys -->
													<div class="row" *ngFor="let item of publishedScripts" >
														<div class="col" >
															<span class="dot purple-dot"></span>
															<span class="sql-file" [ngClass]="{'file-active': item.id == selectedFile.id}" (click)="setFile(item)">{{item.file_name}}</span>
															<span *ngIf="item.owned_query"> (owner)</span>
														</div>
													</div>
												</div>
												<div class="row" style="padding-top:10px" *ngIf="publishedScripts.length > 5">
													<div class="col-12 align-middle text-center" style="padding-left: 0px;">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div>
										    </ng-template>
										  </ngb-panel>
										 <!--  <ngb-panel id="four">
										    <ng-template ngbPanelHeader>
										    	<div class="d-flex align-items-center justify-content-between">
										        	<button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" style="color: #bd7777; font-weight: 700;">DOCUMENTATION QUERIES ({{docScripts.length}}) <i class="fas fa-angle-down"></i></button>
										        </div>
										    </ng-template>
										    <ng-template ngbPanelContent>
									      		<div style="flex-wrap: nowrap;" (scroll)="onScroll($event)">
										    	 My list of querys -->
													<!-- <div class="row" *ngFor="let item of docScripts" >
														<div class="col" >
															<span class="dot skin-dot"></span>
															<span class="sql-file" [ngClass]="{'file-active': item.id == selectedFile.id}" (click)="setFile(item)">{{item.file_name}}</span>
														</div>
													</div>
												</div>
												<div class="row" style="padding-top:10px" *ngIf="docScripts.length > 5">
													<div class="col-12 align-middle text-center" style="padding-left: 0px;">
														<i *ngIf="direction == 'down'" class="fas fa-caret-down fa-lg text-center"  style="display: block; color:gray"></i>
	          											<i *ngIf="direction == 'up'" class="fas fa-caret-up fa-lg text-center"  style="display: block; color:gray"></i>
													</div>
												</div>
										    </ng-template>
										  </ngb-panel> --> 
										</ngb-accordion>
									</div>
								   </div>
								</div>
				              </div>
				            </as-split>
				          </div>
				          <!-- Control bar and results -->
				          <div as-split-area [size]="paneSizes.resultsRow" [minSize]="paneSizes.ResultsRowMin" [order]="2" style="    background-color: white; overflow: hidden;">
				          	
						          	<div #editorControl class="row editor-control-bar" [ngClass]="{'dark-mode' : darkMode}" style="padding: 0px 35px 0px 12px;">
										<div class="col-4 my-auto">
											 <button *ngIf="selectedConn.username != 'Select Connection' && !sqlRunning && !connecting" class="btn btn-primary btn-sg" type="button"    
											(click)="runQuery()" style="margin-right: 8px;">
									            <span class="fal fa-play" style="margin-left: 3px;margin-right: 10px;"></span>RUN
									          </button>
									          <button *ngIf="selectedConn.username != 'Select Connection' && sqlRunning" class="btn btn-primary btn-sg" type="button"    
											(click)="stopQuery()" style="margin-right: 8px; background-color: #c35555">
									            <span class="fal fa-stop" style="margin-left: 3px;margin-right: 10px;"></span>STOP
									          </button>
									          <button *ngIf="selectedConn.username == 'Select Connection' && !connecting" class="btn btn-primary btn-sg" type="button" [disabled]="true"  
													 style="margin-right: 8px; background-color: gray !important;">
									            <span class="fal fa-play" style="margin-left: 3px;margin-right: 10px;"></span>DISCONNECTED
									          </button>
									          <button *ngIf="connecting" class="btn btn-primary btn-sg" type="button" [disabled]="true"  
													 style="margin-right: 8px; background-color: gray !important;">
									            <span class="fal fa-play" style="margin-left: 3px;margin-right: 10px;"></span>CONNECTING...
									          </button>
									          <label class="form-check form-check-inline">
										        <input class="form-check-input" type="checkbox" value="option1" [(ngModel)]="allIsChecked" (change)="RunAllCheckbox($event)">
										        <span *ngIf="!allIsChecked" class="form-check-label">
										          All
										        </span>
										        <span *ngIf="allIsChecked" class="form-check-label">
										          All ({{queryCount}})
										        </span>
										      </label>
										      <label class="form-check form-check-inline" style="margin-right: 0px;">
										        <input class="form-check-input" type="checkbox" value="option1" [(ngModel)]="profileIsChecked">
										        <span class="form-check-label">
										          Profile
										        </span>
										      </label>
										      <span class="fal fa-info-circle fa-xs" style="vertical-align: top; padding-top: .25rem; cursor: pointer;"
										        [ngbPopover]="profileInfo" 
						                        container="body" 
						                        popoverClass="download-popover" 
						                        [autoClose]="'outside'"
						                        placement="top"
										      >
										      </span>

							 			<ng-template #profileInfo>
							                <div style="padding:10px 20px; font-size: 11pt;">
							                  <div style="font-weight:bold; padding-bottom: 10px;">Profile SELECT query results</div>            
							                  <div >
							                   <p>Profiling your query results may increase query execution time by several seconds. Selecting this option will not impact published queries as profiling will be skipped. </p>
							                  </div> 
							                </div>
							             </ng-template>
										      
										</div>
										<div class="col-8 my-auto align-self-end text-right">
											<!-- <span style="font-weight: 700; font-size: small;margin-right: 5px">CURRENT SCHEDULE:</span>
											<span style="font-size:small; margin-right: 20px">{{expressionToFriendly(selectedFile.og_schedule)}}</span>
										
											<button (click)="openScheduleModal(scheduleModal, { windowClass: 'modal-xl animate', centered: true })" class="btn btn-primary btn-sm btn-sg" type="button"
											style="margin-right: 5px;font-weight: bold;"><span class="fal fa-calendar-edit" ></span>&nbsp;&nbsp;SCHEDULE</button> -->

					
											<button (click)="openPublishModal(publishModal, { windowClass: 'modal-lg animate', centered: true })"
											class="btn btn-primary btn-sm btn-sg" type="button" style="font-weight: bold;"><span class="far fa-badge-check"></span>&nbsp;&nbsp;PUBLISH</button>
										</div>
									 </div>	
									 <as-split 
						            	direction="vertical"
						            	[useTransition]="true"
						            	(dragEnd)="dragEndResults($event, false)"
						            	#resultsSplit>
						            	<!-- Editor -->
				              			
						     			<div as-split-area [size]="paneSizes.resultRow / queryResults.length" class="row" [ngClass]="{'dark-mode' : darkMode, 'search-results-main': !darkMode}" style="padding-left: 12px; border-bottom: 1px solid #ddd; background-color: white;overflow: hidden;" *ngFor="let result of queryResults; let i = index">

											<div class="col" style="padding-left: 0px; padding-right: 0px; font-size:11pt">
												<div class="row" *ngIf="result.showTimer" style="padding: 10px 12px;">
													<div class="col-sm-2" style="line-height: 40px">
														<div>
															<span *ngIf="result.sqlRunning? true : false" class="fas fa-stopwatch" style="margin-right: 10px" class="spinner-border spinner-border-sm text-primary" role="status">
																
															</span>
															<span *ngIf="!result.sqlRunning && !result.error && !result.aborted ? true : false" class="fas fa-check" style="margin-right: 10px; color: #579d88;"></span>
															<span *ngIf="!result.sqlRunning  && result.error && !result.aborted ? true : false" class="fas fa-times" style="margin-right: 10px; color: #ca6767;"></span>
															<span *ngIf="!result.sqlRunning  && result.aborted ? true : false" class="fas fa-ban" style="margin-right: 10px; color: red;"></span>
															<span *ngIf="returnTimer(result.timer) != '00:00:00'">{{result.timer.minutes}}:{{result.timer.seconds}}:{{result.timer.milliseconds}}</span>
														</div>
													</div>
													<div class="col-sm-3" style="line-height: 40px" *ngIf="!result.queryError && result.values.length > 0">
														<ng-template #downloadContent>
															<label class="form-check form-check-inline" style="padding-left: 10px;">
													        <input class="form-check-input" type="checkbox" value="option1" [(ngModel)]="includeHeaders">
													        <span class="form-check-label" style="font-size: 12pt">
													          Include headers
													        </span>
													      </label>
															<ul style="list-style-type: none; padding: 0; margin: 0; font-size: 11pt; ">
																<li class="download-list" (click)="download(result, 'clipboard')"><i class="fal fa-copy" style="color: #967f06; padding-left: 10px;"></i>&nbsp;&nbsp;Clipboard</li>
																<li (click)="download(result, 'csv')" class="download-list"><i class="fal fa-file-csv " style="color: green; padding-left: 10px;"></i>&nbsp;&nbsp;CSV</li>
															</ul>
														</ng-template>
														<span class="fal fa-download fa" style="margin-left: 25px; color: #4a4786; cursor: pointer;"
														[ngbPopover]="downloadContent" 
														container="body" 
														popoverClass="download-popover" 
														[autoClose]="'outside'"
														
														></span>
														<span>Rows: {{result.values.length}}</span>
														
													</div>

													<div class="col-sm-2" style="line-height: 40px" *ngIf="!result.queryError && result.values.length > 0 && result.profile != undefined">
										
													<ng-template #profileContent>


														<!-- {{ result.profile | json}} -->

														<div class="row" style="padding: 20px">
															<div class="col" style="padding-bottom: 25px;">
																<h4 style="color: #c7c7c7;">Query Profile Overview</h4>
																<div style="width: 50%; font-size: 11pt;">
																	<div class="row" style="padding: 3px; padding-top: 10px; border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Query ID</div>
														 				<div class="col-8">{{result.profile.query_id}}</div>
														 			</div>
																	<div class="row" style="padding: 3px; border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold; ">Count</div>
														 				<div class="col-8">{{result.profile.n}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Duplicates</div>
														 				<div class="col-8">{{result.profile.n_duplicates}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Duplicates (%)</div>
														 				<div class="col-8">{{result.profile.p_duplicates | percent:'1.0-1'}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Cells Missing</div>
														 				<div class="col-8">{{result.profile.n_cell_missing}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Cells Missing (%)</div>
														 				<div class="col-8">{{result.profile.p_cells_missing | percent:'1.0-1'}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Memory Size</div>
														 				<div class="col-8">{{getSize(result.profile.memory_size)}}</div>
														 			</div>
														 			<div class="row" style="padding: 3px;  border-bottom: 1px solid #ccc;">
														 				<div class="col-4" style="font-weight: bold">Messages</div>
														 				<div class="col-8" style="color: red;">{{result.profile.messages}}</div>
														 			</div>
																</div>
															</div>
															
														</div>
														<div class="row">
															<div class="col" style="padding: 20px;">
																<h4 style="color:#c7c7c7; padding-left: 15px;">Variables</h4>
																	<div class="card">
																		<div *ngFor="let v of result.profile.variables">
																			
																			<div class="row" style="border-bottom: 1px solid #f6f1f1; font-size: 11pt; padding: 25px 5px; margin: 0px;">
																				<div class=col-3>
																					<div style="color: #4cbae3; font-size: 12pt; text-overflow: wrap;">
																						{{v.name}}
																					</div>
																					<div style="color: #c7c7c7; font-size: 13pt;">{{v.type}}</div>
																				</div>
																				<!-- CATEGORICAL -->
																				<div class="col-4" *ngIf="v.type=='Categorical'">
																					<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Count</div>
																		 				<div class="col-6">{{v.count}}</div>
																		 			</div>
																		 			<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Distinct</div>
																		 				<div class="col-6">{{v.n_distinct}}</div>
																		 			</div>
																		 			<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Distinct (%)</div>
																		 				<div class="col-6">{{v.p_distinct | percent:'1.0-1'}}</div>
																		 			</div>
																		 			<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Missing</div>
																		 				<div class="col-6">{{v.n_missing}}</div>
																		 			</div>
																		 			<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Missing (%)</div>
																		 				<div class="col-6">{{v.p_missing | percent:'1.0-1'}}</div>
																		 			</div>
																		 			<div class="row" style="padding: 2px;">
																		 				<div class="col-4" style="font-weight: bold">Memory Size</div>
																		 				<div class="col-6">{{getSize(v.memory_size)}}</div>
																		 			</div>
																					
																				</div>
																				<!-- NUMERICAL -->
																				<div class="col-6" *ngIf="v.type == 'Numeric'">
																					<div class="row">
																						<div class="col-6">
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Distinct</div>
																				 				<div class="col-6">{{v.n_distinct}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Distinct (%)</div>
																				 				<div class="col-6">{{v.p_distinct | percent:'1.0-1'}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Missing</div>
																				 				<div class="col-6">{{v.n_missing}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Missing (%)</div>
																				 				<div class="col-6">{{v.p_missing | percent:'1.0-1'}}
																				 				</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Memory Size</div>
																				 				<div class="col-6">{{getSize(v.memory_size)}}</div>
																				 			</div>
																	 					</div>
																	 					<div class="col-6">
																	 						<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Mean</div>
																				 				<div class="col-6">{{v.mean | number:'1.0':'en-US'}}</div>
																				 			</div>
																	 						<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Minimum</div>
																				 				<div class="col-6">{{v.min}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Maximum</div>
																				 				<div class="col-6">{{v.max}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Zeros</div>
																				 				<div class="col-6">{{v.n_zeros}}</div>
																				 			</div>
																				 			<div class="row" style="padding: 2px;">
																				 				<div class="col-6" style="font-weight: bold">Zeros (%)</div>
																				 				<div class="col-6">{{v.p_zeros| percent:'1.0-1'}}</div>
																				 			</div>
																				 			
																	 					</div>
																						
																					</div>
																					
																				</div>
																				<!-- UNSUPPORTED -->
																				<div class="col=4" *ngIf="v.type=='Unsupported'">
																					{{v | json}}
																				</div>
																				<div class=col-5 *ngIf="v.type=='Categorical'">
																					<div [id]="v.name" style="height: 200px;">
																		 				<!-- <div *ngIf="chartLoading" style="width: 200px">
																			 				<div class="spinner-border" role="status" style="margin:  100px;; color: #4cbae3 !important;">
																				            <span  class="sr-only">Loading...</span>
																				          </div>
																			 			</div> -->
																		 			</div>
																				</div>
																			</div>
																		</div>
																	</div>
															</div>
														</div>




													</ng-template>
														<button type="button" class="btn btn-outline-secondary" [ngbPopover]="profileContent" container="body" popoverClass="profile-popover" [autoClose]="'outside'"  (click)="createTextCharts(result.profile.variables)">
														 	<span class="fal fa-analytics"></span>&nbsp;&nbsp;Query Profile
														</button>
													</div>
												</div>
												<!--Query Results Data Table -->
												
													<!--  -->
												
												 <ngx-datatable style="min-width: 500px; font-size:9pt;"  
												  [hidden]=result.sqlRunning 
												  [ngStyle]="{'height.px':(paneSizes.resultsTableWrapperHeightPx / queryResults.length) - (64 + (64/queryResults.length)) + result.heightMultiplier}" 
												  class="bootstrap"
										          [rows]="result.values"
										          [columns]="result.columns"
										          [columnMode]="ColumnMode.force"
										          [headerHeight]="30"
										          [footerHeight]="0"
										          [rowHeight]="30"
										          [reorderable]="false"
										          [scrollbarV]="true"
										          [scrollbarH]="true"
										          [externalSorting]="true"
										          (sort)="onSort($event, i)"
										          limit="result.limit"
												         
												    >
												  </ngx-datatable>
												       
												        <!-- [ngStyle]="{'height.px':(paneSizes.resultsTableHeightPx / queryResults.length) - (64 + (64/queryResults.length)) + result.heightMultiplier}"   -->
												        <!-- <p-table 
												        	 [limit]="result.limit"
															#dt 
															styleClass="p-datatable-gridlines"
															[columns]="result.columns"
															[value]="result.values" 
															[rows]="10"  
															[scrollable]="true" 
															scrollHeight="{{(paneSizes.resultsTableHeightPx / queryResults.length) + result.heightMultiplier}}px"															[style]="{'width':'100%'}"
															[resizableColumns]="true"
															[columnResizeMode]="fit"

															>

															[pSortableColumn]="col.field"  -->
															    
															       <!--  <ng-template pTemplate="header" style="font-size: 11pt;" let-columns>
															            <tr style="font-size: 11pt;">
															             <th *ngFor="let col of columns" colspan="col.colspan" 
       																		[ngStyle]="{'width': col.width}"
       																		[style.background-color]="col.field == 'snowguide_row_index' ? '#f7f7f7' : 'white'
       																		"
       																		[style.font-size]="col.field == 'snowguide_row_index' ? '9pt' : 'inherit'
       																		">
															                {{col.header}}
															               <p-sortIcon *ngIf="col.field != 'snowguide_row_index'" field="col.field"></p-sortIcon> -->
															           <!--  </th>
												
															            </tr>
															        </ng-template>
															        <ng-template pTemplate="body" let-rowData let-columns="columns" style="font-size: 11pt;">
															            <tr style="font-size: 11pt;">
															    			 <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}"
															    			 [style.background-color]="col.field == 'snowguide_row_index' ? '#f7f7f7' : 'white'
       																		"
       																		[style.font-size]="col.field == 'snowguide_row_index' ? '9pt' : 'inherit'">
																                {{rowData[col.field]}}
																            </td>
															         	</tr>
															         </ng-template>
															</p-table> --> 
												
												 <!-- <div class="row" *ngIf="result.values.length > getRowLimit()">
													<div class="col-12 align-middle text-center">
														
													<i class="fas fa-caret-down fa-lg text-center" style="display: block; color:gray; background-color: #f4f4f4;" aria-hidden="true"></i>
													
													</div>
												</div> -->
											</div>
										</div>
								</as-split>
							</div>
				        </as-split>
						  
						   
						  
						 
						    
								<!-- <div class="row" style="height: 375px; max-height: 375px; resize: both;">
									
								</div> -->
								<!-- Option Bar -->
								
								<!-- Search Results -->
							
						</div>
						<!-- Navigator Section -->
						<!-- <div class="col-lg-3" style="border-left: 1px solid #ddd;">
							
										
							</div> --> 
							<!-- Query File Name -->
							<!-- <div class="row"  style="border-bottom: 1px solid #E1E2E4;  padding-top: 10px; padding-bottom: 10px">
								<div class="col-4" style="font-size: 14pt; font-weight: 700; font-family: roboto;">Query</div>
								<div class="col-8 my-auto">
									<span [ngClass]="{'blue-dot': selectedFile.type == 'own', 'green-dot': selectedFile.type == 'shared', 'purple-dot': selectedFile.type == 'doc'  }"></span>
									<span style="font-weight: 700; font-size: 11pt; font-family: Courier;">{{selectedFile.file_name}}</span>
								</div>
							</div> -->
					
			</div>
		</div>
	</div>
</div>
<ng-template #scheduleModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<span  class="far fa-calendar-alt fa-2x sg-txt"></span>&nbsp;&nbsp;
          	<span style="font-size: 16pt; vertical-align: bottom; line-height: 32px;">Query Schedule</span>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div  class="col-12 scheduler" style="border-right: 1px solid #ccc;">
          	<cron-editor [(cron)]="selectedFile.settings.schedule" [(options)]="cronOptions"></cron-editor>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      	<div class="mr-auto" style="width: 400px;">
      		<span style="font-weight: bold">
      			New Schedule:
      		</span>
      		<span>
      			 {{expressionToFriendly(selectedFile.settings.schedule)}}
      		</span>
      	 </div>
      	<div class="mr-auto">
      		<span style="font-weight: bold">
      			Current Schedule: 
      		</span>
      		<span>
      			{{expressionToFriendly(selectedFile.og_schedule)}}
      		</span>
      	</div>

      	<div class="row">
      		<div class="col">
	      		<div class="row" style="padding-bottom: 10px;">
	      			<div class="col" style="font-size: 9pt; font-style: italic; font-weight: bold">
	      				(Saving will publish a new version)
	      			</div>
	      		</div>
	      		<div class="row">
	      			<div class="col">
	      				<button type="button" class="btn btn-primary btn-sg" (click)="d('save')"><span class="fas fa-save"></span>&nbsp;&nbsp;Save</button>
	        			<button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
	      			</div>
	      		</div>
	      	</div>
      	</div>
      </div>
  </ng-template>
  <ng-template #newConnectionModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fas fa-plug fa-lg sg-txt"></span>&nbsp;&nbsp;
          New Connection<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="border-right: 1px solid #ccc; padding: 10px 35px;">
            <!-- Form -->
	         <form [formGroup]="connectionForm">
	          <div class="form-group">
	            <label class="form-label">Username</label>
	            <span class="text-danger float-right text-right"
                 *ngIf="(passwordFormControl().username.touched || submitted) && passwordFormControl().username.errors?.required">
                Required
              </span>
	            <input formControlName="username" id="defaultEmail" name="email" type="text" class="form-control" autocomplete="new-username"> 
	          </div>
	          <div class="form-group">
	            <label class="form-label d-flex justify-content-between align-items-end">
	              <div>Password</div>
	            </label>
	            <span class="text-danger float-right text-right"
                 *ngIf="(passwordFormControl().password.touched || submitted) && passwordFormControl().password.errors?.required">
                Required
              </span>
	            <input formControlName="password" id="defaultPassword" name="password" type="password" class="form-control" autocomplete="new-password">
	          </div>
	          <div class="form-group" >
	            <label class="form-label" style="margin-bottom: 4px; width: 100%;">
	            	System
	            	 <span class="text-danger float-right text-right"
	                     *ngIf="(passwordFormControl().system.touched || submitted) && passwordFormControl().system.errors?.required">
	                    Required
	                  </span>
	            </label>
	        	<ng-select [items]="connObj.systemOptions"
				      bindLabel="label"
				      bindValue="value"
				      formControlName="system"
				      style="height: 2.4rem !important;"
				      >
					      <ng-template ng-label-tmp let-item="item" >
					      	<img *ngIf="passwordFormControl().system.touched" height="15px" width="15px" [src]="item['logo']" style="margin-bottom: 3px; margin-right: 3px;" />
					        <span class="ng-value-label">{{item['label']}}</span>
					      </ng-template>

					      <ng-template ng-option-tmp let-item="item" >
					      	<img height="15px" width="15px" [src]="item['alt_logo']" style="margin-bottom: 3px; margin-right: 3px;" />
					        <span class="ng-value-label"> {{item['label']}}</span>
					      </ng-template>
				</ng-select>
	          </div>
	              
	          	<div class="form-group " >
                    <label class="form-label" style="margin-bottom: 4px; width: 100%;">
                    	User Type
                    	 <span class="text-danger float-right text-right"
		                     *ngIf="(passwordFormControl().user_type.touched || submitted) && passwordFormControl().user_type.errors?.required">
		                    Required
		                  </span>
                    </label>
	            	<ng-select [items]="connObj.typeOptions"
					      bindLabel="label"
					      bindValue="value"
					      formControlName="user_type"
					      style="height: 2.4rem !important;"
					      >
						      <ng-template ng-label-tmp let-item="item" >
						      	<i [ngClass]="item['icon']"></i>
						        <span class="ng-value-label">{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item" >
						      	<i [ngClass]="item['icon']"></i>
						        <span class="ng-value-label"> {{item['label']}}</span>
						      </ng-template>
					</ng-select>
                  </div>
	              
	    	</form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg"  (click)="saveConn('new')">
        	<span *ngIf="!saving.connection" class="fas fa-save"></span>
	        <div *ngIf="saving.connection" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
        	&nbsp;&nbsp;Save
        </button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>
  <ng-template #editConnectionModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fas fa-plug fa-lg sg-txt"></span>&nbsp;&nbsp;
          Edit Connection<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="border-right: 1px solid #ccc; padding: 10px 35px;">
            <!-- Form -->
	         <form [formGroup]="editConnectionForm">
	          <div class="form-group">
	            <label class="form-label">Username</label>
	            <span class="text-danger float-right text-right"
                 *ngIf="(editPasswordFormControl().username.touched || submitted) && editPasswordFormControl().username.errors?.required">
                Required
              </span>
	            <input formControlName="username" id="defaultEmail" name="email" type="text" class="form-control" autocomplete="new-username"> 
	          </div>
	          <div class="form-group">
	            <label class="form-label d-flex justify-content-between align-items-end">
	              <div>Password</div>
	            </label>
	            <span class="text-danger float-right text-right"
                 *ngIf="(editPasswordFormControl().password.touched || submitted) && editPasswordFormControl().password.errors?.required">
                Required
              </span>
	            <input formControlName="password" id="defaultPassword" name="password" type="password" class="form-control" autocomplete="new-password">
	          </div>
	          <div class="form-group" >
	            <label class="form-label" style="margin-bottom: 4px; width: 100%;">
	            	System
	            	 <span class="text-danger float-right text-right"
	                     *ngIf="(editPasswordFormControl().system.touched || submitted) && editPasswordFormControl().system.errors?.required">
	                    Required
	                  </span>
	            </label>
	        	<ng-select [items]="connObj.systemOptions"
				      bindLabel="label"
				      bindValue="value"
				      formControlName="system"
				      style="height: 2.4rem !important;"
				      >
					      <ng-template ng-label-tmp let-item="item" >
					      	<img *ngIf="editPasswordFormControl().system.touched" height="15px" width="15px" [src]="item['logo']" style="margin-bottom: 3px; margin-right: 3px;" />
					        <span class="ng-value-label">{{item['label']}}</span>
					      </ng-template>

					      <ng-template ng-option-tmp let-item="item" >
					      	<img height="15px" width="15px" [src]="item['alt_logo']" style="margin-bottom: 3px; margin-right: 3px;" />
					        <span class="ng-value-label"> {{item['label']}}</span>
					      </ng-template>
				</ng-select>
	          </div>
	              
	          	<div class="form-group " >
                    <label class="form-label" style="margin-bottom: 4px; width: 100%;">
                    	User Type
                    	 <span class="text-danger float-right text-right"
		                     *ngIf="(editPasswordFormControl().user_type.touched || submitted) && editPasswordFormControl().user_type.errors?.required">
		                    Required
		                  </span>
                    </label>
	            	<ng-select [items]="connObj.typeOptions"
					      bindLabel="label"
					      bindValue="value"
					      formControlName="user_type"
					      style="height: 2.4rem !important;"
					      >
						      <ng-template ng-label-tmp let-item="item" >
						      	<i [ngClass]="item['icon']"></i>
						        <span class="ng-value-label">{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item" >
						      	<i [ngClass]="item['icon']"></i>
						        <span class="ng-value-label"> {{item['label']}}</span>
						      </ng-template>
					</ng-select>
                  </div>
	              
	    	</form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg" (click)="saveConn('update')">
        	<span *ngIf="!saving.connection" class="fas fa-save"></span>
        	<div *ngIf="saving.connection" class="spinner-border spinner-border-sm text-primary" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
        	&nbsp;&nbsp;Save
        </button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>
   <ng-template #deleteConnectionModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fas fa-trash-alt fa-lg sg-txt"></span>&nbsp;&nbsp;
          Delete Connection<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this connection? </p>
        <h4>{{deleteConnUsername}}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="d('delete')"><span class="fas fa-trash-alt"></span>&nbsp;&nbsp;Delete</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>
  <ng-template #deleteFileModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fas fa-file-times fa-lg sg-txt-critical"></span>&nbsp;&nbsp;
          Delete File<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Are you sure you want to delete this file?</p>
        <h4>{{selectedFile.file_name}}</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sg-critical" (click)="d('delete')"><span class="fas fa-trash-alt"></span>&nbsp;&nbsp;Delete</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Cancel</button>
      </div>
  </ng-template>
  <ng-template #publishModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<span class="fas fa-badge-check sg-txt" style="font-size:15pt; line-height: 32px"></span>&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">Publish: </span>
		        	<span style="color: gray; font-size:16pt;vertical-align: bottom; line-height: 32px;">{{selectedFile.file_name}}</span>
		        </div>
		    </div>
		    <div class="row">
		    	<div class="col" style="font-size: 9pt; padding: 15px;">
		    		<span>
		    			Publishing a query will save this file as the latest version. 
		    			Automated jobs using this query file will automatically use the latest published version.
		    			Published queries will be accessible by all users.
		    		 </span>
		    	</div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 2px !important;">
        <div class="row">
          <div class="col-12" style="border-right: 1px solid #ccc; padding: 10px 35px;">
            <!-- Form -->
	         <form [formGroup]="publishForm">
	          <div class="form-group">
	            <label class="form-label">Comment</label>
	            <textarea formControlName="comment" id="comment" name="comment" type="text" class="form-control"></textarea>
	          </div>
	    	</form>
          </div>
        </div>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-primary btn-sg" (click)="d('save')"><span class="fas fa-file-arrow-up"></span>&nbsp;&nbsp;Publish</button>
        <button type="button" class="btn btn-default" (click)="d('Close click')">Close</button>
      </div>
  </ng-template>
   <ng-template #connFailed let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <h5 class="modal-title"><span class="fal fa-exclamation-triangle fa-lg" style="color:red;"></span>&nbsp;&nbsp;
          Connection Unsuccessful<br>
          <!-- <small class="text-muted">We need payment information to process your order.</small> -->
        </h5>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <p>Please check your login credentials and try again </p>
      </div>
  </ng-template>

<div toastContainer></div>
