import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { environment } from '../../../../environments/environment';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router"
import { AuthService } from "../../../shared/auth/auth.service";
import { map, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: [
    '../../../../vendor/styles/pages/authentication.scss'
  ],
})
export class InviteComponent implements OnInit {

  public createForm: FormGroup;
  public templateForm: FormGroup;
  ipAddress: string = '';
  url: string = environment.url;
  toEmail: string = undefined;
  invite = {
    name: '',
    success: false,
    verifying: true,
    verified: false,
    complete: false,
    errorMsg: '',
    hasError: false
  }
  fieldTextType: boolean;
  submitted: boolean = false;
  token: string = '';
  displayMessage: string = '';



  constructor(
    private appService: AppService, 
    private http: HttpClient, 
    private fb: FormBuilder,
    private router: Router, 
    private auth: AuthService,
    private storage: StorageMap,
    private route: ActivatedRoute,
    ) {

    this.appService.pageTitle = 'Invite';

    this.templateForm = fb.group({
        password:['', [Validators.required, Validators.minLength(8), this.createPasswordStrengthValidator()]],
        confirm_password:['', [Validators.required, this.passwordMatchValidator()]],
        source: 'frontend'
    });
    this.createForm = this.templateForm;


  }

  ngOnInit(){

    this.route.queryParams
      .subscribe(params => {
        if(params.token && params.email){
          this.token = params.token;
          var url = this.url + '/user/invite/verify/' + params.token
          this.toEmail = params.email;
          this.http.post(
             url,
             {},
             { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json' }
           ).subscribe(data => {
              this.invite.name = data['message']['first_name']
              this.invite.verified = true;
              this.invite.verifying = false;
            },
             err => {
               if('message' in err){
                 this.invite.errorMsg = err['message']
                 this.invite.hasError = true;
               }
               this.invite.verifying = false;
           });
        }
      }
    );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get inviteFormControl() {
    return this.createForm.controls;
  }

  create(){

    this.submitted = true;
    var url = this.url + '/user/invite/create/' + this.token;
    this.createForm.patchValue({ip_address: this.ipAddress});
    var body = {password: this.createForm.get('password').value}
    
    if (this.createForm.valid) {
      this.http.post(
      url,
      JSON.stringify(body),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'json' }
      ).subscribe(data => {
          console.log('Create Data: ', data)
          this.submitted = false;
          this.invite.complete = true;
          this.invite.success = true;
          this.createForm.reset(this.templateForm.value);
          this.submitted = false;
        },
        err => {
          console.log('Error: ', err)
          if( err['error']['message'] == 'Email address already registered'){
            this.invite.success = false;
            this.displayMessage = 'Email address already registered';
            this.createForm.reset(this.templateForm.value);
            this.submitted = false;
          }
          else{
            this.displayMessage = err['error']['message']
            this.submitted = false;
          }
        });
    }

  }

  passwordMatchValidator() : ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {

      let fg = control.parent;

      if(fg){
        const pControl = fg.get('password')
        return pControl.value === control.value ? null : {mismatch: true};
      }
      return null

    }
  }

  createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        const hasUpperCase = /[A-Z]+/.test(value);
        const hasLowerCase = /[a-z]+/.test(value);
        const hasNumeric = /[0-9]+/.test(value);
        const hasSpecial = /[@$!%*?&]+/.test(value);
        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;

        return !passwordValid ? {passwordStrength:true}: null;
    }
  }

}
