import { Injectable } from  "@angular/core";
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import { Observable } from "rxjs";
import * as moment from "moment";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { StorageMap } from '@ngx-pwa/local-storage';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable()
export class AuthService{

    jwt: string = ''
    indentifier: string = ''
    url: string = environment.url;
    private loggedOut$ = new ReplaySubject<boolean>(1);
   
    constructor(
        private router : Router, 
        private http: HttpClient, 
        private storage: StorageMap

        ) {
        if(this.isLoggedIn()){
            this.jwt = sessionStorage.getItem("CT");
            this.indentifier = sessionStorage.getItem("CI");
            this.loggedOut$.next(false)
        }
        else{
            this.loggedOut$.next(true)
        }
    }

    public getToken(){
        let jwt = sessionStorage.getItem("CT")
        return jwt;
    }

    public getIdentifier(){
        let identifier = sessionStorage.getItem("CI")
        return identifier;
    }

    public setSession(authResult){
        const expiresAt = moment().add(authResult.expires_in,'second');
        sessionStorage.setItem('CT', authResult.ct);
        sessionStorage.setItem('CI', authResult.ci);
        sessionStorage.setItem('CSC', authResult.csc);
        sessionStorage.setItem('TR', authResult.tr);
        sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
    }
    public removeSession(): Observable<any>{
        this.loggedOut$.next(true)
        sessionStorage.removeItem("CT");
        sessionStorage.removeItem("CI");
        sessionStorage.removeItem("CSC");
        sessionStorage.removeItem("expires_at");
        sessionStorage.removeItem("catalog_last_update");
        return this.storage.clear();
    }
    public isLoggedIn() {

        if(moment().isBefore(this.getExpiration()) && sessionStorage.getItem("CT") != null && sessionStorage.getItem("CI") != null){
            return true;
        }
        else{
            return false;
        }
    }

    public isAdmin(){
        if(parseInt(sessionStorage.getItem("TR")) >= 10){
            return true;
        }
        else{
            return false;
        }
    }

    public isSetup(){
        if(sessionStorage.getItem("CSC") == 'true'){
            return true;
        }
        return false;
    }
    public logout(){
        this.removeSession().subscribe(() => {
            this.router.navigate(['/login'])
        });
    }

    public isLoggedOut() {
        return !this.isLoggedIn();
    }

    private getExpiration() {
        const expiration = sessionStorage.getItem("expires_at");
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    } 
    getProfile():Observable<any>{
      // console.log('getting profile')
      let headers = this.getHeaders()
      let url = this.url + '/user/profile'
      console.log(url)
      return this.http.get(url, {headers})
    }

    getHeaders(includeContentType:boolean = true, contentType?:string){
        let headers: HttpHeaders = new HttpHeaders();
        if(contentType && includeContentType){
            headers = headers.append('Content-Type', contentType);
        }
        else if(includeContentType && !contentType){
            headers = headers.append('Content-Type', 'application/json');
        }
        
        headers = headers.append('Authorization', 'Carve Token=' + this.getIdentifier() + ':|:' + this.getToken());
        headers = headers.append('Cache-Control','no-cache')
        return headers
    }

    getAuthToken(){
        return 'Carve Token=' + this.getIdentifier() + ':|:' + this.getToken();
    }

    loggedOutReplay(): Observable<any> {
        return this.loggedOut$.asObservable();
    }
}