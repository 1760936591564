<div class="card cron-editor-main" style="border: none !important;">
  <div class="card-header" style="border-color: white !important">
    <!-- Tabs -->
    <ul class="nav nav-tabs tabs-alt card-header-tabs tab-nav" role="tablist">
      <li class="nav-item" *ngIf="!options.hideMinutesTab">
        <a class="nav-link" [ngClass]="{'active': activeTab === 'minutes'}" aria-controls="minutes" role="tab" data-toggle="tab"
          (click)="setActiveTab('minutes', $event)">
          Minutes
        </a>
      </li>

      <li class="nav-item nav-link" role="presentation" *ngIf="!options.hideHourlyTab">
        <a class="nav-link schedule-link" [ngClass]="{'active': activeTab === 'hourly'}" aria-controls="hourly" role="tab" data-toggle="tab" (click)="setActiveTab('hourly', $event)">
          Hourly
        </a>
      </li>

      <li class="nav-item nav-link"  role="presentation" *ngIf="!options.hideDailyTab">
        <a class="nav-link schedule-link" [ngClass]="{'active': activeTab === 'daily'}" aria-controls="daily" role="tab" data-toggle="tab" (click)="setActiveTab('daily', $event)">
          Daily
        </a>
      </li>

      <li class="nav-item nav-link" role="presentation" *ngIf="!options.hideWeeklyTab">
        <a class="nav-link schedule-link" [ngClass]="{'active': activeTab === 'weekly'}" aria-controls="weekly" role="tab" data-toggle="tab" (click)="setActiveTab('weekly', $event)">
          Weekly
        </a>
      </li>

      <li class="nav-item nav-link" role="presentation" *ngIf="!options.hideMonthlyTab">
        <a class="nav-link schedule-link" [ngClass]="{'active': activeTab === 'monthly'}" aria-controls="monthly" role="tab" data-toggle="tab"
          (click)="setActiveTab('monthly', $event)">
          Monthly
        </a>
      </li>

      <li class="nav-item nav-link" role="presentation" *ngIf="!options.hideYearlyTab">
        <a class="nav-link schedule-link" [ngClass]="{'active': activeTab === 'yearly'}" aria-controls="yearly" role="tab" data-toggle="tab" (click)="setActiveTab('yearly', $event)">
          Yearly
        </a>
      </li>

      <li class="nav-item" role="presentation" *ngIf="!options.hideAdvancedTab">
        <a class="nav-link" [ngClass]="{'active': activeTab === 'advanced'}" aria-controls="advanced" role="tab" data-toggle="tab"
          (click)="setActiveTab('advanced', $event)">
          Advanced
        </a>
      </li>
    </ul>
  </div>

  <!-- Tab content -->
  <div class="card-body cron-editor-container">
    <div class="row">
      <div class="col-12">
        <div class="tab-content">
          <!-- Minutes-->
          <div class="tab-pane" *ngIf="!options.hideMinutesTab" [ngClass]="{'active': activeTab === 'minutes'}">
            <div class="">
              Every
              <select class="minutes" [disabled]="disabled || activeTab !== 'minutes'" (change)="regenerateCron()" [(ngModel)]="state.minutes.minutes"
                [ngClass]="options.formSelectClass">
                <option *ngFor="let minute of selectOptions.minutes" [ngValue]="minute">
                  {{minute}}
                </option>
              </select> minute(s)
              <span *ngIf="!options.hideSeconds && isCronFlavorQuartz">on second</span>
              <select class="seconds" *ngIf="!options.hideSeconds && isCronFlavorQuartz" [disabled]="disabled || activeTab !== 'minutes'"
                (change)="regenerateCron()" [(ngModel)]="state.minutes.seconds" [ngClass]="options.formSelectClass">
                <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                  {{second}}
                </option>
              </select>
            </div>
          </div>

          <!-- Hourly-->
          <div class="tab-pane" *ngIf="!options.hideHourlyTab" [ngClass]="{'active': activeTab === 'hourly'}">
            <div class="">
              Every
              <select class="hours" [disabled]="disabled || activeTab !== 'hourly'" (change)="regenerateCron()" [(ngModel)]="state.hourly.hours"
                [ngClass]="options.formSelectClass">
                <option *ngFor="let hour of selectOptions.hours" [ngValue]="hour">
                  {{hour}}
                </option>
              </select> hour(s) on minute
              <select class="minutes" [disabled]="disabled || activeTab !== 'hourly'" (change)="regenerateCron()" [(ngModel)]="state.hourly.minutes"
                [ngClass]="options.formSelectClass">
                <option *ngFor="let minute of selectOptions.fullMinutes" [ngValue]="minute">
                  {{minute}}
                </option>
              </select>
              <span *ngIf="!options.hideSeconds && isCronFlavorQuartz">and second</span>
              <select class="seconds" *ngIf="!options.hideSeconds && isCronFlavorQuartz" [disabled]="disabled || activeTab !== 'hourly'"
                (change)="regenerateCron()" [(ngModel)]="state.hourly.seconds" [ngClass]="options.formSelectClass">
                <option *ngFor="let second of selectOptions.seconds" [ngValue]="second">
                  {{second}}
                </option>
              </select>
            </div>
          </div>

          <!-- Daily-->
          <div class="tab-pane" *ngIf="!options.hideDailyTab" [ngClass]="{'active': activeTab === 'daily'}">
            <div class="form-group">
              <input type="radio" name="daily-radio" value="everyDays" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.daily.subTab"
                value="everyDays" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.daily.subTab" [ngClass]="state.formRadioClass"
                checked="checked" >
              <label>Every
                <select class="days" [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'" (change)="regenerateCron()"
                  [(ngModel)]="state.daily.everyDays.days" [ngClass]="options.formSelectClass">
                  <option *ngFor="let monthDay of selectOptions.monthDays" [ngValue]="monthDay">
                    {{monthDay}}
                  </option>
                </select>
              </label>&nbsp;
              <label>day(s) at
                <cron-time-picker [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyDays'" (onChange)="regenerateCron()"
                  [(model)]="state.daily.everyDays" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                  [hideSeconds]="options.hideSeconds|| !isCronFlavorQuartz">
                </cron-time-picker>
              </label>
            </div>

            <div class="form-group">
              <input class="" type="radio" name="daily-radio" value="everyWeekDay" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.daily.subTab"
                [ngClass]="state.formRadioClass"> Every working day at
              <cron-time-picker [disabled]="disabled || activeTab !== 'daily' || state.daily.subTab !== 'everyWeekDay'" (change)="regenerateCron()"
                [(model)]="state.daily.everyWeekDay" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
              </cron-time-picker>
            </div>
          </div>

          <!-- Weekly-->
          <div class="tab-pane" *ngIf="!options.hideWeeklyTab" [ngClass]="{'active': activeTab === 'weekly'}" style="width: 900px;">
            <div class="form-group">
              <div class="row" style="margin-bottom: 25px;">
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.MON"
                    [ngClass]="options.formCheckboxClass"> Monday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.TUE"
                    [ngClass]="options.formCheckboxClass"> Tuesday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.WED"
                    [ngClass]="options.formCheckboxClass"> Wednesday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.THU"
                    [ngClass]="options.formCheckboxClass"> Thursday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.FRI"
                    [ngClass]="options.formCheckboxClass"> Friday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.SAT"
                    [ngClass]="options.formCheckboxClass"> Saturday
                </div>
                <div class="col">
                  <input type="checkbox" [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(ngModel)]="state.weekly.SUN"
                    [ngClass]="options.formCheckboxClass"> Sunday
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  at
                  <cron-time-picker [disabled]="disabled || activeTab !== 'weekly'" (change)="regenerateCron()" [(model)]="state.weekly" [selectClass]="options.formSelectClass"
                    [use24HourTime]="options.use24HourTime" [hideSeconds]="options.hideSeconds|| !isCronFlavorQuartz">
                  </cron-time-picker>
                </div>
              </div>
            </div>

          </div>

          <!-- Monthly-->
          <div class="tab-pane" *ngIf="!options.hideMonthlyTab" [ngClass]="{'active': activeTab === 'monthly'}">
            <div class="form-group">
              <input type="radio" name="monthly-radio" value="specificDay" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.monthly.subTab"
                [ngClass]="state.formRadioClass"> On the
              <select *ngIf="options.cronFlavor == 'quartz'" class="month-days" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                (change)="regenerateCron()" [(ngModel)]="state.monthly.specificDay.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts" [ngValue]="monthDaysWithLast">
                  {{monthDayDisplay(monthDaysWithLast)}}
                </option>
              </select>
              <select *ngIf="options.cronFlavor == 'standard'" class="month-days" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'"
                (change)="regenerateCron()" [(ngModel)]="state.monthly.specificDay.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts" [ngValue]="monthDaysWithOutLast">
                  {{monthDayDisplay(monthDaysWithOutLast)}}
                </option>
              </select> of every
              <select class="months-small" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'" (change)="regenerateCron()"
                [(ngModel)]="state.monthly.specificDay.months" [ngClass]="options.formSelectClass">
                <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                  {{month}}
                </option>
              </select> month(s) at
              <cron-time-picker [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificDay'" (change)="regenerateCron()"
                [(model)]="state.monthly.specificDay" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
              </cron-time-picker>
            </div>
            <div class="" *ngIf="!options.hideSpecificWeekDayTab">
              <input type="radio" name="monthly-radio" value="specificWeekDay" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.monthly.subTab"
                [ngClass]="state.formRadioClass"> On the
              <select class="day-order-in-month" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.monthWeek" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                  {{monthWeekDisplay(monthWeek)}}
                </option>
              </select>
              <select class="week-days" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                  {{dayDisplay(day)}}
                </option>
              </select> of every
              <select class="months-small" [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'"
                (change)="regenerateCron()" [(ngModel)]="state.monthly.specificWeekDay.months" [ngClass]="options.formSelectClass">
                <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                  {{month}}
                </option>
              </select> month(s) at
              <cron-time-picker [disabled]="disabled || activeTab !== 'monthly' || state.monthly.subTab !== 'specificWeekDay'" (change)="regenerateCron()"
                [(model)]="state.monthly.specificWeekDay" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
              </cron-time-picker>
            </div>
          </div>

          <!-- Yearly-->
          <div class="tab-pane" *ngIf="!options.hideYearlyTab" [ngClass]="{'active': activeTab === 'yearly'}">
            <div class="form-group">
              <input type="radio" name="yearly-radio" value="specificMonthDay" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.yearly.subTab"
                [ngClass]="state.formRadioClass"> Every
              <select class="months" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'" (change)="regenerateCron()"
                [(ngModel)]="state.yearly.specificMonthDay.month" [ngClass]="options.formSelectClass">
                <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                  {{monthDisplay(month)}}
                </option>
              </select> on the
              <select *ngIf="options.cronFlavor == 'quartz'" class="month-days" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthDay.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts" [ngValue]="monthDaysWithLast">
                  {{monthDayDisplay(monthDaysWithLast)}}
                </option>
              </select>
              <select *ngIf="options.cronFlavor == 'standard'" class="month-days" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'"
                (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthDay.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts" [ngValue]="monthDaysWithOutLast">
                  {{monthDayDisplay(monthDaysWithOutLast)}}
                </option>
              </select> at
              <cron-time-picker [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthDay'" (change)="regenerateCron()"
                [(model)]="state.yearly.specificMonthDay" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
              </cron-time-picker>
            </div>
            <div class="" *ngIf="!options.hideSpecificMonthWeekTab">
              <input type="radio" name="yearly-radio" value="specificMonthWeek" [disabled]="disabled" (change)="regenerateCron()" [(ngModel)]="state.yearly.subTab"
                [ngClass]="state.formRadioClass"> On the
              <select class="day-order-in-month" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthWeek.monthWeek" [ngClass]="options.formSelectClass">
                <option *ngFor="let monthWeek of selectOptions.monthWeeks" [ngValue]="monthWeek">
                  {{monthWeekDisplay(monthWeek)}}
                </option>
              </select>
              <select class="week-days" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'"
                (change)="regenerateCron()" [(ngModel)]="state.yearly.specificMonthWeek.day" [ngClass]="options.formSelectClass">
                <option *ngFor="let day of selectOptions.days" [ngValue]="day">
                  {{dayDisplay(day)}}
                </option>
              </select> of
              <select class="months" [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'" (change)="regenerateCron()"
                [(ngModel)]="state.yearly.specificMonthWeek.month" [ngClass]="options.formSelectClass">
                <option *ngFor="let month of selectOptions.months" [ngValue]="month">
                  {{monthDisplay(month)}}
                </option>
              </select> at
              <cron-time-picker [disabled]="disabled || activeTab !== 'yearly' || state.yearly.subTab !== 'specificMonthWeek'" (change)="regenerateCron()"
                [(model)]="state.yearly.specificMonthWeek" [selectClass]="options.formSelectClass" [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz">
              </cron-time-picker>
            </div>
          </div>

          <!-- Advanced-->
          <div class="tab-pane" *ngIf="!options.hideAdvancedTab" [ngClass]="{'active': activeTab === 'advanced'}">
            Cron Expression
            <input type="text" class="advanced-cron-editor-input" ng-disabled="disabled || activeTab !== 'advanced'" (change)="regenerateCron()"
              [(ngModel)]="state.advanced.expression" [ngClass]="options.formInputClass">
          </div>
        </div>
      </div>
    </div>
    <div  *ngIf="options.showFriendly" style="font-weight: bold; position: absolute; bottom: 0; left: 0; padding: 25px;">
        {{showFriendlyDisplay(localCron)}}
    </div>
    
  </div>
</div>