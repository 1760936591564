<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x bg-dark" style="background-color: black !important;" [ngClass]="currentBg()">

  <!-- Brand demo (see demo.css) -->
  <a routerLink="/explore/overview" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
    <!-- <span class="app-brand-logo demo bg-primary">

    </span> -->
    <img  src="./assets/img/carve_logo.png" style="margin-right: 85px; height: 55px; width: 220px;">
    <!-- <span class="app-brand-text demo font-weight-normal ml-2">Snowguide</span> -->
  </a>


  <!-- Sidenav toggle (see demo.css) -->

  <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
    <!-- Divider -->
    <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

    <div class="navbar-nav align-items-lg-center">
      <!-- Search -->
      <label class="nav-item navbar-text navbar-search-box p-0 active">
        <i class="fal fa-search navbar-icon align-middle"></i>
        <span class="navbar-search-input pl-2">
          <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:250px">
        </span>
      </label>
    </div>
     <div class="navbar-nav align-items-lg-center">
      <div class="row" style="width: 320px">
        <div class="col-3" style="padding:0px 15px">
          <div class="row">
            <div routerLink="/explore/overview" (click)="setRoute('explore')" class="nav-btns explore-btn"></div>
           <!--  <img  src="./assets/img/explore.png" style="height: 50px; width: 50px;"> -->
          </div>
          <div class="row">
           <span class="btn-txt-bottom" [ngClass]="{'navbar-item-active': view=='explore'}">Explore</span>
          </div>
        </div>
        <div class="col-3">
          <div class="row">
            <div routerLink="/query" class="nav-btns query-btn" (click)="setRoute('query')"></div>
          
          </div>
          <div class="row">
            <span class="btn-txt-bottom" [ngClass]="{'navbar-item-active': view=='query'}">Query</span>
          </div>
        </div>
        <div class="col-3">
          <div class="row">
            <div routerLink="/automate" (click)="setRoute('automate')" class="nav-btns automate-btn"></div>
          
          </div>
          <div class="row">
            <span class="btn-txt-bottom" [ngClass]="{'navbar-item-active': view=='automate'}">Automate</span>
          </div>
        </div>

        <!-- <div class="col-3">
          <div class="row">
            <button routerLink="/manage" type="button" class="btn icon-btn btn-lg center-content manage-btn">
            <span style="color:white" class="fas fa-tools"></span>
          </button>
          </div>
          <div class="row">
            <span class="btn-txt-bottom">Manage</span>
          </div>
        </div> -->
         
      </div>
    </div>

    <div class="navbar-nav align-items-lg-center ml-auto">
      <!-- <div ngbDropdown display="dynamic" placement="bottom-right" class="demo-navbar-notifications nav-item mr-lg-3">
        <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
          <i class="fal fa-bell navbar-icon align-middle"></i>
         <span class="badge badge-primary badge-dot indicator"></span> -->
          <!-- <span class="d-lg-none align-middle">&nbsp; Notifications</span>
        </a>
      </div> -->

      <div ngbDropdown display="dynamic" placement="bottom-right" class="demo-navbar-messages nav-item mr-lg-3">
        <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
          <i class="fal fa-envelope navbar-icon align-middle"></i>
          <span class="badge badge-primary badge-dot indicator"></span>
          <span class="d-lg-none align-middle">&nbsp; Messages</span>
        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <div class="bg-primary text-center text-white font-weight-bold p-3">
            1 New Messages
          </div>
          <div class="list-group list-group-flush">
            <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
              <img src="assets/img/snow_circle.png" class="d-block ui-w-40" alt>
              <div class="media-body ml-3">
                <div class="text-dark line-height-condenced">Welcome!</div>
                <div class="small mt-1">
                  Thank you for joining Carve!
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- Divider -->
      <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

      <div ngbDropdown display="dynamic" placement="bottom-right" class="demo-navbar-user nav-item">
        <a ngbDropdownToggle class="nav-link user-profile" href="javascript:void(0)">
          <span class="d-inline-flex  align-items-center align-middle">
            
            <span class="px-1 mr-sm-2 ml-2 ml-lg-0">{{profile.first_name}} {{profile.last_name}}</span>
             
                <span class="fal fa-user-circle fa-lg"></span>
              
          </span>

        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <div style="font-weight: 500;padding-left: 20px;">Settings</div>
         <!-- <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</a>
          <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</a>
          <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</a> -->
          <a href="javascript:void(0)" routerLink="/settings/connections" class="dropdown-item"><i class="fa-solid fa-lock fa-sm" style="color: #585a5e;"></i> &nbsp; Connections</a>
          <div class="dropdown-divider"></div>
          <div *ngIf="isAdmin" style="font-weight: 500;padding-left: 20px;">Admin Settings</div>
          <a  *ngIf="isAdmin" routerLink="/admin/settings/users"  href="javascript:void(0)" class="dropdown-item"><i class="fa-solid fa-user-gear fa-sm" style="color: #585a5e;"></i> &nbsp; Users</a>
          <a  *ngIf="isAdmin" routerLink="/packages"  href="javascript:void(0)" class="dropdown-item"><i class="fa-solid fa-square-code fa-sm" style="color: #585a5e;"></i> &nbsp; Code Packages</a>
          <a  *ngIf="isAdmin" routerLink="/admin/settings/integrations"  href="javascript:void(0)" class="dropdown-item"><i class="fa-solid fa-layer-group fa-sm" style="color: #585a5e;"></i> &nbsp; Integrations</a>
          <div class="dropdown-divider"></div>
          <a (click)="logout()" href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</a>
          <div style="visibility: hidden;">
            <i class="fa-solid fa-user-gear"></i>
            <i class="fa-solid fa-tools fa-sm"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
