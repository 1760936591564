import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './shared/auth/auth.service';
// import { SocketService } from './shared/websocket/socket.service';
import { ApiService } from './shared/api/api.service';
import { AuthenticationGuard } from './shared/auth/authenticationGuard.service';
import { OverviewComponent } from './data/catalog/overview/overview.component';

// *******************************************************************************
// Layouts 

import { Layout1Component } from './layout/layout-1/layout-1.component';
import { Layout2Component } from './layout/layout-2/layout-2.component';
import { LayoutMainNoSidenavComponent } from './layout/layout-main-no-sidenav/layout-main-no-sidenav.component';
import { Layout2FlexComponent } from './layout/layout-2-flex/layout-2-flex.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { LoginComponent } from './pages/login/login.component';
import { CatalogComponent } from './data/catalog/catalog.component';
import { BackupsComponent } from './backups/backups.component';
import { TablesComponent } from './data/catalog/tables/tables.component';
import { TableDetailsComponent } from './data/catalog/tables/table-details/table-details.component';
import { TableOverviewComponent } from './data/catalog/tables/table-overview/table-overview.component';
import { TableColumnsComponent } from './data/catalog/tables/table-columns/table-columns.component';
import { TableLineageComponent } from './data/catalog/tables/table-lineage/table-lineage.component';
import { TableStorageComponent } from './data/catalog/tables/table-storage/table-storage.component';
import { TableUsageComponent } from './data/catalog/tables/table-usage/table-usage.component';
import { TablePermissionsComponent } from './data/catalog/tables/table-permissions/table-permissions.component';
import { ViewsComponent } from './data/catalog/views/views.component';
import { ViewOverviewComponent } from './data/catalog/views/view-overview/view-overview.component';
import { ViewDetailsComponent } from './data/catalog/views/view-details/view-details.component';
import { ViewColumnsComponent } from './data/catalog/views/view-columns/view-columns.component';
import { ViewLineageComponent } from './data/catalog/views/view-lineage/view-lineage.component';
import { ViewStorageComponent } from './data/catalog/views/view-storage/view-storage.component';
import { ViewUsageComponent } from './data/catalog/views/view-usage/view-usage.component';
import { ViewPermissionsComponent } from './data/catalog/views/view-permissions/view-permissions.component';
import { SchemaOverviewComponent } from './data/catalog/schemas/schema-overview/schema-overview.component';
import { SchemaDetailsComponent } from './data/catalog/schemas/schema-details/schema-details.component';
import { DatabaseDetailsComponent } from './data/catalog/databases/database-details/database-details.component';
import { DatabasesOverviewComponent } from './data/catalog/databases/databases-overview/databases-overview.component';
import { FunctionsOverviewComponent } from './data/catalog/functions/functions-overview/functions-overview.component';
import { FunctionDetailsComponent } from './data/catalog/functions/function-details/function-details.component';
import { StagesDetailsComponent } from './data/catalog/stages/stages-details/stages-details.component';
import { StageDetailsComponent } from './data/catalog/stages/stage-details/stage-details.component';
import { StagesOverviewComponent } from './data/catalog/stages/stages-overview/stages-overview.component';
import { FileFormatsOverviewComponent } from './data/catalog/file_formats/file-formats-overview/file-formats-overview.component';
import { FileFormatDetailsComponent } from './data/catalog/file_formats/file-format-details/file-format-details.component';
import { ObjectPrivilegeDetailsComponent } from './data/catalog/object_privileges/object-privilege-details/object-privilege-details.component';
import { ObjectPrivilegesOverviewComponent } from './data/catalog/object_privileges/object-privileges-overview/object-privileges-overview.component';
import { EnabledRoleDetailsComponent } from './data/catalog/enabled_roles/enabled-role-details/enabled-role-details.component';
import { EnabledRolesOverviewComponent } from './data/catalog/enabled_roles/enabled-roles-overview/enabled-roles-overview.component';
import { SequenceDetailsComponent } from './data/catalog/sequences/sequence-details/sequence-details.component';
import { SequencesOverviewComponent } from './data/catalog/sequences/sequences-overview/sequences-overview.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from './shared/shared.module';
import { EditorComponent } from './editor/editor.component';
import { AutomateComponent } from './automate/automate.component';
import { RegisterComponent } from './pages/register/register.component';
import { NgSpinKitModule } from 'ng-spin-kit';
//import { SearchFilterPipe } from './shared/pipes/search-filter.pipe'
import { InviteComponent } from './pages/register/invite/invite.component';
import { ResetComponent } from './pages/login/reset/reset.component';
import { UsersComponent } from './admin/settings/users/users.component';
import { IntegrationsComponent } from './admin/settings/integrations/integrations.component';
import { PasswordsComponent } from './admin/settings/passwords/passwords.component';
import { PackagesComponent } from './packages/packages.component';
// import { ConnectionsComponent } from './connections/connections.component';
import { PackageNewComponent } from './packages/package-new/package-new.component';
import { PackageVariableTemplatesComponent } from './packages/package-variable-templates/package-variable-templates.component';

// added components

// import { CreateSaArchComponent } from 'src/components/create-sa-arch/create-sa-arch.component';


// *******************************************************************************
// Routes

/* tslint:disable */
const routes: Routes = [
  { path: 'login/reset', component: ResetComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'invite', component: InviteComponent },
  {
    path: '',
    component: Layout2Component,
    canActivate: [AuthenticationGuard],
    children: [
            // { path: 'home', component: HomeComponent},
            { path: 'manage', component: BackupsComponent, canActivate:[AuthenticationGuard]},
            { path: 'query', component: EditorComponent, canActivate:[AuthenticationGuard]},
            { path: 'automate', component: AutomateComponent, canActivate:[AuthenticationGuard] },
            { path: 'packages', component: PackagesComponent, canActivate:[AuthenticationGuard] },
            { path: 'packages/new', component: PackageNewComponent, canActivate:[AuthenticationGuard] },
            { path: 'packages/global_configurations', component: PackageVariableTemplatesComponent, canActivate:[AuthenticationGuard] },
            { path: 'packages/update/:package_name', component: PackageNewComponent, canActivate:[AuthenticationGuard] },
            { path: 'settings/connections', component: PasswordsComponent, canActivate:[AuthenticationGuard] },
            { path: 'admin/settings/users', component: UsersComponent, canActivate:[AuthenticationGuard]},
            { path: 'admin/settings/integrations', component: IntegrationsComponent, canActivate:[AuthenticationGuard]},
            { path: 'explore',  component: CatalogComponent, canActivate:[AuthenticationGuard],
              children: [
                { path: 'overview',  component: OverviewComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/object_privileges/:object_privilege', component: ObjectPrivilegeDetailsComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/file_formats/:file_format', component: FileFormatDetailsComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/functions/:function', component: FunctionDetailsComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/stages/:stage', component: StageDetailsComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/sequences/:sequence', component: SequenceDetailsComponent, canActivate:[AuthenticationGuard]},
                { path: 'databases/:database/schemas/:schema/views/:view',  component: TablesComponent,
                   children:[
                    { path: 'overview',  component: TableOverviewComponent, canActivate:[AuthenticationGuard]},
                    { path: 'permissions',  component: TablePermissionsComponent, canActivate:[AuthenticationGuard]},
                    { path: 'columns',  component: TableColumnsComponent, canActivate:[AuthenticationGuard]},
                    { path: 'lineage',  component: TableLineageComponent, canActivate:[AuthenticationGuard]},
                    { path: 'usage',  component: TableUsageComponent, canActivate:[AuthenticationGuard]},
                    { path: '',  redirectTo: 'overview', pathMatch: 'full'}
                  ]
                  , canActivate:[AuthenticationGuard]
                },
                { path: 'databases/:database/schemas/:schema/tables/:table',  component: TablesComponent,
                  children:[
                    { path: 'overview',  component: TableOverviewComponent, canActivate:[AuthenticationGuard]},
                    { path: 'permissions',  component: TablePermissionsComponent, canActivate:[AuthenticationGuard]},
                    { path: 'columns',  component: TableColumnsComponent, canActivate:[AuthenticationGuard]},
                    { path: 'lineage',  component: TableLineageComponent, canActivate:[AuthenticationGuard]},
                    { path: 'usage',  component: TableUsageComponent, canActivate:[AuthenticationGuard]},
                    { path: '',  redirectTo: 'overview', pathMatch: 'full'}
                  ]
                  , canActivate:[AuthenticationGuard]
                }
              ]
            },
              
      ]
  },

  // Not lazy-loaded routes


  // Not Found
  { path: '**', redirectTo: 'query', pathMatch: 'full' }

];
/* tslint:enable */

// *******************************************************************************
//

@NgModule({
  imports: [
    SharedModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(routes,
      { //enableTracing: true,
        //onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [RouterModule, SharedModule, NgSpinKitModule]
  ,

    providers:[AuthenticationGuard, AuthService, ApiService],
    declarations: []

})
export class AppRoutingModule { }
