import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';


// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// *******************************************************************************
// Libs

//import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ContextMenuModule } from 'ngx-contextmenu';
//import { TourNgBootstrapModule } from 'ngx-tour-ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { BlockUIModule } from 'ng-block-ui';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//import { ChartsModule as Ng2ChartsModule } from 'ng2-charts/ng2-charts';
import { MomentModule } from 'ngx-moment';
import { AngularSplitModule } from 'angular-split';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { ThemeSettingsModule } from '../vendor/libs/theme-settings/theme-settings.module';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { CatalogModule } from './data/catalog/catalog.module';
import { BackupsModule } from './backups/backups.module';
import { EditorModule } from './editor/editor.module';
import { HomeModule } from './home/home.module';
import { CodemirrorModule } from 'ng2-codemirror';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CronEditorModule } from './cron-editor/cron-editor.module';
import { AutomateModule } from './automate/automate.module';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { SharedModule } from './shared/shared.module';
import { PackagesModule } from './packages/packages.module';


//import { AuthInterceptor } from './shared/auth/auth.interceptor';

import 'codemirror';
import { InviteComponent } from './pages/register/invite/invite.component';
import { ResetComponent } from './pages/login/reset/reset.component';
import { UsersComponent } from './admin/settings/users/users.component';
import { IntegrationsComponent } from './admin/settings/integrations/integrations.component';
import { PasswordsComponent } from './admin/settings/passwords/passwords.component';



// added components
// import { MatGridListModule } from '@angular/material/grid-list';
// import { FlexLayoutModule } from '@angular/flex-layout';
// import { MatButtonModule } from '@angular/material/button';
// // import { ToolbarComponent } from 'src/components/toolbar/toolbar.component';
// import { MatToolbarModule } from '@angular/material/toolbar';
// // import { ModelerComponent } from '../components/modeler/modeler.component';
// import { MatIconModule } from '@angular/material/icon';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { MatInputModule } from '@angular/material/input';
// // import { DialogAddElementComponent } from 'src/dialogs/dialog-add-element/dialog-add-element.component';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// // import { DialogSaveRADesign } from 'src/dialogs/dialog-save-ra-design/dialog-save-ra-design.component';
// // import { CreateSaArchComponent } from 'src/components/create-sa-arch/create-sa-arch.component';
// import { MatTooltipModule } from '@angular/material/tooltip';
// // import { SelectRaDesign } from 'src/dialogs/select-ra-design/select-ra-design.component';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatSelectModule } from '@angular/material/select';
// import { DesignItemComponent } from 'src/components/design-item/design-item.component';
// import { SuggestionsComponent } from 'src/components/suggestions/suggestions.component';
// import { SettingElementComponent } from 'src/components/setting-element/setting-element.component';
// import { ErrorComponent } from 'src/components/error/error.component';
// import { ConsoleComponent } from 'src/components/console/console.component';



// *******************************************************************************
//

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    InviteComponent,
    ResetComponent,
    UsersComponent,
    IntegrationsComponent,
    PasswordsComponent,
    // ToolbarComponent,
    // //FilterPipe

    // DialogAddElementComponent,
    // DialogSaveRADesign,
    // CreateSaArchComponent,
    // SelectRaDesign,
    // DesignItemComponent,
    // SuggestionsComponent,
    // ModelerComponent,
    // SettingElementComponent,
    // ErrorComponent,
    // ConsoleComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    CodemirrorModule,
    NgxDatatableModule,
    AngularSplitModule,
    CronEditorModule,
    // Ng2ChartsModule,
    MomentModule,
    InputSwitchModule,
    LoadingBarModule,
    PdfViewerModule,

    // App
    AppRoutingModule,
    LayoutModule,
    CatalogModule,
    BackupsModule,
    EditorModule,
    HomeModule,
    AutomateModule,
    TableModule,
    InputTextModule,
    SharedModule,
    PackagesModule,


    ThemeSettingsModule,


    // MatGridListModule,
    // FlexLayoutModule,
    // MatButtonModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatDialogModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatAutocompleteModule,
    // MatProgressSpinnerModule,
    // MatTooltipModule,
    // MatChipsModule,
    // MatTabsModule,
    // MatSelectModule,

    // Libs
    //SweetAlert2Module.forRoot({
    // buttonsStyling: false,
    //confirmButtonClass: 'btn btn-lg btn-primary',
    // cancelButtonClass: 'btn btn-lg btn-default'
    //}),
    ToastrModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      cancelButtonType: 'default btn-sm',
      confirmButtonType: 'primary btn-sm'
    }),
    ContextMenuModule.forRoot(),
    //TourNgBootstrapModule.forRoot(),
    // AgmCoreModule.forRoot({
    //    NOTE: When using Google Maps on your own site you MUST get your own API key:
    //            https://developers.google.com/maps/documentation/javascript/get-api-key
    //            After you got the key paste it in the URL below. 
    //   apiKey: 'AIzaSyCHtdj4L66c05v1UZm-nte1FzUEAN6GKBI'
    // }),
    BlockUIModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],

  providers: [
    Title,
    AppService,
    SharedModule
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    //SharedModule
  ]
})
export class AppModule { }
