-

<div class="container-set">

	<div class="row flex-grow-1" style="padding-top: 20px; padding-bottom: 20px" >
		<div class="col-lg-3" >
			<div class="card mb-2 shadow-hover" style="height: 100%" >
					<div class="card-body" style="padding:0px;">

						<div class="card-subtitle mb-2 card-subtitle-header">
						              <span class="fal fa-database overview-icon" style="color: #e6a84e;"></span>
						              <span class="text-big card-info-title">DATABASES</span>
						          </div>
						            
				            	<div class="card-info-stacked-2">
			                        
			                        <div class="text-center text-big">
			                        	<span>{{summary.total_databases | number:'1.0':'en-US' }}</span>
			                        </div>
			                        <div class="text-muted text-center">Total Databases</div>
			                    </div>
				            	<div class="card-info-stacked-2">
			                        <div class="text-big text-center">
			                        	<span>{{summary.total_schemas | number:'1.0':'en-US' }}</span>
			                    	</div>
			                        <div class="text-muted text-center">Total Schemas</div>
			                    </div>
					</div>
			</div>
		</div>
		<div class="col-lg-3">
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;">
					        <div class="card-subtitle mb-2 card-subtitle-header"> 
					              <span class="fal fa-hdd overview-icon" style="color:#ff7171; margin-left: -20px;"></span>
					              <span class="text-big card-info-title">STORAGE</span>
					          </div>
			            			
		            	 	<div class="card-info-stacked-1">
			                        
		                        <div class="text-big text-center">{{summary.total_size}}</div>
		                        <div class="text-muted text-center">Total Size</div>
		                    </div>
				</div>
			</div>
		</div> 
		<div class="col-lg-3" >
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;">
					
			        <div class="card-subtitle mb-2 card-subtitle-header"> 
				              <span class="fal fa-table overview-icon" style="color:#b692b6; margin-left: -20px;"></span>
				              <span class="text-big card-info-title">TABLES</span>
				          </div>
	            			
            	 	<div class="card-info-stacked-2">
	                        
	                        <div class="text-big text-center">{{summary.total_tables | number:'1.0':'en-US' }}</div>
	                        <div class="text-muted text-center">Total Tables</div>
	                    </div>
		            	<div class="card-info-stacked-2" >
	                        <div class="text-big text-center">{{summary.total_views | number:'1.0':'en-US' }}</div>
	                        <div class="text-muted text-center">Total Views</div>
	                    </div>		            
					
				</div>
			</div>
		</div>
		<div class="col-lg-3" >
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;" >
					<div class="card-subtitle mb-2 card-subtitle-header"> 
					              <span class="fal fa-chart-line overview-icon" style="color:#65bd8a; margin-left: -20px;"></span>
					              <span class="text-big card-info-title">ROW GROWTH</span>
					          </div>
		            			
	            	 	<div class="card-info-stacked-2">
		                        
		                        <div class="text-big text-center">{{summary.total_rows | number:'1.0':'en-US' }}</div>
		                        <div class="text-muted text-center">Total Rows of Data</div>
		                    </div>
			            	<div class="card-info-stacked-2" >
		                        <div id="SparklineRowsDiv" style="height: 50px;"></div>
		                    </div>		            
				
				</div>
			</div>
		</div>
	</div>
		<div class="row" style="height:500px; margin-bottom: 24px;" >
    		<div class="col-lg-6" style="height: 500px">
                <div class="card mb-4 shadow-hover" style="height: 500px;" >
                	<ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="newObj"></ngx-loading-bar>
					<span class="overview-titles"> New Objects Last 15 Days</span>
					<div class="card-body overview-card" >
						<div style="height: 450px; width: 100%;">
							<p-table 
									#newObj
									styleClass="p-datatable-sm"
									selectionMode="single"								
									[value]="newObjects"
									[scrollable]="true" 
									scrollHeight="410px"
									[style]="{height:'410px'}"
									sortMode="single"
				        			responsiveLayout="scroll"
							 >
							<!--  [value]="newObjects"  -->
					        <ng-template pTemplate="header" style="font-size:9pt;">
					            <tr style="font-size: 9pt;">
					            	<th style="width:175px;" pSortableColumn="object_name_full" >Object Name<p-sortIcon field="object_name_full"></p-sortIcon></th>
					                <!-- <th style="width:175px">Object Name</th> -->
					                <th style="width:85px;" pSortableColumn="mod_type">Status<p-sortIcon field="mod_type"></p-sortIcon></th>
					                <!-- <th style="width:85px;">Status</th> -->
					                <th style="width:120px;" pSortableColumn="table_owner">Owner<p-sortIcon field="table_owner"></p-sortIcon></th>
					                <!-- <th style="width:120px;">Owner</th> -->
					                <th style="width:120px;" pSortableColumn="update_date">Update Date<p-sortIcon field="update_date"></p-sortIcon></th>
					                <!-- <th style="width:120px;">Update Date</th> -->

					            </tr>
					        </ng-template>
					        <ng-template pTemplate="body" style="font-size: 9pt;" let-new>
					            <tr style="font-size: 9pt;">
					    			
					           

					                <td style="width: 175px; cursor: pointer;" (click)=routeTableOverview(new.database_name,new.schema_name,new.table_name) >{{new.object_name_full}}</td>
					                <td style="width:85px;">
					                	<div class="text-center" style="width:75px; padding:5px;font-size: 8pt;" [ngClass]="{
		                            	'blue-border': new.mod_type != 'Table Creation', 
		                            	'green-border': new.mod_type == 'Table Creation'
		                            	
		                            	}" 

		                            	>
		                            		<span [ngClass]="{
		                                	'blue-dot': new.mod_type != 'Table Creation',
		                                	'green-dot':  new.mod_type == 'Table Creation'
		                               
		                                	}" 
		                                	class="dot"
		                                	>
		                                	</span>
											<span *ngIf="new.mod_type != 'Table Creation'">Updated</span>
											<span *ngIf="new.mod_type == 'Table Creation'">New</span>
		                            	</div>
					                	
					                </td>
					                <td style="width:120px;">{{new.table_owner}}</td>
					                <td style="width:120px;">{{new.update_date | dateAgo}}</td>
					            </tr>
					        </ng-template>
						</p-table>
					</div>
				</div>
                </div>
            </div>
            <div class="col-lg-6" style="height: 500px;">
                <div class="card mb-4 shadow-hover" style="height: 500px">
                	<ngx-loading-bar fixed [includeSpinner]="false" color="#1900df" ref="popObj"></ngx-loading-bar>
                	<span class="overview-titles">Popular Objects Last 15 Days</span>
                	<div class="card-body overview-card" >
						<div style="height: 450px; width: 100%;">
							<p-table 
									#popObj
									styleClass="p-datatable-sm"
									selectionMode="single"
									[value]="popularObjects"
									[scrollable]="true" 
									scrollHeight="410px"
									[style]="{height:'410px'}"
									sortMode="single"
				        			responsiveLayout="scroll"

				        		
							 >
							<!--  [value]="newObjects"  -->
					        <ng-template pTemplate="header" style="font-size:9pt;">
					            <tr style="font-size:9pt;">
					            	<th style="width:150px;" pSortableColumn="object_name">Object Name<p-sortIcon field="object_name"></p-sortIcon></th>
					            	<th style="width:130px;" pSortableColumn="most_active_user">Most Active User<p-sortIcon field="most_active_user"></p-sortIcon></th>
					            	<th style="width:105px;" pSortableColumn="total_queries">Total Queries<p-sortIcon field="total_queries"></p-sortIcon></th>
					            	<th style="width:105px;" pSortableColumn="distinct_users">Distinct Users<p-sortIcon field="distinct_users"></p-sortIcon></th>
					                <!-- <th style="width:250px">Object Name</th>
					                <th style="width:118px;">Most Active User</th>
					                <th style="width:65px;">Total Queries</th>
					                <th style="width:65px;">Distinct Users</th> -->
					            </tr>
					        </ng-template>
					        <ng-template pTemplate="body" style="font-size: 9pt;" let-new>
					            <tr style="font-size: 9pt;">
					    
					                <td style="width:150px; cursor: pointer;" (click)=routeTableOverview(new.database_name,new.schema_name,new.table_name)>{{new.object_name}}</td>
					                <td style="width:105px;">{{new.most_active_user}}</td>
					                <td style="width: 105px;">{{new.total_queries | number:'1.0':'en-US'}}</td>
					                <td style="width:65px;">{{new.distinct_users | number:'1.0':'en-US'}}</td>
					            </tr>
					        </ng-template>
						</p-table>
					</div>
				</div>
                </div>
            </div>
            
            
        </div>
    	<div class="row" style="height:500px;margin-bottom: 24px;" >
    		<div class="col-lg-6" style="height: 500px">
                <div class="card mb-4 shadow-hover" style="height: 500px;" >
                	<ngx-loading-bar fixed [includeSpinner]="false" color="#00dfac" ref="attenObj"></ngx-loading-bar>
					<span class="overview-titles">Objects Needing Attention</span>
					<div class="card-body overview-card" >
						<div style="height: 450px; width: 100%;">
							<p-table 
									#attObj
									styleClass="p-datatable-sm"
									selectionMode="single"
									[value]="attentionObjects"
									[scrollable]="true" 
									scrollHeight="410px"
									[style]="{height:'410px'}"
									sortMode="single"
				        			responsiveLayout="scroll"
							 >
							<!--  [value]="newObjects"  -->
					        <ng-template pTemplate="header" style="font-size:9pt;">
					            <tr style="font-size: 9pt;">
					            	<th style="width:175px;">Object Name</th>
					            	<th style="width:100px;" pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
					            	<th style="width:200px;" >Details</th>
					                <!-- <th style="width: 175px">Object Name</th>
					                <th style="width:100px;">Status</th>
					                <th style="width:200px;">Details</th> -->
					            </tr>
					        </ng-template>
					        <ng-template pTemplate="body" style="font-size: 9pt;" let-new>
					            <tr style="font-size: 9pt;">
					    
					                <td style="width: 175px; cursor: pointer;" (click)=routeTableOverview(new.database_name,new.schema_name,new.table_name)>{{new.database_name}}.{{new.schema_name}}.{{new.table_name}}</td>
					                <td style="width:100px;">
					                	<div class="text-center" style="width:85px; padding:5px;font-size: 8pt;" [ngClass]="{
		                            	'yellow-border': new.status != 'Duplicates', 
		                            	'red-border': new.status == 'Duplicates'
		                            	
		                            	}" 

		                            	>
		                            		<span [ngClass]="{
		                                	'yellow-dot': new.status != 'Duplicates',
		                                	'red-dot':  new.status == 'Duplicates'
		                               
		                                	}" 
		                                	class="dot"
		                                	>
		                                	</span>
											<span >{{new.status}}</span>
											
		                            	</div>
					                	
					                </td>
					                <td *ngIf="new.status == 'Duplicates'" style="width:200px;"><span style="color:red">{{new.details[0].DUPLICATE_ROWS | number:'1.0':'en-US'}}</span> duplicates out of <span style="font-weight: bold"
					     	>{{new.details[0].TOTAL_ROWS | number:'1.0':'en-US'}} </span> rows as of {{new.details[0].LAST_VALIDATED_DATE | date:'short'}}. <span>Query</span> to identifiy duplicates.</td>
					                 <td *ngIf="new.status == 'Excessive Growth'" style="width:200px;">Grew by <span style="font-weight: bold">{{new.details[0].CURRENT_TABLE_GROWTH_ROWS | number:'1.0':'en-US'}}</span> rows. <span style="color:#b79c5f; font-weight: bold;">{{new.details[0].EXCESSIVE_GROWTH_VALUE| number:'1.0':'en-US'}}</span> rows above the Std. Deviation. 
					                 </td>
					                  <td *ngIf="new.status == 'Limited Growth'" style="width:200px;">Grew by <span style="font-weight: bold">{{new.details[0].CURRENT_TABLE_GROWTH_ROWS | number:'1.0':'en-US'}}</span> rows. <span style="color:#b79c5f; font-weight: bold;">{{new.details[0].LIMITED_GROWTH_VALUE| number:'1.0':'en-US'}}</span> rows above the Std. Deviation. 
					                 </td>
					                
					            </tr>
					        </ng-template>
						</p-table>
					</div>
				</div>
                </div>
                <!-- [{'database_name': 'CLIENT_1_DB', 'schema_name': 'TEST_SCHEMA_1', 'table_name': 'FOREIGN_KEY_TEST', 'status': 'Duplicates', 'details': [{'TOTAL_ROWS': 10, 'UNIQUE_ROWS': 5, 'DUPLICATE_ROWS': 5, 'LAST_VALIDATED_DATE': '2020-08-28T19:09:42.674153', 'DUPICATE_CHECK_QUERY_TEXT': 'select MY_ID, count(*) total_occurrances\nfrom CLIENT_1_DB.TEST_SCHEMA_1.FOREIGN_KEY_TEST\ngroup by MY_ID\nhaving count(*) > 1\nlimit 1000;'}]} -->
            </div>
            <div class="col-lg-6" style="height: 500px">
                <div class="card mb-4 shadow-hover" style="height: 500px">
                	<ngx-loading-bar fixed [includeSpinner]="false" color="#00aadf" ref="allQueries"></ngx-loading-bar>
					<div id="AllQueriesChartDiv" style="height: 500px"></div>
                </div>
            </div>
            
            
        </div>
</div>
