import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-function-details',
  templateUrl: './function-details.component.html',
  styles: []
})
export class FunctionDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
