<div class="container-fluid lineage-screenx" #lineageScreen>

	<ng-container *ngIf="loading ? true : false">
		<div class="row align-items-center h-100">
		  	<div class="col align-self-center " >
	        	<div style="height: 75px; width: 75px; margin:auto">
	    			<img height="75px" width="75px" src="./assets/img/snowguide_loading_main.gif">
	    		</div>
		    </div>   
		</div>  		
	</ng-container>
	<ng-container *ngIf="treeData['children'].length == 0 && !(loading)? true : false">
		<div class="jumbotron jumbotron-fluid" style="background-color: #f9f9f9">
		  <div class="container">
		    <h1 class="display-4">No Data Lineage Found <span style="padding-left: 15px"> <i class="fas fa-chart-network"></i></span></h1>
		    <p class="lead">Give it some time. We're running AI against your query history. </p>
		  </div>
		</div>      		
	</ng-container>
	<ng-container  *ngIf="treeData['children'].length > 0 && !(loading)? true : false">
		<div (click)="showOverview = !showOverview" style="padding-top: 15px;">
			<!-- <i class="fa-solid fa-bars"></i>
		</div>
		<div class="row flex-grow-1" style="padding-top: 20px; padding-bottom: 20px" *ngIf="showOverview">
            <div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%" >
                        <div class="card-body" style="padding:0px;">
                            <div class="card-subtitle mb-2 card-subtitle-header">
                                          <span class="fal fa-mountains overview-icon" style="color:#4cbae3"></span>
                                          <span class="text-big card-info-title">OVERVIEW</span>
                                      </div>
                                        
                                    <div class="card-info-stacked-2">
                                        
                                        <div class="text-center text-big">
                                            <span>{{treeData['sources']}}</span>
                                        </div>
                                        <div class="text-muted small text-center">Total Source Objects</div>
                                    </div>
                                        <div class="card-info-stacked-2">
                                            
                                            <div class="text-center text-big">
                                                <span>{{treeData['destinations']}}</span>
                                            </div>
                                            <div class="text-muted small text-center">Total Destination Objects</div>
                                        </div>
                        </div>
                </div>
            </div> -->
		</div>
		
	</ng-container>
	<div class="chart-div">
		
	</div>
	<svg class="chart-svg" ></svg>
</div>

