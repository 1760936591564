
import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { TablesService } from '../tables.service';
import { Subscription } from 'rxjs/Subscription';
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api/api.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';


@Component({
  selector: 'app-table-columns',
  templateUrl: './table-columns.component.html',
  styles: [],
})
export class TableColumnsComponent implements OnInit {

  public columns: any = [];

  public summary: any = {
  	"most_recent_insert_query_start_time":"...",
  	"last_key_validation_date":"...",
  	"total_columns_count":0,
  	"avg_row_size_mb": 0,
  	"columns_with_all_values_missing":0,
  	"columns_with_missing_cells_in_sample":0,
  	"duplicate_rows_in_sample": 0,
  	"meta_update_date": "...",
  	"missing_cells_in_sample_percent": 0,
  	"profile_date": "...",
  	"sample_warnings_array": [],
  	"loaded": false
  };
  public searchText : string = ''
  databaseName = ''
  schemaName = ''
  tableName = ''
  objectType = ''
  column = ''
  search = ''
  columnFields:any;
  url: string = environment.url;
  tableSubscription: Subscription;
  loaded = false;
  pageName = 'Columns_Overview';
  chartLoading = false;
  charts = [];

  constructor(
  		public http: HttpClient,
			public route: ActivatedRoute,
			public auth: AuthService,
			public tables: TablesService,
			private appService: AppService,
      private loadingBar: LoadingBarService,
      private api: ApiService
  ) { 
  	this.columnFields = {
	  	"column_is_primary_key": "default",
	  	"column_name": "default",
	  	"column_data_type": "default",
	  	"column_is_nullable": "default",
	  	"column_default_value": "default"
	  }
	  this.appService.pageTitle = 'Table Columns';
  }

  

  ngOnInit(){
        
        
        var tableObj = this.tables.getTableObj()
        this.databaseName = tableObj['database']
        this.schemaName = tableObj['schema']
        this.tableName = tableObj['table']
        this.objectType = tableObj['object_type']
        this.getData()
        

        this.tableSubscription = this.tables.getTableObject().subscribe(
            data=>{
                if( this.tables.view == 'columns'){
	                this.databaseName = data['database']
	                this.schemaName = data['schema']
	                this.tableName = data['table']
	                this.objectType = tableObj['object_type']
	                // this.getData()    
	            }
	        }
        );
	}
	ngOnDestroy(){
		// if(this.tableSubscription != undefined){
  //       	console.log('destroy table subscription')
  //      	 	this.tableSubscription.unsubscribe();
  //      	}
    }

    resetSummary(){
      this.summary = {
      "most_recent_insert_query_start_time":"...",
  		"last_key_validation_date":"...",
  		"total_columns_count":0,
  		"avg_row_size_mb": 0,
  		"columns_with_all_values_missing":0,
  		"columns_with_missing_cells_in_sample":0,
  		"duplicate_rows_in_sample": 0,
  		"meta_update_date": "...",
  		"mising_cells_in_sample_percent": 0,
  		"profile_date": "...",
  		"sample_warnings_array": [],
  		"loaded": false
        };
        // console.log('Resetting Summary...')
    }


	getData(){
		this.loaded = false;
		this.summary.loaded = false
        
    let chart1Loader = this.loadingBar.useRef('columns')
    chart1Loader.start()
    chart1Loader.set(25)
        
		this.columns = [];
    let columnURL = this.url + '/catalog/columns/summary'
    let exactPageName = this.pageName + '_' + this.databaseName + '_' + this.schemaName + '_' + this.tableName
    let params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: this.objectType, value: this.tableName}]
    
    this.api.getData(columnURL, exactPageName, 'column_details', {api: true, params: params}).subscribe(
        data=>{
        	// console.log('columns: ', data)
             this.loaded = true;
             this.summary.loaded = true
             chart1Loader.complete()
             console.log('Column Data (getData): ', data)
             this.setColumns(data);
           }
    );
	}

	setColumns(columnData){
		if('message' in columnData){
			this.column = this.route.snapshot.queryParams['column']
			if('column_details' in columnData['message']){
				let columns = columnData['message']['column_details'];
				console.log('columns: ', columns)
	       
		    columns.map(function(el) {
					var c = Object.assign({}, el);
				  c.expanded = false;
				});
		    this.columns = columns;
			}
	    
	    // console.log('columns: ', this.columns)
	    if(this.column){
	    	this.onSearchChange(this.column, true)
	    	this.search = this.column
	    }
	    if('column_summary' in columnData['message']){
	    	this.summary = columnData['message']['column_summary'][0];
	    	this.summary.loaded = true
	    	console.log(this.summary.sample_warnings_array)
	    	console.log(this.summary)
	    }
		}
		
	}

	sortColumns(columnName){
		// console.log(this.columnFields)
		let dir = this.columnFields[columnName]

		if (dir == 'desc' || dir == 'default'){
			
			this.columns.sort((a, b) => {
		
				let fa = a[columnName].toLowerCase(),
					fb = b[columnName].toLowerCase() 

				if (fa < fb){
					return -1
				}
				if (fa > fb) {
			        return 1;
			    }
			    return 0;
			});
			this.columnFields[columnName] = 'asc'
		}
		else{
			
			this.columns.sort((a, b) => {
			
				let fa = a[columnName].toLowerCase(),
					fb = b[columnName].toLowerCase() 

				if (fa > fb){
					return -1
				}
				if (fa < fb) {
			        return 1;
			    }
			    return 0;
			});
			this.columnFields[columnName] = 'desc'
		}
		
	}
	onSearchChange(searchValue : string , exact: boolean =false) {  
	    if (searchValue != ''){
	      this.columns = this.searchColumns(searchValue, exact);
	    }
	    else{
	    	this.clear();
	    }
	    //console.log(this.searchList)
	 }

  searchColumns(searchValue, exact=false){
  	let colNames = []
  	let newCols = []

	for(let i = 0;i < this.columns.length;i++){
		let item = this.columns[i]
	  	for(let property in item){
		  if(item[property] !== null && property in this.columnFields){
			  let val = item[property].toLowerCase()
			  if(exact){
			  	if (item[property].toLowerCase() == searchValue.toLowerCase()){
				   		if(!(colNames.includes(item.column_name))){
				   			colNames.push(item.column_name)
				   			newCols.push(item)
				   		}
				   }
			  }
			  else{
				   if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
				   		if(!(colNames.includes(item.column_name))){
				   			colNames.push(item.column_name)
				   			newCols.push(item)
				   		}
				   }
				 }
			}
	  	}
	}
		
	return newCols;
  }
  getSize(size){
  	if(size/1000 > 1 && size/1000 < 1000){
  		return size/1000 + ' KB'
  	}
  	else if(size/1000000 > 1){
  		return size/1000000 + ' MB'
  	}
  	else{
  		size + ' Bytes'
  	}
  }

  async expandColumn(column){
  	// Retract all other columns
  	this.clearCharts()
  	for(let c of this.columns){
  		if(column.column_name != c.column_name){
  			c.expanded = false;
  		}
  	}
  	column.expanded = !column.expanded

  	// console.log(column.column_profile_details_json)
  	if(column.column_profile_details_json != null){
  		if(column.expanded && column.column_profile_details_json.type == 'Variable.TYPE_CAT'){
	  		this.chartLoading = true;
	  		await this.sleep(100);
	  		this.createTextChart(column.column_profile_details_json);
	  	}
	  	else if (column.expanded && column.column_profile_details_json.type == 'Variable.TYPE_NUM'){
	  		// this.chartLoading = true;
	  		// console.log()
	  		// await this.sleep(500);
	  		// this.createNumChart(column.column_profile_details_json);
	  	}
  	}
  }

  clearCharts(){
  	for(let c of this.charts){
  		c.dispose()
  	}
  	this.charts = []
  }

  
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  createNumChart(profile){

			let data = profile.histogram[0].replace("\\n", "").replace("[", "").replace("]", "").split(" ").filter(Number) 
			
			// Source data
			var maxCols = 10;
			function getHistogramData(source) {
			  
			  // Init
			  var data = [];
			  var min = Math.min.apply(null, source);
			  var max = Math.max.apply(null, source);
			  var range = max - min;
			  var step = range / maxCols;
			  
			  // Create items
			  for(var i = 0; i < maxCols; i++) {
			    var from = min + i * step;
			    var to = min + (i + 1) * step;
			    data.push({
			      from: from,
			      to: to,
			      count: 0
			    });
			  }
			  
			  // Calculate range of the values
			  for(var i = 0; i < source.length; i++) {
			    var value = source[i];
			    var item = data.find(function(el) {
			      return (value >= el.from) && (value <= el.to);
			    });
			    item.count++;
			  }
			  
			  return data;
			}

			// Create chart instance
			var chart = am4core.create("profileNumChartDiv", am4charts.XYChart);
			this.chartLoading = false;
			this.charts.push(chart)
			// Add data
			chart.data = getHistogramData(data);

			// Create axes
			var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "from";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = 30;

			var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

			// Create series
			var series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.valueY = "count";
			series.dataFields.categoryX = "from";
			// series.columns.template.tooltipText = "{from} - {to}\n[bold]Count: {count}[/]";

  }

  createTextChart(profile){

			let chart = am4core.create("profileTextChartDiv", am4charts.XYChart);
			this.charts.push(chart)
			this.chartLoading = false;

			let vals = []
			let high_vals = profile.high_vals;

			for(let k in high_vals){
				let obj = {category: k, count: high_vals[k]}
				vals.push(obj)
			}

			// Add data
			chart.data = vals;
			chart.padding(10, 10, 10, 10);

			let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.dataFields.category = "category";
			categoryAxis.renderer.minGridDistance = 1;
			categoryAxis.renderer.inversed = true;
			categoryAxis.renderer.grid.template.disabled = true;

			let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
			valueAxis.min = 0;

			let series = chart.series.push(new am4charts.ColumnSeries());
			series.dataFields.categoryY = "category";
			series.dataFields.valueX = "count";
			series.tooltipText = "{valueX.value}"
			series.columns.template.strokeOpacity = 0;
			series.columns.template.column.cornerRadiusBottomRight = 5;
			series.columns.template.column.cornerRadiusTopRight = 5;

			let labelBullet = series.bullets.push(new am4charts.LabelBullet())
			labelBullet.label.horizontalCenter = "left";
			labelBullet.label.dx = 10;
			labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.0as')}";
			labelBullet.locationX = 1;

			// as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
			series.columns.template.adapter.add("fill", function(fill, target){
			  return chart.colors.getIndex(target.dataItem.index);
			});

			categoryAxis.sortBySeries = series;

  }

	clear(){
	    this.columns = this.tables.data['columnData']['column_details'];
	    this.search = '';
	  }
  countSummaryDates(){
    let count = 0;
    if (this.summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.meta_update_date != null && this.summary.meta_update_date != ''){
        count++;
    }
    if (this.summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.last_key_validation_date != null && this.summary.last_key_validation_date != ''){
        count++;
    }
    if (this.summary.profile_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.profile_date != null && this.summary.profile_date != ''){
        count++;
    }
    return count;
  }	  
}