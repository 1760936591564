<div class="container-fluid" style="background-color: white;">
	<div class="row flex-grow-1" style="padding-top: 20px; padding-bottom: 20px" >
		<div class="col-lg-3" >
            <div class="card mb-2 shadow-hover" style="height: 100%" >
					<div class="card-body" style="padding:0px;">
						<div class="card-subtitle mb-2 card-subtitle-header">
						              <span class="fal fa-hdd overview-icon" style="color:#ff7171"></span>
						              <span class="text-big card-info-title">SIZE</span>
						          </div>
						            
				            	<div class="card-info-stacked-2">
			                        
			                        <div class="text-center text-big">
			                        	<span>{{summary.total_columns_count}}</span>
			                        </div>
			                        <div class="text-muted text-center">Columns</div>
			                    </div>
				            	<div class="card-info-stacked-2">
			                        <div class="text-big text-center">
                                        <div *ngIf="summary.last_key_validation_date == 'Mon, 01 Jan 1900 00:00:00 GMT' || summary.avg_row_size_mb == null" class="text-big text-center">N/A</div>
                                        <div *ngIf="summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.avg_row_size_mb != null" class="text-center">{{summary.avg_row_size_mb}}</div>
			                    	</div>
			                        <div class="text-muted text-center">Avg Row Size MB</div>
			                    </div>
					</div>
			</div>
		</div>
        <div class="col-lg-3" >
            <div class="card mb-2 shadow-hover" style="height: 100%" >
					<div class="card-body" style="padding:0px;">
						<div class="card-subtitle mb-2 card-subtitle-header">
						              <span class="fal fa-sliders-v-square overview-icon" style="color:#62ae82;"></span>
						              <span class="text-big card-info-title">PROFILE</span>
						          </div>
						            
				            	<div class="card-info-stacked">
			                        
			                        <div class="text-center text-big">
			                        	<span>{{summary.missing_cells_in_sample_percent | percent}}</span>
			                        </div>
			                        <div class="text-muted text-center">Cells of Null Data</div>
			                    </div>
				            	<div class="card-info-stacked">
			                        <div class="text-center text-big">
			                        	<span>{{summary.duplicate_rows_in_sample}}</span>
			                        </div>
			                        <div class="text-muted text-center">Duplicate Rows</div>
			                    </div>
                                <div class="card-info-stacked">
			                        <div class="text-center text-big">
			                        	<span>{{summary.columns_with_missing_cells_in_sample}}</span>
			                        </div>
			                        <div class="text-muted text-center">Columns w/ Null</div>
			                    </div>
					</div>
			</div>
		</div>
		<div class="col-lg-3" >
                <div class="card mb-2 shadow-hover" style="height: 100%">
                    <div class="card-body" style="padding:0px;">
                        <div class="card-subtitle mb-2 card-subtitle-header"> 
                                  <span class="fal fa-calendar-day overview-icon" style="color:#8c6daf; margin-left: -20px;"></span>
                                  <span class="text-big card-info-title">DATES</span>
                              </div>
                        <div *ngIf="summary.loaded == false" class="card-info-stacked-1" style="font-size: 24px;">...</div>
	            		
	            	<!-- <div *ngIf="summary.loaded && summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
            	 		<div *ngIf="summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != ''" class="text-big text-center">{{summary.meta_update_date | dateAgo }}</div>
                        <div *ngIf="summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != null && summary.meta_update_date != ''" class="text-muted text-center">Last Merge</div>
                    </div> -->
                          
       
                    <div *ngIf="summary.loaded && summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                    	<div *ngIf="summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != ''" class="text-big text-center">{{summary.meta_update_date | dateAgo }}</div>
                        <div *ngIf="summary.meta_update_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.meta_update_date != null && summary.meta_update_date != ''" class="text-muted text-center">Last Column Altered</div>
                    </div>
                             

                    <div *ngIf="summary.loaded && summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_key_validation_date != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">	
                    	<div *ngIf="summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_key_validation_date != ''" class="text-big text-center">{{summary.last_key_validation_date | dateAgo }}</div>
                        <div *ngIf="summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_key_validation_date != null && summary.last_key_validation_date != ''" class="text-muted text-center">Not Yet Profiled</div>
                    </div>
                               
					<div *ngIf="summary.loaded && summary.profile_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.profile_date != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                    	<div *ngIf="summary.profile_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.profile_date != ''" class="text-big text-center">{{summary.profile_date | dateAgo }}</div>
                        <div *ngIf="summary.profile_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.profile_date != null && summary.profile_date != ''" class="text-muted text-center">Last Profiled</div>
                    </div>

                                





		            	

		            
					
				</div>
			</div>
		</div>
        <div class="col-lg-3" >
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;">
					
			        <div class="card-subtitle mb-2 card-subtitle-header"> 
				              <span class="fal fa-exclamation-triangle overview-icon" style="color:#ce7d00; margin-left: -20px;"></span>
				              <span class="text-big card-info-title">WARNINGS</span>
				          </div>
	            			
					      <div class="card-info-stacked-1" style="overflow-y: auto !important; overflow-x: hidden; max-height: 65px;">
					  
					      	<ul style="padding-left: 15px;" *ngIf="(summary.sample_warnings_array.length != 1 && summary.sample_warnings_array[0] != '') || summary.sample_warnings_array.length > 0">
					      		<li 
					      		 *ngFor="let warning of summary.sample_warnings_array"
					      		style="font-size: 9pt; text-align: left; padding: 5px;"
					      		>{{warning}}</li>
					      	</ul>
					      	<div *ngIf="summary.sample_warnings_array.length == 1 && summary.sample_warnings_array[0] == '' || summary.sample_warnings_array.length == 0">
					      		<i class="fa-light fa-circle-check" style="color: green;padding-right: 3px;"></i> No Issues
					      	</div>
					      	
			             </div>		            
					
				</div>
			</div>
		</div>
	</div>
	
		<div style="background-color: white; border: 1px solid #eee; margin-bottom: 25px; border-radius: 8px;" class="shadow">
			<div class="row" style=" padding: 15px 10px 15px">
				<div class="col-md-6">
                
				 <label class="nav-item navbar-text navbar-search-box p-0 active">
					<i class="ion ion-ios-search navbar-icon align-middle"></i>
			          <span class="navbar-search-input pl-2" style="width: 400px !important">
			            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important">
			          </span>
			          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
			        </label>
				</div>
				<div class="col-md-6 my-auto text-right">Columns: {{columns.length}}</div>
			</div>
			<div>
                <ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="columns"></ngx-loading-bar>
				<table class="table table-bordered table-hover">
					<thead style="border-color: #979899; font-weight: bold; table-layout: fixed;">
					    <tr style="border-color: #979899;">
					      <th scope="col-sm" style="font-weight: bold; width: 75px">
					      	<span style="float: left">PK</span>
					      	<span  style="color:#a9abac;" class="sort-arrow" (click)="sortColumns('column_is_primary_key')"><i class="fa fa-sort-alt"></i></span>
					      </th>
					      <th scope="col" style="font-weight: bold">
					      	<span style="float:left">Name</span>
					      	<span class="sort-arrow" (click)="sortColumns('column_name')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
					      </th>
					      <th scope="col" style="font-weight: bold">
					      	<span style="float:left">Data Type</span>
					      	<span class="sort-arrow"  (click)="sortColumns('column_data_type')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
					      </th>
					      <th scope="col-sm" style="font-weight: bold">
					      	<span style="float:left">Nullable</span>
					      	<span class="sort-arrow"  (click)="sortColumns('column_is_nullable')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
					      </th>
					      <th scope="col" style="font-weight: bold">
					      	<span style="float:left">Default</span>
					      	<span class="sort-arrow"  (click)="sortColumns('column_default_value')"><i class="fa fa-sort-alt" style="color:#a9abac"></i></span>
					      </th>
					    </tr>
					  </thead>
					  <tbody style="overflow: auto">
					  	<ng-container *ngFor="let column of columns">
					  	<tr class="clickable-row" (click)="expandColumn(column)">
					  	  <td class="text-center"><span *ngIf="column.column_is_primary_key === 'Yes' ? true : false"><i class="far fa-check-circle fa-lg" style="color:#89d28b"></i></span></td>
					      <td>{{column.column_name}}</td>
					      <td>{{column.column_data_type}}</td>
					      <td>{{column.column_is_nullable}}</td>
					      <td>{{column.column_default_value}}</td>
					    </tr>
					      <ng-container *ngIf="column.expanded && column.column_profile_details_json == null">
						    <tr>
						      <td colspan="5" style="padding: 25px 85px;">
						       Unable to retrieve profile data
						      </td>
						    </tr>
						   </ng-container>
						  <ng-container *ngIf="column.expanded && column.column_profile_details_json != null">
						    <tr>
						      <td colspan="5" style="padding: 25px 85px;"> 
						 		<div *ngIf="column.column_profile_details_json.type == 'Variable.TYPE_CAT'" class="row">

						 			<div class="col-3" style="font-size: 11pt;">
						 				<div class="row" style="padding: 2px; font-size: 14pt; padding-bottom: 10px;">
							 				<div class="col-6" style="color: #4cbae3;">Profile</div>
							 				
							 			</div>
							 			<div class="row" style="padding: 2px; padding-top: 10px;">
							 				<div class="col-6" style="font-weight: bold">Count</div>
							 				<div class="col-6">{{column.column_profile_details_json.count}}</div>
							 			</div>
							 			<div class="row" style="padding: 2px;">
							 				<div class="col-6" style="font-weight: bold">Distinct</div>
							 				<div class="col-6">{{column.column_profile_details_json.n_distinct}}</div>
							 			</div>
							 			<div class="row" style="padding: 2px;">
							 				<div class="col-6" style="font-weight: bold">Distinct (%)</div>
							 				<div class="col-6">{{column.column_profile_details_json.p_distinct | percent:'1.0-1'}}</div>
							 			</div>
							 			<div class="row" style="padding: 2px;">
							 				<div class="col-6" style="font-weight: bold">Missing</div>
							 				<div class="col-6">{{column.column_profile_details_json.n_missing}}</div>
							 			</div>
							 			<div class="row" style="padding: 2px;">
							 				<div class="col-6" style="font-weight: bold">Missing (%)</div>
							 				<div class="col-6">{{column.column_profile_details_json.p_missing | percent:'1.0-1'}}</div>
							 			</div>
							 			<div class="row" style="padding: 2px;">
							 				<div class="col-6" style="font-weight: bold">Memory Size</div>
							 				<div class="col-6">{{getSize(column.column_profile_details_json.memory_size)}}</div>
							 			</div>
							 		</div>
							 		<div class="col-1"></div>
							 		<div class="col-6">
							 			
							 			<div id="profileTextChartDiv" style="height: 250px;">
							 				<div *ngIf="chartLoading" style="width: 200px">
								 				<div class="spinner-border" role="status" style="margin:  100px;; color: #4cbae3 !important;">
									            <span  class="sr-only">Loading...</span>
									          </div>
								 			</div>
							 			</div>
							 		</div>
							 		<div class="col-2"></div>
						 		</div>
						 		<div *ngIf="column.column_profile_details_json.type == 'Variable.TYPE_NUM'" class="row">

						 			<div class="col-6" style="font-size: 11pt;">
						 				<div class="row" style="padding: 2px; font-size: 14pt; padding-bottom: 10px;">
							 				<div class="col" style="color: #4cbae3;">Profile</div>
							 			</div>
						 				<div class="row">
						 					<div class="col-6">
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Distinct</div>
									 				<div class="col-6">{{column.column_profile_details_json.n_distinct}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Distinct (%)</div>
									 				<div class="col-6">{{column.column_profile_details_json.p_distinct | percent:'1.0-1'}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Missing</div>
									 				<div class="col-6">{{column.column_profile_details_json.n_missing}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Missing (%)</div>
									 				<div class="col-6">{{column.column_profile_details_json.p_missing | percent:'1.0-1'}}
									 				</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Memory Size</div>
									 				<div class="col-6">{{getSize(column.column_profile_details_json.memory_size)}}</div>
									 			</div>
						 					</div>
						 					<div class="col-6">
						 						<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Mean</div>
									 				<div class="col-6">{{column.column_profile_details_json.mean | number:'1.0':'en-US'}}</div>
									 			</div>
						 						<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Minimum</div>
									 				<div class="col-6">{{column.column_profile_details_json.min}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Maximum</div>
									 				<div class="col-6">{{column.column_profile_details_json.max}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Zeros</div>
									 				<div class="col-6">{{column.column_profile_details_json.n_zeros}}</div>
									 			</div>
									 			<div class="row" style="padding: 2px;">
									 				<div class="col-6" style="font-weight: bold">Zeros (%)</div>
									 				<div class="col-6">{{column.column_profile_details_json.p_zeros| percent:'1.0-1'}}</div>
									 			</div>
									 			
						 					</div>
						 				</div>
							 		</div>
							 		<div class="col-1"></div>
							 		<div class="col-5">
							 			<div *ngIf="chartLoading" style="height: 200px; width: 200px;">
							 				<div class="spinner-border" role="status" style="margin: 50px; color: #4cbae3 !important;">
								            <span  class="sr-only">Loading...</span>
								          </div>
							 			</div>
							 			<!-- <div id="profileNumChartDiv" style="height: 240px"></div> -->
							 		</div>
						 		</div>
						 		<!-- {{column.column_profile_details_json | json}} -->
						 		<div *ngIf="column.column_profile_details_json.type != 'Variable.TYPE_NUM'  || column.column_profile_details_json.type == 'Variable.TYPE_CAT'">
						      		<!-- {{column.column_profile_details_json | json}} -->
						      	</div>
						      </td>
						      
						    </tr>
						  </ng-container>
						</ng-container>

					  </tbody>
				</table>
			</div>
			<!--<ng-container *ngIf="!loaded?true:false">
			  	<div class="align-self-center" style="margin: 25px 0px">
			        	<div style="height: 75px; width: 75px; margin:auto">
		        			<img height="75px" width="75px" src="./assets/img/snowguide_loading_main.gif">
		        		</div>
			    </div>
			</ng-container>-->
		</div>
	
</div>