import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

    public activeItem: any;
    menuItems = new Array<any>();
    private subject$ = new Subject<any>();
    private activeItem$ = new ReplaySubject<any>(6);

    constructor() {}

    setMenu(menu){
        this.menuItems = []
        this.addMenu(menu)
        //console.log(this.menuItems)
        this.subject$.next(this.menuItems)
        //console.log(this.subject$)
    }
    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu(): Observable<any> {
        return this.subject$.asObservable();
    }

}
