import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../shared/auth/auth.service';
import { TablesService } from '../tables.service';
import { DatePipe } from '@angular/common'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import * as am4plugins_regression from "@amcharts/amcharts4/plugins/regression"; 
import { Subscription } from 'rxjs/Subscription';
import { AppService } from '../../../../app.service';
import { ApiService } from '../../../../shared/api/api.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';




@Component({
  selector: 'app-table-usage',
  templateUrl: './table-usage.component.html',
  styles: []
})
export class TableUsageComponent implements OnInit {
    
    databaseName = ''
	schemaName = ''
	tableName = ''
    objectType = ''


	url: string = environment.url;
	display: string = ''
	items: Array<any> = []
	view: String = '';
	routes: Array<any> = []
	scripts: Object = {}
    tableSubscription: Subscription;
	summary_loaded = false;
	summary_url: string = ''
	dailyQueriesData: Array<any> = [{}];
	dayOfWeekData: Array<any> = [{}];
    usersChartData: Array<any> = [{}];
    //private status$ = new Subject<any>();

    dailyQueriesLoaded = false;
    dayOfWeekLoaded = false;
    usersChartLoaded = false;
    gettingChart = false;
    refresh = true;
    dailyQueriesChart;
    dayOfWeekChart;
    usersChart;
    pageName = 'Table_Usage';

    public summary: any = {
      "day_with_highest_average_query_count":"...",
      "distinct_types_of_queries": "...",
      "most_recent_insert_query_start_time":"...",
      "most_recent_merge_query_start_time":"...",
      "most_recent_select_query_start_time": "...",
      "most_recent_update_query_start_time": "...",
      "top_users":[],
      "total_queries_sampled":"...",
      "distinct_users_who_queried":"...",
      "loaded": false
    };

	constructor(
		public http: HttpClient,
		public route: ActivatedRoute,
		public auth: AuthService,
		public tables: TablesService,
		private datePipe: DatePipe,
        private appService: AppService,
        private loadingBar: LoadingBarService,
        private api: ApiService
	) { 
		this.resetSummary();
		this.dailyQueriesData[0]['label'] = 'Queries by Day and Type'
		this.dayOfWeekData[0]['label'] = 'Day of Week'
        this.usersChartData[0]['label'] = 'Queries by User and Type'
        this.dailyQueriesLoaded = false;
        this.dayOfWeekLoaded = false;
        this.usersChartLoaded = false;
        this.refresh = true;
        this.appService.pageTitle = 'Table Usage';
       
    }
  ngOnInit() {
        
        this.resetSummary();
        this.resetCharts();
        var tableObj = this.tables.getTableObj()
        this.databaseName = tableObj['database']
        this.schemaName = tableObj['schema']
        this.tableName = tableObj['table']
        this.objectType = tableObj['object_type']
        this.gettingChart = true;
        this.getData();

        this.tableSubscription = this.tables.getTableObject().subscribe(
            data=>{
                if( this.tables.view == 'usage'){
                   
                

                    let case1 = false
                    if(this.databaseName != data['database']){
                        case1 = true
                    }
                    let case2 = false
                    if(this.schemaName != data['schema']){
                        case1 = true
                    }
                    let case3 = false
                    if(this.tableName != data['table']){
                        case1 = true
                    }

                   

                    if(case1 || case2 || case3){
                        this.resetSummary();
                        this.clearChartDivs()
                        this.databaseName = data['database']
                        this.schemaName = data['schema']
                        this.tableName = data['table']
                        this.objectType = tableObj['object_type']
                        this.gettingChart = true;

                        this.resetCharts();
                      
                        this.getData();
                        this.refresh = false;
                    }
                    
                }
      
            }
        );
	}
    ngOnDestroy(){
        if(this.tableSubscription != undefined){
            this.tableSubscription.unsubscribe();
        }
        am4core.disposeAllCharts();
        this.clearChartDivs()
    }
    resetSummary(){
      this.summary = {
          "day_with_highest_average_query_count":"...",
          "distinct_types_of_queries": "...",
          "most_recent_insert_query_start_time":"...",
          "most_recent_merge_query_start_time":"...",
          "most_recent_select_query_start_time": "...",
          "most_recent_update_query_start_time": "...",
          "top_users":[],
          "total_queries_sampled":"...",
          "distinct_users_who_queried":"...",
          "loaded": false 
        };
        // console.log('Resetting Summary...')
    }

   resetCharts(){
       this.dailyQueriesLoaded = false;
       this.dayOfWeekLoaded = false;
       this.usersChartLoaded = false;
   }
   
  clearChartDivs(){
      if(document.querySelector('div#DailyOverviewDiv') !== undefined){
           document.querySelector('div#DailyOverviewDiv').innerHTML = "";
      }
      if(document.querySelector('div#QueriesByDayDiv') !== undefined){
           document.querySelector('div#QueriesByDayDiv').innerHTML = "";
      }
      if( document.querySelector('div#DailyDetailDiv') !== undefined){
          document.querySelector('div#DailyDetailDiv').innerHTML = "";
      }
   }

  
  private displayDailyOverviewChart(chartData){

    // data
        let data = chartData;


        // Create chart instance
        let chart = am4core.create("DailyOverviewDiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        chart.paddingRight = 40;
        chart.paddingLeft = 40;


        /* Chart code */
        // Themes begin
        //am4core.useTheme(am4themes_animated);
        // Themes end

        // Add data
        chart.data = data;

        // Create axes
        //let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        //categoryAxis.dataFields.category = "query_date";
        //categoryAxis.renderer.grid.template.disabled = true;
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dateFormats.setKey("day", "MMM dt");
        //dateAxis.label.padding(0,50,0,0);
        //totalBullet.label.padding(5, 10, 5, 10);

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.calculateTotals = true;
        valueAxis.extraMax = 0.04;
        
        // Create series
        function createSeries(field, name) {
          
          // Set up series
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.name = name;
          series.dataFields.valueY = field;
          series.dataFields.dateX = "query_date";
          series.sequencedInterpolation = true;
          
          // Make it stacked
          series.stacked = true;
          
          // Configure columns
          series.columns.template.width = am4core.percent(60);
          series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{dateX}: [bold]{valueY}[/] Queries";
          
          
          // Add label
          let labelBullet = series.bullets.push(new am4charts.LabelBullet());
          labelBullet.label.text = "{valueY}";
          labelBullet.locationY = 0.5;
          labelBullet.label.hideOversized = true;
          
          return series;
        }

        createSeries("select_queries", "Select");
        createSeries("insert_queries", "Insert");
        createSeries("merge_queries", "Merge");
        createSeries("delete_queries", "Delete");
        createSeries("update_queries", "Update");
        
        // Create series for total
        var totalSeries = chart.series.push(new am4charts.ColumnSeries());
        totalSeries.dataFields.valueY = "none";
        totalSeries.dataFields.dateX = "query_date";
        totalSeries.stacked = true;
        totalSeries.hiddenInLegend = true;
        totalSeries.columns.template.strokeOpacity = 0;
        
        let totalBullet = totalSeries.bullets.push(new am4charts.LabelBullet());
        totalBullet.dy = -10;
        totalBullet.label.text = "{valueY.total}";
        totalBullet.label.hideOversized = false;
        totalBullet.label.fontSize = 14;
        totalBullet.label.fontWeight = "bold";
        //totalBullet.label.background.fill = totalSeries.stroke;
        //totalBullet.label.background.fillOpacity = 0.2;
        //totalBullet.label.padding(5, 10, 5, 10);
        
        this.dailyQueriesLoaded = true;
  }
  
  private displayDailyDetailChart(chartData){
      
        let chart = am4core.create("DailyDetailDiv", am4charts.XYChart);

        chart.scrollbarY = new am4core.Scrollbar();
        chart.scrollbarY.parent = chart.rightAxesContainer;
        
        let data = chartData;
        
        chart.data = data;
        
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "id";
        //categoryAxis.dataFields.title = "query_type";
        //categoryAxis.renderer.labels.template.visible = false;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.fontSize = 10;
        //categoryAxis.title = "query_type";
        categoryAxis.start = 1;
        categoryAxis.end = .8;
        //categoryAxis.renderer.labels.template.text = "EFF";
        //categoryAxis.keepSelection = true;
        categoryAxis.renderer.labels.template.adapter.add("textOutput", function(text) {
           var re = new RegExp('(.*)_');
           return text.replace(re, "");
         });
        
        

        // Create axes
        //var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        //dateAxis.renderer.grid.template.location = 0;
        //dateAxis.renderer.minGridDistance = 20;
        //dateAxis.renderer.labels.template.fontSize = 10;
        //dateAxis.dateFormats.setKey("month", "MM");
        //dateAxis.periodChangeDateFormats.setKey("month", "MM");

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "value";
        series.dataFields.categoryY = "id";
        //series.dataFields.label = "query_type";
        //series.name = name;
        series.strokeWidth = 2;
        series.columns.template.strokeWidth = 0;
        series.columns.template.propertyFields.fill = "barcolor";
        //series.columns.template.propertyFields.label = "query_type";
        
        
        let columnTemplate = series.columns.template;
        // add tooltip on column
        columnTemplate.column.tooltipText = "[bold]{date}[/] \n [bold]{user}[/] had [bold]{valueX} {query_type}[/] queries";
        columnTemplate.column.cornerRadiusTopRight = 30;
        columnTemplate.column.cornerRadiusBottomRight = 30;
        //columnTemplate2.strokeOpacity = 0;
        
        let bullet = series.bullets.push(new am4charts.LabelBullet);
        bullet.label.text = "{value}";
        //bullet.locationY = 1;
        bullet.dx = 3;
        bullet.label.horizontalCenter = "left";
        bullet.label.fontWeight = "bold";
        //bullet.label.rotation = 90;
        bullet.label.truncate = false;
        bullet.label.hideOversized = false;

/*         series.columns.template.events.once("inited", function(event){
          event.target.fill = chart.colors.getIndex(event.target.dataItem.index);
        });
        series.columns.template.adapter.add("fill", function(fill, target) {
          if (target.dataItem && (target.dataItem.valueY < 0)) {
            return am4core.color("#a55");
          }
          else {
            return fill;
          }
        }); */
        

        //let range = categoryAxis.axisRanges.create();
//range.category = "B";
//range.endCategory = "D";
//range.axisFill.fill = am4core.color("#396478");
//range.axisFill.fillOpacity = 0.3;
//range.locations.category = 0.2;
//range.locations.endCategory = 0.8;

        // Create ranges
        function createRange(from, to, label, istoplevel, fillbackground) {
          var range = categoryAxis.axisRanges.create();
          range.category = from;
          range.endCategory = to;
          range.label.text = label;
          range.label.paddingRight = istoplevel ? 150 : 75;
          range.label.location = 0.5;
          range.label.horizontalCenter = "middle";
          range.label.fontSize = istoplevel ? 16 : 14;
          range.label.fontWeight = istoplevel ? "bold" : "bolder";

          if (fillbackground){
              range.axisFill.fill = am4core.color("#396478");
              range.axisFill.fillOpacity = 0.1;
          }
          //range.locations.date = 0;
          //range.locations.endDate = 1;
          
          range.grid.strokeOpacity = istoplevel ? 1 : .3;
        }
        
        function isEven(n) {
           return n % 2 == 0;
        }
        
        let curDate = "";
        let curUser = "";
        let curQueryType = "";
        let counter = 0;
        let counterDate = 0;
        let curId = "";
        let prevId = "";
        let curPeriodDateId = "";
        let curPeriodDateEndId = "";
        let lastRow = data.length-1;
        
        for (var myDataRow in data) {

            let curDataRow = data[myDataRow];
            let curDataDate = curDataRow["date"];
            let curDataUser = curDataRow["user"];
            let curDataQueryType = curDataRow["query_type"];
            let curDataId = curDataRow["id"];
            
            if (counter == 0) {
                curDate = curDataDate;
                curUser = curDataUser;
                curQueryType = curDataQueryType;
                curId = curDataId;
                prevId = curDataId;
                curPeriodDateId = curDataId;
                curPeriodDateEndId = curDataId;
            }
            else {
                if (curDate !== curDataDate) {
                    
                    createRange(curId, prevId, curUser, false, isEven(counterDate));

                    createRange(curPeriodDateId, curPeriodDateEndId, curDate, true, isEven(counterDate));

                    //reset the variables
                    curDate = curDataDate;
                    curUser = curDataUser;
                    curQueryType = curDataQueryType;
                    curId = curDataId;
                    prevId = curDataId;
                    curPeriodDateId = curDataId;
                    curPeriodDateEndId = curDataId;
                    counterDate++;
                }
                else if (curUser !== curDataUser) {
                    //Create Range just for the current date/user
                    createRange(curId, prevId, curUser, false, isEven(counterDate));
                    //reset the variables
                    curDate = curDataDate;
                    curUser = curDataUser;
                    curQueryType = curDataQueryType;
                    curId = curDataId;
                    prevId = curDataId;
                    curPeriodDateEndId = curDataId;
                }
                else { //This should just be for the same user with a different end id 
                
                    prevId = curDataId;
                    curPeriodDateEndId = curDataId;
                    
                }
            }
            if (counter === lastRow){
                //Create Range just for the current date/user
                createRange(curId, prevId, curUser, false, isEven(counterDate));

                //Create range with shaded background
                createRange(curPeriodDateId, curPeriodDateEndId, curDate, true, isEven(counterDate));
            }
            
            counter++;
            
        }
        /*
        createRange("2020-09-01_dave_Update", "2020-09-01_dave_Select", "Dave", false, true);
        createRange("2020-09-01_cody_Update", "2020-09-01_cody_Select", "Cody", false, true);
        createRange("2020-09-01_adam_Update", "2020-09-01_adam_Select", "Adam", false, true);
        createRange("2020-09-01_nate_Update", "2020-09-01_nate_Select", "Nate", false, true);
        createRange("2020-09-01_dave_Update", "2020-09-01_nate_Select", "2020-09-01", true, true);
        
        createRange("2020-09-02_dave_Update", "2020-09-02_dave_Select", "Dave", false, false);
        createRange("2020-09-02_cody_Update", "2020-09-02_cody_Select", "Cody", false, false);
        createRange("2020-09-02_adam_Update", "2020-09-02_adam_Select", "Adam", false, false);
        createRange("2020-09-02_nate_Update", "2020-09-02_nate_Select", "Nate", false, false);
        createRange("2020-09-02_dave_Update", "2020-09-02_nate_Select", "2020-09-02", true, false);
        */
        
  }
  
    private displayQueriesByDayChart(chartData){
        let chart = am4core.create("QueriesByDayDiv", am4charts.XYChart);
        chart.paddingRight = 0;
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.disabled = true;
        chart.cursor.lineX.disabled = true;

        // Add data
        chart.data = chartData;
        
        /*{
            "day_of_week": "Monday",
            "id": 1.0,
            "total_queries": 3
        },
        {
            "day_of_week": "Tuesday",
            "id": 2.0,
            "total_queries": 2
        },
        {
            "day_of_week": "Thursday",
            "id": 4.0,
            "total_queries": 3
        }*/
        
        /*[{
          "day": "Sunday",
          "value": 25
        }, {
          "day": "Monday",
          "value": 225
        }, {
          "day": "Tuesday",
          "value": 125
        }, {
          "day": "Wednesday",
          "value": 93
        }, {
          "day": "Thursday",
          "value": 98
        }, {
          "day": "Friday",
          "value": 59
        }, {
          "day": "Saturday",
          "value": 29
        }];*/

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        //categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.cursorTooltipEnabled = false;
        //categoryAxis.startLocation = 0.5;
        //categoryAxis.endLocation = 0.5;

        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //valueAxis.baseValue = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;

        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "day";
        series.strokeWidth = 2;
        series.tensionX = 0.7;
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";

        // bullet is added because we add tooltip to a bullet for it to change color
        let bullet = series.bullets.push(new am4charts.Bullet());
        bullet.tooltipText = "{valueY}";

        //bullet.adapter.add("fill", function(fill, target){
        //    if(target.dataItem.valueY < 0){
        //        return am4core.color("#FF0000");
        //    }
        //    return fill;
        //})
        this.dayOfWeekLoaded = true;
    }
  
  getData(){
    this.summary.loaded = false     
    let chart1Loader = this.loadingBar.useRef('usagechart1')
    let chart2Loader = this.loadingBar.useRef('usagechart2')
    let chart3Loader = this.loadingBar.useRef('usagechart3')
    chart1Loader.start()
    chart2Loader.start()
    chart3Loader.start()
    chart1Loader.set(70)
    chart2Loader.set(50)
    chart3Loader.set(60)
    
    let summaryUrl = this.url + '/catalog/table/usage/summary'
    let graphUrl = this.url + '/catalog/table/usage/graph'
    let exactPageName = this.pageName + '_' + this.databaseName + '_' + this.schemaName + '_' + this.tableName
    let params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: "table", value: this.tableName}]
    let ovr_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: "table", value: this.tableName},{key: "graph", value: "usage_overview"}]
    let day_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: "table", value: this.tableName},{key: "graph", value: "usage_day"}]
    let det_params = [{key: "db", value: this.databaseName},{key: "schema", value: this.schemaName},{key: "table", value: this.tableName},{key: "graph", value: "usage_detail"}]
    
    this.api.getData(summaryUrl, exactPageName, 'usage_summary', {api: true, params: params}).subscribe(
        data=>{
            // Make sure the summary isn't garbage
            if(data['message'][0] != null){
                this.summary = data['message'][0]
                this.summary.loaded = true
            }
        }
    );
    
    this.api.getData(graphUrl, exactPageName, 'usage_overview', {api: true, params:ovr_params}).subscribe(
        data=>{
            if(data['message'].length > 0){
                 this.displayDailyOverviewChart(data['message'])
            }
            chart1Loader.complete()
        }
    );
    
    this.api.getData(graphUrl, exactPageName, 'usage_day', {api: true, params:day_params}).subscribe(
        data=>{
            if(data['message'].length > 0){
                 this.displayQueriesByDayChart(data['message'])
            }
            chart2Loader.complete()
         }

    );
    
    this.api.getData(graphUrl, exactPageName, 'usage_detail', {api: true, params:det_params}).subscribe(
        data=>{
             if(data['message'].length > 0){
                 this.displayDailyDetailChart(data['message'])
             }
             
             chart3Loader.complete()
        }
    );
               
        this.gettingChart = false;
  }

  countSummaryDates(){
    let count = 0;
    if (this.summary.most_recent_insert_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.most_recent_insert_query_start_time != null && this.summary.most_recent_insert_query_start_time != ''){
        count++;
    }
    if (this.summary.most_recent_merge_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.most_recent_merge_query_start_time != null && this.summary.most_recent_merge_query_start_time != ''){
        count++;
    }
    if (this.summary.most_recent_select_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.most_recent_select_query_start_time != null && this.summary.most_recent_select_query_start_time != ''){
        count++;
    }
    if (this.summary.most_recent_update_query_start_time != 'Mon, 01 Jan 1900 00:00:00 GMT' && this.summary.most_recent_update_query_start_time != null && this.summary.most_recent_update_query_start_time != ''){
        count++;
    }
    return count;
  }
}