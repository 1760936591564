import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSpinKitModule } from 'ng-spin-kit';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

import { CatalogService } from './catalog.service';
import { CatalogComponent } from './catalog.component';
import { OverviewComponent } from './overview/overview.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { MomentModule } from 'ngx-moment';
import { DatePipe } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { TableDetailsComponent } from './tables/table-details/table-details.component';
import { SchemaOverviewComponent } from './schemas/schema-overview/schema-overview.component';
import { SchemaDetailsComponent } from './schemas/schema-details/schema-details.component';
import { DatabaseDetailsComponent } from './databases/database-details/database-details.component';
import { DatabasesOverviewComponent } from './databases/databases-overview/databases-overview.component';
import { FunctionsOverviewComponent } from './functions/functions-overview/functions-overview.component';
import { FunctionDetailsComponent } from './functions/function-details/function-details.component';
import { StagesDetailsComponent } from './stages/stages-details/stages-details.component';
import { StageDetailsComponent } from './stages/stage-details/stage-details.component';
import { StagesOverviewComponent } from './stages/stages-overview/stages-overview.component';
import { FileFormatsOverviewComponent } from './file_formats/file-formats-overview/file-formats-overview.component';
import { FileFormatDetailsComponent } from './file_formats/file-format-details/file-format-details.component';
import { EnabledRoleDetailsComponent } from './enabled_roles/enabled-role-details/enabled-role-details.component';
import { EnabledRolesOverviewComponent } from './enabled_roles/enabled-roles-overview/enabled-roles-overview.component';
import { ObjectPrivilegesOverviewComponent } from './object_privileges/object-privileges-overview/object-privileges-overview.component';
import { ObjectPrivilegeDetailsComponent } from './object_privileges/object-privilege-details/object-privilege-details.component';
import { SequenceDetailsComponent } from './sequences/sequence-details/sequence-details.component';
import { SequencesOverviewComponent } from './sequences/sequences-overview/sequences-overview.component';
import { TablesComponent } from './tables/tables.component';
import { TablePermissionsComponent } from './tables/table-permissions/table-permissions.component';
import { TableColumnsComponent } from './tables/table-columns/table-columns.component';
import { TableLineageComponent } from './tables/table-lineage/table-lineage.component';
import { TableStorageComponent } from './tables/table-storage/table-storage.component';
import { TableUsageComponent } from './tables/table-usage/table-usage.component';
import { TableOverviewComponent } from './tables/table-overview/table-overview.component';
import { ViewsComponent } from './views/views.component';
import { ViewDetailsComponent } from './views/view-details/view-details.component';
import { ViewPermissionsComponent } from './views/view-permissions/view-permissions.component';
import { ViewColumnsComponent } from './views/view-columns/view-columns.component';
import { ViewLineageComponent } from './views/view-lineage/view-lineage.component';
import { ViewStorageComponent } from './views/view-storage/view-storage.component';
import { ViewUsageComponent } from './views/view-usage/view-usage.component';
import { ViewOverviewComponent } from './views/view-overview/view-overview.component';
import { NgSelectModule } from '@ng-select/ng-select';

// import { SqlFormatterPipe } from '../../shared/pipes/sql-formatter.pipe';
//import { SearchFilterPipe } from '../../shared/pipes/search-filter.pipe';
import { CodemirrorModule } from 'ng2-codemirror';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import {InputSwitchModule} from 'primeng/inputswitch';



@NgModule({
  declarations: [
    CatalogComponent,
    OverviewComponent,
    TableDetailsComponent,
    SchemaOverviewComponent,
    SchemaDetailsComponent,
    DatabaseDetailsComponent,
    DatabasesOverviewComponent,
    FunctionsOverviewComponent,
    FunctionDetailsComponent,
    StagesDetailsComponent,
    StageDetailsComponent,
    StagesOverviewComponent,
    FileFormatsOverviewComponent,
    FileFormatDetailsComponent,
    EnabledRoleDetailsComponent,
    EnabledRolesOverviewComponent,
    ObjectPrivilegesOverviewComponent,
    ObjectPrivilegeDetailsComponent,
    SequenceDetailsComponent,
    SequencesOverviewComponent,
    TablesComponent,
    ViewsComponent,
    ViewOverviewComponent,
    ViewDetailsComponent,
    ViewPermissionsComponent,
    ViewColumnsComponent,
    ViewLineageComponent,
    ViewStorageComponent,
    ViewUsageComponent,
    TablePermissionsComponent,
    TableColumnsComponent,
    TableLineageComponent,
    TableStorageComponent,
    TableUsageComponent,
    TableOverviewComponent,
    //SqlFormatterPipe,
     //SearchFilterPipe
  ],
  imports: [
    ChartsModule,
    CommonModule,
    NgbModule,
    CodemirrorModule,
    RouterModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    LoadingBarModule,
    NgSelectModule,
    InputSwitchModule,
   //CatalogRoutingModule,
    NgSpinKitModule,
    SharedModule,
    TableModule
  ],
  providers:[CatalogService, DatePipe]
})
export class CatalogModule { }
