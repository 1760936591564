import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../shared/api/api.service';
import { AuthService } from '../../../shared/auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import { LoadingBarService } from '@ngx-loading-bar/core';
import {DOCUMENT} from "@angular/common";


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styles: [
  ]
})
export class UsersComponent implements OnInit {
  public userForm: FormGroup;
  headers: HttpHeaders;

  

  usersInfo={
    invited: 0,
    members: 0,
    admins: 0,
    total: 0
  }

  roleOptions = [
      {label: "Member", value: "basic", icon: "fa-regular fa-user"},
      {label: "Admin",  value: "admin", icon: "fa-regular fa-shield-check"}
  ]

  userObjTemplate ={
    roleOptions: this.roleOptions,
    roleSelectValue: {},
    delete:  { name: '', id: 0, invited: false},
    disable: { name: '', id: 0}
  };
  search = '';

  userObj = Object.assign({}, this.userObjTemplate)
  submitted = false;

  users=[];
  usersOG = [];
  saving = {
    new: false, 
    pending: {},
    delete: false
  }
  userFields: any;
  url: string = environment.url;
  tScrollHeight: '';

  constructor(
    private auth: AuthService, 
    private api: ApiService,
    private modalService: NgbModal,
    public toastrService: ToastrService,
    private fb: FormBuilder,
    private http: HttpClient,
    private loadingBar: LoadingBarService,
    @Inject(DOCUMENT) private document

  ) {
   
    this.userForm = this.newUserForm();
    this.headers = this.auth.getHeaders();
    this.userObj = this.userObjTemplate;
    this.userObj.roleOptions = this.roleOptions;
    this.userObj.roleSelectValue =  "basic"
    this.document.body.style.overflow = "auto";
 
  }

  ngOnInit(): void {
    let usersLoad = this.loadingBar.useRef('userLoad')
    usersLoad.set(20)

    this.userFields = {
        "first_name": "default",
        "last_name": "default",
        "email": "default",
        "role": "default"
    }
    this.getUsers(usersLoad);
  }

  getUsers(usersLoad=null){
    let url = this.url + '/users'
    this.api.getApiData(url, []).subscribe(
      data=>{
        console.log(data)
        this.users = data['message']

        if(usersLoad != null) usersLoad.complete();

        this.usersOG = Object.assign([], this.users)
        this.calculateUsers()
      }
    )
  }

  newUserForm(): FormGroup {
     return this.fb.group({
        email: ['', Validators.required],
        first_name:['', [Validators.required]],
        last_name:['', [Validators.required]],
        source: 'frontend'
      })
  }
  userFormControl() {
    return this.userForm.controls;
  }

  inviteUser(user=null){
    if(user != null){
      // Resend the invitation
      this.saving.pending[user.id] = true;
      let data = {
         users: [
           {
             email: user.email,
             first_name: user.first_name,
             last_name: user.last_name, 
             admin: user.role == 'carve_admin' ? true : false
           }
        ]}
      let url = this.url + '/user/invite'
      let headers = this.headers
      this.http.post(url, JSON.stringify(data),{headers}).subscribe(
       data => {
         this.toastrService.success('User invite resent!');
         this.saving.pending[user.id] = false;
       },
       err=>{
         // Check if the email bounced or the user has already been added 
         console.log(err)
         this.toastrService.error('Failed to resend inviation. Try again later.');
         this.saving.pending[user.id] = false;
       }
       );
    }
    else{
      if(this.userForm.valid && this.userObj.roleSelectValue != null){
        // Make sure user doesn't already exist
        if(this.users.find(u=> u.email.toLowerCase() === this.userForm.value.email.toLowerCase())){
          this.toastrService.error('User already exists');
          return
        }
        this.saving.new = true;
          let user_data = {
             users: [
               {
                 email: this.userForm.value.email,
                 first_name: this.userForm.value.first_name,
                 last_name: this.userForm.value.last_name, 
                 admin: this.userObj.roleSelectValue == 'admin' ? true : false
               }
            ]}
          let url = this.url + '/user/invite'
          let headers = this.headers
          this.http.post(url, JSON.stringify(user_data),{headers}).subscribe(
           data => {
             this.toastrService.success('User invite sent!');
             this.saving.new = false;
             // this.users.push({
             //    email: this.userForm.value.email,
             //    first_name: this.userForm.value.first_name,
             //    last_name: this.userForm.value.last_name,
             //    active: 0,
             //    created_date: null, 
             //    email_sent_date: new Date().toLocaleString(),
             //    last_login_date: null,
             //    role:  this.userObj.roleSelectValue == 'admin' ? 'admin' : 'basic',
             //    security_level: null
             // })
             this.getUsers();
             // this.calculateUsers()
             this.resetUserForm()
           },
           err=>{
             // Check if the email bounced or the user has already been added 
             console.log(err)
             this.toastrService.error('Failed to invite user. Please check email address is valid.');
             this.saving.new = false;
             this.resetUserForm()
             
           }
         );
        
      }
      else{
        console.log('display error over role')
      }
    }
    
  }

  calculateUsers(){
    this.usersInfo.invited = 0;
    this.usersInfo.members = 0;
    this.usersInfo.admins = 0;
    this.usersInfo.total = this.users.length;

    for(let u of this.users){
      if(u.created_date == null){
        this.usersInfo.invited++;
      }
      else if(u.role == 'basic'){
        this.usersInfo.members++;
      }
      else if(u.role == 'admin'){
        this.usersInfo.admins++;
      }
    }
  }

  resetUserForm(){
    this.userForm.reset(this.newUserForm());
    this.userObj.roleSelectValue = 'basic'
  }

  openDeleteUserModal(content, options={}, user){
    
    this.userObj.delete.name = user.first_name + ' ' + user.last_name;
    this.userObj.delete.id = user.id;
    this.userObj.delete.invited = user.created_date == null ? true : false;
    this.document.body.style.overflow = "hidden";

    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
        this.document.body.style.overflow = "auto";
      });
  }

  deleteUser(){

    this.saving.delete = true;
    let headers = this.headers
    let url = '';
    if(this.userObj.delete.invited){
       url = this.url + '/user/invite/' + this.userObj.delete.id;
    }
    else{   
       url = this.url + '/user/' + this.userObj.delete.id;
    }
   
    this.http.delete(url, {headers}).subscribe(
     data => {
       this.toastrService.success('User removed successfully');
       this.saving.delete = false;
       this.modalService.dismissAll();
       this.getUsers();
     },
     err=>{
       this.toastrService.error('Unable to remove user');
       this.saving.delete = false;
       this.modalService.dismissAll();
     });
  }

  submitRole(user){
    let url = this.url + '/user/settings'
    let headers = this.headers
    let role_data = {
      user_id: user.id,
      user_email: user.email,
      setting_name: 'role',
      setting_value: user.role
    }
    this.http.post(url, JSON.stringify(role_data),{headers}).subscribe(
      data=>{
         this.toastrService.success('User role changed successfully');
      },
      err=>{
         this.toastrService.error('Unable to change user role');
      }
    )
  }

  checkEnable(user){
    let url = this.url + '/user/settings'
    let headers = this.headers
    let role_data = {
      user_id: user.id,
      user_email: user.email,
      setting_name: 'active',
      setting_value: user.active ? 1 : 0
    }
    this.http.post(url, JSON.stringify(role_data),{headers}).subscribe(
      data=>{
         let change_type = user.active ? 'activated' : 'deactivated'
         this.toastrService.success('User ' + change_type + ' successfully');
      },
      err=>{
         let change_type = user.active ? 'activate' : 'deactivate'
         this.toastrService.error('Unabled to ' + change_type + ' user');
      }
    )
  }
  onSearchChange(searchValue : string ) {  
      if (searchValue != ''){
        this.users = this.searchUsers(searchValue);
      }
      else{
        this.clear()
      }
  }

  searchUsers(searchValue){
    let userIds = []
    let newUsers = []

    for(let i = 0;i < this.users.length;i++){
      let item = this.users[i]
      
      for(let property in item){
        if(item[property] !== null && property in this.userFields){
          let val = item[property].toLowerCase()
           if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
               if(!(userIds.includes(item.id))){
                 userIds.push(item.id)
                 newUsers.push(item)
               }
           }
        }
      }
    }
      
    return newUsers;

  }
  clear(){
      this.users = Object.assign([],this.usersOG);
      this.search = '';
  }

}
