import { Injectable } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service'
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs/Subject';
import { ApiService } from '../../shared/api/api.service';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';


@Injectable()
export class CatalogService {

  Databases: Object;
  Schemas: Object;
  Tables: Object;
  EnabledRoles: Object;
  FileFormats: Object;
  Functions: Object;
  ObjectPrivileges: Object;
  Pipes: Object;
  Stages: Object;
  Views: Object;
  Sequences: Object;
  url: string = environment.url;
  catalog: Object;
  columns: any;
  catalog_url: string = '';
  column_url: string = '';
  column_params: any;
  menu: Array<any> = [];
  categories: Array<any> = []
  isEditor: boolean = false;
  isRefreshing: boolean = false;
  loadingTables: boolean = false;
  schemaSearching: boolean = false;
  waitingOnConnection: boolean = false;
  menuSettings: any;
  menuItems: Array<any> = [];
  private cachedCatalog$ = new Subject<any>();
  private cachedColumns$ = new Subject<any>();
  private catalogObject$ = new ReplaySubject<any>(1);
  headers: HttpHeaders;
  navStart: String = '';
  navEnd: String = '';
  prevEnd: String = '';


  hasCatalog: boolean = false; 
  needConnection: boolean = false;
  opened = [];
  nextLoading: Boolean = false;
  start: boolean = false;
  loading: Boolean = false;

  constructor(
    private api: ApiService,
    private auth: AuthService, 
    public http: HttpClient,
    private router: Router,
  ) {

    this.headers = this.auth.getHeaders()
    this.menuItems = [];

    this.categories = [
      'Databases', 'Schemas', 'Enabled Roles', 'Object Privileges', 'Stages',
      'Functions', 'File Formats', 'Pipes', 'Tables', 'Views', 'Sequences'
    ]

  	// Top Level Objects
  	this.Databases = {
  		text: 'Databases',
    	link: "/explore/databases",
      param: 'databases',
    	icon: 'fal fa-database',
    	submenu: [],
    	parent: null,
    	level: 1,
      api_name: 'databases'
  	}
  
  	this.EnabledRoles = {
  		text: 'Enabled Roles',
    	link: '/explore/enabled_roles',
    	icon: 'fal fa-user-check',
    	submenu: [],
    	parent: null,
    	level: 2,
      api_name: 'enabled_roles'
  	}
    this.catalog_url = this.url + '/catalog'
    this.column_url = this.url + '/catalog/columns'
    this.column_params =[{key: 'format_type', value: 'column'}]

    router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        this.navStart = event.url
      }
      if (event instanceof NavigationEnd){
        this.prevEnd = this.navEnd;
        this.navEnd = event.url;
      }
    });
  }

  // getCatalogReplay(): Observable<any> {
  //     return this.catalogObject$.asObservable();
  // }

  // setCatalogReplay(object){
  //     console.log('Setting replay object: ', object)
  //     this.catalogObject$.next(object)
  // }

  stageObject(){
    return {
      text: 'Stages',
      icon: 'fal fa-inbox',
      submenu: []
    }
  }
  pipeObject(){
    return {
      text: 'Pipes',
      icon: 'fal fa-grip-lines-vertical',
      submenu: []
    }
  }

  functionObject(){
    return {
      text: 'Functions',
      icon: 'fal fa-file-code',
      submenu: []
    }
  }

  fileFormatObject(){
    return {
      text: 'File Formats',
      icon: 'fal fa-file-alt',
      submenu: []
    }
  }

  schemaObject(){
     return {
      text: 'Schemas',
      icon: 'fal fa-sitemap',
      submenu: []
    }
  }

  tableObject(){
    return {
      text: 'Tables',
      icon: 'fal fa-table',
      submenu: []
    }
  }

  viewObject(){
    return {
      text: 'Views',
      icon: 'fal fa-window-maximize',
      submenu: []
    }
  }

  sequenceObject(){
    return {
      text: 'Sequences',
      icon: 'fal fa-window-maximize',
      submenu: []
    }
  }


  objPrivigesObject(){
    return {
      text: 'Object Privileges',
      icon: 'fal fa-cubes',
      submenu: []
    }
  }

  // getCatalog():Observable<any>{
  //   return this.api.getData(this.catalog_url, 'catalog', 'catalog')
  //   // if(this.haveCachedCatalog()){
  //   //   //console.log('found cached version of the catalog')
  //   //   return this.getCatalog();
  //   // }
  //   // else{
  //   //   let headers = this.auth.getHeaders()
  //   //   return this.http.get(, {headers})
  //   // }
  // }

  // getLatestColumns():Observable<any>{
  //   return this.api.getData(this.column_url, 'catalog', 'columns', this.column_params)
  //   // if(this.haveCachedColumns()){
  //   //   //console.log('found cached version of the columns')
  //   //   return this.getColumns();
  //   // }
  //   // else{
  //   //   let params: HttpParams = new HttpParams();
  //   //   let headers = this.auth.getHeaders()
  //   //   params = params.append('format_type', 'column')
  //   //   return this.http.get(this.url + '/catalog/columns', {headers, params})
  //   // }
  // }
  checkMenuSettings(){
    if(this.menuSettings['connection_id'] == undefined || this.menuSettings['connection_id'] == 'Select Connection') return false;
    if(this.menuSettings['role'] == undefined || this.menuSettings['role'] == 'Select Role') return false;
    return true
  }


  getFreshCatalog(){
     let obj = {api: true}
     let headers = this.headers;
     this.menuItems = []
     this.opened = [];
     this.isRefreshing = true;
     this.start = true;
     if(this.isEditor){
        
        let url = 'query_svc/catalog/menu/user';
        let data = {settings: this.menuSettings};

        // Ignore empty entries
        if(!this.checkMenuSettings()){
         
          this.isRefreshing = false; 
          return;
        }
        setTimeout(()=>{
            this.start = false; 
        },3000);
        this.http.post(url, data, {headers}).subscribe(
          data=>{
           
            this.start = true;
            this.menuItems = JSON.parse(JSON.stringify(data['message']));
            if(this.menuItems[0]['submenu'].length > 0){
              this.hasCatalog = true;
            }
            this.isRefreshing = false;
            let schemaObj = this.parseUrl(this.navEnd)
            if(schemaObj){
              this.getObjects(schemaObj, true);
            }
          },
          err=>{

          }
        )
     }
     else{
     
       this.api.getData(this.catalog_url, 'catalog', 'explorer_catalog', obj).subscribe(
          data=>{
            
            this.start = true;
            this.menuItems = JSON.parse(JSON.stringify(data['message']));
            if(this.menuItems[0]['submenu'].length > 0){
              this.hasCatalog = true;
            }
            this.isRefreshing = false;
            this.storeCatalog(this.menuItems)
            let schemaObj = this.parseUrl(this.navEnd)
            if(schemaObj){
              this.getObjects(schemaObj, true);
            }
          },
          err=>{
            
          }
       )
     }
  }
  
  getCatalog(){
     this.opened = []
     this.menuItems = []
     this.isRefreshing = true;
     this.api.getData(this.catalog_url, 'catalog', 'explorer_catalog').subscribe(
          data=>{
            if(data == undefined){
              this.getFreshCatalog()
            }
            else{
              this.menuItems = data;
              if(this.menuItems[0]['submenu'].length > 0){
                this.hasCatalog = true;
              }
              this.isRefreshing = false;
              let schemaObj = this.parseUrl(this.navEnd)
              if(schemaObj){
                this.getObjects(schemaObj, false);
              }
            }
          },
          err=>{
            // log error 
          }
       )
  }


  getSchemaObjects(schemaObj, searchValue){
    let db = schemaObj.database
    let schema = schemaObj.name
    let key = db + "_" + schema
    this.loadingTables = true;

    let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.name == db));   
    let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.name == schema));
    this.getTablesViews(db, schema, false).subscribe(
       data=>{

            this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu = [];
            this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu = [];

            let tables = data['message']['tables'];
            let views = data['message']['views'];
           
             
            for(let t of tables){
              if(t.text.toLowerCase().includes(searchValue.toLowerCase())){
                this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
              }
            }

            //append all of the views
            for(let v of views){
              if(v.text.toLowerCase().includes(searchValue.toLowerCase())){
                this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.push(v)
              }
            }

            this.schemaSearching = false;
            this.loadingTables = false;
          },
          err=>{
            this.loadingTables = false;
          }
     );
    

  }

  // schemaObjectsReset(schemaObj){
  //   let db = schemaObj.database
  //   let schema = schemaObj.name
  //   let key = db + "_" + schema
  //   this.loadingTables = true;

  //   let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.name == db));   
  //   let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.name == schema));
  //   this.getTablesViews(db, schema, false).subscribe(
  //      data=>{

  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu = [];
  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu = [];

  //           let tables = data['message']['tables'];
  //           let views = data['message']['views'];
           
  //           tables = tables.slice(0, 25)
  //           views = views.slice(0, 25)
             
  //           for(let t of tables){
  //             this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
  //           }

  //           //append all of the views
  //           for(let v of views){
  //              this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.push(v)
  //           }

  //           this.loadingTables = false;
            
  //         },
  //         err=>{
  //           this.loadingTables = false;
           
  //         }
  //    );
  // }
  // getColumns():Observable<any>{
  //     return this.api.getData(this.column_url, 'catalog', 'columns', {api: true, params: this.column_params});
  // }

  getLastUpdated():Observable<any>{
    let url = this.url + '/catalog/lastupdated'
    return this.api.getData(url, 'catalog', 'catalog_last_update', {api: true});
  }
    
  parseUrl(url){
    if(url.indexOf("/tables") > 0 || url.indexOf("/views") > 0){

        let endStr = url.indexOf("/tables") > -1 ? "/tables"  : '/views'
        let database = url.substring(
          url.lastIndexOf("databases/") + 10, 
          url.indexOf("/schemas")
        ); 
        let schema = url.substring(
          url.lastIndexOf("schemas/") + 8, 
          url.indexOf(endStr)
        ); 

      console.log('schema: ', schema)

      if(schema == undefined){
        let schema = url.substring(
            url.lastIndexOf("schemas/") + 8, 
            url.indexOf("/views")
        ); 
      }
      return {database: database, name: schema}
    }
    return false
  }

  // haveCachedCatalog(){
  //   let index = this.api.getIndex('catalog', 'catalog')
  //   return this.api.inDatabase(index)
  // }

  // setCachedCatalog(){
  //     if(this.haveCachedCatalog()){

  //       let catalog = this.api.getData(url, 'catalog', 'catalog');
  //       this.cachedCatalog$.next(catalog)
  //     }
  // }
  
   
  // haveCachedColumns(){
  //   let index = this.api.getIndex('catalog', 'columns')
  //   return this.api.inDatabase(index)
  // }

  // setCachedColumns(){
  //     if(this.haveCachedColumns()){
  //       let columns = JSON.parse(sessionStorage.getItem("columns"));
  //       this.cachedColumns$.next(columns)
  //     }
  // }
  storeUserCatalog(catalog){
    // this.catalog = catalog;
    this.api.setData('catalog', 'user_catalog', catalog, true)
    // sessionStorage.setItem("catalog", JSON.stringify(catalog));
  }

  storeCatalog(catalog){
    // this.catalog = catalog;
    this.api.setData('catalog', 'explorer_catalog', catalog, true)
    // sessionStorage.setItem("catalog", JSON.stringify(catalog));
  }

  // storeTables(database, schema, tables){
  //   let key = 'explorer_tables_'+ database + '_' + schema
  //   this.api.setData('catalog', key, tables, true)
  //   // sessionStorage.setItem("catalog", JSON.stringify(catalog));
  // }

  storeTablesViews(database, schema, message){
    let key = 'explorer_t&v_'+ database + '_' + schema
    this.api.setData('catalog', key, message, true)
  }

  getTablesViews(database, schema, refresh):Observable<any>{
    let headers = this.headers;
    // Need to make this dynamic between user and explore
    if(this.isEditor){
        let url = '/query_svc/catalog/menu/user/objects';
        let data = {db: database, schema: schema, settings: this.menuSettings};
        return this.http.post(url, data, {headers})
        
     }
     else{
     
         let url = this.url + '/catalog/menu/tables_views'
         let key = 'explorer_t&v_'+ database + '_' + schema
         let params =[
            {key: 'db', value: database},
            {key: 'schema', value: schema}
         ]
       
        return this.api.getData(url, 'catalog', key, {api: refresh, params: params});
     }

   
     
  }

  getColumns(database, schema, table, refresh):Observable<any>{

    if(this.isEditor){
      let headers = this.headers;
      let url = '/query_svc/catalog/menu/user/columns';
      let data = {db: database, schema: schema, table: table, settings: this.menuSettings};
      return this.http.post(url, data, {headers})
    }
    else{
      let url = this.url + '/catalog/columns'
      let key = 'explorer_columns_'+ database + '_' + schema + '_' + table
      let params =[
        {key: 'db', value: database},
        {key: 'schema', value: schema},
        {key: 'table', value: table}
      ]
      return this.api.getData(this.column_url, 'catalog', key,  {api: refresh, params: params});
    }
  }

  storeColumns(database, schema, table, columns){
    let key = 'explorer_columns_'+ database + '_' + schema + '_' + table
    this.api.setData('catalog', key, columns, true)
  }

  reconfigLink(link, stuff){
    var last = link.slice(link.lastIndexOf('/')+1, link.length)
    var newStr = link.replace(last, stuff + '/' + last)
    return newStr
  }

  getObjects(schemaObj, refresh, next=false){
    // if the tables and views haven't been retrieved

    if(this.menuItems.length == 0){
      console.log('No menu items - returning')
      return;
    } 

    let db = schemaObj.database
    let schema = schemaObj.name
    let key = db + "_" + schema

    // console.log('checking for ', key, ' in ', this.opened,'. Refresh: ', refresh, '. Next: ', next)

    // console.log('menu items: ', this.menuItems)
    let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.name == db));   
    let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.name == schema));
    let tLength = this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.length
    let vLength = this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.length

    let tOffset = 0, 
        vOffset = 0;

    if(next){
      this.nextLoading = true;
      tOffset = tLength;
      vOffset = vLength;
    }

    // If objects are already loaded, don't do anything
    if((tLength > 0 || vLength > 0) && !refresh && !next) return;

    if((!this.opened.includes(key) && !this.loadingTables) || refresh || next){
       if(!next){
         this.loadingTables = true;
       }
       
       if(!this.opened.includes(key)){
         this.opened.push(key)
       }
       this.getTablesViews(db, schema, refresh).subscribe(
         data=>{
             console.log('schema objects: ', data)

             this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu = [];
             this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu = [];

             let tables = data['message']['tables'];
             let views = data['message']['views'];
             if(next){
               tables = tables.slice(0, tOffset + 25)
               views = views.slice(0, vOffset + 25)
             }
             else{
               tables = tables.slice(0, 25)
               views = views.slice(0, 25)
             }

              for(let t of tables){
                this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
              }

              //append all of the views
              for(let v of views){
                 this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.push(v)
              }
              this.storeTablesViews(db, schema, data);
              this.loadingTables = false;

              tables = null;
              views = null;
              
              this.nextLoading = false;
            },
            err=>{
              this.loadingTables = false;
              this.nextLoading = false;
             
            }
       )
            
       
       // forkJoin(
       //    this.getTables(db, schema),
       //    this.getViews(db, schema)
       //  ).subscribe(([tableData, viewData]) => {

       //      // Find the database and schema in the menu 
       //      let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.database == db));
       //      let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.schema == schema));
            
       //      // append all of the tables
       //      for(let t of tableData['message']){
       //        // console.log('schema object: ', this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex])
       //        this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
       //      }
      
       //      //append all of the views
       //      for(let v of viewData['message']){
       //       this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[2].submenu.push(v)
       //      }
               
        
       //      this.catalog.storeCatalog(this.menuItems);
       //      this.opened.push(key)
       //      this.loadingTables = false;
       //  })
    }
  }

  // buildCatalogMenu(catalog){
  //   // console.log('catalog: ', catalog)

  //   if('message' in catalog){
  //     catalog = catalog['message'];
  //   }
  // 	var new_menu = [];
  //   //console.log('building catalog menu: ', catalog)
  //   var base = '/explore/'
  //   this.Databases['submenu'] = catalog['databases'].sort((a, b) => a.text > b.text ? 1 : -1);
  //   this.EnabledRoles['submenu'] = catalog['enabled_roles'].sort((a, b) => a.text > b.text ? 1 : -1);
  //   //this.ObjectPrivileges['submenu'] = catalog['object_privileges'].sort((a, b) => a.text > b.text ? 1 : -1);

  //   // Populate the database tree
  //   for (let db of this.Databases['submenu']){
  //     var schemas = this.schemaObject();
  //     var stages = this.stageObject();
  //     var fileFormats = this.fileFormatObject();
  //     var functions = this.functionObject();
  //     var pipes = this.pipeObject();
  //     var sequences = this.sequenceObject();

  //     schemas['link'] = base + 'databases/' + db['text'] + '/schemas';
  //     stages['link'] = base + 'databases/' + db['text'] + '/stages';
  //     fileFormats['link'] = base + 'databases/' + db['text'] + '/file_formats';
  //     functions['link'] = base + 'databases/' + db['text'] + '/functions';
  //     pipes['link'] = base + 'databases/' + db['text'] + '/pipes';
  //     sequences['link'] = base + 'databases/' + db['text'] + '/sequences';
     
  //     // Populate schema tree
  //     let schema_count = 0
  //     for (let s of catalog['schemas']){
  //       if(s['database'] == db['name']){
  //         schemas['submenu'].push(s)
  //         schema_count++;
  //       }
  //     }
  //     let table_count = 0;  
  //     let view_count = 0;

  //     for (let s of schemas['submenu']){
  //       var tables = this.tableObject()
  //       var views = this.viewObject();
  //       var schema_table_count = 0;
  //       var schema_view_count = 0;

  //       var stuff = 'databases/' + db['text'] + '/schemas/' + s['text'];

  //       tables['link'] = base + stuff + '/tables'
  //       views['link'] =  base + stuff + '/views';

  //       // Push tables into Schema
  //       for (let t of catalog['tables']){
  //         if(t['database'] == db['name'] && t['schema'] == s['name']){
  //           tables['submenu'].push(t)
  //           table_count ++;
  //           schema_table_count++;
  //         }
  //       }
  //       // Push views into Schema
        
  //       for (let v of catalog['views']){
  //         if(v['database'] == db['name'] && v['schema'] == s['name']){
  //           views['submenu'].push(v)
  //           view_count ++;
  //           schema_view_count++;
  //         }
  //       }
        
  //       tables['submenu'] = tables['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //       views['submenu'] = views['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //       s['submenu'] = [tables, views]
  //       if('meta' in s){
  //         s['meta']['TABLE_COUNT'] = schema_table_count;
  //         s['meta']['VIEW_COUNT'] = schema_view_count;
  //       }
  //     }

  //     if('meta' in db){
  //       db['meta']['SCHEMA_COUNT'] = schema_count;
  //       db['meta']['TABLE_COUNT'] = table_count;
  //       db['meta']['VIEW_COUNT'] = view_count;
  //     }
      
  //     // Populate stages 
      
  //     for (let s of catalog['stages']){
  //       if(s['database'] == db['name']){
  //         stages['submenu'].push(s)
  //       }
  //     }
  //     // Populate file formats 
  //     for (let f of catalog['file_formats']){
  //       if(f['database'] == db['name']){
  //         fileFormats['submenu'].push(f)
  //       }
  //     }
  //     // Populate functions 
  //     for (let f of catalog['functions']){
  //       if(f['database'] == db['name']){
  //         functions['submenu'].push(f)
  //       }
  //     }
  //     // Populate pipes 
  //     for (let p of catalog['pipes']){
  //       if(p['database'] == db['name']){
  //         pipes['submenu'].push(p)
  //       }
  //     }

  //     // Populate sequences 
  //     for (let p of catalog['sequences']){
  //       if(p['database'] == db['name']){
  //         sequences['submenu'].push(p)
  //       }
  //     }

     
  //     schemas['submenu'] = schemas['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //     stages['submenu'] = stages['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //     fileFormats['submenu'] = fileFormats['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //     functions['submenu'] = functions['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //     pipes['submenu'] = pipes['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
  //     sequences['submenu'] = sequences['submenu'].sort((a, b) => a.text > b.text ? 1 : -1);
     

  //     db['submenu'] = [schemas, stages, fileFormats, functions, pipes, sequences]
  //   }

		// new_menu = [this.Databases, this.EnabledRoles];
  //   console.log(new_menu)
  // 	return new_menu
  // }
  // toggleMenu(menuType){
  // 	if(menuType == 'Hierarchy'){
  // 		menuType = 'Flat'
  // 	}
  // 	else{
  // 		menuType = 'Hierarchy'
  // 	}
  // 	return menuType;
  // }
}
