<div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style="background-image: url('assets/img/Aurora_Gradient_Landscape.jpg');">
  <div class="ui-bg-overlay bg-dark opacity-25"></div>

  <div class="py-5" >
    <div class="card" style="width:1200px; border: none;">
      <div class="row h-100">
        <div class="col-lg-6 ui-bg-cover ui-bg-overlay-container p-5" style="background-color: black;border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;">
          <div class="ui-bg-overlay bg-dark opacity-50" style="border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;"></div>
          <!-- Logo -->
          <div style="height:33%">
               <img width="200px" height="52px" src="./assets/img/carve_logo_white.png">
          </div>

          <!-- Text -->
          <div class="w-100 text-white" style="height:33%">
           <!--  <h1 class="display-2 font-weight-bolder mb-4">JOIN OUR<br>COMMUNITY</h1> -->
            <div class="text-large font-weight-light" style="padding-right: 75px; font-family: Roboto !important;">
                <h2>Data Experience</h2>
                <p>
                  The Carve data experience platform provides visibility, insights, and action on the data in your Snowflake data warehouse.
                </p>
            </div>
          </div>
          <div style="height:33%; text-align:center;" class="d-flex justify-content-center align-items-end">
            <div>
               <img width="250px" height="89px" src="./assets/img/carve+snowflake.png">
             </div>
          </div>
          <!-- /.Text -->
        </div>
        <div class="theme-bg-white d-flex col-lg-6 align-items-center p-5" style="height: 660px;border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;">
          <!-- Inner container -->
          <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
          <div class="col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto" style="max-width: 500px;">
           
             
                <!-- Logo -->
                <!-- <div class="d-flex justify-content-center align-items-center pb-2 mb-4">
                  <div class="ui-w-100">
                    <div class="w-100 position-relative">
                      <img *ngIf="!loggingIn" height="90px" width="90px" src="./assets/img/snowguide_white.png">
                      <img *ngIf="loggingIn" height="90px" width="90px" src="./assets/img/snowguide_loading_main.gif">
                    </div>
                  </div>
                </div> -->
                <!-- / Logo -->
                <div *ngIf="!reset.active" style="max-width: 400px" class="mx-auto">
                  <h2 class="text-center text-darker font-weight-normal mt-5 mb-5" style="color:black;">Login to your Account</h2>
                  <h5  [ngClass]="{'text-left text-muted font-weight-normal mb-4 error': errorCount > 0, 'text-left text-muted font-weight-normal mb-4': errorCount === 0 }">{{displayMessage}}</h5>

                  <!-- Form -->
                  <form [formGroup]="loginForm" (keydown.enter)="login()" >
                    <div class="form-group">
                     
                        <label class="form-label d-flex justify-content-between align-items-end">
                      
                          <span>Email</span>
                          <span class="text-danger"
                            *ngIf="(loginFormControl.email.touched || loggingIn) && loginFormControl.email.errors?.required">
                            Required
                          </span>
                          <span class="text-danger"
                            *ngIf="(loginFormControl.email.touched || loggingIn) && !loginFormControl.email.errors?.required && loginFormControl.email.errors?.pattern">
                            Please enter valid email address
                          </span>

                      </label>
                      <input formControlName="email" id="defaultEmail" name="email" type="text" class="form-control form-border">
                    </div>

                    <div class="form-group">
                       <label class="form-label d-flex justify-content-between align-items-end">
                        <span>Password</span>
                          <span class="text-danger"
                            *ngIf="(loginFormControl.password.touched || loggingIn) && loginFormControl.password.errors?.required">
                            Required
                          </span>
                          <a href="javascript:void(0)" (click)="startReset();" class="d-block small">Forgot password?</a>
                      </label>
                      <input formControlName="password" id="defaultPassword" name="password" type="password" class="form-control form-border">
                    </div>
                    <button type="button" class="btn btn-primary btn-block mt-4 mb-4" (click)="login()">
                    <div *ngIf="loggingIn" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                      <span  class="sr-only">Loading...</span>
                    </div>
                    Sign In
                  </button>
                  </form>
                <!-- / Form -->

                  <div class="text-center text-muted">
                      Don't have an account yet? <a href="register">Sign Up</a>
                  </div>
                </div>

                <div *ngIf="reset.active && !reset.complete" style="max-width: 400px;" class="mx-auto">
                  <div class="text-right text-muted" style="padding-top:0px;">
                      Already have an account? <a href="login">Login</a>
                  </div>
                  <div class="text-center text-darker font-weight-normal mt-5 mb-5" style="color:black; font-size: 19pt;">
                    <span><i class="fal fa-user-unlock"></i></span>
                    <span style="padding-left: 5px;">
                      Send password reset email
                    </span>

                  </div>
                  <h5  [ngClass]="{'text-left text-muted font-weight-normal mb-4 error': errorCount > 0, 'text-left text-muted font-weight-normal mb-4': errorCount === 0 }">{{displayMessage}}</h5>

                  <!-- Form -->
                  <form [formGroup]="resetForm" (keydown.enter)="resetPassword()" >
                    <div class="form-group">
                     
                        <label class="form-label d-flex justify-content-between align-items-end">
                      
                          <span>Email</span>
                          <span class="text-danger"
                            *ngIf="(resetFormControl.email.touched || loggingIn) && resetFormControl.email.errors?.required">
                            Required
                          </span>
                          <span class="text-danger"
                            *ngIf="(resetFormControl.email.touched || loggingIn) && !resetFormControl.email.errors?.required && resetFormControl.email.errors?.pattern">
                            Please enter valid email address
                          </span>

                      </label>
                      <input formControlName="email" id="defaultEmail" name="email" type="text" class="form-control form-border">
                    </div>

        
                    <button type="button" class="btn btn-primary btn-block mt-4 mb-4" (click)="resetPassword()">
                    <div *ngIf="reset.submitted" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                      <span  class="sr-only">Loading...</span>
                    </div>
                    Send
                  </button>
                  </form>
                </div>

                <div *ngIf="reset.active && reset.complete" style="max-width: 500px;">
                  <div class="text-center">
                    <span style="color: green; padding-right: 7px;"><i class="fal fa-check-circle fa-2x"></i></span>
                    <span style="color:black; font-size: 19pt;">Password Reset email sent!</span>
                  </div>
                    
                  <div style="font-size:12pt; padding-bottom: 40px; border-bottom: 1px solid #d4d0d0;border-radius: 0px;" class="email-sent">
                    <div>
                      <span>We sent an email to</span>
                      <span style="font-weight:bold;">{{toEmail}}</span>
                    </div>
                    <div style="padding-top:40px;">
                      Click the <span style="font-weight: bold;">"Reset Password"</span> button in the email to finish resetting your password.
                    </div>
                    <div style="padding-top:40px;">
                      If you can't find the email, you may need to <span style="font-weight:bold;">check your spam</span> folder. 
                    </div>
                </div>
          </div>
      </div>
    </div>
  </div>
</div>