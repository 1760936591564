import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/api/api.service';
import { AuthService } from '../../shared/auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CanActivate , ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import { PackagesService } from '../../packages/packages.service';
 

@Component({
  selector: 'app-package-new',
  templateUrl: './package-new.component.html',
  styles: [
  ]
})
export class PackageNewComponent implements OnInit {

  @ViewChild('configCode') configCode: ElementRef;

  public pythonPackageForm: FormGroup;
  public dbtPackageForm: FormGroup;
  public zipPackageForm: FormGroup;
  public profileForm: FormGroup;
  public variableForm: FormGroup;
  public repoPackageForm: FormGroup;
  public basicForm: FormGroup;


  headers: HttpHeaders;
  page = 'package_new'
  url: string = environment.url;
  sub: any;
  packageId: number = null;

  profileSubmitted: boolean = false;
  submitted: boolean = false;
  filesChecked: boolean = false;
  filesCheckError: boolean = false;
  filesChecking: boolean = false;
  gettingPackageData: boolean = false;
  branchError = '';
  branchesChecking: boolean = false;
  branchesCheckError: boolean = false;
  reposLoading: boolean = false;
  hasConfiguration: boolean = false;
  selectedIntegration = null;
  selectedRepo = null;

  createError = null;
  codeConfig = {
      lineNumbers: true,
      mode: {
        name: "javascript",
        json: true,
        statementIndent: 2
      },
      readOnly: false,
      tabSize: 4
  }

  // Environment Variable Configuration Object
  conf: any = {
    jsonError :"",
    fromJSON : false,
    fromJSONCode :'',
    fromEnv : false,
    fromEnvCode :'',
    defaultEnvs : ['DEV', 'STAGE', 'PROD'],
    configurations : [],
    configurationsList: [],
    configurationsOG : [],
    configsLoading: false,
    creatingPackage: false,
    environmentVars: {
        configuration_environments: ['DEV', 'STAGE', 'PROD'],
        configuration_variables: []
    },
    newVariableTemplate :{
      id: null,
      key: null, 
      environments: {
        'DEV': '',
        'STAGE': '',
        'PROD': ''
      },
      is_secure: false,
      is_optional: false,
      global_config_id: null
    },
    jsonTemplate : {
    "configuration_environments": ["DEV", "STAGE", "PROD"],
    "configuration_variables": [
      {
          "key": '[KEY NAME HERE]', 
          "environments": {
            'DEV': '[DEV VALUE HERE]',
            'STAGE': '[STAGE VALUE HERE]',
            'PROD': '[PROD VALUE HERE]'
          },
          "is_secure": false,
          "is_optional": false
        }
       ]
    },
    configurationChanges : {
      'new': [], // entire variable object
      'updated': [],// should include all environments or other settings
      'deleted_variables': [], // {'key': ''}
      'deleted_environments': [] // 'TEST_ENV'
    },
    newEnv : '',
    newVariable: null,
    isNewKey: false,
    variableOptions: [{label: 'Create New Variable', value: null}],
    optionalVariables: [],
    newVariableError : '',
    configurationError : '',
    saving : false,
    deleting : false,
    search : '',
    updateConfig : false,
    configuration : null,
    configurationOG : null,
    configurationFields: {},
    updatingConfig : false,
    configurationForm: null,
    usingConfiguration: false
  }

  deleteMessage = '';
  fileError = '';
  fileNameOptions = [];
  fileFunctionOptions = [];

  languages = {
    'Python': [
      {label: '3.6', value: '3.6'}, 
      {label: '3.8', value: '3.8'}, 
      {label: '3.9', value: '3.9'}, 
      {label: '3.10', value: '3.10'}
    ],
    'DBT': [
      {label: '3.6', value: '3.6'}, 
      {label: '3.8', value: '3.8'}, 
      {label: '3.9', value: '3.9'}, 
      {label: '3.10', value: '3.10'}
    ]
  }
  language = null;

  sizeOptions = [
    {label: 'X-Small (.25 CPU, .5GB Memory)', value: 'X-Small'},
    {label: 'Small (.5 CPU, 1 GB Memory)', value: 'Small'},
    {label: 'Medium (1 CPU, 2 GB Memory)', value: 'Medium'},
    {label: 'Large (2 CPU, 4 GB Memory)', value: 'Large'},
    {label: 'X-Large (4 CPU, 8 GB Memory)', value: 'X-Large'},
    {label: '2X-Large (8 CPU, 16 GB Memory)', value: '2X-Large'},
    {label: '3X-Large (8 CPU, 32 GB Memory)', value: '3X-Large'},
  ]

  options = [
    {'title': 'From Git Repo', 'description': "After setting up the Git integration, select the repository for the package. You will need a requirements.txt file."}
  ]

  availableRepos = [];
  availableBranches = [];

  selectedFileInfo = '';

  languageOptions = [
      {label: "Python", value: "Python", icon: "./assets/img/python-transparent.png"},
      {label: "DBT", value: "DBT", icon: "./assets/img/dbt-orange.png"}
  ]

  icons = {'bitbucket': './assets/img/bitbucket_icon.png', 'github': './assets/img/github.png'}

  versionOptions = this.languages['Python']

  existingPackages = []

 configurationChanges = {
    'new': [], // entire variable object
    'updated': [],// should include all environments or other settings
    'deleted_variables': [], // {'key': ''}
    'deleted_environments': [] // 'TEST_ENV'
  };

  packageObj ={
      languageOptions: this.languageOptions,
      versionOptions: this.versionOptions,
      sizeOptions: this.sizeOptions,
      existingPackages: this.existingPackages,
      packageToCopy: null,
      fileOptions: [],
      functionOptions: [],
      profileOptions: []
   };

   selectedOption = this.options[0]['title'];
   isCopy = false;
   isReady = true;

   envCollapsed = true;
   completedVariableForms = []
   profileModalError = '';
   existingProfiles = []
   validFiles = {}

  constructor(
     private router: Router,
     private route: ActivatedRoute,
     private fb: FormBuilder,
     private modalService: NgbModal,
     private auth: AuthService,
     private http: HttpClient,
     public toastrService: ToastrService,
     private api: ApiService,
     public packageService: PackagesService
  ) { 
  
    this.pythonPackageForm = this.newPythonPackageForm();
    this.dbtPackageForm = this.newDbtPackageForm();
    this.profileForm = this.newProfileForm();
    this.variableForm = this.newVariableForm();
    this.repoPackageForm = this.newRepoPackageForm();
    this.basicForm = this.newBasicForm();
    this.headers = this.auth.getHeaders()
    this.conf.newVariable = this.copy(this.conf.newVariableTemplate);
  }

  ngOnInit(): void {
    this.conf.configurationForm = this.newconfigurationForm();
    this.conf.fromJSONCode = JSON.stringify(this.conf.jsonTemplate, null, 2)
    // this.zipPackageForm = this.newZipPackageForm();
    this.getGitIntegrations();
    this.getConfigurations();
    this.language = 'Python'
    if(this.packageService.package != null){
       
       this.selectedOption = 'From Git Repo'

       this.isReady = true;
       let language = this.packageService.package.package_language.toLowerCase()

       this.basicForm = this.updateBasicForm(this.packageService.package)

       if( language == 'python'){
         this.pythonPackageForm = this.updatePythonPackageForm(this.packageService.package)
       }
       else if(language == 'dbt'){
         this.dbtPackageForm = this.updateDbtPackageForm(this.packageService.package)
       }
       
       // Get the configuation
       this.getPackageConfiguration(this.packageService.package.package_id);

    }
    // this.sub = this.route.params.subscribe(routeParams => {
    //     if(this.packageService.package == null){
    //       console.log('returning to packages')
    //       this.returnPackages();
    //     }
    //   });

  }

  ///backend/packages/<int:package_id>/configuration
  getPackageConfiguration(package_id){

    let url = this.url + '/packages/' + package_id + '/configuration'
    this.api.getData(url, this.page, 'git_package_configuration').subscribe(
        data=>{
          this.hasConfiguration = false;
          if('status' in data){
            if(data['status'] == 'success'){
              console.log(data['message'])
             
              this.conf.configuration = []
              this.conf.configuration.push(
                 {
                   configuration_environments: data['message'][0]['package_configuration_environments'],
                   configuration_variables: data['message'][0]['package_configuration_variables'],
                   global_configuration_id: data['message'][0]['global_configuration_id'],
                   package_configuration_id: data['message'][0]['package_configuration_id']
                 }
               )
              this.hasConfiguration = true;
              this.conf.configurationOG =  JSON.parse(JSON.stringify(this.conf.configuration))
            }
          }    
        },
        err=>{
         this.hasConfiguration = false;
        }
      );
  }

  returnPackages(){
    // Need to reset everything 
    this.packageService.clear();
    this.router.navigateByUrl('/packages');
  }

  newBasicForm(): FormGroup {
     return this.fb.group({
        package_name: ['', Validators.required],
        package_ecs_size: ['Medium', [Validators.required]],
        package_timeout_minutes: 0,
        package_language: ['Python', [Validators.required]],
        package_language_version: ['3.9', [Validators.required]],
        source: 'frontend'
      })
  }

  updateBasicForm(package_obj): FormGroup {
     let p = package_obj;
     return this.fb.group({
        package_name: [p.package_name, Validators.required],
        package_ecs_size: [p.package_ecs_size, [Validators.required]],
        package_timeout_minutes: p.package_timeout_minutes,
        package_language: [p.package_language, [Validators.required]],
        package_language_version: [p.package_language_version, [Validators.required]],
        source: 'frontend'
      })
  }

  newDbtPackageForm(): FormGroup {
     return this.fb.group({
        package_file_name:['', [Validators.required]],
        source: 'frontend'
      })
  }

  updateDbtPackageForm(package_obj): FormGroup {
     let p = package_obj;
     return this.fb.group({
        package_file_name:[p.package_file_name_path, [Validators.required]],
        source: 'frontend'
      })
  }

  newPythonPackageForm(): FormGroup {
     return this.fb.group({
        package_file_name:['', [Validators.required]],
        package_function_name:['', [Validators.required]],
        source: 'frontend'
      })
  }

  updatePythonPackageForm(package_obj): FormGroup {
     let p = package_obj;
     return this.fb.group({
        package_file_name:[p.package_file_name_path, [Validators.required]],
        package_function_name:[p.package_function_name, [Validators.required]],
        source: 'frontend'
      })
  }
   
  newRepoPackageForm(): FormGroup {
     return this.fb.group({
        package_repo_name: ['', Validators.required],
        package_repo_branch_name: ['', Validators.required],
        source: 'frontend'
      })
  }

  updateRepoPackageForm(package_obj): FormGroup {

     let p = package_obj;
     console.log('repo name: ', p.package_repository_name)
     return this.fb.group({
        package_repo_name: [p.customer_integration_id, [Validators.required]],
        package_repo_branch_name: [p.package_repository_branch_name, [Validators.required]],
        source: 'frontend'
      })
  }

  newProfileForm(): FormGroup{
    return this.fb.group({
        package_profile_name: ['', Validators.required],
        source: 'frontend'
      })
  }

  newVariableForm(): FormGroup{
    return this.fb.group({
        package_profile_variable_key: [null, Validators.required],
        package_profile_variable_value :[null, [Validators.required]],
        package_profile_variable_is_secure: [false, Validators.required],
        source: 'frontend'
      })
  }



  resetEnvironmentVars(){
   this.conf.environmentVars = {
        configuration_environments: ['DEV', 'STAGE', 'PROD'],
        configuration_variables: []
    }
    this.conf.newVariable =  this.copy(this.conf.newVariableTemplate);
  }

   newconfigurationForm(): FormGroup{
    return this.fb.group({
        global_configuration_id: [null]
      })
  }

  loadConfigurationForm(configuration): FormGroup{
    return this.fb.group({
        global_configuration_id: [configuration.global_configuration_id]
      })
  }

  loadEnvironmentVars(configuration){
    this.conf.environmentVars = {
        configuration_environments: Object.keys(configuration.configuration_variables[0].environments),
        configuration_variables: configuration.configuration_variables
    }
  }

  // setOption(option){
  //   this.selectedOption = option.title;
  //   this.basicPackageForm = this.newBasicPackageForm();
  //   this.filesChecked = false;

  //   if(this.selectedOption == 'From Existing Package'){
  //     this.isCopy = true;
  //     this.isReady = false;
  //   }
  //   else{
  //     this.isCopy = false;
  //     this.isReady = true;
  //   }
  // }

  addVariableForm(variableForm){
    this.completedVariableForms.push(variableForm)
    this.variableForm = this.newVariableForm();
  }

  removeVariableForm(index){
    this.completedVariableForms.splice(index, 1);
  }

  // setCopyPackage(){
  //   this.basicPackageForm = this.update(this.packageObj.packageToCopy);
  //   this.isReady = true;
  // }

  setFunctionOptions(){
    this.packageObj.functionOptions = []
    console.log(this.conf.files)
    let file = this.conf.files.find(x => x.file_name === this.pythonPackageForm.value.package_file_name);

    for(let func of file.functions){
      this.packageObj.functionOptions.push(
        {label: func, value: func}
      )
    }
  }

  setProfileOptions(){
    this.packageObj.profileOptions = []
    let file = this.conf.files.find(x => x.file_name === this.dbtPackageForm.value.package_file_name);

    for(let profile of file.profiles){
      this.packageObj.profileOptions.push(
        {label: profile, value: profile}
      )
    }
  }

  setVersions(){
    
    this.language = this.basicForm.value.package_language;
    this.versionOptions = this.languages[this.language];
    this.packageObj.versionOptions = this.languages[this.language];
  }

  return(){
    this.router.navigateByUrl('/packages');
  }


  hasError(form, controlName){
    if ((form.controls[controlName].touched || this.profileSubmitted) && form.controls[controlName].errors?.required){
        return true
    }
    return false
  }

 getPlaceholder(){
    if(this.conf.newVariableError.length > 0){
      return this.conf.newVariableError
    }
    else if(this.conf.usingConfiguration && !this.conf.isNewKey){
      return 'Select Variable'
    }
    return 'Variable Name'
  }

  pythonFormControl() {
    return this.pythonPackageForm.controls;
  }
  dbtFormControl() {
    return this.dbtPackageForm.controls;
  }

  basicFormControl() {
    return this.basicForm.controls;
  }

  repoFormControl() {
    return this.repoPackageForm.controls;
  }

  variableFormControl(form){
    return form.controls;
  }

  profileFormControl(){
    return this.profileForm.controls;
  }

  newVariableFormControl(){
    return this.variableForm.controls;
  }

  clickCheckBox(form){
    form.controls.package_profile_variable_is_secure.value = !form.controls.package_profile_variable_is_secure.value;
  }

  openProfileModal(content, options={}){
  
    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
          if(reason == 'close'){
            this.completedVariableForms = [];
            this.variableForm = this.newVariableForm();
      }
      });
  }

  navigateIntegrations(){
    this.router.navigateByUrl('/admin/settings/integrations');
  }

  getGitIntegrations(){
    this.reposLoading = true;
    let url = this.url + '/admin/settings/integrations/git'
       this.api.getData(url, this.page, 'git_integrations').subscribe(
        data=>{
          if('status' in data){
            if(data['status'] == 'success'){
              
              this.availableRepos = [];
              for(let repo of data['message']){
                let icon = this.icons[repo['integration_type']]
                let obj = {label: repo['integration_name'], value: repo['integration_id'], icon: icon}
                this.availableRepos.push(obj)
              }
             
              if(this.packageService.package != null){
                this.setVersions()
                this.repoPackageForm = this.updateRepoPackageForm(this.packageService.package);
                this.repoBranches({value: this.packageService.package.customer_integration_id})
                this.repoCheck({value: this.packageService.package.package_repository_branch_name})
              }
              this.reposLoading = false;
            }
          }    
        },
        err=>{
          this.reposLoading = false;
        }
      )
  }

  setGlobalVariable($event){
   
    if($event == undefined){
      this.conf.newVariable = JSON.parse(JSON.stringify(this.conf.newVariableTemplate));
    }
    else if($event.value == null){ // This is for a new variable not from configuration
      this.conf.isNewKey = true;
      this.conf.newVariable = JSON.parse(JSON.stringify(this.conf.newVariableTemplate));
    }
    else{
       // find the object by index 
       let varIndex = this.conf.optionalVariables.findIndex((obj => obj.id == $event.value));
       this.conf.newVariable = this.copy(this.conf.optionalVariables[varIndex]);
       
    }
  }

  setGlobalConfiguration(){
    // check the form value
    let glblConfId = this.conf.configurationForm.controls.global_configuration_id.value;

    if(glblConfId == null){
      // remove all of the global config variables
      for(let i = this.conf.environmentVars.configuration_variables.length-1; i >= 0; i--){
        if(this.conf.environmentVars.configuration_variables[i].global_config_id != null){
      
          this.conf.environmentVars.configuration_variables.splice(i, 1)
        }
      }
      this.conf.usingConfiguration = false;
      return
    }

    let confIndex = this.conf.configurations.findIndex((obj => obj.configuration_id == glblConfId));
    let glblConf = this.copy(this.conf.configurations[confIndex]);

    if(glblConf){
      // Get the full value from the conf.configurations
      this.conf.usingConfiguration = true;
      let varLength = glblConf.configuration_variables.length;
      
      for(let i = varLength-1; i >= 0; i--){
        glblConf.configuration_variables[i]['global_config_id'] = glblConf.configuration_id;
        if(glblConf.configuration_variables[i].is_optional){
          let obj = {label: glblConf.configuration_variables[i].key, value: glblConf.configuration_variables[i].id}
          this.conf.variableOptions.push(obj)
          this.conf.optionalVariables.push(this.copy(glblConf.configuration_variables[i]))
          glblConf.configuration_variables.splice(i, 1)
        }
      }

      // Need to preserve the existing package variables - merge in 
      if(this.conf.environmentVars.configuration_variables.length == 0){
        this.conf.environmentVars = JSON.parse(JSON.stringify(glblConf));
      }
      else{
        // Clear out any old global values first
        let ogLength = this.conf.environmentVars.configuration_variables.length - 1;
        for(let i = ogLength; i > -1; i--){
          if(this.conf.environmentVars.configuration_variables[i].global_config_id != null){
            // remove var 
            this.conf.environmentVars.configuration_variables.splice(i, 1)
          }
        }
        
        // Add any missing environments (add them to all existing variables as null value)
        for(let env of glblConf.configuration_environments){

          // if it doesn't exist, add it. 
          if(!(this.conf.environmentVars.configuration_environments.includes(env))){
            this.conf.environmentVars.configuration_environments.push(env)
             // update all of the other variable environment values
            for(let v of this.conf.environmentVars.configuration_variables){
              
              if(!(env in v.environments)){
                v.environments[env] = null;
              }
            }
          }
        }
        // Add any missing variables
        for(let v of glblConf.configuration_variables){
          // if it doesn't exist, add it. Or overwrite
          let varIndex = this.conf.environmentVars.configuration_variables.findIndex(x => x.key === v.key);
          if(varIndex > -1){
            this.conf.environmentVars.configuration_variables[varIndex] = v
          }
          else{
            this.conf.environmentVars.configuration_variables.push(v)
          }
        }
      }
    }
  }

  getConfigurations(){
    this.conf.configsLoading = true;
    let url = this.url + '/packages/configurations/global'
       this.api.getData(url, this.page, 'package_configurations').subscribe(
        data=>{
          if('status' in data){
            if(data['status'] == 'success'){
              
              let configs = data['message']
              this.conf.configurations = configs;

              for(let conf of configs){
                let obj = { label: conf['configuration_name'], value: conf['configuration_id'] }
                this.conf.configurationsList.push(obj)
              }
              this.conf.configsLoading = false;
            }
          }    
        },
        err=>{
          this.conf.configsLoading = false;
        }
      )
  }

  // public setFileFunctions(file){
  //   this.fileFunctionOptions = file['functions'];
  // }

  public fileCheck($event) {
    let fileList: FileList = $event.target.files;

    this.filesChecking = true;
    this.filesCheckError = false;
    this.fileError = '';

    this.packageObj.fileOptions = []
    this.fileNameOptions = [];

    if(fileList[0]['type'] != 'application/zip'){
      this.fileError = 'File needs to be a zip file'
      this.filesCheckError = true;
      return
    }

    if (fileList.length > 0) {
      let file: File = fileList[0];
      console.log(file)
      this.selectedFileInfo = file.name + ' (' + file.size / 1000 + ' KB)';
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      formData.append('fileType', 'zip');
      let headers = this.auth.getHeaders(false);
      let url = this.url + '/packages/file_upload/check'
      this.http.post(url, formData ,{headers}).subscribe(
       data => {

         if(data['message']['has_req'] && data['message']['valid']){
           
            for(let file of data['message']['files']){
              this.packageObj.fileOptions.push(
                {
                   label: file['file_name'],
                   value: file
                 }
               )
            }
            this.filesChecked = true;
            this.filesChecking = false;
           
         }
         else{
            this.filesCheckError = true;
            this.filesChecking = false;
            this.fileError = 'No valid files with functions found.'
         }
         
      }
      ,err=>{
        this.filesCheckError = true;
        this.filesChecking = false;
        this.fileError = 'Could not extract zip file. Check your file and try again.'
        
      });
      
    }
  } 

  public repoBranches($event) {

    if($event == null){
      this.selectedIntegration = null;
      this.availableBranches = [];
      return
    }
    
    let headers = this.auth.getHeaders(false);
    let url = this.url + '/admin/settings/integrations/git/branches'

    let formData = {integration_id: $event.value}
    this.selectedIntegration = $event.value;

    this.branchError = '';
    this.branchesChecking = true;
    this.branchesCheckError = false;
    
    
    this.http.post(url, formData ,{headers}).subscribe(
       data => {

         if(data['message']){

             for(let branch of data['message']){
               this.availableBranches.push(
                 {
                   label: branch,
                   value: branch
                 }
               )
             }
         }
         else{
            this.branchesCheckError = true;
            
            this.branchError = 'Unable to retrieve branches from repository. Please check permissions in integration.'
         }
         this.branchesChecking = false;
         
      }
      ,err=>{
        this.branchesCheckError = true;
        this.branchesChecking = false;
        this.branchError = 'Unable to retrieve branches from repository. Please check permissions in integration.'
       
      });

  }

  public repoCheck($event) {

    if($event == null){
      this.filesChecked = false;
      return
    }
    
    let headers = this.auth.getHeaders(false);
    let url = this.url + '/admin/settings/integrations/git/check'

    let formData = {integration_id: this.selectedIntegration, branch: $event.value, runtime: this.language}

    this.fileError = '';
    this.filesChecking = true;
    this.filesCheckError = false;
    
    this.http.post(url, formData ,{headers}).subscribe(
       data => {

         if(data['message']['has_req'] && data['message']['valid']){
           
            this.fileNameOptions = [];
            this.conf.files = data['message']['files'];
            for(let file of data['message']['files']){
              this.packageObj.fileOptions.push(
                {
                   label: file['file_name'],
                   value: file['file_name']
                 }
               )
            }
            this.filesChecked = true;
            this.filesChecking = false;

            if(this.packageService.package != null){
              if(this.language == 'Python'){
                this.setFunctionOptions()
              }
            }
           
         }
         else{
            this.filesCheckError = true;
            this.filesChecking = false;
            this.fileError = 'No valid files with functions found.'
         }
         
      }
      ,err=>{
        this.filesCheckError = true;
        this.filesChecking = false;
        this.fileError = 'No valid files'
       
      });

  }


removeEnv(env){
    var index = this.conf.environmentVars.configuration_environments.indexOf(env);
    this.conf.environmentVars.configuration_environments.splice(index, 1);

    for(let v of this.conf.environmentVars.configuration_variables){
      delete v['environments'][env];
    }
    if('package_configuration_id' in this.conf.environmentVars){
      this.conf.configurationChanges.deleted_environments.push(env);
    }
  }

  addEnv(){
    
    let env = this.conf.newEnv;
    if(env == ''){
      return;
    }
    if(env in this.conf.environmentVars.configuration_environments){
      return
    }
    this.conf.environmentVars.configuration_environments.push(env)
    for(let v of this.conf.environmentVars.configuration_variables){
      v['environments'][env] = ''
    }
    this.conf.newVariable.environments[env] = '';
    this.conf.newEnv = '';
  }

  addVariable(){
    this.conf.newVariableError = ''
    if(this.conf.newVariable.key == '' || this.conf.newVariable.key == null){
      this.conf.newVariableError = 'ENTER VARIABLE NAME'
      return
    }

    for(let v of this.conf.environmentVars.configuration_variables){
      if(v['key'] == this.conf.newVariable.key){
        this.conf.newVariableError = 'DUPLICATE KEY!'
        return
      }
    }

    // remove any invalid environments
    for(let env in this.conf.newVariable.environments){
      
      let in_var = true ? this.conf.environmentVars.configuration_environments.includes(env) : false;
      
      if(!(in_var)){
        delete this.conf.newVariable.environments[env];
      }
    }
    if(this.conf.isNewKey){
      this.conf.isNewKey = false;
    }

    if(this.conf.newVariable.global_config_id != null){ // remove from options
      // Get index of option and remove
      let varIndex = this.conf.variableOptions.findIndex((obj => obj.value == this.conf.newVariable.id));
      this.conf.variableOptions.splice(varIndex, 1)
      this.conf.variableOptions = this.copy(this.conf.variableOptions);
    }

    this.conf.environmentVars.configuration_variables.push(this.copy(this.conf.newVariable))
    this.conf.newVariable = this.copy((this.conf.newVariableTemplate));
  }

  copy(object){
    return JSON.parse(JSON.stringify(object))
  }
s
 
  removeVariable(variable){
    var index = this.conf.environmentVars.configuration_variables.indexOf(variable);
    this.conf.environmentVars.configuration_variables.splice(index, 1);
    if('package_configuration_id' in this.conf.environmentVars){
      if(variable.id != null){
        this.conf.configurationChanges.deleted_variables.push({id: variable.id});
      } 
    }
    if(variable.global_config_id != null){
      this.conf.variableOptions.push({label: variable.key, value: variable.id});
      this.conf.variableOptions = this.copy(this.conf.variableOptions);
    }
  }

  configurationFormControl(){
    return this.conf.configurationForm.controls;
  }

  checkUniqueKeys(){
    let vList = [];
    for(let v of this.conf.environmentVars.configuration_variables){
      if(vList.includes(v.key)){
        console.log('Key not unique!')
        return false
      }
      vList.push(v.key)
    }
    return true;
  }

  isUpdating(environmentVars){
    let is = 'package_configuration_id' in environmentVars
    return is
  }

cancel(){
    this.resetEnvironmentVars();
    this.resetConfig();
  }

  resetConfig(){
     // this.conf.configuration = null;
     this.conf.variableOptions = [{label: 'Create New Variable', value: null}];
     // this.conf.configurationOG = null;
     this.conf.configurationForm.reset();
     this.modalService.dismissAll();
     this.conf.saving = false;
     this.deleteMessage = '';
     this.conf.configurationChanges = {
      'new': [], // entire variable object
      'updated': [],// should include all environments or other settings
      'deleted_variables': [], // {'key': ''}
      'deleted_environments': [] // 'TEST_ENV'
    };
    this.conf.configurationError = '';
    this.conf.usingConfiguration = false;
  }
openNewConfigModal(content, options={}, configuration=null){
    
    if(configuration != null){
     
      this.conf.environmentVars =this.copy(configuration);
      // this.conf.configurationOG = this.copy(this.conf.environmentVars);
      this.conf.updateConfig = true;
      this.conf.configurationForm = this.loadConfigurationForm(configuration);
      this.setGlobalConfiguration()
      // this.conf.configurationForm.controls.configuration_name.disable();
    
    }
 
    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
        this.cancel();
      });
  }

submitEnv(){
  this.conf.envError = ''
  // Parse key-value pairs 
  let envObj = JSON.parse(this.conf.fromEnvCode)

  let envTemplate = {
    "configuration_environments": ["DEV", "STAGE", "PROD"],
    "configuration_variables": []
    }
  let varTemplate = {
    "key": '', 
    "environments": {
      'DEV': '',
      'STAGE': '',
      'PROD': ''
    },
    "is_secure": false,
    "is_optional": false
  }

  // const dotenv = require('dotenv')
  // const config = dotenv.parse(envObj)
  // console.log(config)

}
submitJSON(){
    let main_keys = ['configuration_environments', 'configuration_variables']
    let variable_keys = ['key', 'environments', 'is_secure', 'is_optional']
    this.conf.jsonError = ''

    // Check if valid json
    if(!this.isValidJSON(this.conf.fromJSONCode)){
      this.conf.jsonError = 'Invalid JSON'
      return 
    }

    let jsonObj = JSON.parse(this.conf.fromJSONCode)

    // Check main keys 
    for(let key of main_keys){
      if(!(key in jsonObj)){
        this.conf.jsonError = 'Missing root key value: ' + key
        return
      }
    }

    // Check for the environments
    if(jsonObj['configuration_environments'].length == 0){
      this.conf.jsonError = 'No environments are defined'
      return
    }

   
    let all_keys = []
   
    // Check the keys for each variable
    let vars = jsonObj['configuration_variables']
    for(let i = 0; i < vars.length; i++){
      for(let k of variable_keys){
        if(!(k in vars[i])){
          this.conf.jsonError = 'Missing attribute "' + k + '" at variable index ' + i
          return
        }
      }

      // Check for any duplicate keys
      if(vars[i]['key'] in all_keys){
        this.conf.jsonError = 'Duplicate key found in configuration_variables list: "' + vars[i]['key'] + '"'
        return
      }
      else{
        all_keys.push(vars[i]['key'])
      }
      // Check that each variable has a value for each environment
      for(let env of jsonObj['configuration_environments']){
        if(!(env in vars[i]['environments'])){
          this.conf.jsonError = 'Missing environment "' + env + '" at variable index ' + i
          return
        }
      }
    }    
    // If all the tests are passed, make the json the new object
    this.conf.environmentVars = this.copy(jsonObj);
    this.conf.fromJSONCode = JSON.stringify(this.conf.jsonTemplate, null, 2)
    this.conf.fromJSON = false;
  }

  isValidJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
           return true
        }
    }
    catch (e) { }

    return false
  }
  toggleJSON(){
    // If we have variables then load those
    if(this.conf.environmentVars.configuration_variables.length > 0){
      this.conf.fromJSONCode = JSON.stringify(this.conf.environmentVars, null, 2)
    }
    this.conf.fromJSON = !this.conf.fromJSON;
  }

  toggleEnv(){
    // If we have variables then load those
    if(this.conf.environmentVars.configuration_variables.length > 0){
      this.conf.fromEnvCode = JSON.stringify(this.conf.environmentVars, null, 2)
    }
    this.conf.fromEnv = !this.conf.fromEnv;
  }

  readyToCreate(){
    if(this.pythonPackageForm.valid || this.dbtPackageForm.valid){
      return true
    }
    return false
  }

  createPackage(){
    this.conf.creatingPackage = true;

    if (!this.basicForm.valid || (!this.dbtPackageForm.valid && !this.pythonPackageForm.valid)){
      this.createError = 'Please fill out any required fields'
      this.conf.creatingPackage = false;
      console.log(this.createError)
      console.log('Basic form: ', this.basicForm)
      console.log('Repo form: ', this.repoPackageForm)
      console.log('Python form: ', this.pythonPackageForm)
      return 
    }

    // Repo Config
    let repo_name = this.repoPackageForm.valid ? this.availableRepos.find(x => x.value === this.selectedIntegration).label : null;
    let repo_branch = this.repoPackageForm.valid ? this.repoPackageForm.controls.package_repo_branch_name.value : null;
    let integration_id = this.repoPackageForm.valid ? this.selectedIntegration : null;
    // Configuration
    let envs = this.conf.configuration == null ? [] : this.conf.configuration[0].configuration_environments;
    let vars = this.conf.configuration == null ? {} : this.conf.configuration[0].configuration_variables;
    let global_id = this.conf.configuration == null ? null : this.conf.configuration[0].global_configuration_id;
    let language = this.basicForm.controls.package_language.value.toLowerCase()

    let control = null;

    if(language == 'python'){
      control = this.pythonFormControl();
    }
    else if(language == 'dbt'){
      control = this.dbtFormControl();
    }

    // PUT Package if updating, else POST
    if(this.packageService.package != null){

        for(let newVar of vars){
          if(newVar.id == null){
            this.configurationChanges.new.push(newVar)
          }
        }

        // Figure out all of the updated variables by comparing the OG environment variables and latest
        for(let ogVar of this.conf.configurationOG[0].configuration_variables){
          // Find the match of the new variable list
          for(let newVar of vars){
            if(ogVar['id'] == newVar['id']){
              let environments = {};
              let changed = false;

              // Find all the environment value differences
              for(let key in ogVar['environments']){

                // Check for changes with existing environments
                if(key in newVar['environments']){
                  if(ogVar['environments'][key] != newVar['environments'][key]){
                    environments[key] = JSON.parse(JSON.stringify(newVar['environments'][key]));
                    changed = true;
                  }
                }
              }
              // find the new environments
              for(let key in newVar['environments']){
                if(!(key in ogVar['environments'])){
                  environments[key] = JSON.parse(JSON.stringify(newVar['environments'][key]));
                  changed = true;
                }
              }
              let updatedVar = JSON.parse(JSON.stringify(newVar))
              if(changed){
                updatedVar['environments'] = environments;
                this.configurationChanges.updated.push(updatedVar)
              }
              else{
                if(ogVar['is_optional'] != newVar['is_optional']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
                else if(ogVar['is_secure'] != newVar['is_secure']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
                else if(ogVar['key'] != newVar['key']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
              }
            }
          }
        }

      let put_package = {
          package_name: this.basicForm.controls.package_name.value,
          package_file_name_path: control.package_file_name.value,
          package_function_name: null,
          package_language: this.basicForm.controls.package_language.value,
          package_language_version: this.basicForm.controls.package_language_version.value,
          package_size: this.basicForm.controls.package_ecs_size.value,
          package_timeout_minutes: this.basicForm.controls.package_timeout_minutes.value,
          package_configuration_id: this.conf.configuration[0].package_configuration_id,
          package_configuration_environments: envs,
          package_configuration_changes: this.configurationChanges,
          package_repository_name: repo_name,
          package_repository_branch_name: repo_branch,
          customer_integration_id: integration_id,
          global_configuration_id: global_id
      }
      
      if(language == 'python'){
        put_package['package_function_name'] = control.package_function_name.value;
      }
      

      let headers = this.auth.getHeaders();
      let url = this.url + '/packages/' + this.packageService.package.package_id
      this.http.put(url, put_package ,{headers}).subscribe(
         data => {
             // Success toast
           this.toastrService.success('Package Updated Successfully')
             // Redirect back to the package page
           this.conf.creatingPackage = false;
           this.returnPackages()
         },
         err=>{
           this.createError = 'Failed to update package'
           this.conf.creatingPackage = false;
           this.toastrService.error('Failed to Update Package')

             // Report error
         }
      );
    }
    else{

      let post_package = {
          package_name: this.basicForm.controls.package_name.value,
          package_file_name_path: control.package_file_name.value,
          package_function_name: null,
          package_language: this.basicForm.controls.package_language.value,
          package_language_version: this.basicForm.controls.package_language_version.value,
          package_size: this.basicForm.controls.package_ecs_size.value,
          package_timeout_minutes: this.basicForm.controls.package_timeout_minutes.value,
          package_configuration_environments: envs,
          package_configuration_variables: vars,
          package_repository_name: repo_name,
          package_repository_branch_name: repo_branch,
          customer_integration_id: integration_id,
          global_configuration_id: global_id
      }

     
      if(language == 'python'){
        post_package['package_function_name'] = control.package_function_name.value;
      }
     

      let headers = this.auth.getHeaders();
      let url = this.url + '/packages'
      this.http.post(url, post_package ,{headers}).subscribe(
         data => {
             // Success toast
           this.toastrService.success('Package Created Successfully')
             // Redirect back to the package page
           this.conf.creatingPackage = false;
           this.returnPackages()
         },
         err=>{
           this.createError = 'Failed to create package'
           this.conf.creatingPackage = false;

             // Report error
         }
      );
    }
  }


  saveConfiguration(){
    
    if(this.conf.environmentVars.configuration_variables.length > 0 && (this.conf.configurationForm.valid || this.conf.configurationForm.controls.configuration_name.value.length > 0 )){

        if(!(this.checkUniqueKeys())){
          this.conf.configurationError = 'Environment keys are not unique!'
          return
        }

        // Save the configuration
        let configData = {
          package_configuration_id: this.conf.configuration == null ? null : this.conf.configuration[0].package_configuration_id,
          global_configuration_id:  this.conf.configurationForm.controls.global_configuration_id.value,
          configuration_environments: this.conf.environmentVars.configuration_environments,
          configuration_variables: this.conf.environmentVars.configuration_variables
        }

        this.conf.configuration = [this.copy(configData)]
        console.log(configData)

        this.modalService.dismissAll();
        this.hasConfiguration = true;
   
    }
    else{
        this.conf.configurationError = 'Please provide a configuration name and complete the form'
        return
    }
  }
  getList(list_type){
    var newStr = ''

    if(list_type == 'variables'){
      return this.conf.configuration[0].configuration_variables

      // for(let v of this.conf.configuration[0].configuration_variables){
      //     if(newStr == '') {
      //       newStr = v.key
      //     }
      //     else{
      //       newStr = newStr + ' | ' + v.key
      //     }
      // }  
    }
    else if(list_type == 'environments'){
       return this.conf.configuration[0].configuration_environments
    //   for(let v of this.conf.configuration[0].configuration_environments){
    //       if(newStr == '') {
    //         newStr = v
    //       }
    //       else{
    //         newStr = newStr + ' | ' + v
    //       }
    //   }  
    }
    // return newStr
    
  }
  deletePackage(){
    if(!this.conf.delete_check){
      this.conf.delete_check = true;
      return;
    }
    this.conf.deleting = true;

    let url = this.url + '/packages/' + this.packageService.package.package_id;
    let headers = this.headers
    this.http.delete(url, {headers}).subscribe(
        data=>{
          if(data['status'] == 'success'){
            this.conf.deleting = false;
            this.conf.delete_check = false;
            this.toastrService.success('Package Deleted Successfully');
            this.returnPackages()
          }
       },
       err=>{
          this.conf.deleting = false;
          this.conf.delete_check = false;
          this.toastrService.success('Failed to delete package');
       }
    );
    

  }
}
