<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">

				<div style="width: 100%" style="padding-bottom: 5px;">
					<div style="display: inline; font-size: 18pt; font-weight: bold; cursor: pointer; color: black;" [ngStyle]="newIntegration && {'color':'#717171'}" (click)="returnIntegrations()"><i class="fa-thin fa-layer-group" style="margin-right: 10px;"></i>Integrations</div>
					<div *ngIf="newIntegration" style="display: inline;padding-left: 10px;color: #ccc; font-size: 18pt; font-weight: bold;">/</div>
					<div *ngIf="newIntegration" style="display: inline;padding-left: 10px; color: black; font-size: 18pt; font-weight: bold;"> New Integration</div>
				</div>
				<p class="text-muted">Connect to other applications and use them in Carve.</p>
				<div *ngIf="!newIntegration" class="card mb-4 shadow-hover">
		            	<ngx-loading-bar fixed [includeSpinner]="false" color="#0083a8" ref="intLoad"></ngx-loading-bar>
		            	<div class="card-header" style="padding-right: 20px; padding-left: 20px;">
		            		<div class="float-left">
								 <label class="nav-item navbar-text navbar-search-box p-0 active">
									<i class="ion ion-ios-search navbar-icon align-middle"></i>
							          <span class="navbar-search-input pl-2" style="width: 400px !important">
							            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important; margin-left: 20px !important;">
							          </span>
							          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
							        </label>
							</div>
							
							<div class="float-right"  style="padding-right: 10px">
					              <button class="btn btn-outline-primary" type="button" (click)="startNewIntegration()">
							           <i class="fa-thin fa-layer-group" style="margin-right: 5px;"></i> New Integration 
							      </button>
				      		</div>
			            </div>
		            	<div class="card-body overview-card" >
							<div style="width: 100%; height: 100%;">
								<p-table 
										#integrationsTable
										styleClass="p-datatable-m"
										[value]="existingIntegrations"
										sortMode="single"
									  	[paginator]="false" 
		        						responsiveLayout="scroll"
								 >
								
							        <ng-template pTemplate="header" style="font-size:9pt;">
							            <tr>
							                <th pSortableColumn="configuration_name">
							                	Integration Name
							                	<p-sortIcon field="configuration_name"></p-sortIcon></th>
							                <th>
							                	Application
							               	</th> 	
							                <th pSortableColumn="created_date">Created<p-sortIcon field="created_date"></p-sortIcon></th>
							                <th pSortableColumn="last_updated_date">Last Updated
							                <p-sortIcon field="last_updated_date"></p-sortIcon></th>
							                <th pSortableColumn="last_updated_by_username">Last Updated By<p-sortIcon field="last_updated_by_username"></p-sortIcon></th>
							                <th></th>
							            </tr>
							        </ng-template>
							        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-int>
							            <tr style="text-align: left;" class="hover clickable-row" (click)="openIntegrationConfigModal(integrationConfigModal, { windowClass: 'modal animate', centered: true }, int)">

							                <td>
							                	{{int.integration_name}}
							              	</td>
							                <td>
							                	<img height="15px" width="15px" [src]='int.logo' style="margin-bottom: 3px; margin-right: 5px;" />
							                {{int.integration_type | titlecase}}
							              </td>
							                <td>{{int.created_date | date:'short'}}</td>
							                <td>{{int.last_updated_date | dateAgo}}</td>
							                <td>{{int.last_updated_by_username}}</td>
							                <td>
							                </td>
							            </tr>
							        </ng-template>
								</p-table>
							</div>
						</div>
				</div>
				<div *ngIf="newIntegration">
					<div *ngFor="let nInt of newIntegrations" style="display: inline-block; padding-right: 25px; padding-bottom: 25px;">
						<div *ngIf="nInt.href != ''" style="position: relative;">
							<div *ngIf="nInt.loading" class="spinner-border spinner-border-sm" role="status" style="margin: auto;position: absolute;right: 10px;top: 10px;color:#2196F3;">
									        <span  class="sr-only">Loading...</span>
									</div>
							<a [href]="nInt.href">
								<button class="btn integration-btn">
									<img height="30px" width="30px" [src]='nInt.logo' style="margin-bottom: 5px; margin-right: 3px;" />
									<a *ngIf="nInt.att == null" style="display: None" href="https://www.flaticon.com/free-icons/sftp" title="sftp icons">Sftp icons created by Freepik - Flaticon</a>
									<span style="font-weight: bold; color: black;">{{nInt.display}}</span>
								</button>
							</a>
						</div>
						<div *ngIf="nInt.href == ''" style="position: relative;">
							<div *ngIf="nInt.loading" class="spinner-border spinner-border-sm" role="status" style="margin: auto;position: absolute;right: 10px;top: 10px; color:#2196F3;">
									<span  class="sr-only">Loading...</span>
							</div>
							<button class="btn integration-btn" 
							(click)="openIntegrationConfigModal(integrationConfigModal, {windowClass: 'modal animate', centered: true }, nInt)">
								<img height="30px" width="30px" [src]='nInt.logo' style="margin-bottom: 5px; margin-right: 3px;"/>
								<span style="font-weight: bold; color: black;">{{nInt.display}}</span>
							</button>
						</div>	
				</div>
		</div>
	</div>
</div>
  <div style="visibility:hidden;">
  	<img src='./assets/img/slack_logo.jpg'/>
  	<img src='./assets/img/bitbucket_icon.png'/>
  	<img src='./assets/img/github.png'/>
  	<img src='./assets/img/tableau.png'/>
  </div>

<ng-template #integrationConfigModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
		        	<img height="30px" width="30px" [src]='edit.logo'/>&nbsp;&nbsp;
		        	<span style="font-weight: bold; font-size:16pt; vertical-align: bottom; line-height: 32px;">{{edit.display}} Setup </span> 
		        	<!-- <span style="font-size:16pt; vertical-align: bottom; line-height: 32px;"> {{edit.integration_name}}</span> -->
		        	<div *ngIf="edit.last_updated_date == edit.created_date && edit.created_date != null" class="mr-auto" style="font-size: 9pt; padding-top: 10px;">Created by {{edit.created_by_username}} on {{edit.created_date | date:'short'}}</div>
      				<div  *ngIf="edit.last_updated_date != edit.created_date && edit.created_date != null"  class="mr-auto" style="font-size: 9pt;padding-top: 10px;">Updated by {{edit.created_by_username}} on {{edit.last_updated_date | date:'short'}}</div>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
      	<div *ngIf="edit.type == 's3'">
<!---------------------------- S3 FORM --------------------------------->
		      	<form [formGroup]="edit.form" (keydown.enter)="saveIntegration()" autocomplete="off">
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Bucket Name</span>
                  <span class="text-danger"
                    *ngIf="(formControl().bucket.touched || submitted) && formControl().bucket.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="bucket" id="bucket" name="bucket" type="text" class="form-control form-border" >
              </div>
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Access Key ID</span>
                  <span class="text-danger"
                     *ngIf="(formControl().access_key_id.touched || submitted) && formControl().access_key_id.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="access_key_id" id="access_key_id" name="access_key_id" type="text" class="form-control form-border" autocomplete="off">
              </div>
          
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Access Key Secret
                  </span>
                    <span class="text-danger"
                     *ngIf="(formControl().access_key_secret.touched || submitted) && formControl().access_key_secret.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="access_key_secret" id="access_key_secret" name="access_key_secret" type="password" class="form-control form-border" autocomplete="new-password">
              </div>
            </form>

<!---------------------------- END S3 FORM --------------------------------->
					</div>
					<div *ngIf="edit.type == 'sftp'">
<!---------------------------- SFTP FORM --------------------------------->
		      	<form [formGroup]="edit.form" (keydown.enter)="saveIntegration()" autocomplete="off">
		      		<div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Name</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().name.touched || submitted) && formControl().name.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="name" id="name" name="name" type="text" class="form-control form-border" >
		              </div>
		      		<div class="row">
		      			<div class="col-10">
		              <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Server</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().server.touched || submitted) && formControl().server.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="server" id="server" name="server	" type="text" class="form-control form-border" >
		              </div>
		            </div>
		            <div class="col-2">
		              <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Port</span>
		                  <span class="text-danger"
		                     *ngIf="(formControl().port.touched || submitted) && formControl().port.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="port" id="port" name="port" type="number" class="form-control form-border">
		              </div>
		            </div>
		          </div>
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Username
                  </span>
                    <span class="text-danger"
                     *ngIf="(formControl().username.touched || submitted) && formControl().username.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="username" id="username" name="username" type="text" class="form-control form-border">
              </div>
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Password
                  </span>
                    <span class="text-danger"
                     *ngIf="(formControl().password.touched || submitted) && formControl().password.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="password" id="password" name="password" type="password" class="form-control form-border" autocomplete="new-password">
              </div>
              <div class="form-group">
                <label class="form-label d-flex justify-content-between align-items-end">
                  <span>Path
                  </span>
                    <span class="text-danger"
                     *ngIf="(formControl().path.touched || submitted) && formControl().path.errors?.required">
                    Required
                  </span>
                </label>
                
                <input formControlName="path" id="path" name="path" type="text" class="form-control form-border">
              </div>
            </form>
<!---------------------------- END SFTP FORM --------------------------------->
          </div>
          <div *ngIf="edit.type == 'bitbucket'">
<!---------------------------- BITBUCKET FORM --------------------------------->
						<div>
							<form [formGroup]="edit.form" (keydown.enter)="saveIntegration()" autocomplete="off">
								<div class="form-group" *ngIf="formControl().url.touched || formControl().url.valid">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Repository Name</span>
		                </label>
		                <input formControlName="name" id="name" name="name" type="text" class="form-control form-border" readonly="readonly">
		           </div>
		           <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Source URL (SSH)</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().url.touched || submitted) && formControl().url.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input (change)="parseRepository()" formControlName="url" id="url" name="url" type="text" class="form-control form-border" placeholder="git@bitbucket.org:[project]/[repository].git">
		           </div>
		           <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Main Branch (any branch can be used after setup)</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().branch.touched || submitted) && formControl().branch.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="branch" id="branch" name="branch" type="text" class="form-control form-border" placeholder="main or master">
		           </div>
              </form>
             <div style="margin: 15px 0px; border: 1px solid #e6e5e5; width: 100%;"></div>
							<div style="font-size: 1rem; padding-bottom: 10px;">Read Access to Repository <span style="color: gray;">(Required)</span></div>
							 <ol>
							 		<li>In Bitbucket, select the repository you want to add.</li>
							 		<li>Click <span style="font-weight: bold;">Repository Settings.</span></li>
							 		<li>Click <span style="font-weight: bold;">Access Keys</span> >  <span style="font-weight: bold;">Add Key</span></li>
							 		<ul>
							 			<li>Generate Carve SSH key by clicking the button below </li>
							 			<li>Copy and paste the public key generated below into Bitbucket.</li>
							 			<li>In Bitbucket, click the <span style="font-weight: bold;">Add SSH Key</span> button</li>
							 		</ul>
							 		
							 </ol>
							 <div style="width: 100%; text-align: left; margin-bottom: 15px; padding-left: 25px;">
								 <div class="btn btn-outline-secondary" (click)="generateKey()">
									<i  *ngIf="!edit.generating_key" class="fal fa-file-certificate"></i>
									<div *ngIf="edit.generating_key" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
							        <span  class="sr-only">Loading...</span>
							    </div>
									<span style="margin-left: 5px;">Generate SSH Key</span>
								</div>
							</div>
							<div *ngIf="publicKey">
								<div style="font-weight: bold; color: gray; padding: 5px 0px;">Public Key</div>
								<div style="border: 1px solid gray; border-radius: 10px; padding: 10px; width: 100%; word-wrap: break-word; font-family: monospace;">
									{{publicKey}}
								</div>
							</div>
						</div>
						<div style="margin: 15px 0px; border: 1px solid #e6e5e5; width: 100%;"></div>
						<div style="font-size: 1rem;">Continuous Delivery<span style="color: gray;"> (Optional)</span></div>
						<div style="font-size: small; margin-bottom: 10px; color: gray;">Trigger updates to your Carve code packages with a webhook.</div>
						<ol>
						 		<li>In Bitbucket, select the repository you want to add.</li>
						 		<li>Click <span style="font-weight: bold;">Repository Settings.</span></li>
						 		<li>Click <span style="font-weight: bold;">Webhooks</span> >  <span style="font-weight: bold;">Add Webhook</span></li>
						 		<ul>
						 			<li>Generate a Carve Webhook URL by clicking the button below. </li>
						 			<li>Copy and paste the URL generated.</li>
						 			<li>Trigger recommendations: <span style="font-weight: bold;">Repository Push</span> and <span style="font-weight: bold;">Pull Request Merged</span>.</li>
						 			<li>Click the <span style="font-weight: bold;">Save</span> button in Bitbucket</li>
						 		</ul>
						 </ol>
						 <div style="width: 100%; text-align: left; padding-left: 25px;" *ngIf="!webhookURL">
						 	<div style="text-align:;" class="btn btn-outline-secondary" (click)=generateWebhook();>
								<i  *ngIf="!edit.generating_webhook" style="margin-right: 5px;" class="fal fa-cloud-word"></i> 
								<div *ngIf="edit.generating_webhook" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
							        <span  class="sr-only">Loading...</span>
							  </div>
								Generate Webhook URL 
							</div>
						 </div>
						<div *ngIf="webhookURL">
							<div style="font-weight: bold; color: gray; padding: 5px 0px;">Webhook URL</div>
							<div style="border: 1px solid gray; border-radius: 10px; padding: 10px; width: 100%; word-wrap: break-word; font-family: monospace;">
								{{webhookURL}}
							</div>
						</div>
          </div>
<!---------------------------- END BITBUCKET FORM --------------------------------->
					 <div *ngIf="edit.type == 'github'">
<!---------------------------- GITHUB FORM --------------------------------->
						<div>
							<form [formGroup]="edit.form" (keydown.enter)="saveIntegration()" autocomplete="off">
								<div class="form-group" *ngIf="formControl().url.touched || formControl().url.valid">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Repository Name</span>
		                </label>
		                <input formControlName="name" id="name" name="name" type="text" class="form-control form-border" readonly="readonly">
		           </div>
		           <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Source URL (SSH)</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().url.touched || submitted) && formControl().url.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input (change)="parseRepository()" formControlName="url" id="url" name="url" type="text" class="form-control form-border" placeholder="git@github.com:[project]/[repository].git">
		           </div>
		           <div class="form-group">
		                <label class="form-label d-flex justify-content-between align-items-end">
		                  <span>Main Branch (any branch can be used after setup)</span>
		                  <span class="text-danger"
		                    *ngIf="(formControl().branch.touched || submitted) && formControl().branch.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="branch" id="branch" name="branch" type="text" class="form-control form-border" placeholder="main or master">
		           </div>
              </form>
             <div style="margin: 15px 0px; border: 1px solid #e6e5e5; width: 100%;"></div>
							<div style="font-size: 1rem; padding-bottom: 10px;">Read Access to Repository <span style="color: gray;">(Required)</span></div>
							 <ol>
							 		<li>In GitHub, select the repository you want to add.</li>
							 		<li>Click <span style="font-weight: bold;">Settings</span></li>
							 		<li>Click <span style="font-weight: bold;">Deploy Keys</span> >  <span style="font-weight: bold;">Add deploy key</span></li>
							 		<ul>
							 			<li>Generate Carve SSH key by clicking the button below </li>
							 			<li>Copy and paste the public key generated below into the <span style="font-weight: bold;">key</span> field in GitHub.</li>
							 			<li>Click the <span style="font-weight: bold;">Add Key</span> button</li>
							 		</ul>
							 		
							 </ol>
							 <div style="width: 100%; text-align: left; margin-bottom: 15px; padding-left: 25px;">
								 <div class="btn btn-outline-secondary" (click)="generateKey()">
									<i  *ngIf="!edit.generating_key" class="fal fa-file-certificate"></i>
									<div *ngIf="edit.generating_key" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
							        <span  class="sr-only">Loading...</span>
							    </div>
									<span style="margin-left: 5px;">Generate SSH Key</span>
								</div>
							</div>
							<div *ngIf="publicKey">
								<div style="font-weight: bold; color: gray; padding: 5px 0px;">Public Key</div>
								<div style="border: 1px solid gray; border-radius: 10px; padding: 10px; width: 100%; word-wrap: break-word; font-family: monospace;">
									{{publicKey}}
								</div>
							</div>
						</div>
						<div style="margin: 15px 0px; border: 1px solid #e6e5e5; width: 100%;"></div>
						<div style="font-size: 1rem;">Continuous Delivery<span style="color: gray;"> (Optional)</span></div>
						<div style="font-size: small; margin-bottom: 10px; color: gray;">Trigger updates to your Carve code packages with a webhook.</div>
						<ol>
						 		<li>In GitHub, select the repository you want to add.</li>
						 		<li>Click <span style="font-weight: bold;">Settings.</span></li>
						 		<li>Click <span style="font-weight: bold;">Webhooks</span> >  <span style="font-weight: bold;">Add webhook</span></li>
						 		<ul>
						 			<li>Generate a Carve Webhook URL by clicking the button below. </li>
						 			<li>Copy and paste the URL generated into the <span style="font-weight: bold;">Payload URL</span> field in GitHub.</li>
						 			<li>Select <span style="font-weight: bold;">application/json</span> for the Content Type.</li>
						 			<li>Trigger recommendations (select "Let me select individual events"): <span style="font-weight: bold;">Pushes</span> and <span style="font-weight: bold;">Pull Requests</span>.</li>
						 			<li>Click the <span style="font-weight: bold;">Add webhook</span> button</li>
						 		</ul>
						 </ol>
						 <div style="width: 100%; text-align: left; padding-left: 25px;" *ngIf="!webhookURL">
						 	<div style="text-align:;" class="btn btn-outline-secondary" (click)=generateWebhook();>
								<i  *ngIf="!edit.generating_webhook" style="margin-right: 5px;" class="fal fa-cloud-word"></i> 
								<div *ngIf="edit.generating_webhook" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
							        <span  class="sr-only">Loading...</span>
							  </div>
								Generate Webhook URL 
							</div>
						 </div>
						<div *ngIf="webhookURL">
							<div style="font-weight: bold; color: gray; padding: 5px 0px;">Webhook URL</div>
							<div style="border: 1px solid gray; border-radius: 10px; padding: 10px; width: 100%; word-wrap: break-word; font-family: monospace;">
								{{webhookURL}}
							</div>
						</div>
          </div>
<!---------------------------- END BITBUCKET FORM --------------------------------->
          <div *ngIf="edit.error" style="font-weight: bold; color: red;">{{edit.error}}</div>
      </div>
        
      <div class="modal-footer">
      	<div  *ngIf="edit.delete_check" style="font-weight: bold;">Are you sure?</div>
      	<button type="button" class="btn btn-outline-danger mr-auto" (click)="deleteIntegration()" [disabled]='edit.deleting' *ngIf="edit.integration_id != null">
        	<span *ngIf="!edit.deleting" class="fas fa-trash-alt"></span>
        	<div *ngIf="edit.deleting" class="spinner-border spinner-border-sm spinner-border-delete" role="status" style="margin: auto; color: #d9534f;">
		        <span  class="sr-only">Loading...</span>
			    </div>
			    &nbsp;&nbsp;<span *ngIf="!edit.delete_check && !edit.deleting">Delete</span><span *ngIf="edit.delete_check">Yes, Delete!</span><span *ngIf="edit.deleting">Deleting</span>
				</button>
      	<button type="button" class="btn btn-outline-secondary" (click)="testIntegration();" [disabled]="!edit.form.valid" *ngIf="edit.integration_id != null">
        	<span *ngIf="!edit.testing && edit.connected" class="fas fa-check" style="color: green;"></span>
        	<span *ngIf="!edit.testing && !edit.connected" class="fas fa-plug"></span>
        	<div *ngIf="edit.testing" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    	</div>
        	&nbsp;&nbsp;<span *ngIf="!edit.testing && !edit.connected">Test</span><span *ngIf="edit.testing">Connecting</span><span *ngIf="edit.connected" style="color: green;">Connected</span>
      	</button>
      	<button type="button" class="btn btn-primary btn-sg" (click)="saveIntegration();" [disabled]="!edit.form.valid">
        	<span *ngIf="!edit.saving" class="fas fa-save"></span>
        	<div *ngIf="edit.saving" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white;">
		        <span  class="sr-only">Loading...</span>
		    </div>
        	&nbsp;&nbsp;
        	Save
      	</button>

        
       
      </div>
  </ng-template>


 