import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

	private overviewObjects$ = new ReplaySubject<any>(1);
   //private columnData$ = new Subject<any>();
    private overviewObject = {}
    public graphData = new Object;
    public summaryData = new Object;
    public columnSummaryData = new Object;
    public columnData = new Object();
    private overviewList = new Array();
    public changed = false;
    public chartReg: any = [];
    public overview = '';

    constructor(private storage: StorageMap) {}

    getOverviewObject(): Observable<any> {
        return this.overviewObjects$.asObservable(); 
    }

    getOverviewObj(){
        return this.overviewObject
    }
    getStoredView(){
        return JSON.parse(sessionStorage.getItem("overviewObject"));
    }
    getGraphQueryView(){
        return JSON.parse(sessionStorage.getItem("graphQueryView"));
    }
    storeGraphQueryView(overviewObj){
        sessionStorage.setItem("graphQueryView", JSON.stringify(overviewObj));
    }
    storeGraph(data, graphType){
        // Get list of cached Overview
        this.graphData[graphType] = data;
        this.storage.set("graphData", data).subscribe(() => {});
    }
    getGraph(){
        this.storage.get("graphData").subscribe(()=>{});
    }

    getSummary(): Observable<any>{
        return this.storage.get("summaryData")
    }

    storeSummary(summaryData){
        this.storage.set("summaryData", summaryData).subscribe(() => {});
    }

    addChart(chart, chartType){
        this.chartReg[chartType] = chart;
    }

    disposeChart(chartType){
        // if(chartType in this.chartReg){
        //     console.log('disposing ', chartType)
        //     this.chartReg[chartType].dispose()
        // }
       
    }

    getData(index): Observable<any>{
        return this.storage.get(index)
    }

    setData(index, data){
        this.storage.set(index, data).subscribe(() => {});
    }


    compareOverview(overviewObj1, overviewObj2){
        if(! overviewObj1){
            return false;
        }
        if (overviewObj1 != overviewObj2){
            return false;
        }
        return true
    }
    setView(overview){
        this.overview = overview;
    }

    // getCachedColumns(): Observable<any> {
    //     return this.columnData$.asObservable();
    // }
    // findCachedColumns(){
    //     this.columnData$.next(JSON.parse(sessionStorage.getItem("columnData")))
    // }
}
