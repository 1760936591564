import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { AuthService } from "../../shared/auth/auth.service";
import { Subscription } from 'rxjs/Subscription';
import { ApiService } from '../../shared/api/api.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutNavbarComponent{
  isExpanded = false;
  isRTL: boolean;
  profile = {
    first_name: '', 
    last_name: '', 
    first_initial: '',
    last_initial: '',
    email: ''  ,
    carve_top_role: 0 
  }
  url: string = environment.url;
  profileSubscription: Subscription;
  view:string ='';
  isAdmin = false;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') private hostClassMain = true;

  constructor(
    private appService: AppService, 
    private layoutService: LayoutService, 
    private auth: AuthService,
    private api: ApiService,
    private router: Router, 
    private route: ActivatedRoute) {
    this.isRTL = appService.isRTL;

    this.profileSubscription = this.auth.getProfile().subscribe((data:any)=>{
         this.profile = data['message'];
         if(this.profile.carve_top_role >= 10){
           this.isAdmin = true;
         }
         let i = this.profile.first_initial.toLowerCase()
         // console.log('Profile: ', this.profile)

     });
    // let url = this.url + '/catalog/objectlastupdate';
    // this.api.getApiData(url, []).subscribe((data:any)=>{
    //   console.log('Last Updated Objects: ', data)
    // });
    

    // Set the Nav item to active 
    let routes = this.router.url.split("/")

    // console.log(routes)
    let view = routes[1];
    this.setRoute(view);

  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  logout(){
    this.auth.logout()
    this.api.resetKeys();
  }

  setRoute(view){
    this.view = view;
  }
}
