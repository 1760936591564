<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">
			<!-- <div style="width: 100%">
				<div style="padding-bottom: 5px; display: inline;color: black; font-size: 22pt; font-weight: bold; cursor: pointer;" (click)="returnPackages()">Packages</div>
				<div style="padding-bottom: 5px; display: inline;padding-left: 10px;color: #ccc; font-size: 22pt; font-weight: bold;">/</div>
				<div style="padding-bottom: 5px; display: inline;padding-left: 10px; color: #717171; font-size: 18pt; font-weight: bold;">New Package</div>
			</div>
 -->
			<div style="width: 100%" style="padding-bottom: 5px;">
				<div style="display: inline;color:#717171; font-size: 18pt; font-weight: bold; cursor: pointer;" (click)="returnPackages()"><i class="fa-thin fa-rectangle-code" style="margin-right: 5px;"></i> Code Packages</div>
				<div style="display: inline;padding-left: 10px;color: #ccc; font-size: 18pt; font-weight: bold;">/</div>
				<div *ngIf="packageService.package == null" style="display: inline;padding-left: 10px; color: black; font-size: 18pt; font-weight: bold;">New Package</div>
				<div *ngIf="packageService.package != null" style="display: inline;padding-left: 10px; color: black; font-size: 18pt; font-weight: bold;">{{packageService.package.package_name}}</div>
				<div style="display: inline; float: right;">
					<span  *ngIf="conf.delete_check" style="font-weight: bold; margin-right: 10px;">Are you sure?</span>
			      	<button type="button" class="btn btn-outline-danger" (click)="deletePackage()" [disabled]='conf.deleting' *ngIf="packageService.package != null">
		        		<span *ngIf="!conf.deleting" class="fas fa-trash-alt"></span>
		        		<div *ngIf="conf.deleting" class="spinner-border spinner-border-sm spinner-border-delete" role="status" style="margin: auto; color: #d9534f;">
				        <span  class="sr-only">Loading...</span>
					    </div>
					    &nbsp;&nbsp;<span *ngIf="!conf.delete_check && !conf.deleting">Delete</span><span *ngIf="conf.delete_check && !conf.deleting">Yes, Delete!</span><span *ngIf="conf.deleting && conf.delete_check">Deleting</span>
					</button>
				</div>
			</div>
			<p *ngIf="packageService.package == null" class="text-muted">Create a new code package.</p>
			<p *ngIf="packageService.package != null"></p>
		
			<!-- <div class="row" style="width: 100%; padding-bottom: 25px;" *ngIf="packageService.package == null">

	    		<div *ngFor="let option of options" class="col-3">
	    			<div class="package-option-box" (click)="setOption(option)" [ngClass]="{'package-option-selected': selectedOption == option.title}">
	    				<div class="row">
	    					<div class="col-10">
	    						<div class="package-option-title">{{option.title}}</div>
	    					</div>
	    					<div class="col-2" style="text-align: right;">
	    						<div class="fal fa-circle-check fa-lg package-option-selected-icon" *ngIf="selectedOption == option.title"></div>
	    						<div class="fal fa-circle fa-lg package-option-icon" *ngIf="selectedOption != option.title"></div>
	    					</div>
	    				</div>
	    				<div class="row">
	    					<div class="col-12">
	    						<div class="package-option-description">{{option.description}}</div>
	    					</div>
	    				</div>
	    			</div>
	    		</div>
	    	</div> -->
	    	<div class="card mb-4 shadow-hover">
		    	<div class="card-header">
		    		<!-- <div *ngIf="selectedOption == 'From Zip File'" style="font-size: 14pt;">
		    			<i class="fal fa-file-zipper" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">Files</span>
		    		</div> -->
		    		<div style="font-size: 14pt;">
		    			<i class="fal fa-cog" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">Basic Configuration</span>
		    		</div>
		    		
		    	</div>
		    	<div class="card-body" style="padding-top: 5px; padding-bottom:0px;">
		    		<div>
			    		<form [formGroup]="basicForm" autocomplete="off">
			    			  <div class="row">
			    				<div class="col-8">
						      		<div class="form-group">
						                <label class="form-label d-flex justify-content-between align-items-end">
						                  <span>Package Name</span>
						                  <span class="text-danger"
						                    *ngIf="(basicFormControl().package_name.touched || submitted) && basicFormControl().package_name.errors?.required">
						                    Required
						                  </span>
						                </label>
						                
						                <input formControlName="package_name" id="package_name" name="package_name" type="text" class="form-control form-border" >
						            </div>
						        </div>
					          </div>
					      	  <div class="row">
				      			<div class="col-4">
				              		<div class="form-group ng-carve" >
				                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
				                        	Language
				                        	 <span class="text-danger float-right text-right"
							                     *ngIf="(basicFormControl().package_language.touched || submitted) && basicFormControl().package_language.errors?.required">
							                    Required
							                  </span>
				                        </label>
						            	<ng-select [items]="packageObj.languageOptions"
										      bindLabel="label"
										      bindValue="value"
										      formControlName="package_language"
										      (change)="setVersions()"
										      style="margin-bottom: 25px;"
										      >
										      <ng-template ng-label-tmp let-item="item" >
										       	<img [src]="item['icon']" style="height:16px; width:16px" class="ng-value-label"/>
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										      	<img [src]="item['icon']"  style="height:16px; width:16px"  class="ng-value-label"/>
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									    </ng-select>
			                      </div>
				            	</div>
					            <div class="col-4">
					              <div class="form-group ng-carve" class="ng-carve">
			                        <label class="form-label" style="margin-bottom: 4px; width: 100%;" *ngIf="language == 'Python'">
			                        Version
			                         <span class="text-danger float-right text-right"
						                     *ngIf="(basicFormControl().package_language_version.touched || submitted) && basicFormControl().package_language_version.errors?.required">
						                    Required
						                  </span>
			                    	</label>
			                    	<label class="form-label" style="margin-bottom: 4px; width: 100%;" *ngIf="language == 'DBT'">
			                        Python Version
			                         <span class="text-danger float-right text-right"
						                     *ngIf="(basicFormControl().package_language_version.touched || submitted) && basicFormControl().package_language_version.errors?.required">
						                    Required
						                  </span>
			                    	</label>
					            	<ng-select [items]="packageObj.versionOptions"
									      bindLabel="label"
									      bindValue="value"
									      formControlName="package_language_version"

									      style="margin-bottom: 25px;"
									      >
										      <ng-template ng-label-tmp let-item="item" >
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									    </ng-select>
			                      </div>
			                  	</div>
			                   </div>
		                       <div class="row">
						          <div class="col-4">
						            <div class="form-group ng-carve" style="padding-top: 2.5px;">
						                <label class="form-label" style="margin-bottom: 4px; width: 100%">
						                	Compute Size
						                	 <span class="text-danger float-right text-right"
						                     *ngIf="(basicFormControl().package_ecs_size.touched || submitted) && basicFormControl().package_ecs_size.errors?.required">
						                    Required
						                  </span>

						                	<div style="color: gray; font-size: 9pt;">Amount of compute resources needed to run your package.</div>
						                </label>
						                
						            	<ng-select [items]="packageObj.sizeOptions"
									      bindLabel="label"
									      bindValue="value"
									      formControlName="package_ecs_size"
									      style="margin-bottom: 25px;"
									      >
										      <ng-template ng-label-tmp let-item="item" >
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									    </ng-select>
						            </div>
						          </div>
						          <div class="col-4">
					                <div class="form-group">
					                  <label class="form-label">
					                   Timeout Minutes
					                  
					                  <div style="color: gray; font-size: 9pt;">Set a threshold limit for your job. 0 for no timeout.</div>
					                  
					                </label>
					                
					                <input formControlName="package_timeout_minutes" id="package_timeout_minutes" name="package_timeout_minutes" type="number" class="form-control form-border">
					              </div>
					            </div>
					           </div>
		           		</form>
		           	</div>
	           	</div>
	           	<div class="card-footer" *ngIf="language == 'DBT' || language == 'Python'">
	           		* <span style="font-weight: bold" >requirements.txt</span> file is required at the root of your project
	           	</div>
					
			</div>

	    	<div class="card mb-4 shadow-hover">
		    	<div class="card-header">
		    		<!-- <div *ngIf="selectedOption == 'From Zip File'" style="font-size: 14pt;">
		    			<i class="fal fa-file-zipper" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">Files</span>
		    		</div> -->
		    		<div style="font-size: 14pt;">
		    			<i class="fal fa-code-branch" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">Git Repository</span>
		    		</div>
		    		
		    	</div>
		    	<div class="card-body" style="padding-top: 5px; padding-bottom:0px;">
		    		<!-- <div *ngIf="selectedOption == 'From Zip File'" style="font-size: 10pt; color:gray; padding-bottom: 10px;">Please include the <span style="font-weight: bold;">requirements.txt</span> file in the root of your packaged zip file
		    		.</div> -->
		    		<!-- <div style="font-size: 10pt; color:gray; padding-bottom: 10px;">Please include the <span style="font-weight: bold;">requirements.txt</span> file in the root of your repository
		    		.</div> -->
		    		<div class="row">
		    			<div class="col">
				    		<div class="form-group ng-carve" style="padding-top: 2.5px;">
					    		
							   	<!-- <input style="display: none;" type="file"  (change)="fileCheck($event)" #hiddenfileinput/> -->
							   	<!-- (change)="uploadFileToServer($event)" -->
							   <!-- 	<button   *ngIf="selectedOption == 'From Zip File'"(click)="hiddenfileinput.click()" class="btn btn-outline-dark" type="button"><i class="fal fa-cloud-arrow-up fa-lg"></i>	Select Zip File
							   	</button> -->
							   

							   	<!-- Dropdown to select repository integration -->

							   	<div *ngIf="reposLoading">
							   		Searching Git integrations for repositories 
							   		<div class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: #2ea0c9;">
								        <span  class="sr-only">Loading...</span>
								    </div>
							   	</div>
							   	<div *ngIf="selectedOption == 'From Git Repo' && availableRepos.length > 0 && !reposLoading">
							   		<form [formGroup]="repoPackageForm" autocomplete="off">
							   			<div class="row">
							   				<div class="col-4">
											   	<div class="form-group ng-carve" >
							                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
							                        	Repository
							                        	 <span class="text-danger float-right text-right"
										                     *ngIf="(repoFormControl().package_repo_name.touched || submitted) && repoFormControl().package_repo_name.errors?.required">
										                    Required
										                  </span>
							                        </label>
									            	<ng-select [items]="availableRepos"
													      bindLabel="label"
													      bindValue="value"
													      formControlName="package_repo_name"
													      (change)="repoBranches($event)"
													      style="margin-bottom: 25px;"
													      >
														      <ng-template ng-label-tmp let-item="item" >
													      		<img *ngIf="repoFormControl().package_repo_name.touched" [src]="item['icon']" style="height:16px; width:16px" class="ng-value-label"/>
														        <span class="ng-value-label">{{item['label']}}</span>
														      </ng-template>

														      <ng-template ng-option-tmp let-item="item" >
													      		<img *ngIf="repoFormControl().package_repo_name.touched" [src]="item['icon']" style="height:16px; width:16px" class="ng-value-label"/>
														        <span class="ng-value-label"> {{item['label']}}</span>
														      </ng-template>
													    </ng-select>
							                     </div>
							                 </div>
							                 <div *ngIf="branchesChecking" class="col-4" style="padding-top: 35px;" >
									        	<div>
									        		Gathering available branches
												   	<div class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: #2ea0c9; margin-left: 5px;">
											             <span  class="sr-only">Loading...</span>
											        </div>
											    </div>
											   	<i *ngIf="branchesChecked && !branchesCheckError" class="fal fa-circle-check fa-lg" style="color: green; margin-left: 5px"></i>
											   	<i *ngIf="branchesCheckError" class="fal fa-cirle-xmark fa-lg" style="color: red; margin-left: 5px"></i>
											    <span *ngIf="branchesCheckError">{{branchError}}</span>
								            </div>
								            <div class="col-4">
									            <div class="form-group ng-carve" *ngIf="selectedIntegration !== null && !branchesChecking">
								                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
								                        	Branch
								                        	 <span class="text-danger float-right text-right"
											                     *ngIf="(repoFormControl().package_repo_branch_name.touched || submitted) && repoFormControl().package_repo_branch_name.errors?.required">
											                    Required
											                  </span>
								                        </label>
										            	<ng-select [items]="availableBranches"
														      bindLabel="label"
														      bindValue="value"
														      formControlName="package_repo_branch_name"
														      (change)="repoCheck($event)"
														      
														      style="margin-bottom: 25px;"
														      >
															      <ng-template ng-label-tmp let-item="item" >
															        <span class="ng-value-label">{{item['label']}}</span>
															      </ng-template>

															      <ng-template ng-option-tmp let-item="item" >
															        <span class="ng-value-label"> {{item['label']}}</span>
															      </ng-template>
														    </ng-select>
								                     </div>
								            </div>
								            <div class="col-4" style="padding-top: 35px;" *ngIf="selectedIntegration !== null && !branchesChecking">
									        	<div *ngIf="filesChecking">
									        		Checking branch files
												   	<div class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: #2ea0c9; margin-left: 5px;">
											             <span  class="sr-only">Loading...</span>
											        </div>
											    </div>
											   	<i *ngIf="filesChecked && !filesCheckError" class="fal fa-circle-check fa-lg" style="color: green; margin-left: 5px"></i>
											   	<i *ngIf="filesCheckError" class="fal fa-cirle-xmark fa-lg" style="color: red; margin-left: 5px"></i>
											    <span *ngIf="filesCheckError">{{fileError}}</span>
								            </div>
								        </div>
							        </form>
							     </div>
					             <div *ngIf="selectedOption == 'From Git Repo' && availableRepos.length == 0 && !reposLoading">
					             	<div style="color: red; margin-bottom: 10px;">
					             		No Git integrations found. Click to see Git integration options. 
					             	</div>
						             <button  (click)="navigateIntegrations()" class="btn btn-outline-dark" type="button"><i class="fal fa-screwdriver-wrench" style="padding-right: 5px;"></i>	Integration Options
								   	</button>
								</div>
							   	<div *ngIf="selectedOption == 'From Zip File'">
								   	<span style="padding-left: 10px;">{{selectedFileInfo}}</span>
								   	<div *ngIf="filesChecking" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: #2ea0c9; margin-left: 10px;">
							            <span  class="sr-only">Loading...</span>
							        </div>
								   	<i *ngIf="filesChecked && !filesCheckError" class="fal fa-circle-check fa-lg" style="color: green; margin-left: 5px"></i>
								   	<i *ngIf="filesCheckError" class="fal fa-cirle-xmark fa-lg" style="color: red; margin-left: 5px"></i>
								    <span *ngIf="filesCheckError">{{fileError}}</span>
								</div>
							</div>
						</div>
						<!-- <div class="col-6">
							<div style="padding-bottom: 5px;">Example package file structures</div>
							<img style="padding-right: 15px;" src="./assets/img/folder_structure_2.png" />
							<img src="./assets/img/folder_structure_1.png" />
							
						</div> -->
					</div>
					
		    	</div>
			</div>
		    <div class="card mb-4 shadow-hover" *ngIf="filesChecked">
		    	<!-- <div class="card-header">
		    		<div style="font-size: 14pt; font-weight: bold" *ngIf="language == 'Python'">
			    		<img src="./assets/img/python-logo-only.svg" style="margin-right: 5px; height: 24px; width: 20px;"/>
			    		Python Configuration
		    		</div>
		    		<div style="font-size: 14pt; font-weight: bold" *ngIf="language == 'DBT'">
			    		<i class="fal fa-sitemap" style="padding-right: 5px;"></i>
			    		DBT Configuration
		    		</div>
		    	</div> -->
		    	<div class="card-header">
		    		<div style="font-size: 14pt;">
		    			<i class="fal fa-file" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">File Configuration</span>
		    		</div>
		    	</div>
		    	<div class="card-body">
		    		<!-- Python package -->
		    		<div *ngIf="language == 'Python'">
			    		<form [formGroup]="pythonPackageForm" autocomplete="off">    			
				          <div class="row">
		    				<div class="col-4">
				              <div class="form-group ng-carve" >
		                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
		                        	File Name
		                        	 <span class="text-danger float-right text-right"
					                     *ngIf="(pythonFormControl().package_file_name.touched || submitted) && pythonFormControl().package_file_name.errors?.required">
					                    Required
					                  </span>
		                        </label>
				            	<ng-select [items]="packageObj.fileOptions"
								      bindLabel="label"
								      bindValue="value"
								      formControlName="package_file_name"
								      style="margin-bottom: 25px;"
								      (change)="setFunctionOptions()"
								      >
									      <ng-template ng-label-tmp let-item="item" >
									        <span class="ng-value-label">{{item['label']}}</span>
									      </ng-template>

									      <ng-template ng-option-tmp let-item="item" >
									        <span class="ng-value-label"> {{item['label']}}</span>
									      </ng-template>
								    </ng-select>
		                      </div>
				          	</div>
				  			<div class="col-4">
				              <div class="form-group ng-carve" >
		                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
		                        	Function Name
		                        	 <span class="text-danger float-right text-right"
					                     *ngIf="(pythonFormControl().package_function_name.touched || submitted) && pythonFormControl().package_function_name.errors?.required">
					                    Required
					                  </span>
		                        </label>
				            	<ng-select [items]="packageObj.functionOptions"
								      bindLabel="label"
								      bindValue="value"
								      formControlName="package_function_name"
								      style="margin-bottom: 25px;"
								      >
									      <ng-template ng-label-tmp let-item="item" >
									        <span class="ng-value-label">{{item['label']}}</span>
									      </ng-template>

									      <ng-template ng-option-tmp let-item="item" >
									        <span class="ng-value-label"> {{item['label']}}</span>
									      </ng-template>
								    </ng-select>
		                      </div>
				            </div>
				      	  </div>
		           		</form>
		           	</div>
		           	<!-- Python package -->
		    		<div *ngIf="language == 'DBT'">
			    		<form [formGroup]="dbtPackageForm" autocomplete="off">
					          <div class="row">
			    				<div class="col-4">
					              <div class="form-group ng-carve" >
			                        <label class="form-label" style="margin-bottom: 4px; width: 100%;">
			                        	Project Path
			                        	 <span class="text-danger float-right text-right"
						                     *ngIf="(dbtFormControl().package_file_name.touched || submitted) && dbtFormControl().package_file_name.errors?.required">
						                    Required
						                  </span>
			                        </label>
					            	<ng-select [items]="packageObj.fileOptions"
									      bindLabel="label"
									      bindValue="value"
									      formControlName="package_file_name"
									      style="margin-bottom: 25px;"
									      
									      >
										      <ng-template ng-label-tmp let-item="item" >
										        <span class="ng-value-label">{{item['label']}}</span>
										      </ng-template>

										      <ng-template ng-option-tmp let-item="item" >
										        <span class="ng-value-label"> {{item['label']}}</span>
										      </ng-template>
									    </ng-select>
			                      </div>
					          	</div>
					  		  </div>
		           		</form>
		           	</div>
	           	</div>
		    </div>
		    
			<div class="card mb-4 shadow-hover"  *ngIf="isReady && filesChecked">
		    	<div class="card-header" >
		    		<div style="font-size: 14pt;">
		    			<i class="fal fa-square-code" style="padding-right: 5px;"></i>
		    			<span style="font-weight: bold;">Environment Variables</span>
		    		</div>
		    	</div>
		    	<div class="card-body">
		    		<div *ngIf="hasConfiguration">
		    			<p-table 
								styleClass="p-datatable-sm"
							  	[paginator]="false"
							  	[value]="conf.configuration"
						 >
					        <ng-template pTemplate="header" style="font-size:9pt;">
					            <tr>			           
					               	<th>Variables ({{conf.configuration[0].configuration_variables.length}})</th>
					               	<th>Environments ({{conf.configuration[0].configuration_environments.length}})</th>
					            </tr>
					        </ng-template>
					        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-profile>
					            <tr style="text-align: left;" class="hover clickable-row" (click)="openNewConfigModal(newConfiguration, { windowClass: 'modal-custom-lg animate', centered: true }, profile)">
					            	<td>
					                	<div style="width: 100%; overflow: auto;">
						                	<span *ngFor="let v of getList('variables')" class="badge badge-success variable-pills">{{v.key}}</span>
						                	<div  *ngIf="getList('variables').length == 0" class="btn btn-outline-secondary">
								    			Add Environment Variables
								    		</div>
						                </div>
					                </td>
					                <td>
					                	<div style="width: 100%; overflow: auto;">
						                	<span class="badge badge-secondary variable-pills" *ngFor="let env of getList('environments')">{{env}}</span>
						                </div>
					                </td>
					               
					            </tr>
					        </ng-template>
						</p-table>
		    		</div>
		    		<div *ngIf="!hasConfiguration" class="btn btn-outline-secondary" (click)="openNewConfigModal(newConfiguration, { windowClass: 'modal-custom-lg animate', centered: true })">
		    			Add Environment Variables
		    		</div>
		    	</div>
		    </div>
			<div class="d-flex flex-row-reverse mb-4">
				<button type="button" class="btn btn-outline-primary" (click)="createPackage()" [disabled]="!filesChecked && readyToCreate()">
					<i *ngIf="!conf.creatingPackage" class="fal fa-box-check" style="padding-right: 5px;"></i>
					<div *ngIf="conf.creatingPackage" class="spinner-border spinner-border-sm" role="status" style="margin: auto; padding-right: 5px;" >
				        <span  class="sr-only">Loading...</span>
				    </div>
				    <span *ngIf="packageService.package == null">
						Create Package
					</span>
					<span *ngIf="packageService.package != null">
						Save Changes
					</span>
				</button>
	
				<button style="margin-right: 5px;" type="button" class="btn" (click)="returnPackages();">Cancel</button>
			</div>

		</div>
	</div>
</div>

<ng-template #newConfiguration let-c="close" let-d="dismiss">
    <div class="modal-header">
        <div class="modal-title">
        	<div class="row">
        		<div class="col">
		        	<span style="font-weight: bold; font-size:14pt; vertical-align: bottom; line-height: 24px;">Environment Variable Configuration</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
    </div>
    <div class="modal-body" style="font-size: 11pt;">
    	<form [formGroup]="conf.configurationForm">
	    	<div class="row form-group">
				<!-- <div class="col-3">
					
		                <label class="form-label"  style="width: 100%;">
		                	Configuration Name
		                 <span class="text-danger float-right"
	                     *ngIf="(configurationdbtFormControl().configuration_name.touched || submitted) && configurationFormControl().configuration_name.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="configuration_name" id="configuration_name" name="configuration_name" [type]="text" class="form-control form-border" autocomplete="new-password">
		           

	            </div> -->
	           
	            <div *ngIf="conf.configurations.length > 0" class="col-3">
	            	<div class="form-group ng-carve" style="padding-top: 2.5px;">
		                <label class="form-label" style="margin-bottom: 4px; width: 100%">
		                	Load Global Configuration
		                	<!-- <div style="color: gray; font-size: 9pt;">Amount of compute resources needed to run your package.</div> -->
		                </label>
		                
		            	<ng-select [items]="conf.configurationsList"
					      bindLabel="label"
					      bindValue="value"
					      formControlName="global_configuration_id"
					      (change)="setGlobalConfiguration()"
					      style="margin-bottom: 25px;"
					      >
						      <ng-template ng-label-tmp let-item="item" >
						        <span class="ng-value-label">{{item['label']}}</span>
						      </ng-template>

						      <ng-template ng-option-tmp let-item="item" >
						        <span class="ng-value-label"> {{item['label']}}</span>
						      </ng-template>
					    </ng-select>
		            </div>
	            </div>
			</div>
		</form>
		<div class="row" *ngIf="!isUpdating(conf.environmentVars) && !conf.usingConfiguration" style="margin-bottom: 20px;">
			<div class="col">
				<button class="btn btn-outline-secondary" (click)="toggleJSON()">
					<i class="fal fa-brackets-curly" style="margin-right: 5px;"></i>Use JSON Template
				</button>
			</div>
		</div>
		<div class="row" *ngIf="!isUpdating(conf.environmentVars) && conf.fromJSON"  style="margin-bottom: 20px;">
			<div class="col">
				<div style="height: 300px; border: 1px solid #ccc;">
					<codemirror 
		          #configCode
		          [(ngModel)]="conf.fromJSONCode"
		          [config]="conf.codeConfig"
		        >
					</codemirror>
				</div>
				<div class="row">
					<div class="col">
						<div style="color: red; text-align: center; padding: 10px;">{{conf.jsonError}}</div>
					</div>
				</div>
				<button style="margin-top: 10px; float: right;" class="btn btn-outline-success" (click)="submitJSON()">Load</button>
			</div>
		</div>
		<div class="row" *ngIf="!isUpdating(conf.environmentVars) && conf.fromEnv && !conf.fromJSON"  style="margin-bottom: 20px;">
			<div class="col">
				<div style="height: 300px; border: 1px solid #ccc;">
					<codemirror 
		          #configCode
		          [(ngModel)]="conf.fromEnvCode"
		          [config]="conf.codeConfig"
		        >
					</codemirror>
				</div>
				<div class="row">
					<div class="col">
						<div style="color: red; text-align: center; padding: 10px;">{{conf.envError}}</div>
					</div>
				</div>
				<button style="margin-top: 10px; float: right;" class="btn btn-outline-success" (click)="submitJSON()">Load</button>
			</div>
		</div>
			<div class="card mb-4 shadow-hover" style="min-height: 400px; margin-top: 20px;">
				<!-- Header row -->
				<div class="card-header">
					<i class="fal fa-square-code fa-lg" style="padding-right: 10px;"></i>
					<span style="font-size: 12pt;">
						Environment Variables
					</span>
				</div>
				<div class="card-body">
					<table style="width: 100%;">
						<thead>
							<tr>
								<th style="min-width: 150px; padding-right: 15px;"></th>
								<th *ngFor="let env of conf.environmentVars.configuration_environments">
									{{env}}
									<i class="fal fa-minus-circle fa-xs" style="margin-left: 5px; cursor: pointer;" (click)="removeEnv(env)"></i>
								</th>
								<th style="width: 170px;">
									<div  *ngIf="!conf.usingConfiguration">
										<input type="text" class="" [(ngModel)]="conf.newEnv" placeholder="[New environment]" style="border: none; width: 130px;" (keyup.enter)="addEnv()">
										<i *ngIf="conf.newEnv.length > 0" class="fal fa-plus-circle " (click)="addEnv()" style="cursor:pointer; "></i>
									</div>
								</th>
								<td>Secure</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							<tr style="border-bottom: 1px solid #ccc;">
								<td style="min-width: 150px; padding: 10px 15px 25px 0px;">
									<div style="position: relative;">
										<input *ngIf="!conf.usingConfiguration || conf.isNewKey" type='text' [(ngModel)]="conf.newVariable.key" class="form-control form-border" autocomplete="new-password" [ngClass]="{'input-error': conf.newVariableError.length > 0}" [placeholder]="getPlaceholder()" (input)="conf.newVariableError = ''">

										<div *ngIf="conf.isNewKey" (click)="conf.isNewKey = false;" style="position:absolute; top: 2px; right: 5px; cursor: pointer; color: #2ea0c9;"><i class="fal fa-arrow-rotate-left"></i></div>
										<div *ngIf="conf.usingConfiguration && !conf.isNewKey" class="ng-carve">
											<ng-select 
											  [items]="conf.variableOptions"
										      bindLabel="label"
										      bindValue="value"
										      [(ngModel)]="conf.newVariable.id"
										      (change)="setGlobalVariable($event)"
										      [placeholder]="getPlaceholder()"
										      autocomplete="new-password" 
										      [ngClass]="{'input-error': conf.newVariableError.length > 0}"
										      [clearable]="false"
										      notFoundText="No variable keys found"
										      
										      >
											      <ng-template ng-label-tmp let-item="item" >
											        <span class="ng-value-label" [ngStyle]="item['label'] == 'Create New Variable' && {'font-weight': 'bold'}"> <i *ngIf="item['label'] == 'Create New Variable'" class="fa-regular fa-square-plus"  style="padding-right: 5px;"></i> {{item['label']}} </span>
											      </ng-template>

											      <ng-template ng-option-tmp let-item="item" >
											        <span [ngStyle]="item['label'] == 'Create New Variable' && {'font-weight': 'bold'}" class="ng-value-label"> <i *ngIf="item['label'] == 'Create New Variable'" class="fa-regular fa-square-plus" style="padding-right: 5px;"></i> {{item['label']}} </span>
											      </ng-template>
										    </ng-select>
										</div>
									</div>
								</td>
								<td *ngFor="let env of conf.environmentVars.configuration_environments" style="padding: 10px 5px 25px 0px;">
									<input [(ngModel)]="conf.newVariable.environments[env]" [type]="conf.newVariable.is_secure ? 'password' : 'text'" class="form-control form-border" autocomplete="new-password" placeholder="{{env}} Value" [disabled]="conf.newVariable.global_config_id != null" [readonly]="conf.newVariable.global_config_id != null">
								</td>
								<td></td>
								<td style="padding: 10px 5px 25px 0px;">
									<input type="checkbox" style="width: 40px; display: none;" #newFormCheckBox  [(ngModel)]="conf.newVariable.is_secure" [disabled]="conf.newVariable.global_config_id != null" >
									<div *ngIf="conf.newVariable.is_secure" (click)="newFormCheckBox.click()" class="fal fa-lock-keyhole fa-xl" style="cursor: pointer ;padding-top: 2px; width: 60px;" [ngStyle]="conf.newVariable.global_config_id != null && {'cursor' : 'not-allowed'}" ></div>
								   	<div *ngIf="!conf.newVariable.is_secure" (click)="newFormCheckBox.click()" class="fal fa-lock-keyhole-open fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;" [ngStyle]="conf.newVariable.global_config_id != null && {'cursor': 'not-allowed'}" ></div>
								   	
								</td>
								<td style="padding: 10px 15px 25px 10px;">
									<button type="button" class="btn btn-outline-success" (click)="addVariable()">
										<i class="fal fa-plus"></i>
									</button>
								</td>
							</tr>
							
						</tbody>
						<tfoot>
							<tr *ngFor="let v of conf.environmentVars.configuration_variables">
								
								<th style="min-width: 150px; padding-right: 15px; padding-top: 10px;">
									<input type='text' [(ngModel)]="v.key" class="form-control form-border" autocomplete="new-password" [disabled]="v.global_config_id != null" [readonly]="v.global_config_id != null">
								</th>
								<td *ngFor="let env of conf.environmentVars.configuration_environments" style="padding-top: 10px;">
									<input [(ngModel)]="v.environments[env]" [type]="v.is_secure ? 'password' : 'text'" class="form-control form-border" autocomplete="new-password" placeholder="{{env}} Value" [disabled]="v.global_config_id != null">
								</td>
								<td style="padding-top: 10px;"></td>
								<td style="padding-top: 10px;">
									<input type="checkbox" style="width: 40px; display: none;" [(ngModel)]="v.is_secure">
									<div *ngIf="v.is_secure && v.global_config_id == null" (click)="v.is_secure = false" class="fal fa-lock-keyhole fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;"></div>
								   	<div *ngIf="!v.is_secure && v.global_config_id == null" (click)="v.is_secure = true" class="fal fa-lock-keyhole-open fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;"></div>
								</td>
				
								<td style="padding-left: 10px; padding-top: 10px;">
									<button *ngIf="v.is_optional || v.global_config_id == null" type="button" class="btn btn-outline-danger" (click)="removeVariable(v)" ><i class="fal fa-trash-alt"></i></button>
								</td>
							</tr>
						</tfoot>
					</table>
					<div *ngIf="conf.configurationError != ''" style="padding: 15px; color: red; text-align: center;">
						{{configurationError}}
					</div>
				</div>
			</div>
	</div>
      <div class="modal-footer">
      	<div style="margin-right: 25px; color: red;">{{deleteMessage}}</div>
        <button class="btn btn-outline-secondary" style="display:inline-block;margin-right: 10px;" (click)="cancel()">
			<i class="fal fa-times" style="margin-right: 5px;"></i>
			Cancel
		</button>
		<button *ngIf="isUpdating(conf.environmentVars) && conf.deleteMessage == ''" class="btn btn-outline-danger" style="display:inline-block;" (click)="delete()">
			<i *ngIf="!conf.deleting" class="fal fa-trash-alt" style="margin-right: 5px;"></i>
			<div *ngIf="conf.deleting" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Delete
		</button>
		<button *ngIf="isUpdating(conf.environmentVars) && deleteMessage != ''" class="btn btn-outline-danger" style="display:inline-block;" (click)="delete(true)">
			<i *ngIf="!conf.deleting" class="fal fa-trash-alt" style="margin-right: 5px;"></i>
			<div *ngIf="conf.deleting" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Yes, Delete
		</button>
		<button class="btn btn-outline-primary" style="display:inline-block;" (click)="saveConfiguration()">
			<i *ngIf="!conf.saving" class="fal fa-floppy-disk" style="margin-right: 5px;"></i>
			<div *ngIf="conf.saving" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Save
		</button>
      </div>
</ng-template>

<div toastContainer></div>

