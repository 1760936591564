import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { AuthService } from '../../../shared/auth/auth.service'
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts/ng2-charts';
import { MomentModule } from 'ngx-moment';
import { DatePipe } from '@angular/common';
import { TablesService } from './tables.service'
import { EditorService } from '../../../editor/editor.service'
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'codemirror/mode/sql/sql';
import { NgbModal, ModalDismissReasons,NgbTooltipModule,  NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { NgModel, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styles: []
})
export class TablesComponent implements OnInit {
	url: string = environment.url;
	display: string = ''
	table_data_url: string = '';
	sub: any;
	last_input: string = ''
	public databaseName: string = '';
	public schemaName: string = '';
	public tableName: string = '';
	public objectType: string = '';
	private scriptStatus = ''
	private scriptStatus$ = new Subject<any>();
	items: Array<any> = [];
	view: String = '';
	description: Object = {};
	routes: Array<any> = [];
	script: Object = {}
	scripts: Object = {};
	loading = false;
	routeSubscription: Subscription;
	public descriptionForm: FormGroup;
	headers = new HttpHeaders();

	public ctConfigForm: FormGroup;
	public ctObj: any = {
	  	
	  	databaseOptions: [
	  			'PROD', 'DEV', 'STAGE'
		  ],
	  	databaseValue: null,
	  	isEnabled: false
	  }


	constructor(
		private auth: AuthService, 
		public http: HttpClient, 
		private router: Router, 
		private route: ActivatedRoute,
		private datePipe: DatePipe,
		public tables: TablesService,
		private modalService: NgbModal,
		private editorService: EditorService,
		private fb: FormBuilder
	) {
		this.descriptionForm = fb.group({
	        description: null
	    })
	   this.headers = this.auth.getHeaders()

	}

	ngOnInit() {
		console.log('ngOnInit running for table')

		this.router.events.subscribe((val) => {
	        // see also 
      if(val instanceof NavigationEnd){
      	this.routes = this.router.url.split("/")
				let view = this.routes[this.routes.length-1]
	        	if(view.indexOf('?') > -1){
					let viewSplit = view.split('?')
					view = viewSplit[0]
				}
				this.setRoute(view)
				
	    }	
	  });

		
		this.routeSubscription = this.route.params.subscribe(routeParams => {

			this.routes = this.router.url.split("/")
			
			let db = routeParams['database'], 
			schema = routeParams['schema'],
			table = routeParams['table'],
			vw = routeParams['view'],
			paramKey = '',
			paramVal = '',
			view = this.routes[this.routes.length-1]


			// TO DO
			// Check that this table is actually valid before tyring to build details, else route back to explorer/overview
			this.getDescription()
			
			// check to see if there are query params
			if(view.indexOf('?') > -1){
				let viewSplit = view.split('?')
				view = viewSplit[0]
				paramKey = viewSplit[1].split('=')[0];
				paramVal = viewSplit[1].split('=')[1];
			}
			this.setRoute(view)

			// Check what actually changed
			let case1 = this.databaseName == db ? true : false
			let case2 = this.schemaName == schema ? true : false
			let case3 = false;
			if(vw != undefined){
				case3 = this.tableName == vw ? true : false
			}
			else{
				case3 = this.tableName == table ? true : false
			}
			
			// let case4 = this.view == view ? true : false

			let case5 = false;
			if(this.databaseName == '' && this.schemaName == '' && this.tableName == ''){
				case5 = true;
			}
			// let case6 = true ? this.view == undefined || this.view == 'overview' : false;

			// table didn't change
			
			// If the table stayed the same or there is no table object variables set
			if(case1 && case2 && case3  || case5){
				this.view = view != undefined ? view : 'overview';
			}
			
			this.tables.setView(this.view)
			// Perform all logic

			this.loading = true;
			
			this.databaseName = routeParams['database']
			this.schemaName = routeParams['schema']
			this.tableName = routeParams['table'] != undefined ? routeParams['table'] : routeParams['view']	

			if(vw != undefined){
				this.objectType = 'view'
				this.tables.setObjects(routeParams['database'], routeParams['schema'], routeParams['view'], this.objectType)
			}
			else{
				this.objectType = 'table'
				this.tables.setObjects(routeParams['database'], routeParams['schema'], routeParams['table'], this.objectType)
			}
				
			this.items = [ 
				{ 'class': 'fa-light fa-mountains', 'text': 'Overview', 'link': '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName +'/overview' }, 
				{ 'class': 'fa-light fa-table-columns', 'text': 'Columns', 'link': '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName +'/columns' },
				{ 'class': 'fa-light fa-chart-network','text': 'Lineage', 'link': '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName +'/lineage' },
				{ 'class': 'fa-light fa-chart-column','text': 'Usage', 'link': '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName +'/usage'},
				{ 'class': 'fa-light fa-shield-keyhole','text': 'Permissions', 'link': '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName +'/permissions' }
			]
			var table_script_url = this.url + '/catalog/databases/' + this.databaseName
	  	table_script_url += '/schemas/' +  this.schemaName
	  	table_script_url += '/' + this.objectType + 's/' + this.tableName + '/scripts'
			
			//this.getTableScript()
		
		  	let params: HttpParams = new HttpParams();
		  	let headers = this.headers;
		    var config = {
		    	lineNumbers: true, mode: 'sql'
		    }
		    // this.http.get(table_script_url, {headers}).subscribe(
		    // 	data=>{
		    // 		// console.log('Quick Scripts: ', data['message'])
		    // 		var output = data['message'] 
		    // 		var pre = ''
		    // 		var post = ''

		    // 		if('merge_statement' in output && output['merge_statement'] != null){
		    // 			pre = output['merge_statement'].split("[Insert Subquery or Table Name Here]")[0]
		    // 			post =  output['merge_statement'].split("[Insert Subquery or Table Name Here]")[1]
		    // 		}
		    		
		    // 		this.scripts['ddl']    = {"title":"Create DDL", "code": output['ddl_statement'], "config": config, "og_code":output['ddl_statement'] }
		    // 		this.scripts['select'] = {"title":"SELECT", "code": output['select_statement'], "config": config, "og_code":output['select_statement']}
		    // 		this.scripts['insert'] = {"title":"INSERT", "code": output['insert_statement'], "config": config, "og_code":output['insert_statement']}
		    // 		this.scripts['update'] = {"title":"UPDATE", "code": output['update_statement'], "config": config, "og_code":output['update_statement']}
		    // 		this.scripts['merge']  = {"title":"MERGE", "code": output['merge_statement'], "config": config, "og_code": output['merge_statement'], "pre": pre, "post": post}
		    // 		this.scripts['delete'] = {"title":"DELETE", "code": output['delete_statement'], "config": config, "og_code": output['delete_statement']}
		    // 		this.scriptStatus = 'done'
		    // 		this.scriptStatus$.next('done')
		    // 		this.loading = false;

		    // })

		    // If any of the table object variables changed
		    if(!case1 || !case2 || !case3){
		    	let url = ''
		    	if(paramKey){
		    		url = '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName + '/' + this.view + '?' + paramKey + '=' + paramVal
		    	}
		    	else{
		    		url = '/explore/databases/' + this.databaseName +'/schemas/' + this.schemaName +'/' + this.objectType + 's/' + this.tableName + '/' + this.view
		    	}
		    	console.log('navigating to :', url)
					this.router.navigateByUrl(url);
		    }

			//}
			
			//console.log(this.databaseName, this.schemaName, this.tableName)
		});
	}

	ngOnDestroy(){
		// if(this.routeSubscription != undefined){
		// 	this.routeSubscription.unsubscribe();
		// }
		
	}

	setRoute(view){
	    this.view = view.toLowerCase();
	}

	openScriptModal(content, options={}, scriptType){

		if(this.scriptStatus == 'done'){
			this.script = this.scripts[scriptType]
			this.modalService.open(content, options).result.then((result) => {
		    }, (reason) => {
		    });
		}
		else{
			this.scriptStatus$.subscribe(data=>{
				this.script = this.scripts[scriptType]
				this.modalService.open(content, options).result.then((result) => {
			    }, (reason) => {
			    });
			})
		}
	}

  	private getDismissReason(reason: any): string {
	    if (reason === ModalDismissReasons.ESC) {
	      return 'by pressing ESC';
	    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	      return 'by clicking on a backdrop';
	    } else {
	      if(reason == 'send'){
	       
	      }
	      else if(reason == 'copy'){
	     //    let selBox = document.createElement('textarea');
		    // selBox.style.position = 'fixed';
		    // selBox.style.left = '0';
		    // selBox.style.top = '0';
		    // selBox.style.opacity = '0';
		    // selBox.value = this.script['code'];
		    // document.body.appendChild(selBox);
		    // selBox.focus();
		    // selBox.select();
		    // document.execCommand('copy');
		    // document.body.removeChild(selBox);

	      }
	    }
	    this.script["code"] = this.script["og_code"]
	    return reason
	}

	onFocus(){

	}
	onBlur(){

	}

	onSearchChange(searchValue : string ) {
		this.scripts["merge"]["code"] = this.scripts["merge"]["pre"] + searchValue + this.scripts["merge"]["post"]
    }

    openEditor(code){
    	this.editorService.code = code;
    	this.editorService.active = true;
    	this.modalService.dismissAll();
    	this.router.navigateByUrl('/create');
    }
    getDescription(){

    	// Check if the value has been cached
    	this.tables.getData('tableDescription').subscribe(
    		data=>{
    			if(data && data !== undefined){
    				
    				this.description = data;
    				this.descriptionForm.setValue({description: this.description['description']});
    			}
    			else{
    				
    				let descURL = this.url + '/catalog/objects'
				    let params: HttpParams = new HttpParams();
				    params = params.append('db', this.databaseName)
			        params = params.append('schema', this.schemaName)
			        params = params.append('table', this.tableName)
				    let headers = this.headers
					this.http.get(descURL, {headers, params}).subscribe(
						data=>{
							
							this.description = data['message']
							// Set the input form 
							if(this.description){
								this.descriptionForm.setValue({description: this.description['description']});
								// cache the value
								// this.tables.setData('tableDescription', this.description)
							}
						}
					)
    			}
    		}
    	)
    	
    }

    postDescription(){
    	//data = {"db": db, "schema": schema, "object": object_name, "description": description}
    	
    	let descURL = this.url + '/catalog/objects'
	    let params: HttpParams = new HttpParams();
	    let headers = this.headers
		let body = {
			db: this.databaseName,
			schema: this.schemaName,
			object: this.tableName,
			description: this.descriptionForm.value.description
		}

		if(this.description['description'] != this.descriptionForm.value.description){
			this.http.post(descURL, body, {headers}).subscribe(
	        data=>{ 
	
		           this.description = data['message']
		           // this.tables.setData('tableDescription', this.description)
		        }
		    )
		} 
	}

	openCTModal(content, options={}){
		this.ctConfigForm =  this.fb.group({
        destination_table: ['', Validators.required],
        only_daily_inserts: [true],
        auto_add_new_columns: [false],
        source: 'frontend'
    })

		// for(let j of this.jobs){
		// 		this.jobObj.runAfterSelectOptions.push(
		// 			{label: j.name , value: j.job_id}
		// 		) 
		// }

		this.modalService.open(content, options).result.then((result) => {
	    }, (reason) => {
	      if(reason == 'save'){
	      	
	      }
	    });
	}

	checkEnable(){
		this.ctObj.isEnabled = !this.ctObj.isEnabled;
		console.log(this.ctObj)
	}


}





	

	// public chartOptions = {
	// 	scaleShowVerticalLines: false,
	// 	responsive: true,
	// 	scales : {
	//         yAxes: [{
	//             ticks: {
	//             beginAtZero: true,
	//                 steps: 10
	//             }
	//         }],
	//         xAxes: [{
	//             ticks: {
	//                 steps: 5,
	//                 minRotation: 30
	//             }
	//         }]
	//     }
	// };
	// // Dates
	// public chartLabels = [];
	// public chartType = 'line';
	// public chartLegend = true;
	// public sizeData = [
	// {data: [0], label: 'Storage MB'},
	// ];
	// public rowsData = [
	// {data: [0], label: 'Rows'},
	// ];

	// public colors1 = [{
	//     backgroundColor: 'rgb(230, 46, 0, 0.3)',
	//     borderColor:     '#b32400',
	// }];

	// public colors2 = [{
	//     backgroundColor: 'rgb(0, 179, 179, 0.3)',
	//     borderColor:     '#006666',
	// }];
	// //1000000000

 
  	
  // 	this.sub = this.route.params.subscribe( params => {

  // 		console.log('params: ', params)
		// this.table_data_url = this.url + '/catalog/databases/' + params['database']
		// this.table_data_url += '/schemas/' + params['schema']
		// this.table_data_url += '/tables/' + params['table'] + '/details'
		// this.databaseName = params['database']
		// this.schemaName = params['schema']
		// this.tableName = params['table']
  // 		this.getGrowthData()
  // 	});
  // }

  // getGrowthData(){
  // 	let params: HttpParams = new HttpParams();
  // 	params = params.append('db', this.databaseName)
  // 	params = params.append('schema', this.schemaName)
  // 	params = params.append('table', this.tableName)

  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Content-Type', 'application/json');
  //   headers = headers.append('Authorization', 'SGToken ' + this.auth.getToken());
  //   var dates = []
  //   var rows = []
  //   var bytes = []
  //   this.http.get(this.table_data_url, {headers}).subscribe(
  //   	data=>{
  //   		for(var row of data['message']['growth']){
  //   			var formDate = this.datePipe.transform(row['date'],"MMM d, y");
  //   			dates.push(formDate)
  //   			rows.push(row['table_growth_rows'])
  //   			bytes.push(row['table_growth_bytes'] / 1000000)
  //   		}
  //   		this.chartLabels = dates
  //   		this.rowsData[0]['data'] = rows;
  //   		this.sizeData[0]['data'] = bytes;
  //   	}
  //   )
  // }