import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Injectable()
export class ApiService {
  private keys: Array<any> =[];
  private headers;
  subscription: Subscription;
  browserRefresh = false;

  constructor(
    private storage: StorageMap,
    private http: HttpClient,
    private auth: AuthService,
    private router: Router
    ) {
      this.storage.keys().subscribe({
        next: (key) => {
           this.keys.indexOf(key) === -1 ? this.keys.push(key): void(0);
        },
        complete: () => {
        },
      });

      this.auth.loggedOutReplay().subscribe({
        next: (isLoggedOut) => {
           if(isLoggedOut){
             //Reset the keys
             this.keys = []
           }
        },
        complete: () => {
        },
      });

      this.subscription = router.events.subscribe((event) => {
      
        if (event instanceof NavigationStart) {
          this.browserRefresh = !router.navigated;
          // console.log('Refreshed: ', this.browserRefresh)
        }
        else if(event instanceof NavigationEnd){
          // console.log('navigated: ', router.navigated)
        }
      });
  }

  getData(
    url, 
    page, 
    key, 
    options?: {api?: boolean, params?: object}
    ): Observable<any>{
      let index = this.getIndex(page, key)
      let api = false;
      let params = undefined;
      if(options != undefined){
          api = options['api'] == undefined ? false : options['api'];
          params = options['params']
      }
   
      // check if key exists in db, else make api call

      // console.log('Index: ', index, '. Keys:', this.keys, '. API: ', api)

      if((this.inDatabase(index) && !api) || (key == 'explorer_catalog' && !api)){
        console.log('Getting from database :', index)
        return this.storage.get(index)
      }
      else{
        console.log('Getting from api :', index)
        return this.getApiData(url, params)
      }
	}

  setData(page, key, data, force?: boolean){
      var index = this.getIndex(page, key)
      if(data !== undefined){
        if(force || !this.inDatabase(index)){
          this.storage.set(index, data).subscribe(() => {});
        }
          this.keys.indexOf(index) === -1 ? this.keys.push(index) : void(0);
      }
      
  }

  removeData(page, key){
     var index = this.getIndex(page, key)
     this.storage.delete(index).subscribe(() => {});
  }

  async checkCache(index){
      return await this.storage.has(index).toPromise();
  }

  inDatabase(index){
    let i = this.keys.indexOf(index)
    return i == -1 ? false : true; 
  }

  getIndex(page, key){
    return page + "_" + key
  }

  resetKeys(){
    this.keys = []
  }

  
  getApiData(url, parameter): Observable<any>{
    let params: HttpParams = new HttpParams();
    if(parameter != undefined){
      for(let item of parameter){
        params = params.append(item['key'], item['value'])
      }
    }
    
    let headers = this.auth.getHeaders();
    return this.http.get(url, {headers, params})
  }

}
