<div class="carve-scrollable-container">
	<div class="layout-container">
		<div class="layout-content" style="justify-content: normal; padding: 25px;">

			<div style="width: 100%" style="padding-bottom: 5px;">
				<div style="display: inline;color:#717171; font-size: 18pt; font-weight: bold; cursor: pointer;" (click)="returnPackages()"><i class="fa-thin fa-rectangle-code" style="margin-right: 5px;"></i> Code Packages</div>
				<div style="display: inline;padding-left: 10px;color: #ccc; font-size: 18pt; font-weight: bold;">/</div>
				<div style="display: inline;padding-left: 10px; color: black; font-size: 18pt; font-weight: bold;">Global Environment Configurations</div>
			</div>
			<p class="text-muted">Create global environment variable configurations to use in code packages.</p>
			<div class="card mb-4 shadow-hover">
	            	<ngx-loading-bar fixed [includeSpinner]="false" color="#3dabab" ref="configLoad"></ngx-loading-bar>
	            	<div class="card-header" style="padding-right: 20px; padding-left: 20px;">
	            		<div class="float-left">
							 <label class="nav-item navbar-text navbar-search-box p-0 active">
								<i class="ion ion-ios-search navbar-icon align-middle"></i>
						          <span class="navbar-search-input pl-2" style="width: 400px !important">
						            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="font-style: italic;"  (input)="onSearchChange($event.target.value)" [(ngModel)]="search" name="search" autocomplete="off" style="width: 400px !important; margin-left: 20px !important;">
						          </span>
						          <i *ngIf="search.length > 0 ? true : false" class="icon ion-md-close navbar-icon align-left" (click)="clear()" style="cursor: pointer;"></i>
						        </label>
						</div>
						
						<div class="float-right"  style="padding-right: 10px">
				              <button class="btn btn-outline-primary" type="button" (click)="openNewConfigModal(newConfiguration, { windowClass: 'modal-custom-lg animate', centered: true })">
						           <i class="fal fa-memo-pad" style="padding-right: 5px;"></i>  New Configuration 
						      </button>
			      		</div>
		            </div>
	            	<div class="card-body overview-card" >
						<div style="width: 100%; height: 100%;">
							<p-table 
									#packageTable
									styleClass="p-datatable-sm"
									[value]="configurations"
									sortMode="single"
								  	[paginator]="false" 
	        						responsiveLayout="scroll"
							 >
							
						        <ng-template pTemplate="header" style="font-size:9pt;">
						            <tr>
						                <th pSortableColumn="configuration_name">
						                	Configuration Name
						                	<p-sortIcon field="configuration_name"></p-sortIcon></th>
						                <th>
						                	Variables
						               	</th>
						               	 <th>
						                	Environments
						               	</th>
						               	
						                <th pSortableColumn="created_date">Created<p-sortIcon field="created_date"></p-sortIcon></th>
						                <th pSortableColumn="last_updated_date">Last Updated
						                <p-sortIcon field="last_updated_date"></p-sortIcon></th>
						                <th pSortableColumn="last_updated_by_username">Last Updated By<p-sortIcon field="last_updated_by_username"></p-sortIcon></th>
						                <th></th>
						            </tr>
						        </ng-template>
						        <ng-template pTemplate="body" style="font-size: 9pt; text-align: left;" let-conf>
						            <tr style="text-align: left;" class="hover clickable-row" (click)="openNewConfigModal(newConfiguration, { windowClass: 'modal-custom-lg animate', centered: true }, conf)">
						                <td>
						                	<i class="fa-thin fa-folder-gear" style="margin-right: 5px;"></i>
						                {{conf.configuration_name}}
						                </td>
						                <td>{{conf.configuration_variables.length}}</td>
						                <td>{{conf.configuration_environments.length}}</td>
						                <td>{{conf.created_date | date:'short'}}</td>
						                <td>{{conf.last_updated_date | dateAgo}}</td>
						                <td>{{conf.last_updated_by_username}}</td>
						                <td>
						                </td>
						            </tr>
						        </ng-template>
							</p-table>
						</div>
					</div>
			</div>
		</div>
	</div>
</div>

<ng-template #newConfiguration let-c="close" let-d="dismiss">
    <div class="modal-header" >
        <div class="modal-title">
        	<div class="row">
        		<div class="col" >
       
		        	<span style="font-weight: bold; font-size:14pt; vertical-align: bottom; line-height: 24px;">Environment Variable Configuration</span>
		        </div>
		    </div>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
    </div>
    <div class="modal-body" style="font-size: 11pt;">
    	<div class="row form-group">
				<div class="col-3">
					<form [formGroup]="configurationForm">
		                <label class="form-label"  style="width: 100%;">
		                	Configuration Name
		                 <span class="text-danger float-right"
	                     *ngIf="(configurationFormControl().configuration_name.touched || submitted) && configurationFormControl().configuration_name.errors?.required">
		                    Required
		                  </span>
		                </label>
		                
		                <input formControlName="configuration_name" id="configuration_name" name="configuration_name" [type]="text" class="form-control form-border" autocomplete="new-password">
		            </form>

	            </div>
			</div>
			<div class="row" *ngIf="!isUpdating(environmentVars)" style="margin-bottom: 20px;">
				<div class="col">
					<button class="btn btn-outline-secondary" (click)="toggleJSON()">
						<i class="fal fa-brackets-curly" style="margin-right: 5px;"></i>Use JSON Template
					</button>
				</div>
			</div>
			<div class="row" *ngIf="!isUpdating(environmentVars) && fromJSON"  style="margin-bottom: 20px;">
				<div class="col">
					<div style="height: 300px; border: 1px solid #ccc;">
						<codemirror 
			          #configCode
			          [(ngModel)]="fromJSONCode"
			          [config]="codeConfig"
			        >
						</codemirror>
					</div>
					<div class="row">
						<div class="col">
							<div style="color: red; text-align: center; padding: 10px;">{{jsonError}}</div>
						</div>
					</div>
					<button style="margin-top: 10px; float: right;" class="btn btn-outline-success" (click)="submitJSON()">Load</button>
				</div>
			</div>
			<div class="card mb-4 shadow-hover" style="min-height: 400px; margin-top: 20px;">
				<!-- Header row -->
				<div class="card-header">
					<i class="fal fa-square-code fa-lg" style="padding-right: 10px;"></i>
					<span style="font-size: 12pt;">
						Environment Variables
					</span>
				</div>
				<div class="card-body">
					<table style="width: 100%;">
						<thead>
							<tr>
								<th style="min-width: 150px; padding-right: 15px;"></th>
								<th *ngFor="let env of environmentVars.configuration_environments">
									{{env}}
									<i class="fal fa-minus-circle fa-xs" style="margin-left: 5px; cursor: pointer;" (click)="removeEnv(env)"></i>
								</th>
								<th style="width: 170px;">
									<input type="text" class="" [(ngModel)]="newEnv" placeholder="[New environment]" style="border: none; width: 130px;" (keyup.enter)="addEnv()">
									<i *ngIf="newEnv.length > 0" class="fal fa-plus-circle " (click)="addEnv()" style="cursor:pointer; "></i>
								</th>
								<td>Secure</td>
								<td>Optional</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							<tr style="border-bottom: 1px solid #ccc;">
								<td style="min-width: 150px; padding: 10px 15px 25px 0px;">
									<input type='text' [(ngModel)]="newVariable.key" class="form-control form-border" autocomplete="new-password" [ngClass]="{'input-error': newVariableError.length > 0}" [placeholder]="getPlaceholder()" (input)="newVariableError = ''">
								</td>
								<td *ngFor="let env of environmentVars.configuration_environments" style="padding: 10px 5px 25px 0px;">
									<input [(ngModel)]="newVariable.environments[env]" [type]="newVariable.is_secure ? 'password' : 'text'" class="form-control form-border" autocomplete="new-password" placeholder="{{env}} Value">
								</td>
								<td></td>
								<td style="padding: 10px 5px 25px 0px;">
									<input type="checkbox" style="width: 40px; display: none;" #newFormCheckBox  [(ngModel)]="newVariable.is_secure">
									<div *ngIf="newVariable.is_secure" (click)="newFormCheckBox.click()" class="fal fa-lock-keyhole fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;"></div>
								   	<div *ngIf="!newVariable.is_secure" (click)="newFormCheckBox.click()" class="fal fa-lock-keyhole-open fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;" ></div>
								   	
								</td>
								<td style="padding: 10px 5px 25px 0px;">
									<input type="checkbox" style="width: 40px; height: 20px; vertical-align: middle;" [(ngModel)]="newVariable.is_optional">
								</td>
								<td style="padding: 10px 15px 25px 10px;">
									<button type="button" class="btn btn-outline-success" (click)="addVariable()">
										<i class="fal fa-plus"></i>
									</button>
								</td>
							</tr>
							
						</tbody>
						<tfoot>
							<tr *ngFor="let v of environmentVars.configuration_variables">
								
								<th style="min-width: 150px; padding-right: 15px; padding-top: 10px;">
									<input type='text' [(ngModel)]="v.key" class="form-control form-border" autocomplete="new-password">
								</th>
								<td *ngFor="let env of environmentVars.configuration_environments" style="padding-top: 10px;">
									<input [(ngModel)]="v.environments[env]" [type]="v.is_secure ? 'password' : 'text'" class="form-control form-border" autocomplete="new-password" placeholder="{{env}} Value">
								</td>
								<td style="padding-top: 10px;"></td>
								<td style="padding-top: 10px;">
									<input type="checkbox" style="width: 40px; display: none;" [(ngModel)]="v.is_secure">
									<div *ngIf="v.is_secure" (click)="v.is_secure = false" class="fal fa-lock-keyhole fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;"></div>
								   	<div *ngIf="!v.is_secure" (click)="v.is_secure = true" class="fal fa-lock-keyhole-open fa-xl" style="cursor: pointer;padding-top: 2px; width: 60px;" ></div>
								</td>
								<td style="padding-top: 10px;">
									<input type="checkbox" style="width: 40px; height: 20px; vertical-align: middle;" [(ngModel)]="v.is_optional">
								</td>
				
								<td style="padding-left: 10px; padding-top: 10px;">
									<button type="button" class="btn btn-outline-danger" (click)="removeVariable(v)" ><i class="fal fa-trash-alt"></i></button>
								</td>
							</tr>
						</tfoot>
					</table>
					<div *ngIf="configurationError != ''" style="padding: 15px; color: red; text-align: center;">
						{{configurationError}}
					</div>
				</div>
			</div>
	</div>
      <div class="modal-footer">
      	<div style="margin-right: 25px; color: red;">{{deleteMessage}}</div>
        <button class="btn btn-outline-secondary" style="display:inline-block;margin-right: 10px;" (click)="cancel()">
			<i class="fal fa-times" style="margin-right: 5px;"></i>
			Cancel
		</button>
		<button *ngIf="isUpdating(environmentVars) && deleteMessage == ''" class="btn btn-outline-danger" style="display:inline-block;" (click)="delete()">
			<i *ngIf="!deleting" class="fal fa-trash-alt" style="margin-right: 5px;"></i>
			<div *ngIf="deleting" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Delete
		</button>
		<button *ngIf="isUpdating(environmentVars) && deleteMessage != ''" class="btn btn-outline-danger" style="display:inline-block;" (click)="delete(true)">
			<i *ngIf="!deleting" class="fal fa-trash-alt" style="margin-right: 5px;"></i>
			<div *ngIf="deleting" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Yes, Delete
		</button>
		<button class="btn btn-primary" style="display:inline-block;" (click)="save()">
			<i *ngIf="!saving" class="fal fa-floppy-disk" style="margin-right: 5px;"></i>
			<div *ngIf="saving" class="spinner-border spinner-border-sm" role="status" style="margin: auto;">
		        <span  class="sr-only">Loading...</span>
		    </div>
			Save
		</button>
      </div>
</ng-template>

  <div toastContainer></div>