import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthService } from '../../../shared/auth/auth.service';
import { OverviewService } from './overview.service';
import { DatePipe } from '@angular/common'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import * as am4plugins_regression from "@amcharts/amcharts4/plugins/regression"; 
import { Subscription } from 'rxjs/Subscription';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { AppService } from '../../../app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApiService } from '../../../shared/api/api.service';
import { TableModule } from 'primeng/table';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styles: []
})
export class OverviewComponent implements OnInit {

	url: string = environment.url;
	display: string = ''
	items: Array<any> = []
    newObjects: Array<Object> = []
    popularObjects: Array<any> = []
    attentionObjects: Array<any> = []
	routes: Array<any> = []
    overviewSubscription: Subscription;
	summary_loaded = false;
	summary_url: string = ''
	queryData: Array<any> = [{}];
    pageName = 'Overview';
    //private status$ = new Subject<any>();

    queryLoaded = false;
    gettingChart = false;
    refresh = true;
    queryChart;

    public summary: any = {
    "total_databases": 0,
    "total_schemas": 0,
    "total_size": 0,
    "total_tables": 0,
    "total_overview": 0,
    "total_rows": 0,
    "total_views": 0

    };

    interval = null;

  dailyLoaded: boolean = false;

	constructor(
        private api: ApiService,
		public http: HttpClient,
		public route: ActivatedRoute,
        private router: Router, 
		public auth: AuthService,
		public overview: OverviewService,
		private datePipe: DatePipe,
    private appService: AppService,
    private loadingBar: LoadingBarService
	) { 
		
		    this.queryData[0]['label'] = 'Users and Role Access'
        this.queryLoaded = false;
        this.refresh = true;
        this.appService.pageTitle = 'Explore Overview';
       
    }

  
  ngOnInit() {
        this.resetSummary();
        this.resetCharts();
        this.gettingChart = true;

        this.getData();

        this.overviewSubscription = this.overview.getOverviewObject().subscribe(
            data=>{
                  
                    this.resetSummary();
                    this.clearChartDivs()
                    this.gettingChart = true;
                    this.resetCharts();
                    this.getData();
                    this.refresh = false;
            }
        );
	}
    ngOnDestroy(){
        if(this.overviewSubscription != undefined){
            
            this.overviewSubscription.unsubscribe();
        }
        am4core.disposeAllCharts();

        if(this.interval !== undefined || this.interval != null){
            clearInterval(this.interval)
        }
    }
    resetSummary(){
      this.summary = {
        "total_databases": 0,
        "total_schemas": 0,
        "total_size": 0,
        "total_tables": 0,
        "total_overview": 0,
        "total_rows": 0,
        "total_views": 0
        };
    }

   resetCharts(){
       this.queryLoaded = false;
   }
   
  clearChartDivs(){
      if(document.querySelector('div#AllQueriesChartDiv') != null){
          document.querySelector('div#AllQueriesChartDiv').innerHTML = "";
      }   
   }


  
  private displayRowsSparkline(chartData){
      
    //am4core.useTheme(am4themes_animated);

    var chart = am4core.create("SparklineRowsDiv", am4charts.XYChart);

    chart.data = chartData;
    /* [ {
            "date": "2020-11-23",
            "value": 57
        }, {
            "date": "2020-11-24",
            "value": 27
        }, {
            "date": "2020-11-25",
            "value": 24
        }, {
            "date": "2020-11-26",
            "value": 59
        }, {
            "date": "2020-11-27",
            "value": 33
        }, {
            "date": "2020-11-28",
            "value": 46
        }, {
            "date": "2020-11-29",
            "value": 20
        }, {
            "date": "2020-11-30",
            "value": 42
        },{
            "date": "2020-12-01",
            "value": 57
        }, {
            "date": "2020-12-02",
            "value": 27
        }, {
            "date": "2020-12-03",
            "value": 24
        }, {
            "date": "2020-12-04",
            "value": 59
        }, {
            "date": "2020-12-05",
            "value": 33
        }, {
            "date": "2020-12-06",
            "value": 46
        }, {
            "date": "2020-12-07",
            "value": 20
        }, {
            "date": "2020-12-08",
            "value": 42
        }, {
            "date": "2020-12-09",
            "value": 59,
        "opacity": 1
        }
    ]; */

    chart.padding(0, 0, 0, 10);

    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.7;
    dateAxis.cursorTooltipEnabled = false;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;

    var series = chart.series.push(new am4charts.LineSeries());
    series.tooltipText = "{date}: [bold]{value}";
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.tensionX = 0.8;
    series.strokeWidth = 2;

    // render data points as bullets
    //var bullet = series.bullets.push(new am4charts.CircleBullet());
    //bullet.circle.opacity = 0;
    //bullet.circle.propertyFields.opacity = "opacity";
    //bullet.circle.radius = 3;
    
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.disabled = true;
    bullet.propertyFields.disabled = "disabled";

    let secondCircle = bullet.createChild(am4core.Circle);
    secondCircle.radius = 6;
    secondCircle.fill = chart.colors.getIndex(8);


    bullet.events.on("inited", function(event){
      animateBullet(event.target.circle);
    })


    function animateBullet(bullet) {
        let animation = bullet.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
        animation.events.on("animationended", function(event){
          animateBullet(event.target.object);
        })
    }
  }
  
  private displayQueriesChart(chartData){

        //alert(formatDate(new Date) + " " + formatAMPM(new Date));

        //alert(formatAMPM(new Date));
      
        let chart = am4core.create("AllQueriesChartDiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;
        chart.legend = new am4charts.Legend();
        chart.padding(10, 0, 0, 0);
        let title = chart.titles.create();
        title.text = "Total Queries Executed Today by Start Time";
        title.fontSize = 17;
        // title.fontWeight = 400;
        title.paddingTop = 5;
        title.paddingLeft = 10;
        title.align = "left";
        title.fill = am4core.color("#4E5155");
        chart.zoomOutButton.disabled = true;
        let data_full = chartData;

        let data = [];
        //let visits = 10;
        let i = 0;
        let query_count = 0;
        //Add in the initial data with prior 30 seconds populated to center the chart
        for (i = 0; i <= 30; i++) {

            query_count = data_full[0].daily_queries_running_total

            data.push({ date: new Date().setSeconds(i - 30), value: query_count, queries_queued_or_blocked: 0 });
        }
        chart.data = data;
        //chart.exporting.menu = new am4core.ExportMenu();
        //alert(String(data));

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.dateFormats.setKey("second", "ss");
        dateAxis.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
        dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
        dateAxis.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
        dateAxis.renderer.inside = true;
        dateAxis.renderer.axisFills.template.disabled = true;
        dateAxis.renderer.ticks.template.disabled = true;
        dateAxis.renderer.grid.template.disabled = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.interpolationDuration = 500;
        valueAxis.rangeChangeDuration = 500;
        valueAxis.renderer.inside = true;
        valueAxis.renderer.minLabelPosition = 0.05;
        valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxis.renderer.axisFills.template.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.fill = chart.colors.getIndex(0);
        valueAxis.renderer.labels.template.fontWeight = "bold";
        
        let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        //valueAxis2.tooltip.disabled = true;
        valueAxis2.interpolationDuration = 500;
        valueAxis2.rangeChangeDuration = 500;
        valueAxis2.renderer.inside = true;
        valueAxis2.renderer.minLabelPosition = 0.1;
        valueAxis2.renderer.maxLabelPosition = 0.95;
        valueAxis2.renderer.axisFills.template.disabled = true;
        valueAxis2.renderer.ticks.template.disabled = true;
        valueAxis2.renderer.grid.template.disabled = true;
        valueAxis2.renderer.opposite = true;
        valueAxis2.renderer.labels.template.fill = chart.colors.getIndex(3);
        valueAxis2.renderer.labels.template.fontWeight = "bold";

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.name = "Total Queries Executed";
        //series.tooltipText = "{dateX}: [b]{valueY}[/]";
        series.interpolationDuration = 500;
        series.defaultState.transitionDuration = 0;
        series.strokeWidth = 1.5;
        series.tensionX = 0.8;
        series.legendSettings.labelText = "[{stroke}]{name}[/]";
        
        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.dateX = "date";
        series2.dataFields.valueY = "queries_queued_or_blocked";
        series2.interpolationDuration = 500;
        series2.defaultState.transitionDuration = 0;
        series2.tensionX = 0.8;
        series2.name = "Queued or Blocked";
        series2.yAxis = valueAxis2;
        //series2.fillOpacity = 0.5;
        series2.strokeWidth = .5;
        series2.stroke = chart.colors.getIndex(3);
        series2.fill = chart.colors.getIndex(3);
        series2.legendSettings.labelText = "[{stroke}]{name}[/]";

        chart.events.on("datavalidated", function () {
            //alert('here');
            dateAxis.zoom({ start: 1/15, end: 1.5 }, false, true);
        });

        dateAxis.interpolationDuration = 500;
        dateAxis.rangeChangeDuration = 500;

        // document.addEventListener("visibilitychange", function() {
        //     if (document.hidden) {
        //         if (this.interval) {
        //             clearInterval(this.interval);
        //         }
        //     }
        //     else {
        //         this.interval = startInterval();
        //     }
        // }, false);

        // add data
        let interval;
        function startInterval() {
            interval = setInterval(function() {
                //Series 1 data
                let lastdataItem = series.dataItems.getIndex(series.dataItems.length - 1);
                if(lastdataItem == undefined){
                    return
                }
                let query_count = lastdataItem.valueY;
                let myDate2 = new Date(lastdataItem.dateX.getTime() + 1000);
                let scnds2 = Math.round(myDate2.getTime() / 1000);
                //alert(scnds);
                for (let index = 0; index < data_full.length; index++) { 

                    if (data_full[index].date === scnds2) {
                        //alert('Found');
                        query_count = data_full[index].daily_queries_running_total
                        
                    }
                }
                
                //Series 1 data
                let lastdataItem2 = series2.dataItems.getIndex(series.dataItems.length - 1);
                let blocked = 0;
                let myDate3 = new Date(lastdataItem2.dateX.getTime() + 1000);
                let scnds3 = Math.round(myDate3.getTime() / 1000);
                
                for (let index = 0; index < data_full.length; index++) { 

                    if (data_full[index].date === scnds3) {
                        //alert('Found');
                        blocked = data_full[index].queries_queued_or_blocked
                        
                    }
                }

                chart.addData(
                    { date: new Date(lastdataItem.dateX.getTime() + 1000), value: query_count, queries_queued_or_blocked: blocked },1 );
            }, 1000);
            return interval
        }

        this.interval = startInterval();

        // all the below is optional, makes some fancy effects
        // gradient fill of the series
        series.fillOpacity = 1;
        let gradient = new am4core.LinearGradient();
        gradient.addColor(chart.colors.getIndex(0), 0.2);
        gradient.addColor(chart.colors.getIndex(0), 0);
        series.fill = gradient;

        // this makes date axis labels to fade out
        dateAxis.renderer.labels.template.adapter.add("fillOpacity", function (fillOpacity, target) {
            let dataItem = target.dataItem;
            return dataItem.position;
        })

        // need to set this, otherwise fillOpacity is not changed and not set
        dateAxis.events.on("validated", function () {
            am4core.iter.each(dateAxis.renderer.labels.iterator(), function (label) {
                label.fillOpacity = label.fillOpacity;
            })
        })

        // this makes date axis labels which are at equal minutes to be rotated
        dateAxis.renderer.labels.template.adapter.add("rotation", function (rotation, target) {
            let dataItem = target.dataItem;
            //alert(dataItem["date"].getTime());//1608534000000
            //alert(new Date(Math.round(dataItem["date"].getTime() / 60000) * 60000).getTime());
            if (dataItem["date"] && dataItem["date"].getTime() == (new Date(Math.round(dataItem["date"].getTime() / 60000) * 60000).getTime())) {
                target.verticalCenter = "middle";
                target.horizontalCenter = "left";
                return -90;
            }
            else {
                target.verticalCenter = "bottom";
                target.horizontalCenter = "middle";
                return 0;
            }
        })

        // bullet at the front of the line
        let bullet = series.createChild(am4charts.CircleBullet);
        bullet.circle.radius = 5;
        bullet.fillOpacity = 1;
        bullet.fill = chart.colors.getIndex(0);
        bullet.isMeasured = false;

        series.events.on("validated", function() {
            bullet.moveTo(series.dataItems.last.point);
            bullet.validatePosition();
        });
        
        // bullet at the front of the line
        let bullet2 = series2.createChild(am4charts.CircleBullet);
        bullet2.circle.radius = 5;
        bullet2.fillOpacity = 1;
        bullet2.fill = chart.colors.getIndex(3);
        bullet2.isMeasured = false;

        series2.events.on("validated", function() {
            bullet2.moveTo(series2.dataItems.last.point);
            bullet2.validatePosition();
        });
  }
  
  getData(){
  
        let summaryUrl = this.url + '/catalog/summary'
        let chartUrl = this.url + '/catalog/summary/graph'
        let queryGraphUrl = this.url + '/catalog/summary/queries'
        let recent_params = [{key: "graph", value: "new_objects"}]
        let pop_params = [{key: "graph", value: "popular_objects"}]
        let att_params = [{key: "graph", value: "attention_objects"}]

  
        let viewObj = {
        }
        this.overview.graphData = {}

        // Check for a cached overview 

        let newObjLoader = this.loadingBar.useRef('newObj')
        let popObjLoader = this.loadingBar.useRef('popObj')
        let attenObjLoader = this.loadingBar.useRef('attenObj')
        let allQueriesLoader = this.loadingBar.useRef('allQueries')

        newObjLoader.start()
        popObjLoader.start()
        attenObjLoader.start()
        allQueriesLoader.start()
        

        newObjLoader.set(70)
        popObjLoader.set(50)
        attenObjLoader.set(60)
        allQueriesLoader.set(20)
      
        this.api.getData(summaryUrl, this.pageName, 'explorer_overview').subscribe(
            data=>{
        

                //this.summary = data["overview_data"][0]                
                this.summary = data['message']["overview_data"][0]
                //this.displayRowsSparkline(data['row_growth_by_day'])
                allQueriesLoader.complete()
                // console.log(this.summary)
                //this.summary = data['message']["overview_data"][0]
                // this.summary.total_terabytes = this.convertTB(this.summary.total_terabytes)
                // this.api.setData(this.pageName, 'explorer_overview', data, true)
                this.displayRowsSparkline(data['message']['row_growth_by_day']);
            }
        );
        
        this.api.getData(chartUrl, this.pageName, 'explorer_recent', {params: recent_params}).subscribe(
            data=>{
                 this.newObjects = data['message']
                 
                 newObjLoader.complete()
                 this.api.setData(this.pageName, 'explorer_recent', data, true)
            }
        );
        
        this.api.getData(chartUrl, this.pageName, 'explorer_popular', {params: pop_params}).subscribe(
            data=>{
                 this.popularObjects = data['message']
                 
                 popObjLoader.complete()
                 this.api.setData(this.pageName, 'explorer_popular', data, true)
            }
        );
        
        this.api.getData(chartUrl, this.pageName, 'explorer_attention', {params: att_params}).subscribe(
            data=>{
                 this.attentionObjects = data['message']
                 
                 attenObjLoader.complete()
                 this.api.setData(this.pageName, 'explorer_attention', data, true)
            }
        );
        
        this.api.getData(queryGraphUrl, this.pageName, 'queries_running').subscribe(
            data=>{
                 if(data['message'].length > 0) this.displayQueriesChart(data['message'])
                 allQueriesLoader.complete()
                 this.api.setData(this.pageName, 'queries_running', data, true)
            }
        );

  }

    convertTB(tbs){
    // console.log('total_terabytes: ', tbs)
    let newNum = ''
    if(tbs < 1 && tbs >= .001){
      tbs = tbs * 1000
      newNum = tbs.toFixed(2) + ' GB'
    }
    else if ( tbs < .001 && tbs >= .000001){
      tbs = tbs * 1000000
      newNum = tbs.toFixed(2) + ' MB'
    }
    else{
      newNum = tbs.toString() + ' TB'
    }
    return newNum
  }

    routeTableOverview(db, schema, table){
    let tableOverview = '/explore/databases/' + db.toUpperCase() + '/schemas/' + schema.toUpperCase() + '/tables/' + table.toUpperCase() +'/overview'
    this.router.navigateByUrl(tableOverview);
}


}
