<div class="container-fluid">
	<div class="row flex-grow-1" style="padding-top: 20px; padding-bottom: 20px" >
        <div class="col-lg-6" >
            <div class="card mb-2 shadow-hover" style="height: 100%" >
                    <div class="card-body" style="padding:0px;">
                        <div class="card-subtitle mb-2 card-subtitle-header">
                                      <span class="fal fa-file-magnifying-glass overview-icon" style="color:#ff7171"></span>
                                      <span class="text-big card-info-title">READ ACCESS OVERVIEW</span>
                                  </div>
                                    
                                <div class="card-info-stacked-4">
                                    
                                    <div class="text-center text-big">
                                        <span>{{summary.total_different_privileges_granted}}</span>
                                    </div>
                                    <div class="text-muted text-center">Total Privileges Granted</div>
                                </div>
                                <div class="card-info-stacked-4">
                                    
                                    <div class="text-center text-big">
                                        <span>{{summary.users_with_access_inherited_from_role}}</span>
                                    </div>
                                    <div class="text-muted text-center">Users with Inherited Read Access</div>
                                </div>
                                <div class="card-info-stacked-4">
                                    
                                    <div class="text-center text-big">
                                        <span>{{summary.roles_with_read_access}}</span>
                                    </div>
                                    <div class="text-muted text-center">Roles with Read Access</div>
                                </div>
                                <div class="card-info-stacked-4">
                                    
                                    <div class="text-center text-big">
                                        <span>{{summary.users_with_read_access}}</span>
                                    </div>
                                    <div class="text-muted text-center">Total Users with Read Access</div>
                                </div>
                    </div>
            </div>
        </div>
        <div class="col-lg-6" >
            <div class="card mb-2 shadow-hover" style="height: 100%" >
                    <div class="card-body" style="padding:0px;">
                        <div class="card-subtitle mb-2 card-subtitle-header">
                                      <span class="fal fa-file-pen overview-icon" style="color:#62ae82;"></span>
                                      <span class="text-big card-info-title">WRITE ACCESS OVERVIEW</span>
                                  </div>
                                    
                                <div class="card-info-stacked-2">
                                    
                                    <div class="text-center text-big">
                                        <span>{{summary.roles_with_write_access}}</span>
                                    </div>
                                    <div class="text-muted text-center">Roles with Write Access</div>
                                </div>
                                <div class="card-info-stacked-2">
                                    <div class="text-center text-big">
                                        <span>{{summary.users_with_write_access}}</span>
                                    </div>
                                    <div class="text-muted text-center">Users with Write Access</div>
                                </div>
                    </div>
            </div>
        </div>
	</div>
	<div class="row" [ngStyle]="{'height.px': mainHeight }" >
        <div class="col-lg-12" [ngStyle]="{'height.px': insideHeight }">
            <div class="card mb-4 card-block shadow-hover" [ngStyle]="{'height.px': insideHeight }">
                <ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="permchart1"></ngx-loading-bar>
                <div class="card-subtitle text-muted card-subtitle-header" style="margin: 0px">
                  <span class="text-big card-info-title">Permission  |  Role  |  User</span>
                </div>
                <div class="card-body" [ngStyle]="{'height.px': sankeyHeight } " style="padding: 0px">
                    <div id="TablePermissionsSankeyDiv" [ngStyle]="{'height.px': sankeyHeight } ">
                    </div>
                </div> 
            </div>
		</div>
		<br />
        <br />
    </div>
</div>