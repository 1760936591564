import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { environment } from '../../../../environments/environment';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from "@angular/router"
import { AuthService } from "../../../shared/auth/auth.service";
import { map, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: [
    '../../../../vendor/styles/pages/authentication.scss'
  ],
})

export class ResetComponent implements OnInit {

  public resetForm: FormGroup;
  public templateForm: FormGroup;
  ipAddress: string = '';
  url: string = environment.url;
  toEmail: string = undefined;
  loading: boolean = true;
  reset = {
    name: '',
    success: false,
    verified: false,
    complete: false,
    submitting: false,
    errorMsg: '',
    hasError: false
  }

  verify = {
    hasError: false,
    submitting: false,
    resent: false
  }

  fieldTextType: boolean;
  submitted: boolean = false;
  token: string = '';
  displayMessage: string = '';



  constructor(
    private appService: AppService, 
    private http: HttpClient, 
    private fb: FormBuilder,
    private router: Router, 
    private auth: AuthService,
    private storage: StorageMap,
    private route: ActivatedRoute,
    ) {

    this.appService.pageTitle = 'Password Reset';

    this.templateForm = fb.group({
        password:['', [Validators.required, Validators.minLength(8), this.createPasswordStrengthValidator()]],
        confirm_password:['', [Validators.required, this.passwordMatchValidator()]],
        source: 'frontend'
    });
    this.resetForm = this.templateForm;


  }

  ngOnInit(){

    this.route.queryParams
      .subscribe(params => {
        if(params.token && params.email){
          this.token = params.token;
          var url = this.url + '/login/reset/verify/' + params.token
          this.toEmail = params.email;
          this.http.post(
             url,
             {},
             { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json' }
           ).subscribe(data => {
             
              this.reset.verified = true;
              this.loading = false;
       
            },
             err => {
               if('message' in err){
                 this.verify.hasError = true;
               }
               this.loading = false;
           });
        }
      }
    );
  }

  resetPassword(){
    this.reset.submitting = true;
    this.reset.hasError = false;
    this.reset.errorMsg = '';
    var url = this.url + '/user/reset/' + this.token;
    var body = {password: this.resetForm.get('password').value}
    
    if (this.resetForm.valid) {
      this.http.post(
      url,
      JSON.stringify(body),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'json' }
      ).subscribe(data => {
          console.log('Reset Data: ', data)
          this.reset.name = data['message']['name'];
          this.reset.submitting = false;
          this.reset.complete = true;
          this.reset.success = true;
          this.resetForm.reset(this.templateForm.value);
        },
        err => {
          console.log('Error: ', err)
          
          this.reset.success = false;
          this.reset.errorMsg = err['error']['message'];
          this.resetForm.reset(this.templateForm.value);
          this.reset.submitting = false; 
          this.reset.hasError = true;
        }
      );

    }
  }

  resendEmail(){

    this.verify.submitting = true;
    var url = this.url + '/login/reset'
    this.resetForm.patchValue({ip_address: this.ipAddress});
    
    this.http.post(
      url,
      JSON.stringify({email: this.toEmail, source: 'frontend'}),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'json' }
    ).subscribe(data => {
      console.log(data)
      this.verify.submitting = false;
      this.verify.resent = true;

      },
      err => {
        console.log(err)
        this.displayMessage = 'Incorrect Email or Password'
         this.verify.submitting = false
      }
    );
    
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  get resetFormControl() {
    return this.resetForm.controls;
  }
  passwordMatchValidator() : ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {

      let fg = control.parent;

      if(fg){
        const pControl = fg.get('password')
        return pControl.value === control.value ? null : {mismatch: true};
      }
      return null

    }
  }

  createPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {

        const value = control.value;

        if (!value) {
            return null;
        }
        const hasUpperCase = /[A-Z]+/.test(value);
        const hasLowerCase = /[a-z]+/.test(value);
        const hasNumeric = /[0-9]+/.test(value);
        const hasSpecial = /[@$!%*?&]+/.test(value);
        const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;

        return !passwordValid ? {passwordStrength:true}: null;
    }
  }
}


