import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertSeconds',
  pure: true
})
export class ConvertSecondsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
        if (value) {
            if(value >= 60 && value < 3600){
              let minutes = Math.floor(value / 60);
              let seconds =  Math.floor((value % 60));
              return minutes + ' min ' + seconds + ' sec' 
            }
            else if(value > 3600){
              let hours =  Math.floor(value / 3600);
              let minutes = Math.floor((value % 3600)/60);
              return hours + ' hr ' + minutes + ' min'
            }
            else{
              return parseFloat(value).toFixed(1) + ' seconds'
            }
        }
        return value;
    }

}
