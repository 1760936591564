import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { StorageMap } from '@ngx-pwa/local-storage';
import {Subject} from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';



@Injectable({
  providedIn: 'root'
})
export class EditorService {

  public code = '';
  public active = false;
  public query_id = null;
  public version_id = null;
  private code$ = new ReplaySubject<any>(1);
  constructor(private storage: StorageMap) {}

  getData(index): Observable<any>{
	    return this.storage.get(index)
	}

  setData(index, data){
        this.storage.set(index, data).subscribe(() => {});
  }

  resetVariables(){
    this.code = '';
    this.active = false;
    this.query_id = null;
    this.version_id = null;
  }

  setCode(code){
    this.code$.next(code)
  }

  getCode(): Observable<any> {
      return this.code$.asObservable();
  }

}
