
<div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style="background-image: url('assets/img/Aurora_Gradient_Landscape.jpg');">
  <div class="ui-bg-overlay bg-dark opacity-25"></div>

  <div class="py-5" >
    <div class="card" style="width:1200px; border: none;">
      <div class="row h-100">
        <div class="col-lg-6 ui-bg-cover ui-bg-overlay-container p-5" style="background-color: black;border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;">
          <div class="ui-bg-overlay bg-dark opacity-50" style="border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;"></div>
          <!-- Logo -->
          <div style="height:33%">
               <img width="200px" height="52px" src="./assets/img/carve_logo_white.png">
          </div>

          <!-- Text -->
          <div class="w-100 text-white" style="height:33%">
           <!--  <h1 class="display-2 font-weight-bolder mb-4">JOIN OUR<br>COMMUNITY</h1> -->
            <div class="text-large font-weight-light" style="padding-right: 75px; font-family: Roboto !important;">
                <h2>Data Experience</h2>
                <p>
                  The Carve data experience platform provides visibility, insights, and action on the data in your Snowflake data warehouse.
                </p>
            </div>
          </div>
          <div style="height:33%; text-align:center;" class="d-flex justify-content-center align-items-end">
            <div>
               <img width="250px" height="89px" src="./assets/img/carve+snowflake.png">
             </div>
          </div>
          <!-- /.Text -->
        </div>
        <div class="theme-bg-white d-flex col-lg-6 align-items-center p-5" style="height: 700px;border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;">
          <!-- Inner container -->
          <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
          <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 mx-auto" style="max-width: 450px;">
            <div *ngIf="!registration.success && !verify.success && !verify.error.found && !loading" style="margin: 0 auto; ">
               <div class="text-right text-muted" style="padding-top:0px;">
                  Already have an account? <a href="login">Sign In</a>
              </div>
              <h3 class="text-left text-darker font-weight-normal mt-4 mb-0" style="color:black;">Create your Carve Account!</h3>
              <div style="font-size:10pt;color:#aaa;padding:3px 0px;" class="text-left">Get started with a 14 Day Free Trial.</div>
              <h5  [ngClass]="{'text-center text-muted font-weight-normal mb-4 error': errorCount > 0, 'text-center text-muted font-weight-normal mb-4': errorCount === 0 }" >{{displayMessage}}</h5>
              <form [formGroup]="registerForm" (keydown.enter)="register()" >
                <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">
                    <span>Company</span>
                    <span class="text-danger"
                      *ngIf="(registerFormControl.company.touched || submitted) && registerFormControl.company.errors?.required">
                      Required
                    </span>
                  </label>
                  
                  <input formControlName="company" id="company" name="company" type="text" class="form-control form-border" >
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label d-flex justify-content-between align-items-end">
                        <span>First Name</span>
                        <span class="text-danger"
                          *ngIf="(registerFormControl.first_name.touched || submitted) && registerFormControl.first_name.errors?.required">
                          Required
                        </span>
                      </label>
                      <input formControlName="first_name" id="first_name" name="first_name" type="text" class="form-control form-border" >
                    </div>
                  </div>
                  <div class="col">
                    <label class="form-label d-flex justify-content-between align-items-end">
                      <span>Last Name</span>
                      <span class="text-danger"
                        *ngIf="(registerFormControl.last_name.touched || submitted) && registerFormControl.last_name.errors?.required">
                        Required
                      </span>
                    </label>
                    <input formControlName="last_name" id="last_name" name="last_name" type="text" class="form-control form-border" >
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">
                    
                    <span>Email Address</span>
                    <span class="text-danger"
                      *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                      Required
                    </span>
                    <span class="text-danger"
                      *ngIf="(registerFormControl.email.touched || submitted) && !registerFormControl.email.errors?.required && registerFormControl.email.errors?.pattern">
                      Please enter valid email address
                    </span>

                  </label>
                  
                  <input formControlName="email" id="defaultemail" name="email" type="email" class="form-control form-border">
                </div>
                <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">

                    <span style="padding-right: 15px;">Create Password 
                      <i 
                        class="fal fa-info-circle" 
                        style="cursor:pointer;"
                        [ngbPopover]="passwordInfo" 
                        container="body" 
                        popoverClass="download-popover" 
                        [autoClose]="'outside'"
                        placement="top"
                      >
                          
                        </i>
                    </span>
                    <span class="text-danger"
                      *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.required">
                      Required 
                  </span>
                  <span class="text-danger"
                      *ngIf="(registerFormControl.password.touched || submitted) && registerFormControl.password.errors?.passwordStrength">
                     Password requirements not met
                  </span>

                   
                  </label>
                  
                  <div class="input-group">
                    <input formControlName="password" id="defaultPassword" name="password" [type]="fieldTextType ? 'text' : 'password'" class="form-control" style="border: 1px solid #0a0d10;">
                    <div class="input-group-append" style="border: 1px solid #0a0d10;border-top-right-radius: 5px;border-bottom-right-radius: 5px; border-left:none;">
                      <span class="input-group-text" style="cursor:pointer; width:50px">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">
                    <span>Snowflake Account Name 
                       <i 
                        class="fal fa-info-circle" 
                        style="cursor:pointer;"
                        [ngbPopover]="snowflakeInfo" 
                        container="body" 
                        popoverClass="download-popover" 
                        [autoClose]="'outside'"
                        placement="top"
                      >
                          
                      </i>
                    </span>
                    <span class="text-danger"
                      *ngIf="(registerFormControl.snowflake_account.touched || submitted) && registerFormControl.snowflake_account.errors?.required">
                      Required
                  </span>
                  <span class="text-danger"
                      *ngIf="(registerFormControl.snowflake_account.touched || submitted) && registerFormControl.snowflake_account.errors?.invalidURL && !registerFormControl.snowflake_account.errors?.required">
                      Invalid Snowflake Account
                  </span>
                  </label>
                  
                  <input formControlName="snowflake_account" id="snowflakeAccount" name="snowflakeAccount" type="text" class="form-control form-border" >
                </div>
                <button type="button" class="btn btn-primary btn-block mt-5" (click)="register()">
                  <div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                    <span  class="sr-only">Loading...</span>
                  </div>
                  Create Account
                </button>
               
                <div class="text-light small mt-2">
                  By clicking "Create Account", you agree to our
                  <a href="javascript:void(0)" (click)="openTermsModal(termsModal, { windowClass: 'modal-xl animate', centered: true }, nav)">terms of service and privacy policy</a>.
                  We’ll occasionally send you account related emails.
                </div>
              </form>
              <!-- / Form -->
             <ng-template #passwordInfo>
                <div style="padding:10px 20px; font-size: 11pt;">
                  <div style="font-weight:bold; padding-bottom: 10px;">Password Requirements</div>            
                  <div >
                   <ul style="padding-inline-start: 15px;">
                     <li> At least 8 characters</li>
                     <li> At least 1 number</li>
                     <li> At least 1 uppercase letter</li>
                     <li> At least 1 special character</li>
                   </ul>
                  </div> 
                </div>
             </ng-template>
             <ng-template #snowflakeInfo>
                  <div style="padding:10px 20px; font-size: 11pt;">
                    <div style="font-weight:bold; padding-bottom: 10px;">Snowflake Account Name</div>            
              
                    <div style="width: 240px;">
                    
                      <span style="background-color: #ffffcb; border: 1px solid blue; border-radius: 5px; padding: 2px;">account</span>
                      <span>.snowflakecomputing.com</span>
                    </div>
                  </div>
             </ng-template>
            </div>
            <div *ngIf="registration.success && !verify.success && verify.error.message != 'Email already registered' && verify.error.message != 'Customer exists' && !loading" style="font-size: 12pt; text-align: center;">
              <div *ngIf="!emailResent">
                <span style="color: #097897; padding-right: 10px;"><i class="fal fa-envelope fa-2x"></i></span>
                <span style="color:black; font-size: 20pt;">Please verify your email</span>
              </div>
              <div *ngIf="emailResent">
                <span style="color: green; padding-right: 10px;"><i class="fal fa-check-circle fa-2x"></i></span>
                <span style="color:black; font-size: 20pt;">Another email sent!</span>
              </div>
              <div *ngIf="verify.error.message != 'Invalid or expired token'" class="email-sent">
                <div>
                  <span>Just a few more steps! We sent an email to</span>
                  <span style="font-weight:bold;">{{toEmail}}</span>
                </div>
                <div style="padding-top:40px;">
                  Click the <span style="font-weight:bold;">"Verify Email"</span> button in the email to continue your registration. 
                </div>
                <div style="padding-top:40px;">
                  If you can't find the email, you may need to <span style="font-weight:bold;">check your spam</span> folder or resend the email. 
                </div>
              </div>
              <div style="padding-top: 40px; font-size: 13pt;" *ngIf="verify.error.message == 'Invalid or expired token'">
                <div>
                  Looks like your verification token is invalid or has expired.
                </div>
                <div style="font-size: 32pt;text-align: center;">😕</div>
                <div style="padding-top: 10px; font-size: 13pt;">
                 Click the Resend Email button below for a fresh token. 
               </div>
              </div>
              <div style="padding-top:20px; width: 100%; text-align: center;"> 
                <button class="btn btn-primary btn-block mt-4" (click)="resendEmail()">
                  <div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                    <span  class="sr-only">Loading...</span>
                  </div>
                  Resend Email
                </button>
              </div>
              <div style="padding-top:40px; text-align: center; width: 100%;"><a href="https://carvedata.io">Contact us</a> for additional help</div>
            </div>

            <div *ngIf="verify.error.found && verify.error.message !== 'Invalid or expired token'" style="font-size: 20pt;">
              <div *ngIf="verify.error.message == 'Customer exists' && !loading" style="text-align: center;">
                <div>
                 Your company is already registered with us!
                </div>
                <div style="padding-top: 24px; color: #ff6464;">
                  <i class="fal fa-comment-alt-exclamation fa-2x"></i> 
                </div>
                <div style="padding-top: 20px; font-size: 12pt;">
                  Please reach out to your Snowflake/Carve administrator to setup your user account.
                </div>
                <div style="height: 200px; font-size: 12pt; margin-top: 40px;">
                    <a  href="login"><button type="button" class="btn btn-primary btn-block" style="font-size: 12pt;">Back to Login</button></a>
                </div>
                <div style="padding-top:40px; text-align: center; width: 100%; font-size: 12pt;"><a href="https://carvedata.io">Contact us</a> for additional help</div>
              </div>
              <div *ngIf="verify.error.message == 'Email already registered'" style="text-align: center;">
                <span style="color: green; padding-right: 10px;"><i class="fal fa-check-circle fa-lg"></i></span> <span style="text-align: center;">You have already completed registration.</span>
                <div style="height: 200px; font-size: 12pt; margin-top: 40px;">
                    <a  href="login"><button type="button" class="btn btn-primary btn-block" style="font-size: 12pt;">Go to Login</button></a>
                  </div>
              </div>
            </div>
             <div style="margin:auto;" *ngIf="loading">
              <img height="75px" width="75px" src="../../../assets/img/snowguide_loading_main.gif">
            </div>
            <div *ngIf="verify.success && !loading" style="width: 375px; margin: auto;">
               <h2 class="text-center mb-4">Thanks, {{verify.name}}!</h2>
               <div class="display-1 lnr lnr-checkmark-circle text-center text-success mb-4"></div>

               
                <p class="text-center text-big mb-4">Your email address has been verified.</p>
                <div *ngIf="registration.complete" @fadeSlideInOut>
                  <div class="text-center" style="font-size: 16pt; margin-top: 50px; padding-bottom: 10px;">We're finished!</div>
                  <div style="height: 200px; font-size: 12pt;">
                    <a  href="login"><button type="button" class="btn btn-primary btn-block" style="font-size: 12pt;">Let's log in</button></a>
                  </div>
                </div>

               
                
                <div *ngIf="!registration.complete">
                  <div class="text-center" style="font-size: 16pt; margin-top: 50px; margin-bottom: 25px;">Hang tight! We're creating your </div>
                  <div  class="text-right text-big mb-4" style="width: 400px; height: 200px;">
                    <!-- <div class="col-6" style="text-align:right; padding-right: 2px;" >Setting up your </div>
                    <div class="col-6" style="text-align:left; padding-left: 2px;">  -->
                      <div class="row" @fadeSlideInOut *ngFor="let step of verify.status.steps">
                        <div style="font-weight: bold; font-size: 16pt; text-align: right; display: inline; width:200px;"> {{step.name}} </div>
                        <div *ngIf="!step.complete" style="text-align: left; padding-top:3px; width:200px; padding-left: 5px; display: inline;"><sk-double-bounce></sk-double-bounce></div>
                        <div *ngIf="step.complete" style="text-align: left;color: #26B4FF; padding-top:3px; width:200px;  padding-left: 5px; display: inline; color: green;"><i class="fal fa-check fa-lg"></i></div>
                      </div>

                   <!--  </div> -->
                </div>

            </div>
          </div>
     
      <!-- end row  -->
      </div> 
    </div>
    </div>
  </div>
<ng-template #termsModal let-c="close" let-d="dismiss">
      <div class="modal-header" >
        <div style="text-align: left">
          <span><i class="fal fa-file-contract fa-lg"></i></span><span style="font-size: 15pt; padding-left: 10px">Terms and Agreements</span>
        </div>
        <button type="button" class="close" (click)="d('Cross click')">&times;</button>
      </div>
      <div class="modal-body" style="padding: 25px !important;">
        <ul ngbNav #nav="ngbNav" class="nav-pills">
          <li ngbNavItem>
            <a ngbNavLink>Terms of Service</a>
            <ng-template ngbNavContent>
                
                  <!-- <pdf-viewer 
                    src="../../assets/pdf/Carve Inc. - Terms of Use.pdf"
                    [render-text]="true"
                    [original-size]="false"
                    (pages-initialized)="pageInitialized($event)"
                  ></pdf-viewer> -->
                  <iframe src="../../assets/pdf/Carve Inc. - Terms of Use.pdf" style="width: 1100px;height: 400px;border: 1px solid black;"></iframe>

                  <!-- <object data="./../assets/pdf/Carve Inc. - Terms of Use.pdf" width="1000px" height="400px" type='application/pdf'> -->
                   <!-- <p>Sorry, the PDF couldn't be displayed :(</p>
                   <a href="./../assets/pdf/Carve Inc. - Terms of Use.pdf" target="_blank">Click Here</a> -->
                 <!-- </object> -->
                
              
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>Privacy Policy</a>
            <ng-template ngbNavContent>
               <iframe src="../../assets/pdf/Carve Inc. - Privacy Policy.pdf" style="width: 1100px;height: 400px;border: 1px solid black;"></iframe>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" style="height: 400px;"></div>
      </div>
  </ng-template>
</div>

