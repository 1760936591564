import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/api/api.service';
import { AuthService } from '../../shared/auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router, CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, RouterModule} from "@angular/router";
import { AppService } from '../../app.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DateAgoPipe } from '../../shared/pipes/date-ago.pipe';


import 'codemirror/mode/javascript/javascript.js';


@Component({
  selector: 'app-package-variable-templates',
  templateUrl: './package-variable-templates.component.html',
  styles: [
  ]
})
export class PackageVariableTemplatesComponent implements OnInit {
  
  page = 'package_configurations'
  url: string = environment.url;
  headers: HttpHeaders;
  toastContainer: ToastContainerDirective;
  @ViewChild('configCode') configCode: ElementRef;
  
  codeConfig = {
      lineNumbers: true,
      mode: {
        name: "javascript",
        json: true,
        statementIndent: 2
      },
      readOnly: false,
      tabSize: 4
  }

  jsonError = ""
  fromJSONCode = '';

  defaultEnvs = ['DEV', 'STAGE', 'PROD']

  environmentVars: any = {
      configuration_environments: ['DEV', 'STAGE', 'PROD'],
      configuration_variables: []
  }

  fromJSON = false;

  configurations = [];
  configurationsOG = [];

  newVariableTemplate = {
    id: null,
    key: '', 
    environments: {
      'DEV': '',
      'STAGE': '',
      'PROD': ''
    },
    is_secure: false,
    is_optional: false
  }


  jsonTemplate = {
    "configuration_environments": ["DEV", "STAGE", "PROD"],
    "configuration_variables": [
      {
        "key": '[KEY NAME HERE]', 
        "environments": {
          'DEV': '[DEV VALUE HERE]',
          'STAGE': '[STAGE VALUE HERE]',
          'PROD': '[PROD VALUE HERE]'
        },
        "is_secure": false,
        "is_optional": false
      }
     ]
  }

  configurationChanges = {
    'new': [], // entire variable object
    'updated': [],// should include all environments or other settings
    'deleted_variables': [], // {'key': ''}
    'deleted_environments': [] // 'TEST_ENV'
  };

  newEnv = '';
  newVariableError = '';
  configurationError = '';
  newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
  configurationForm;
  saving = false;
  deleting = false;
  search = '';
  updateConfig = false;
  configuration = null;
  configurationOG = null;
  deleteMessage = '';
  configurationFields: any;
  updatingConfig: boolean = false;

  constructor(
     private fb: FormBuilder,
     private modalService: NgbModal,
     private auth: AuthService,
     private http: HttpClient,
     private router: Router,
     private appService: AppService,
     public toastrService: ToastrService,
     private api: ApiService,
     private loadingBar: LoadingBarService
  ) { 
     this.configurationForm = this.newconfigurationForm();
     this.configurationFields = {
        "configuration_name": "default",
        "created_date": "default",
        "last_updated_date": "default",
        "last_updated_by_username": "default"
      }
      this.appService.pageTitle = 'Package Configurations';
      this.headers = this.auth.getHeaders()
     
  }

  ngOnInit(): void {
    this.getData();
    this.fromJSONCode = JSON.stringify(this.jsonTemplate, null, 2)
  }


  getData(){
      let configLoader = this.loadingBar.useRef('configLoad')
      configLoader.start()
      configLoader.set(35)
      let url = this.url + '/packages/configurations/global'
       this.api.getData(url, this.page, 'configurations').subscribe(
        data=>{
          if('status' in data){
            if(data['status'] == 'success'){
              this.configurations = data['message']
              console.log(this.configurations)
              configLoader.set(75)
              this.configurationsOG = JSON.parse(JSON.stringify(this.configurations))
              configLoader.complete()
            }
          }    
        }
      )
  }

  removeEnv(env){
    var index = this.environmentVars.configuration_environments.indexOf(env);
    this.environmentVars.configuration_environments.splice(index, 1);

    for(let v of this.environmentVars.configuration_variables){
      delete v['environments'][env];
    }
    if('configuration_id' in this.environmentVars){
      this.configurationChanges.deleted_environments.push(env);
    }
  }

  resetEnvironmentVars(){
   this.environmentVars = {
        configuration_environments: ['DEV', 'STAGE', 'PROD'],
        configuration_variables: []
    }
    this.newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
  }

   newconfigurationForm(): FormGroup{
    return this.fb.group({
        configuration_name: ['', Validators.required]
      })
  }

  loadConfigurationForm(configuration): FormGroup{
    return this.fb.group({
        configuration_name: [configuration.configuration_name, Validators.required]
      })
  }

  loadEnvironmentVars(configuration){
    this.environmentVars = {
        configuration_environments: Object.keys(configuration.configuration_variables[0].environments),
        configuration_variables: configuration.configuration_variables
    }
  }

  getPlaceholder(){
    if(this.newVariableError.length > 0){
      return this.newVariableError
    }
    return 'Variable Name'
  }

  addEnv(){
    
    let env = this.newEnv;
    if(env == ''){
      console.log('No env value submitted')
      return;
    }
    if(env in this.environmentVars.configuration_environments){
      console.log('duplicate environment')
      // set an error value
      return
    }
    this.environmentVars.configuration_environments.push(env)
    for(let v of this.environmentVars.configuration_variables){
      v['environments'][env] = ''
    }
    this.newVariable.environments[env] = '';
    this.newEnv = '';
  }

  addVariable(){
    this.newVariableError = ''
    if(this.newVariable.key == '' || this.newVariable.key == null){
      this.newVariableError = 'ENTER VARIABLE NAME'
      return
    }

    for(let v of this.environmentVars.configuration_variables){
      if(v['key'] == this.newVariable.key){
        this.newVariableError = 'DUPLICATE KEY!'
        return
      }
    }

    // remove any invalid environments
    for(let env in this.newVariable.environments){
      console.log('looking for env: ', env, ' in ', this.environmentVars.configuration_environments)
      let in_var = true ? this.environmentVars.configuration_environments.includes(env) : false;
      console.log('is env in master envs: ', in_var)
      if(!(in_var)){
        delete this.newVariable.environments[env];
      }
    }
    this.environmentVars.configuration_variables.push(JSON.parse(JSON.stringify(this.newVariable)))
    this.newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
  }

 
  removeVariable(variable){
    var index = this.environmentVars.configuration_variables.indexOf(variable);
    this.environmentVars.configuration_variables.splice(index, 1);
    if('configuration_id' in this.environmentVars){
      if(variable.id != null){
        this.configurationChanges.deleted_variables.push({id: variable.id});
      } 
    }
  }

  configurationFormControl(){
    return this.configurationForm.controls;
  }

  delete(confirmed=false){
    
    if(confirmed != false){
      this.deleting = true;

      let headers = this.headers
      let url = this.url + '/packages/configurations/global' + this.configuration.configuration_id;
      this.http.delete(url, {headers}).subscribe(
          data=>{ 
             console.log(data)
             if(data['status'] == 'success'){
                   this.deleting = false;
                   this.toastrService.success('Configuration Deleted Successfully')
                   let index = this.configurations.findIndex(this.configuration)
                   this.configurations.splice(index)
             }
             this.configurationForm.reset();
             this.newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
             this.resetEnvironmentVars();
             this.resetConfig();
             this.modalService.dismissAll();
          },
          err=>{
            this.toastrService.error('Configuration not deleted')
            this.deleting = false;
            this.modalService.dismissAll();
          }
      )
      this.deleteMessage = '';
    }
    else{
      this.deleteMessage = 'This configuration will be permanently deleted. Are you sure?'
    }
  }

  checkUniqueKeys(){
    let vList = [];
    for(let v of this.environmentVars.configuration_variables){
      if(vList.includes(v.key)){
        console.log('Key not unique!')
        return false
      }
      vList.push(v.key)
    }
    return true;
  }

  save(){
    this.saving = true;

    console.log('saving ', this.environmentVars)
    if(this.environmentVars.configuration_variables.length > 0 && (this.configurationForm.valid || this.configurationForm.controls.configuration_name.value.length > 0 )){


      if(!('configuration_id' in this.environmentVars)){
        if(!(this.checkUniqueKeys())){
          this.configurationError = 'Environment keys are not unique!'
          return
        }

        // Save the configuration
        let configData = {
          configuration_name: this.configurationForm.controls.configuration_name.value,
          configuration_environments: this.environmentVars.configuration_environments,
          configuration_variables: this.environmentVars.configuration_variables
        }
        
        let headers = this.headers
        let url = this.url + '/packages/configurations/global';
        this.http.post(url, configData, {headers}).subscribe(
            data=>{ 
               console.log(data)
               if(data['status'] == 'success'){
                     this.saving = false;
                     this.toastrService.success('Configuration Created Successfully')
                     let newConfiguration = data['message'];

                     this.configurations.push(newConfiguration)

                     console.log('Pushed: ', newConfiguration, '. To: ', this.configurations)
               }

               this.configurationForm.reset();
               this.newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
               this.resetEnvironmentVars();
               this.resetConfig();
               this.modalService.dismissAll();
            },
            err=>{
              this.toastrService.error('Configuration not created')
              this.saving = false;
              this.modalService.dismissAll();
            }
        )
      }
      else{

        if(!(this.checkUniqueKeys())){
          this.configurationError = 'Environment keys are not unique!'
          return
        }
        for(let newVar of this.environmentVars['configuration_variables']){
          if(newVar.id == null){
            this.configurationChanges.new.push(newVar)
          }
        }

        // Figure out all of the updated variables by comparing the OG environment variables and latest
        for(let ogVar of this.configurationOG['configuration_variables']){
          // Find the match of the new variable list
          for(let newVar of this.environmentVars['configuration_variables']){
            if(ogVar['id'] == newVar['id']){
              let environments = {};
              let changed = false;

              // Find all the environment value differences
              for(let key in ogVar['environments']){

                // Check for changes with existing environments
                if(key in newVar['environments']){
                  if(ogVar['environments'][key] != newVar['environments'][key]){
                    environments[key] = JSON.parse(JSON.stringify(newVar['environments'][key]));
                    changed = true;
                  }
                }
              }
              // find the new environments
              for(let key in newVar['environments']){
                if(!(key in ogVar['environments'])){
                  environments[key] = JSON.parse(JSON.stringify(newVar['environments'][key]));
                  changed = true;
                }
              }
              let updatedVar = JSON.parse(JSON.stringify(newVar))
              if(changed){
                updatedVar['environments'] = environments;
                this.configurationChanges.updated.push(updatedVar)
              }
              else{
                if(ogVar['is_optional'] != newVar['is_optional']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
                else if(ogVar['is_secure'] != newVar['is_secure']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
                else if(ogVar['key'] != newVar['key']){
                  updatedVar['environments'] = {};
                  this.configurationChanges.updated.push(updatedVar)
                }
              }
            }
          }
        }

        let configData = {
          configuration_environments: this.environmentVars['configuration_environments'],
          configuration_changes: this.configurationChanges
        }

        let headers = this.headers
        let url = this.url + '/packages/configurations/global/' + this.environmentVars['configuration_id'];
        this.http.put(url, configData, {headers}).subscribe(
            data=>{ 
               console.log(data)
               if(data['status'] == 'success'){
                     this.saving = false;
                     this.toastrService.success('Configuration Updated Successfully')
                     let updatedConfiguration = data['message'];
                     // replace the updated configuration
                     let configIndex = this.configurations.findIndex((obj => obj.configuration_id == this.environmentVars['configuration_id']));
                     this.configurations[configIndex] = updatedConfiguration;
               }
               this.configurationForm.reset();
               this.newVariable = JSON.parse(JSON.stringify(this.newVariableTemplate));
               this.resetEnvironmentVars();
               this.resetConfig();
               this.modalService.dismissAll();
            },
            err=>{
              console.log('Error: ', err)
              this.toastrService.error('Configuration not updated')
              this.saving = false;
              this.modalService.dismissAll();
            }
        )
      }
      
    }
    else{
      this.saving = false;
    }
  }

  cancel(){
    this.resetEnvironmentVars();
    this.resetConfig();
  }

  resetConfig(){
     this.configuration = null;
     this.configurationOG = null;
     this.updateConfig = false;
     this.configurationForm.reset();
     this.modalService.dismissAll();
     this.saving = false;
     this.deleteMessage = '';
     this.configurationChanges = {
      'new': [], // entire variable object
      'updated': [],// should include all environments or other settings
      'deleted_variables': [], // {'key': ''}
      'deleted_environments': [] // 'TEST_ENV'
    };
    this.configurationError = '';
  }

  openNewConfigModal(content, options={}, configuration=null){
    
    if(configuration != null){
      console.log(configuration)
      this.environmentVars = JSON.parse(JSON.stringify(configuration));
      this.configurationOG = JSON.parse(JSON.stringify(this.environmentVars));
      this.updateConfig = true;
      this.configurationForm = this.loadConfigurationForm(configuration);
      this.configurationForm.controls.configuration_name.disable();
    
    }
    else{
      this.configurationForm.controls.configuration_name.enable();
    }
 
    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
        this.cancel();
      });
  }

  returnPackages(){
    this.router.navigateByUrl('/packages')
  }

  onSearchChange(searchValue : string ) {  
      if (searchValue != ''){
        this.configurations = this.searchConfigurations(searchValue);
      }
      else{
        this.clear()
      }
  }
  searchConfigurations(searchValue){
    let configurationIds = []
    let newConfigurations= []

    for(let i = 0;i < this.configurations.length;i++){
      let item = this.configurations[i]
      
      for(let property in item){
        if(item[property] !== null && property in this.configurationFields){
          let val = item[property].toLowerCase()
           if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
               if(!(configurationIds.includes(item.id))){
                 configurationIds.push(item.id)
                 newConfigurations.push(item)
               }
           }
        }
      }
    }
      
    return newConfigurations;

  }

  submitJSON(){
    let main_keys = ['configuration_environments', 'configuration_variables']
    let variable_keys = ['key', 'environments', 'is_secure', 'is_optional']
    this.jsonError = ''

    console.log('Checking JSON: ', this.fromJSONCode)

    // Check if valid json
    if(!this.isValidJSON(this.fromJSONCode)){
      this.jsonError = 'Invalid JSON'
      return 
    }

    let jsonObj = JSON.parse(this.fromJSONCode)

    // Check main keys 
    for(let key of main_keys){
      if(!(key in jsonObj)){
        this.jsonError = 'Missing root key value: ' + key
        return
      }
    }

    // Check for the environments
    if(jsonObj['configuration_environments'].length == 0){
      this.jsonError = 'No environments are defined'
      return
    }

   
    let all_keys = []
   
    // Check the keys for each variable
    let vars = jsonObj['configuration_variables']
    for(let i = 0; i < vars.length; i++){
      for(let k of variable_keys){
        if(!(k in vars[i])){
          this.jsonError = 'Missing attribute "' + k + '" at variable index ' + i
          return
        }
      }

      // Check for any duplicate keys
      if(vars[i]['key'] in all_keys){
        this.jsonError = 'Duplicate key found in configuration_variables list: "' + vars[i]['key'] + '"'
        return
      }
      else{
        all_keys.push(vars[i]['key'])
      }
      // Check that each variable has a value for each environment
      for(let env of jsonObj['configuration_environments']){
        if(!(env in vars[i]['environments'])){
          this.jsonError = 'Missing environment "' + env + '" at variable index ' + i
          return
        }
      }
    }    
    // If all the tests are passed, make the json the new object
    this.environmentVars = JSON.parse(JSON.stringify(jsonObj));
    this.fromJSONCode = JSON.stringify(this.jsonTemplate, null, 2)
    this.fromJSON = false;
  }

  isValidJSON (jsonString){
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
           return true
        }
    }
    catch (e) { }

    return false
  }

  isUpdating(environmentVars){
    let is = 'configuration_id' in environmentVars
    return is
  }
  clear(){
      this.configurations = Object.assign([],this.configurationsOG);
      this.search = '';
  }

  toggleJSON(){
    // If we have variables then load those
    if(this.environmentVars.configuration_variables.length > 0){
      this.fromJSONCode = JSON.stringify(this.environmentVars, null, 2)
    }
    this.fromJSON = !this.fromJSON;
  }
}
