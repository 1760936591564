<div class="container-fluid" style="background-color:white;">
	<div class="row flex-grow-1" style="padding-top: 24px; padding-bottom: 20px" >
		<div class="col-lg-3">
			<div class="card mb-2 shadow-hover" style="height: 100%" >
					<div class="card-body" style="padding:0px;">
						<div class="card-subtitle mb-2 card-subtitle-header">
						              <span class="fal fa-hdd overview-icon" style="color:#ff7171"></span>
						              <span class="text-big card-info-title">SIZE</span>
						          </div>
						            
				            	<div class="card-info-stacked" *ngIf="objectType == 'table'">
			                        
			                        <div class="text-center text-big">
			                        	<span>{{summary.table_total_size}} </span>
			                        	
			                        </div>
			                        <div class="text-muted text-center">Storage</div>
			                    </div>
				            	<div [ngClass]="{'card-info-stacked': objectType === 'table', 'card-info-stacked-1' : objectType === 'view' }">
			                        <div class="text-center text-big">
			                        	<span>{{summary.column_count | number:'1.0':'en-US' }}</span>
			                    		<span>{{summary.column_count_sum}}</span>
			                    	</div>
			                        <div class="text-muted text-center">Columns</div>
			                    </div>
				            	<div class="card-info-stacked" *ngIf="objectType == 'table'">
			                        <div class="text-center text-big">
			                        	<span>{{summary.table_row_count | number:'1.0':'en-US' }}</span>
			                        	<span>{{summary.table_row_count_sum}}</span>
			                        </div>
			                        <div class="text-muted text-center">Rows</div>
			                    </div>
			           		
					</div>
			</div>
		</div>
		<div class="col-lg-3">
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;">
					        <div class="card-subtitle mb-2 card-subtitle-header"> 
					              <span class="fal fa-heart-rate overview-icon" style="color:#3dce77; margin-left: -20px;"></span>
					              <span class="text-big card-info-title">HEALTH</span>
					          </div>
			            			
		            	 	<div class="card-info-stacked ">
			                    
                                <div *ngIf="summary.has_invalid_constraint_or_key != 'True' && summary.has_invalid_constraint_or_key != '...'" class="text-center text-big">
                                	<i class="fa-light fa-circle-check" style="color: green;padding-right: 3px;"></i>
                                	None
                                	</div>
                                <div *ngIf="summary.has_invalid_constraint_or_key == 'True'" class="text-center text-big"><i class="fa-light fa-circle-exclamation" style="color: red;padding-right: 3px;"></i>Detected!</div>
                                 <div *ngIf="summary.has_invalid_constraint_or_key == '...'">...</div>
                                 <div class="text-muted text-center">Duplicates</div>
		                    </div>
		                   
			            	<div class="card-info-stacked" >
                     
                                <div *ngIf="summary.has_row_growth_outside_stdev != 'True' && summary.has_row_growth_outside_stdev != '...'" class="text-center text-big">
                                	<i class="fa-light fa-circle-check" style="color: green;padding-right:3px;"></i>Normal
                                </div>
                                <div *ngIf="summary.has_row_growth_outside_stdev == 'True'" class="card-info-warning"><i class="fa-light fa-circle-exclamation" style="color: red;padding-right: 3px;"></i>Outside StDev!</div>
                                <div *ngIf="summary.has_row_growth_outside_stdev == '...'">...</div>
                                <div class="text-muted text-center">Table Growth</div>
		                    </div>
		                     <div class="card-info-stacked">
		                        <div *ngIf="summary.last_key_validation_date == 'Mon, 01 Jan 1900 00:00:00 GMT'" class="text-center text-big">Never</div>
                                <div *ngIf="summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_key_validation_date != '' && summary.last_key_validation_date != '...'" class="text-center text-big">{{summary.last_key_validation_date | date:'shortDate'}}</div>
                                <div *ngIf="summary.last_key_validation_date == ''" class="text-big text-center">...</div>
		                        <div class="text-muted text-center">Last Checked</div>
		                    </div>
			            	
				</div>
			</div>
		</div> 
		<div class="col-lg-3" >
            <div class="card mb-2 shadow-hover" style="height: 100%">
                <div class="card-body" style="padding:0px;">
                    <div class="card-subtitle mb-2 card-subtitle-header"> 
                        <span class="fal fa-calendar-day overview-icon" style="color:#8c6daf; margin-left: -20px;"></span>
                        <span class="text-big card-info-title">DATES</span>
                    </div>
                    <div *ngIf="summary.loaded == false" class="card-info-stacked-1" style="font-size: 24px;">...</div>

	            			
            	 	<div *ngIf="summary.loaded && summary.created != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.created != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                        <div *ngIf="summary.created != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.created != ''" class="text-big text-center">{{summary.created | dateAgo }}</div>
                        <div *ngIf="summary.created != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.created != null && summary.created != ''" class="text-muted text-center">Created</div>
                    </div>

                        <div *ngIf="summary.loaded && summary.last_queried != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_queried != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                            <div *ngIf="summary.last_queried != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_queried != ''" class="text-big text-center">{{summary.last_queried | dateAgo }}</div>
                            <div *ngIf="summary.last_queried != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_queried != null && summary.last_queried != ''" class="text-muted text-center">Last Queried</div>
                        </div>

                        <div *ngIf="summary.loaded && summary.last_updated != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_updated != null" [ngClass]="{'card-info-stacked-1': countSummaryDates()==1 || countSummaryDates()==0 , 'card-info-stacked-2': countSummaryDates()==2,  'card-info-stacked-3': countSummaryDates()==3, 'card-info-stacked-4': countSummaryDates()==4 }">
                            <div *ngIf="summary.last_updated != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_updated != ''" class="text-big text-center">{{summary.last_updated | dateAgo }}</div>
                            <div *ngIf="summary.last_updated != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.last_updated != null && summary.last_updated != ''" class="text-muted text-center">Last Updated</div>
                        </div>			            
					
				</div>
			</div>
		</div>
		<div class="col-lg-3" >
			<div class="card mb-2 shadow-hover" style="height: 100%">
				<div class="card-body" style="padding:0px;" >
					<div class="card-subtitle mb-2 card-subtitle-header"> 
					              <span class="fal fa-sitemap overview-icon" style="color:#ce7d00; margin-left: -20px;"></span>
					              <span class="text-big card-info-title">INFO</span>
					          </div>
		            			
	            	 	<div [ngClass]="{'card-info-stacked': objectType === 'table', 'card-info-stacked-2' : objectType === 'view' }">
		                        
		                        <div *ngIf="objectType == 'table'" class="text-big text-center">{{summary.table_type}}</div>
		                        <div *ngIf="objectType == 'view'" class="text-big text-center">View</div>
		                        <div class="text-muted text-center">Type</div>
		                    </div>
			            	<div  *ngIf="objectType == 'table'"  class="card-info-stacked" >
		                        <div class="text-big text-center">{{summary.time_travel}}</div>
		                        <div class="text-muted text-center">Time Travel</div>
		                    </div>
			            	<div [ngClass]="{'card-info-stacked': objectType === 'table', 'card-info-stacked-2' : objectType === 'view' }">
		                       
                                <div *ngIf="objectType == 'table'" class="text-center">{{summary.table_owner}}</div>
                                 <div *ngIf="objectType == 'view'" class="text-center">{{summary.view_owner}}</div>

                                <!--  <div *ngIf="summary.last_key_validation_date != 'Mon, 01 Jan 1900 00:00:00 GMT' && summary.table_owner.length > 7" class="text-big text-center" ngbTooltip="{{summary.table_owner}}">{{shortenText(summary.table_owner)}}</div>
 -->
		                        <div class="text-muted text-center">Owner</div>
		                    </div>			            
				
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="objectType == 'table'" class="row" style="height:360px; margin-top: 24px;">
		<div class="col-lg-12 my-auto align-self-center" style="height:360px">
            <div class="card mb-4 card-block shadow-hover" style="height:360px">
            	<ngx-loading-bar fixed [includeSpinner]="false" color="#01bccb" ref="chart1"></ngx-loading-bar>
            	<div class="card-subtitle text-muted card-subtitle-header" style="margin: 0px">
	              <span class="text-big card-info-title">Table Growth in Rows per Day</span>
	          </div>
            	<div class="card-body" style="height:300px; padding: 0px">
	                <div [hidden]="growthLoaded ? false : true" id="growthChartDiv"   style="height:300px; padding: 15px">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" style="height:500px; margin-top: 24px;">
		<div class="col-lg-6" style="height: 500px">
			<div class="card mb-4 shadow-hover" style="height: 500px">
				<ngx-loading-bar fixed [includeSpinner]="false" color="#1900df" ref="chart3"></ngx-loading-bar>
				<div class="card-subtitle text-muted card-subtitle-header">
					
	              <span class="text-big card-info-title">Top 5 Users in the Last 30 days by Query Count</span>
	          </div>
				<div class="card-body" style="height:440px; padding: 0px;">
					<div [hidden]="queriesLoaded ? false : true" id="queryChartDiv"  style="height: 440px;padding: 15px">
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="objectType == 'table'" class="col-lg-6" style="height: 500px">
			<div class="card mb-4 shadow-hover" style="height: 500px">
				<ngx-loading-bar fixed [includeSpinner]="false" color="#00dfac" ref="chart2"></ngx-loading-bar>
				<div class="card-subtitle text-muted card-subtitle-header"  style="margin: 0px">
	              <span class="text-big card-info-title">Storage by Day</span>
	          </div>
				<div class="card-body" style="height:440px; padding: 0px">
					<div [hidden]="storageLoaded ? false : true" id="storageChartDiv" style="height: 440px;padding: 15px">
					</div>
				</div>
			</div>
		</div>
		<div  *ngIf="objectType == 'view'" class="col-lg-6" style="height: 500px">
			<div class="card mb-4 shadow-hover" style="height:500px">
				<ngx-loading-bar fixed [includeSpinner]="false" color="#00aadf" ref="chart4"></ngx-loading-bar>
				<div class="card-subtitle text-muted card-subtitle-header">
	              <span class="text-big card-info-title">Total Queries by Day</span>
	          </div>
				<div class="card-body" style="height:440px; padding:0px">
					<div [hidden]="ddlLoaded ? false : true" id="DDLQueryChartDiv" style="height:440px;padding: 15px"></div>
				</div>

			</div>
		</div>
	</div>
	<div *ngIf="objectType == 'table'" class="row" style="height:420px; margin-top: 24px;"  >
		<div class="col-lg-12" style="height:370px">
			<div class="card mb-4 shadow-hover" style="height:370px">
				<ngx-loading-bar fixed [includeSpinner]="false" color="#00aadf" ref="chart4"></ngx-loading-bar>
				<div class="card-subtitle text-muted card-subtitle-header">
	              <span class="text-big card-info-title">Total Queries by Day</span>
	          </div>
				<div class="card-body" style="height:320px; padding:0px">
					<div [hidden]="ddlLoaded ? false : true" id="DDLQueryChartDiv" style="height:320px;padding: 15px"></div>
				</div>

			</div>
		</div>
	</div>
	
</div>




