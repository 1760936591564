import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { PackagesComponent } from '../packages/packages.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { PackageNewComponent } from './package-new/package-new.component';
import { RouterModule } from '@angular/router';
import { PackageVariableTemplatesComponent } from './package-variable-templates/package-variable-templates.component';
import { SharedModule } from '../shared/shared.module';
import { CodemirrorModule } from 'ng2-codemirror';

@NgModule({
  
  declarations: [PackagesComponent, PackageNewComponent, PackageVariableTemplatesComponent ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ToastrModule, 
    ToastContainerModule,
    BrowserAnimationsModule,
    NgSelectModule,
    LoadingBarModule,
    SharedModule,
    CodemirrorModule
  ]
})
export class PackagesModule { }
