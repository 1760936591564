import { 
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  HostBinding,
  ElementRef,
  ViewChild,
  NgZone,
  HostListener
} from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import { MenuService } from '../../shared/menu/menu.service';
import { CatalogService } from '../../data/catalog/catalog.service';
import { Observable, Subscription, forkJoin} from 'rxjs';
import { NgbTooltipModule, NgbTooltip, NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { trigger, transition, animate, style } from '@angular/animations'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/auth/auth.service';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import 'codemirror/mode/sql/sql';
import { EditorService } from '../../editor/editor.service'
import { ApiService } from '../../shared/api/api.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }'],
  styleUrls: ['../../../vendor/libs/spinkit/spinkit.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('200ms ease-in', style({transform: 'translateY(100%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(100%)'}))
      ])
    ])
   ]
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidenavComponent implements OnInit, AfterViewInit {
  @Input() orientation = 'vertical';
  @HostBinding('class.layout-sidenav') private hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') private hostClassFlex = false;
  @HostListener('scroll', ['$event'])
  
  //@ViewChild('level1') level1: ElementRef;
  subscription: Subscription;
  catalogSubscription: Subscription;
  columnsSubscription: Subscription;
  menuItems: Array<any> = [];
  columns: Array<any> = [];
  columnNames: Array<any> = [];
  menuItemsBackup: Array<any> = [];
  loading: Boolean = true;
  excludes: Array<any> = [];
  level: String = '';
  searchList: Object = {};
  selectedItem = {
    meta: null
  };
  needConnection: Boolean = false;
  newPage: Boolean = false;
  routeChange: boolean = false;
  navStart: String = '';
  navEnd: String = '';
  prevEnd: String = '';
  search = ''
  direction = 'down'
  isExplore: Boolean = false;
  showNav: Boolean = false;
  refreshing: Boolean = false;
  showColumns: Boolean = false;
  showDatabase: Boolean = false;
  showSchema: Boolean = false;
  setTime = null;
  isCollapsed: Boolean = false;
  hasCatalog: boolean = true;
  closeCatalogWarning: boolean = false;
  scripts = [];
  tableScripts = {};
  tableScriptsLoading: boolean = false;
  columnsLoading: boolean = false;
  object: any = {
    refreshing: false
  }
  url: string = environment.url;
  script = {}
  loadingTables: Boolean = false;
  opened = [];
  nextLoading: Boolean = false;
  schemaSearching: Boolean = false;
  isSetup: Boolean = true;
  headers: HttpHeaders;


  constructor(
    private router: Router,
    private appService: AppService,
    private layoutService: LayoutService,
    public menu: MenuService,
    public catalog: CatalogService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    public auth: AuthService,
    private modalService: NgbModal,
    private editorService: EditorService,
    private http: HttpClient,
    private api: ApiService
    ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
    this.excludes = ['Tables', 'Views']
    this.level = 'Main'
    this.searchList = {"count": -1}
    this.headers = this.auth.getHeaders();
    this.isSetup = true;

    router.events.subscribe(event => {


      if (event instanceof NavigationStart) {
        this.navStart = event.url
      }
      if (event instanceof NavigationEnd){
        this.prevEnd = this.navEnd;
        this.navEnd = event.url;
          
        if(this.navEnd.indexOf('explore') > 0 || this.navEnd.indexOf('query') > 0 ){
          this.showNav = true;
          if(this.navEnd.indexOf('explore') > 0){
             this.isExplore = true;
             let schemaObj = this.parseUrl(this.navEnd)
              if(schemaObj){
                this.catalog.getObjects(schemaObj, false);
              }
          }
          else{
            this.showColumns=  false;
            this.isExplore = false;
          }
        }
        else{
          this.showNav = false;
          this.isExplore = false;
        }
      }


      this.scripts = [
        {display: 'DDL', key: 'ddl', class: "fa-light fa-brackets-round"},
        {display: 'SELECT', key: 'select', class: "fa-light fa-table-list"},
        {display: 'INSERT', key: 'insert', class: "fa-light fa-list-check"},
        {display: 'UPDATE', key: 'update', class: "fa-light fa-pen-to-square"},
        {display: 'DELETE', key: 'delete', class: "fa-light fa-trash-list"},
        {display: 'MERGE', key: 'merge', class: "fa-light fa-merge"}
      ]
      

      //console.log('nav start: ', this.navStart);
      //console.log('nav end: ', this.navEnd);
      //console.log('prev end: ', this.prevEnd);

     

      this.routeChange = this.navStart != this.navEnd || this.navStart != this.prevEnd ? true : false;
    });

    //console.log(this.menu.observableMenu$)
    
    //console.log(this.menu.getMenu())
   
  }

  ngOnInit(){

    // Compare catalog last updated dates 
    // console.log('init sidenav')
    let url = this.url + '/catalog/setup'
    this.api.getApiData(url, []).subscribe(
      data=>{
        if(data['message']=="true" || data['message']==true){
          this.isSetup = true;
        }
        else{
          this.isSetup = this.auth.isSetup();
        }
      }, 
      err=>{
         this.isSetup = this.auth.isSetup();
      }
    )
    this.hasCatalog = this.catalog.menuItems.length > 0;
   
    
 
    // this.catalog.getColumns().subscribe((data:any)=>{

    //   // console.log('column data: ', data)
    //     if('message' in data){
    //         this.columns = data['message'];
    //         this.catalog.storeColumns(this.columns);
    //     }
    //     else{
    //         this.columns = data;
    //     }
    // });

    // this.catalog.getCatalog().subscribe((data:any)=>{
    //   // console.log(data)
    //   if(data == undefined){
    //     let menuUrl = this.url + '/catalog/menu'
    //     console.log('Getting fresh catalog')
    //     this.api.getApiData(menuUrl, []).subscribe(
    //         data=>{
    //             this.menuItems = data['message'];
    //             this.catalog.storeCatalog(this.menuItems);
    //         }  
    //     )
    //   }
    //   else{
    //       console.log('Getting cached catalog')
    //       this.menuItems = data
    //   }
    // })
  }

  ngAfterViewInit() {
    // Safari bugfix
   //var catalogObj = this.catalog.getCachedCatalog();
    //console.log(catalog)
    // this.catalogSubscription = this.catalog.getCatalogReplay().subscribe(
    //       // Check if the editor is being used 
    //       data=>{
    //          if(this.catalog.isEditor){
    //               console.log('user catalog: ', data)
    //               this.catalog.menuItems = data;
    //               let schemaObj = this.parseUrl(this.navEnd)
    //               if(schemaObj){
    //                 this.getObjects(schemaObj, false);
    //               }
    //               else{
    //                 console.log('No schema obj - not getting objects')
    //               }
    //           }
    //           else{
    //             this.catalog.menuItems = data;
    //             console.log('explorer catalog: ', data)
    //             // console.log('last updated does not match, getting fresh catalog.')
    //             this.catalog.storeCatalog(this.menuItems);
    //             let schemaObj = this.parseUrl(this.navEnd)
    //             if(schemaObj){
    //               this.getObjects(schemaObj, false);
    //             }else{
    //                   console.log('No schema obj - not getting objects')
    //                 }
    //           }
    //       },
    //       err=>{

    //       }
    //   );

    // this.catalog.getLastUpdated().subscribe((data:any)=>{
    //   // console.log(data)
    //   let catalogLastUpdate = data['message']['catalog_last_update'];
    //   let lastUpdated = sessionStorage.getItem("catalog_last_update");
    //   if(catalogLastUpdate == null )  this.hasCatalog = false;

    //   // console.log('frontend catalog last update: ', lastUpdated, '. backend catalog last update: ', catalogLastUpdate)
    //   if(lastUpdated != catalogLastUpdate || this.catalog.isEditor){
    //     sessionStorage.setItem("catalog_last_update", catalogLastUpdate);
    //     if(this.catalog.isEditor && this.catalog.menuSettings == {}){
    //       this.needConnection = true;
    //       return
    //     }
    //     else{
    //       this.needConnection = false; 
    //     }
    //   }
    //   else{
    //     this.catalog.getCatalog().subscribe((data:any)=>{
    //       //console.log('finished loading catalog: ', data)
    //       let schemaObj = this.parseUrl(this.navEnd)
    //       console.log(this.navEnd)
    //       if(data == undefined){
    //         this.catalog.getFreshCatalog().subscribe((data:any)=>{
    //           // console.log('Getting fresh catalog')
    //           this.catalog.setCatalogReplay(data['message'])
    //           if(schemaObj){
    //             this.getObjects(schemaObj, false);
    //           }
    //           else{
    //             console.log('No schema obj - not getting objects')
    //           }
    //         })
    //       }
    //       else if('message' in data){
    //           this.catalog.setCatalogReplay(data['message'])
    //           if(schemaObj){
    //             this.getObjects(schemaObj, false);
    //           }else{
    //             console.log('No schema obj - not getting objects')
    //           }
    //       }
    //       else{
    //           // console.log(data)
    //           // console.log('Using cache for catalog!')
    //           this.catalog.setCatalogReplay(data)
    //           if(schemaObj){
    //             this.getObjects(schemaObj, false);
    //           }else{
    //             console.log('No schema obj - not getting objects')
    //           }
    //       }     
    //     });
    //   }
    // });
    
    if(this.showNav && !(this.isExplore)){
      this.isCollapsed = true;
      this.layoutService.setCollapsed(true, true);
    }
    this.layoutService._redrawLayoutSidenav();
    this.update()
  }

  parseUrl(url){
    if(url.indexOf("/tables") > 0 || url.indexOf("/views") > 0){

        let endStr = url.indexOf("/tables") > -1 ? "/tables"  : '/views'
        let database = url.substring(
          url.lastIndexOf("databases/") + 10, 
          url.indexOf("/schemas")
        ); 
        let schema = url.substring(
          url.lastIndexOf("schemas/") + 8, 
          url.indexOf(endStr)
        ); 

      console.log('schema: ', schema)

      if(schema == undefined){
        let schema = url.substring(
            url.lastIndexOf("schemas/") + 8, 
            url.indexOf("/views")
        ); 
      }
      return {database: database, name: schema}
    }
    return false
  }

  onScroll(event: any) {

      // visible height + pixel scrolled >= total height 
      if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
        this.direction = 'up'
        // change the class of which arrow is being pointed
      }
      else{
        this.direction = 'down'
      }
  }
  // getClasses() {
  //   let bg = this.appService.layoutSidenavBg;

  //   if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
  //     bg = bg
  //       .replace(' sidenav-dark', '')
  //       .replace(' sidenav-light', '')
  //       .replace('-darker', '')
  //       .replace('-dark', '');
  //   }

  //   return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  // }

  isActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  routeToConnections(){
    this.router.navigateByUrl('/settings/connections');
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
    this.isCollapsed = !this.isCollapsed;

    // layout-collapsed
    // sidenav-hover
  }

  async onSchemaSearchChange(schemaObj, searchValue){
    // while(this.searching){
    //   await this.sleep(100);
    // }
    this.schemaSearching = true;
    
    // if(searchValue.length > 2){
    //   this.schemaObjects(schemaObj, searchValue);
    // }
    // else if(searchValue == ''){
    //   this.schemaObjectsReset(schemaObj);
    // }
  }
  

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // schemaObjects(schemaObj, searchValue){
  //   let db = schemaObj.database
  //   let schema = schemaObj.name
  //   let key = db + "_" + schema
  //   this.loadingTables = true;

  //   let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.name == db));   
  //   let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.name == schema));
  //   this.catalog.getTablesViews(db, schema, false).subscribe(
  //      data=>{

  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu = [];
  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu = [];

  //           let tables = data['message']['tables'];
  //           let views = data['message']['views'];
           
             
  //           for(let t of tables){
  //             if(t.text.toLowerCase().includes(searchValue.toLowerCase())){
  //               this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
  //             }
  //           }

  //           //append all of the views
  //           for(let v of views){
  //             if(v.text.toLowerCase().includes(searchValue.toLowerCase())){
  //               this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.push(v)
  //             }
  //           }
  //           this.schemaSearching = false;
  //           this.loadingTables = false;
  //         },
  //         err=>{
  //           this.loadingTables = false;
  //         }
  //    );
    

  // }

  // schemaObjectsReset(schemaObj){
  //   let db = schemaObj.database
  //   let schema = schemaObj.name
  //   let key = db + "_" + schema
  //   this.loadingTables = true;
  //   this.search = '';

  //   let dbIndex = this.menuItems[0].submenu.findIndex((obj => obj.name == db));   
  //   let schemaIndex = this.menuItems[0].submenu[dbIndex].submenu[0].submenu.findIndex((obj => obj.name == schema));
  //   this.catalog.getTablesViews(db, schema, false).subscribe(
  //      data=>{

  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu = [];
  //           this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu = [];

  //           let tables = data['message']['tables'];
  //           let views = data['message']['views'];
           
  //           tables = tables.slice(0, 25)
  //           views = views.slice(0, 25)
             
  //           for(let t of tables){
  //             this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[0].submenu.push(t)
  //           }

  //           //append all of the views
  //           for(let v of views){
  //              this.menuItems[0].submenu[dbIndex].submenu[0].submenu[schemaIndex].submenu[1].submenu.push(v)
  //           }

  //           this.loadingTables = false;
            
  //         },
  //         err=>{
  //           this.loadingTables = false;
           
  //         }
  //    );
  // }

  

  getTables(database, schema):Observable<any>{
      let menuUrl = this.url + '/catalog/menu'
      let params = [
        {key: 'db', value: database}, 
        {key: 'schema', value: schema},
        {key: 'object_type', value: 'tables'}
      ]
      return this.api.getApiData(menuUrl, params);
  }
  getViews(database, schema):Observable<any>{
      let menuUrl = this.url + '/catalog/menu'
      let params = [
        {key: 'db', value: database}, 
        {key: 'schema', value: schema},
        {key: 'object_type', value: 'views'}
      ]
      return this.api.getApiData(menuUrl, params);
  }

  setActiveItem(item, index){
    var ts = Math.floor(Date.now() / 1000)
    var obj = {"index": index, "item": item, "timestamp": ts }
    this.menu.activeItem = obj;
  }

  buildText(item){
    return item['text'] + ' ('+ item['submenu'].length +')'
  }

  checkSubmenu(item){

    if ('submenu' in item){
      if (item['submenu'].length > 0){      
        return true;
      }
      else{
        return false;
      }
    }
    else {
      return false;
    }
  }

  getIcon(item){
    if(this.excludes.includes(item['text'])){
      return item['icon']
    }
    else{
      return ''
    }
  }

  getRoute(item){
    if(this.excludes.includes(item['text'])){
      return '/data/catalog'
    }
    else{
      return item['link']
    }
  }

  getClass(item){
    if(this.excludes.includes(item['text'])){
      return 'non-pointer'
    }
    else{
      return ''
    }
  }

  onSearchChange(searchValue : string ) {  
    if (searchValue == ''){
      this.searchList = {"count": -1}
    }
    else if(searchValue.length < 2){
      this.searchList = {"count": -1}
    }
    else{
      this.searchList = this.searchMenu(searchValue);
    }
    //console.log(this.searchList)
  }

  getMenu(){
    return this.menuItems;
  }

  searchMenu(searchValue){
    var searchList = {"count": 0}
    var databases = []
    var enabled_roles = []
    var object_privileges = []
    var schemas = []
    var functions = []
    var file_formats = []
    var stages = []
    var tables = []
    var views = []
    var pipes = []
    var sequences = []
    var columns = []
    var allTables = []
    var allViews = []
    
    for (let l1 of this.menuItems){
       if ('submenu' in l1){
         for (let l2 of l1['submenu'] ){
             if (l2.text.toLowerCase().includes(searchValue.toLowerCase())){
               if (l1.text == 'Databases'){
                 databases.push(l2)
               }
               else if (l1.text == 'Enabled Roles'){
                 enabled_roles.push(l2)
               }
               else if (l1.text == 'Object Privileges'){
                 object_privileges.push(l2)
               }
             }
            if ('submenu' in l2){
               for (let l3 of l2['submenu'] ){
                  if ('submenu' in l3){
                     for (let l4 of l3['submenu'] ){

                       if (l4.text.toLowerCase().includes(searchValue.toLowerCase())){
                             if (l3.text == 'Schemas'){
                               schemas.push(l4)
                             }
                             else if (l3.text == 'Functions'){
                               functions.push(l4)
                             }
                             else if (l3.text == 'File Formats'){
                               file_formats.push(l4)
                             }
                             else if (l3.text == 'Pipes'){
                               pipes.push(l4)
                             }
                             else if (l3.text == 'Sequences'){
                               sequences.push(l4)
                             }
                             else if (l3.text == 'Stages'){
                               stages.push(l4)
                             }
                           }
                       if ('submenu' in l3){
                         // Schemas, Functions, File Formats, etc
                         for (let l4 of l3['submenu'] ){
                            if ('submenu' in l4){
                               for (let l5 of l4['submenu'] ){
                                  if ('submenu' in l5){
                                     // Tables and Views
                                     for (let l6 of l5['submenu'] ){
                                        if (l6.text.toLowerCase().includes(searchValue.toLowerCase())){
                                           if (l5.text == 'Tables'){
                                             if (!tables.includes(l6)){
                                               l6['columns'] = []
                                               tables.push(l6)
                                             }
                                           }
                                           else if (l5.text == 'Views'){
                                             l6['columns'] = []
                                             views.push(l6)
                                           }
                                        }
                                        if (l5.text == 'Tables'){
                                          allTables.push(l6)
                                        }
                                        if (l5.text == 'Views'){
                                          allViews.push(l6)
                                        }
                                     }
                                   }
                               }
                             }
                         }
                       }
                     }
                   }
               }
            }
         }
       }
    }

     for(let col of this.columns){
      let parts = col.name.split('.'),
      db = parts[0],
      schema = parts[1],
      table = parts[2],
      name = parts[3]

      if (name.toLowerCase().includes(searchValue.toLowerCase())){
          let link = '';
          let search = '';
          let obj = {};
          let tType = this.getTableType(db, schema, table, allTables, allViews);
          if(tType && tType == 'table'){
            link = '/explore/databases/'+db+'/schemas/'+schema+'/tables/'+table+"/columns"
            obj = {"name": name, "database": db, "schema": schema, "table": table, "column": name, "link": link}
            search = db + '.' + schema + '.' + table
            if(tables.findIndex(x => x.name === table && x.database === db && x.schema === schema) === -1){
              let tIndex = allTables.findIndex(x => x.name === table && x.database === db && x.schema === schema)
              //console.log('tIndex of all Tables: ', tIndex)
              let tObj = allTables[tIndex]
              tObj['columns'] = []
              //console.log('added column key')
              tables.push(tObj)
            }
            let tIndex = tables.findIndex(x => x.name === table && x.database === db && x.schema === schema);
            //console.log('tIndex of added Tables: ', tIndex)
            if(!('columns' in tables[tIndex])){
               tables[tIndex]['columns'] = [];
               //console.log('added column key')
            }
            //console.log('Adding to object:', tables[tIndex])
            tables[tIndex]['columns'].push(obj)
          }
          else if(tType && tType == 'view'){
            link = '/explore/databases/'+db+'/schemas/'+schema+'/views/'+table+"/columns"
            obj = {"name": name, "database": db, "schema": schema, "table": table, "column": name, "link": link}
            search = db + '.' + schema + '.' + table
            if(views.findIndex(x => x.name === table && x.database === db && x.schema === schema) === -1){
              let tIndex = allViews.findIndex(x => x.name === table && x.database === db && x.schema === schema)
              //console.log('tIndex of all Views: ', tIndex)
              let tObj = allViews[tIndex]
              tObj['columns'] = []
              //console.log('added column key')
              views.push(tObj)
            }
            let tIndex = views.findIndex(x => x.name === table && x.database === db && x.schema === schema);
            //console.log('tIndex of added Tables: ', tIndex)
            if(!('columns' in views[tIndex])){
               views[tIndex]['columns'] = [];
            }
            //console.log('Adding to object:', tables[tIndex])
            views[tIndex]['columns'].push(obj)
          }
      }
    }

   
    searchList['count'] = (
      databases.length + enabled_roles.length + object_privileges.length + schemas.length
      + stages.length + tables.length + views.length + sequences.length + file_formats.length
      + pipes.length + functions.length
    )

    // console.log("dbs:",databases.length)
    // console.log("ers:",enabled_roles.length)
    // console.log("ops:",object_privileges.length)
    // console.log("schemas:",schemas.length)
    // console.log("stages:",stages.length)
    // console.log("dbs:",databases.length)
    // console.log("dbs:",databases.length)
    // console.log("dbs:",databases.length)
    // console.log("dbs:",databases.length)
    // console.log("dbs:",databases.length)
    // console.log("dbs:",databases.length)

    searchList['databases'] = databases
    searchList['enabledRoles'] = enabled_roles
    searchList['objectPrivileges'] = object_privileges
    searchList['schemas'] = schemas
    searchList['functions'] = functions
    searchList['fileFormats'] = file_formats
    searchList['stages'] = stages
    searchList['tables'] = tables
    searchList['views'] = views
    searchList['pipes'] = pipes
    searchList['sequences'] = sequences;
  
    return searchList;
  }

  getTableType(db, schema, table, tables, views){
    if(tables.findIndex(x => x.name === table && x.database === db && x.schema === schema) > -1){
      return 'table'
    }
    else if(views.findIndex(x => x.name === table && x.database === db && x.schema === schema) > -1){
      return 'view'
    }
    return false
  }

  restoreMenu(){
    return this.menuItemsBackup;
  }

  isDisabled(item){
    if ('submenu' in item){
      if (item['submenu'].length > 0){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return true;
    } 
  }

  isToggle(link){
    if(this.router.isActive(link, false)){
      //return ''
      return 'sidenav-toggle-alt'
    }
    else{
      return ''
    }
  }

  // Check if the menu is expanded
  isMenuElExpanded(menu) {
    if (!menu.toggleLink) return false
    return menu.toggleLink.nativeElement.parentNode.classList.contains('open')
  }

  // Handle close event
  onMenuClose (instance: any, itemEl: any, linkEl: any) {
    return this.zone.runOutsideAngular(() => {
      // If menu item has route param
      
      const isRoute = linkEl.getAttribute('href') !== '#' || itemEl.hasAttribute('data-route');
      if (!isRoute || this.zone.run(() => this.orientation !== 'vertical')) return true

      return new Promise(resolve => {
        // Wait a bit
        setTimeout(() => {
   
          // Count all opened menus of the direct parent element, close the menu if opened more than one
          let openedCount = instance._findChild(itemEl.parentNode, ['sidenav-item', 'open']).length
          if (openedCount >= 1 && !this.routeChange) resolve(true)

          // Prevent closing
          resolve(false)
        }, 20)
      })
    })
  }

  onRightClick(event, item){
      event.preventDefault()
      // console.log('Event: ', event)
      // console.log('Item: ', item)
  }

  triggerShowColumns(item){


    if(!item){
      this.showColumns = false;
      return;
    }
    
    let keep = false;
    
    if(this.selectedItem){
      if(this.selectedItem['name'] == item['name']){
        keep = false;
      }
      else{
        keep = true;
      }
      
    }

    this.selectedItem = item;
    // Get the Quickscripts

    this.getScripts(item)
    // Need to get list of columns
    let col_list = []
    this.selectedItem['columns'] = []
    this.columnsLoading = true;
    this.catalog.getColumns(item['database'], item['schema'], item['name'], false).subscribe(
       data=>{
          // console.log('columns: ',  data['message'])
          this.columnsLoading = false;
          for(let i in data['message']){
            data['message'][i]['link'] ='/explore/databases/'+item['database']+'/schemas/'+item['schema']+'/' +item['obj']+ '/'+item['name']+"/columns";
          }
          this.selectedItem['columns'] = data['message'];
          console.log(this.selectedItem)
          this.catalog.storeColumns(item['database'], item['schema'], item['name'], data)
       }
    );

    // for(let col of this.columns){
    //     let parts = col.name.split('.'),
    //     db = parts[0],
    //     schema = parts[1],
    //     objName = parts[2],
    //     name = parts[3]
    //     if(db == item['database'] && schema == item['schema'] && objName == item['name']){
    //       // We have a match! 
    //       let col_obj = {name: name, data_type: col.data_type}
    //       col_list.push(col_obj)
    //     }
    // }
   
    if(!keep){
      this.showColumns = !this.showColumns;
    }
    else{
      this.showColumns = true;
      this.showDatabase = false;
      this.showSchema = false;
    }
    
  }

  getScripts(item){
    this.tableScriptsLoading = true;
    if(this.catalog.isEditor){
      let headers = this.headers;
        let url = 'query_svc/catalog/menu/user/scripts';
        let data = {settings: this.catalog.menuSettings, db: item['database'], schema: item['schema'], table: item['name']};
        var config = {
          lineNumbers: true, mode: 'sql'
        }

        this.http.post(url, data, {headers}).subscribe(
          data=>{
            var output = data['message'] 
            console.log('scripts: ', output)
            var pre = ''
            var post = ''

            if('merge_statement' in output && output['merge_statement'] != null){
              pre = output['merge_statement'].split("[Insert Subquery or Table Name Here]")[0]
              post =  output['merge_statement'].split("[Insert Subquery or Table Name Here]")[1]
            }
            this.tableScripts['ddl']    = {"title":"Create DDL", "code": output['ddl_statement'], "config": config, "og_code":output['ddl_statement'], "class": "fa-light fa-brackets-curly" }
            this.tableScripts['select'] = {"title":"SELECT", "code": output['select_statement'], "config": config, "og_code":output['select_statement'], "class": "fa-light fa-table-list"}
            this.tableScripts['insert'] = {"title":"INSERT", "code": output['insert_statement'], "config": config, "og_code":output['insert_statement'], "class": "fa-light fa-list-check"}
            this.tableScripts['update'] = {"title":"UPDATE", "code": output['update_statement'], "config": config, "og_code":output['update_statement'], "class": "fa-light fa-pen-to-square"}
            this.tableScripts['merge']  = {"title":"MERGE", "code": output['merge_statement'], "config": config, "og_code": output['merge_statement'], "pre": pre, "post": post, "class": "fa-light fa-merge"}
            this.tableScripts['delete'] = {"title":"DELETE", "code": output['delete_statement'], "config": config, "og_code": output['delete_statement'], "class": "fa-light fa-trash-list"}
            this.tableScriptsLoading = false;
          },
          err=>{

          }
        )
    }
    else{
       var table_script_url = this.url + '/catalog/databases/' + item['database']
        table_script_url += '/schemas/' +  item['schema']
        table_script_url += '/tables/' + item['name'] + '/scripts'
        let headers = this.headers;
        var config = {
          lineNumbers: true, mode: 'sql'
        }

        
        this.http.get(table_script_url, {headers}).subscribe(
          data=>{
            // console.log('Quick Scripts: ', data['message'])
            var output = data['message'] 
            var pre = ''
            var post = ''

            if('merge_statement' in output && output['merge_statement'] != null){
              pre = output['merge_statement'].split("[Insert Subquery or Table Name Here]")[0]
              post =  output['merge_statement'].split("[Insert Subquery or Table Name Here]")[1]
            }
            this.tableScripts['ddl']    = {"title":"Create DDL", "code": output['ddl_statement'], "config": config, "og_code":output['ddl_statement'], "class": "fa-light fa-brackets-curly" }
            this.tableScripts['select'] = {"title":"SELECT", "code": output['select_statement'], "config": config, "og_code":output['select_statement'], "class": "fa-light fa-table-list"}
            this.tableScripts['insert'] = {"title":"INSERT", "code": output['insert_statement'], "config": config, "og_code":output['insert_statement'], "class": "fa-light fa-list-check"}
            this.tableScripts['update'] = {"title":"UPDATE", "code": output['update_statement'], "config": config, "og_code":output['update_statement'], "class": "fa-light fa-pen-to-square"}
            this.tableScripts['merge']  = {"title":"MERGE", "code": output['merge_statement'], "config": config, "og_code": output['merge_statement'], "pre": pre, "post": post, "class": "fa-light fa-merge"}
            this.tableScripts['delete'] = {"title":"DELETE", "code": output['delete_statement'], "config": config, "og_code": output['delete_statement'], "class": "fa-light fa-trash-list"}
            this.tableScriptsLoading = false;
        })

    }
    
  }

  openScriptModal(content, options={}, scriptType){
    this.script = this.tableScripts[scriptType]
    this.modalService.open(content, options).result.then((result) => {
      }, (reason) => {
    });
  }

  triggerShowDatabase(item){
    let setTime = Date.now();

    // Don't set if the propogation triggered this
    if(this.setTime == null){
      this.setTime = setTime;
    }
    else if ((setTime - this.setTime) < 1000){
      return;
    }
    // console.log('Showing Database!', item)
    if(!item){
      this.showDatabase = false;
      return;
    }

    this.showDatabase = true;
    this.showColumns = false;
    this.showSchema = false;
    
    let keep = false;
    
    if(this.selectedItem){
      if(this.selectedItem['name'] == item['name']){
        keep = false;
      }
      else{
        keep = true;
      }
      
    }
    console.log('selected new database')

    this.selectedItem = item;
    
  }

  triggerShowSchema(item){
    this.setTime = Date.now();
   
    if(!item){
      this.showSchema = false;
      return;
    }
    this.showSchema = true;
    this.showDatabase = false;
    this.showColumns = false;
    
    let keep = false;
    
    if(this.selectedItem){
      if(this.selectedItem['name'] == item['name']){
        keep = false;
      }
      else{
        keep = true;
      }
      
    }

    this.selectedItem = item;
    
  }

  update() {
    this.cd.detectChanges();
  }

  // Run change detector
  onMenuActionDone() {
    this.zone.run(() =>  this.layoutService._redrawLayoutSidenav());
  }

  clear(){
    this.searchList = {};
    this.search = '';
  }

  refresh(){
    this.refreshing = true;
    // Get Columns no matter what
    // this.catalog.getColumns().subscribe((data:any)=>{
    //   if('message' in data){
    //       this.columns = data['message'];
    //       this.catalog.storeColumns(this.columns);
    //   }
    //   else{
    //       this.columns = data;
    //   }
    // });
    this.catalog.menuItems = [];
    if(this.catalog.isEditor){
       this.catalog.getFreshCatalog();
    }
    else{
      this.catalog.getLastUpdated().subscribe((data:any)=>{

      // console.log(data)
      let catalogLastUpdate = data['message']['catalog_last_update'];
      let lastUpdated = sessionStorage.getItem("catalog_last_update");
    
      // console.log('frontend catalog last update: ', lastUpdated, '. backend catalog last update: ', catalogLastUpdate)
      sessionStorage.setItem("catalog_last_update", catalogLastUpdate);
      this.catalog.getFreshCatalog();
    });
    }
   
  }

  refreshSchema(item){
    // console.log('Refreshing Schema: ', item)
    this.object.refreshing = true;
    let data = { database: item.database, schema: item.name};
    let url = this.url + '/catalog/schema/refresh'
    let headers = this.headers;
    this.http.post(url, JSON.stringify(data),{headers}).subscribe(
       data => {
         this.object.refreshing = false;
         // RUn the refresh...
         this.refresh()
         // console.log('Refreshed Schema!', data)
     },
     err =>{
       this.object.refreshing = false;
     });
  }

  refreshDatabase(item){
    // console.log('Refreshing Database: ', item)
    this.object.refreshing = true;
    let data = { database: item.name};
    let url = this.url + '/catalog/database/refresh'
    let headers = this.headers;
    this.http.post(url, JSON.stringify(data),{headers}).subscribe(
       data => {
         this.object.refreshing = false;
         // RUn the refresh...
         // console.log('Refreshed Database!', data)
         this.refresh()
        
     },
     err =>{
       this.object.refreshing = false;
     });
  }

  openEditor(code){
      this.editorService.code = code;
      this.editorService.active = true;
      this.modalService.dismissAll();
      this.editorService.setCode(code);
      this.router.navigateByUrl('/query');
  }


  shortenText(text, type){
    var max = 30
    var div = 2.5
    if(type == 'search'){
      max = 42
    }
    else if (type == 'display'){
      max = 26
    }
    else if (type == 'menu'){
      max = 26
    }
    else if (type == 'column'){
      max = 22
    }
    var newText = text
    if(text.length > max){
      var len = text.length
      var toRemove = (len - max) + 3
      var start = (len - toRemove) / div
      var end = start+ toRemove
      newText = text.replace(text.substring(start, end), '...')
    }
    return newText;
  }

}