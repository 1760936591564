import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CodemirrorModule } from 'ng2-codemirror';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    NgbModule,
    CodemirrorModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class HomeModule { }
