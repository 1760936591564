import { environment } from '../../environments/environment';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbTooltip, NgbDropdown, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn ,AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router, CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot, RouterModule} from "@angular/router";
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApiService } from '../shared/api/api.service';
import { PackagesService } from '../packages/packages.service';


@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styles: [
  ]
})
export class PackagesComponent implements OnInit {
  url: string = environment.url;
  error: string;
  constructor(
     private router: Router,
     public toastrService: ToastrService,
     private api: ApiService,
     private loadingBar: LoadingBarService,
     public packageService: PackagesService
  ) { 
    this.error = '';
  }
  packages: Array <any> = new Array <any>();
  search = '';
  packagesOG = [];
  packageFields: any;
  page = 'packages'


  ngOnInit(): void {
    this.packageFields = {
        "package_name": "default",
        "package_size": "default",
        "package_last_status": "default",
        "package_version": "default"
    }
    this.getPackages();
  
  }

  // public uploadFileToServer(event) {
  //   let fileList: FileList = event.target.files;

  //   console.log(fileList)

  //   if(fileList[0]['type'] != 'application/zip'){
  //     this.error = 'File needs to be a zip file'
  //     return
  //   }

  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     let formData: FormData = new FormData();
  //     formData.append('uploadFile', file, file.name);
  //     formData.append('fileType', 'zip');
  //     let headers = new Headers();
  //     headers.append('Accept', 'application/json');
  //   }
  // } 

  getPackages(){
     let packageLoader = this.loadingBar.useRef('packageLoad')
      packageLoader.start()
      packageLoader.set(35)

      let url = this.url + '/packages'
       this.api.getData(url, this.page, 'packages').subscribe(
         
        data=>{
          packageLoader.set(75)
          packageLoader.complete()
          console.log(data)
          if('status' in data){
            if(data['status'] == 'success'){
              this.packages = data['message']
            }
            this.packagesOG = Object.assign([], this.packages)
          }    
        },
        err=>{
          console.log(err)
          packageLoader.set(75)
         packageLoader.complete()
        }
      )
  }


  loadPackage(package_obj){
    this.packageService.package = package_obj;
    this.router.navigateByUrl('/packages/update/' + package_obj.package_name);
  }

  newPackage(){
    console.log('navigating to new packages page')
    this.router.navigateByUrl('/packages/new');
  }

  variableTemplate(){
    this.router.navigateByUrl('/packages/global_configurations');
  }

  onSearchChange(searchValue : string ) {  
      if (searchValue != ''){
        this.packages = this.searchPackages(searchValue);
      }
      else{
        this.clear()
      }
  }
  searchPackages(searchValue){
    let packageIds = []
    let newPackages = []

    for(let i = 0;i < this.packages.length;i++){
      let item = this.packages[i]
      
      for(let property in item){
        if(item[property] !== null && property in this.packageFields){
          let val = item[property].toLowerCase()
           if (item[property].toLowerCase().includes(searchValue.toLowerCase())){
               if(!(packageIds.includes(item.id))){
                 packageIds.push(item.id)
                 newPackages.push(item)
               }
           }
        }
      }
    }
      
    return newPackages;

  }
  clear(){
      this.packages = Object.assign([],this.packagesOG);
      this.search = '';
  }
 

}
