<div class="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style="background-image: url('assets/img/Aurora_Gradient_Landscape.jpg');">
  <div class="ui-bg-overlay bg-dark opacity-25"></div>
  <div class="py-5" >
    <div class="card" style="width:1200px; border: none;">
      <div class="row h-100">
        <div class="col-lg-6 ui-bg-cover ui-bg-overlay-container p-5" style="background-color: black;border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;">
          <div class="ui-bg-overlay bg-dark opacity-50" style="border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;"></div>
          <div style="height:33%">
               <img width="200px" height="52px" src="./assets/img/carve_logo_white.png">
          </div>
          <div class="w-100 text-white" style="height:33%">
            <div class="text-large font-weight-light" style="padding-right: 75px; font-family: Roboto !important;">
                <h2>Data Experience</h2>
                <p>
                  The Carve data experience platform provides visibility, insights, and action on the data in your Snowflake data warehouse.
                </p>
            </div>
          </div>
          <div style="height:33%; text-align:center;" class="d-flex justify-content-center align-items-end">
            <div>
               <img width="250px" height="89px" src="./assets/img/carve+snowflake.png">
             </div>
          </div>
        </div>
        <div class="theme-bg-white d-flex col-lg-6 align-items-center p-5" style="height: 740px;border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;">
          <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 mx-auto" style="max-width: 500px;">
            <div *ngIf="!reset.success && !reset.complete && !verify.hasError && !loading" style="margin: 0 auto; width: 400px;">
               <div class="text-center text-darker font-weight-normal mt-5 mb-5" style="color:black; font-size: 19pt;">
                    <span><i class="fal fa-user-unlock"></i></span>
                    <span style="padding-left: 5px;">
                      Reset your Password
                    </span>
                  </div>
              <h5  [ngClass]="{'text-center text-muted font-weight-normal mb-4 error': reset.hasError, 'text-center text-muted font-weight-normal mb-4': !reset.hasError }">{{reset.errorMsg}}</h5>
              <form [formGroup]="resetForm" (keydown.enter)="resetPassword()" class="mt-4" >
                <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">
                    <span style="padding-right: 15px;">New Password 
                      <i 
                        class="fal fa-info-circle" 
                        style="cursor:pointer;"
                        [ngbPopover]="passwordInfo" 
                        container="body" 
                        popoverClass="download-popover" 
                        [autoClose]="'outside'"
                        placement="top"
                      >
                        </i>
                    </span>
                    <span class="text-danger"
                      *ngIf="(resetFormControl.password.touched || submitted) && resetFormControl.password.errors?.required">
                      Required 
                  </span>
                  <span class="text-danger"
                      *ngIf="(resetFormControl.password.touched || submitted) && resetFormControl.password.errors?.passwordStrength">
                     Password requirements not met
                  </span>
                  </label>
                  <div class="input-group">
                    <input formControlName="password" id="defaultPassword" name="password" [type]="fieldTextType ? 'text' : 'password'" class="form-control" style="border: 1px solid #0a0d10;">
                    <div class="input-group-append" style="border: 1px solid #0a0d10;border-top-right-radius: 5px;border-bottom-right-radius: 5px; border-left:none;">
                      <span class="input-group-text" style="cursor:pointer; width:50px">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
               <div class="form-group">
                  <label class="form-label d-flex justify-content-between align-items-end">
                    <span style="padding-right: 15px;">Confirm Password</span>
                    <span class="text-danger"
                      *ngIf="(resetFormControl.confirm_password.touched || submitted) && resetFormControl.confirm_password.errors?.required">
                      Required 
                  </span>
                  <span class="text-danger"
                      *ngIf="(resetFormControl.confirm_password.touched || submitted) && resetFormControl.confirm_password.errors?.mismatch">
                     Passwords do not match
                  </span>
                  </label>
                  <div class="input-group">
                    <input formControlName="confirm_password" id="default_confirm_password" name="confirm_password" [type]="fieldTextType ? 'text' : 'password'" class="form-control" style="border: 1px solid #0a0d10;">
                    <div class="input-group-append" style="border: 1px solid #0a0d10;border-top-right-radius: 5px;border-bottom-right-radius: 5px; border-left:none;">
                      <span class="input-group-text" style="cursor:pointer; width:50px">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn btn-primary btn-block mt-5" (click)="resetPassword()">
                  <div *ngIf="reset.submitting" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                    <span  class="sr-only">Loading...</span>
                  </div>
                  Reset Password
                </button>
              </form>
             <ng-template #passwordInfo>
                <div style="padding:10px 20px; font-size: 11pt;">
                  <div style="font-weight:bold; padding-bottom: 10px;">Password Requirements</div>            
                  <div >
                   <ul style="padding-inline-start: 15px;">
                   	 <li> Different than last password </li>
                     <li> At least 8 characters</li>
                     <li> At least 1 number</li>
                     <li> At least 1 uppercase letter</li>
                     <li> At least 1 special character</li>
                   </ul>
                  </div> 
                </div>
             </ng-template>
            </div>
          
            <div *ngIf="reset.success && reset.complete && !reset.hasError && !loading && !verify.resent" style="width: 375px; margin: auto;">
               <h2 class="text-center mb-4">Thanks, {{reset.name}}!</h2>
               <div class="display-1 lnr lnr-checkmark-circle text-center text-success mb-4"></div>
                <p class="text-center text-big mb-4">Your password has been reset.</p>
                <div>
                  <div style="height: 200px; font-size: 12pt;">
                    <a  href="login"><button type="button" class="btn btn-primary btn-block" style="font-size: 12pt;">Back to Login</button></a>
                  </div>
                </div>
	        </div>
	        <div  *ngIf="verify.resent" style="width: 500px; margin: auto;">
              <div>
	        	    <span style="color: green; padding-right: 10px;"><i class="fal fa-check-circle fa-2x"></i></span>
                <span style="color:black; font-size: 22pt;">Another reset email sent!</span>
              </div>
                <div class="email-sent">
                    <div>
                      <span>We sent an email to</span>
                      <span style="font-weight:bold;">{{toEmail}}</span>
                    </div>
                    <div style="padding-top:40px;">
                      Click the <span style="font-weight: bold;">"Reset Password"</span> button in the email to finish resetting your password.
                    </div>
                    <div style="padding-top:40px;">
                      If you can't find the email, you may need to <span style="font-weight:bold;">check your spam</span> folder. 
                    </div>
                </div>
	        </div>
            <div style="padding-top: 40px; font-size: 16pt; max-width: 400px;" class="mx-auto" *ngIf="verify.hasError && !verify.resent">

                <div>
                  Looks like your reset token is invalid or has expired.
                </div>
                <div style="font-size: 40pt;text-align: center;">😕</div>
                <div style="padding-top: 10px; font-size: 16pt;">
                 Click the Resend Email button below for a fresh token. 
               </div>
              
              <div style="padding-top:40px; width: 100%; text-align: center;"> 
                <button class="btn btn-primary btn-block mt-4" (click)="resendEmail()">
                  <div *ngIf="verify.submitting" class="spinner-border spinner-border-sm" role="status" style="margin: auto; color: white !important; margin-right: 15px;">
                    <span  class="sr-only">Loading...</span>
                  </div>
                  Resend Email
                </button>
              </div>
              <div style="padding-top:40px; text-align: center; width: 100%; font-size: 11pt;"><a href="https://carvedata.io">Contact us</a> for additional help
              </div>
          </div>
          <div style="margin:auto;" *ngIf="loading">
            <img height="75px" width="75px" src="../../../assets/img/snowguide_loading_main.gif">
          </div>
	      </div>
	      
      </div> 
    </div>
    </div>
  </div>
</div>

