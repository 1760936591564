
<a #toggleLink href="#" class="sidenav-link sidenav-toggle" [ngClass]="linkClass" *ngIf="!route">
  <i class="sidenav-icon" *ngIf="icon" [ngClass]="icon"></i>
  <div [innerHTML]="text"></div>
  <div *ngIf="badge" class="ml-auto pl-1"><div class="badge" [ngClass]="badgeClass">{{badge}}</div></div>
</a>
<a #toggleLink *ngIf="route"
  [routerLink]="route"
  [queryParams]="queryParams"
  [fragment]="fragment"
  [queryParamsHandling]="queryParamsHandling"
  [preserveFragment]="preserveFragment"
  [skipLocationChange]="skipLocationChange"
  [replaceUrl]="replaceUrl"
  class="sidenav-link sidenav-toggle"
  [ngClass]="linkClass">
  <i class="sidenav-icon" *ngIf="icon" [ngClass]="icon"></i>
  <div [innerHTML]="text"></div>
  <div *ngIf="badge" class="ml-auto pl-1"><div class="badge" [ngClass]="badgeClass">{{badge}}</div></div>
</a>
<div class="sidenav-menu">
  <ng-content></ng-content>
</div>
  